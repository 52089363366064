import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { useNavigate } from "react-router-dom";
import { DetailObject, ImageTemp, InitialPallets, SingleReportResponse } from '../interfaces/intakes.reports';
import { ActionNum, DetailName, GradeNum, ScoreNum } from "../interfaces/interfaces";
// import { sleep } from "../helpers/sleep";

interface Keys {
    keyName: string
    keyLow: string
}

export interface ImagesToRemove extends Keys {
    reportId: string
    palletId: string
    detailName: DetailName
}

export interface FetchPalletSection {
    reportId: string
    palletId: string
    detailName: DetailName
    palletData: DetailObject[]
    palletMinor?: DetailObject[]
    palletMajor?: DetailObject[]
}

export interface FetchImageSection {
    reportId: string
    palletId: string
    detailName: DetailName
    imagesTemp: ImageTemp[]
}

export interface FinishPallet {
    reportId: string
    palletId: string
    score: ScoreNum
    action: ActionNum
    grade: GradeNum
    comments: string
}

export interface NewPallet {
    reportId: string
    newPallet: InitialPallets
}
export interface NewSplitPallet extends NewPallet {
    palletId: string
    palletIndex: number
}

export interface RemovePallet {
    reportId: string
    palletId: string
}
export interface Finish {
    reportId: string
    comments: string
}


const getTempReport = async (id: string) => {
    const { data } = await qcareApi.get<SingleReportResponse>(`/report/${id}`);
    return data
};

const addPalletSection = async ({ reportId, ...fetchData }: FetchPalletSection) => {
    const { data } = await qcareApi.post(`/report/add-pallet-section/${reportId}`, fetchData)
    return data
};

const addImageSection = async ({ reportId, ...fetchData }: FetchImageSection) => {

    if (fetchData.imagesTemp.length === 0) return

    const formData = new FormData();

    for (const img of fetchData.imagesTemp) {
        formData.append('uploady', img.file)
    }
    formData.append('palletId', fetchData.palletId)
    formData.append('detailName', fetchData.detailName)
    const { data } = await qcareApi.post(`/report/add-images-section/${reportId}`, formData)
    return data
};

const removeSectionImage = async (removeImage: ImagesToRemove) => {
    const { data } = await qcareApi.post('report/remove-image-section', removeImage)
    return data
};

const finishPallet = async ({ reportId, ...fetchData }: FinishPallet) => {
    const { data } = await qcareApi.post(`/report/finish-pallet/${reportId}`, fetchData)
    return data
};

const addNewPallet = async ({ reportId, newPallet }:NewPallet) => {
    const { data } = await qcareApi.post(`report/add-new-pallet/${reportId}`, { newPallet })
    return data
};

const splitPallet = async ({ reportId, ...fetchData }:NewSplitPallet) => {
    const { data } = await qcareApi.post(`report/add-split-pallet/${reportId}`, fetchData)
    return data
};

const removePallet = async ({ palletId, reportId }: RemovePallet) => {
    const { data } = await qcareApi.post(`report/remove-pallet/${reportId}`, { palletId })
    return data
};

const finishReport = async ({reportId, comments}:Finish) => {
    const { data } = await qcareApi.post(`report/finish-report/${reportId}`, {comments})
    return data
};

//------------------------- HOOKS --------------------------------

export const useTempReport = (id: string) => {
    return useQuery(
        ['reportTemp', id],
        () => getTempReport(id),
        { 
            staleTime: 1000 * 60 * 10,
            select: (data) => { return data.singleReport }
        }
    );
}

export const useFetchPalletSection = () => {
    return useMutation(addPalletSection )
}

export const useFetchImagesSection = () => {
    return useMutation(addImageSection)
}

export const useFinishPallet = () => {
    return useMutation( finishPallet )
}

export const useRemoveSectionImage = () => {
    return useMutation(removeSectionImage)
}

export const useAddNewPallet = () => {
    return useMutation(addNewPallet)
}

export const useSplitPallet = () => {
    const queryClient = useQueryClient()
    return useMutation(splitPallet, {
        onSuccess: async (data) => {
            await queryClient.invalidateQueries(['reportTemp', data.reportId])
        }
    })
}

export const useRemovePallet = () => {
    return useMutation( removePallet )
}

export const useFinishReport = () => {
    return useMutation( finishReport )
}