import { useOldDate } from "../../hooks/useOldDate"
import { ScoreNum } from "../../interfaces/interfaces"

interface Props {
  date?: Date,
  score: ScoreNum
}

export const ScoreBtn = ({ date=new Date(), score }: Props) => {

  const { oldDate, scoreNumber } = useOldDate(date, score)

  return (
    <div className={`score score-${scoreNumber}`}>
      {
        score === "0"
        ? <p>No Score</p>
        : <p>{ score } of { oldDate ? "5" : "8" }</p>
      }
    </div>
  )
}
