import { useSelector } from 'react-redux';

export const SelectTeam = ({ toggle, handleToggle, title="All Reports" }) => {

    const { teams } = useSelector(state => state.auth);

    return (
        <>
            {
                teams.length > 0 &&

                <div className='mb-1'>
                    <select className='intake-select' onChange={handleToggle} value={toggle} name='team-selector'>
                        <option value="">{title}</option>
                        {
                            teams.map(btn => (
                                <option
                                    key={btn._id}
                                    value={btn._id}>{btn.name}</option>
                            ))
                        }
                    </select>

                </div>
            }
        </>
    )
}
