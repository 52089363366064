
import { DetailObject, InitialPallets } from '../../interfaces/intakes.reports'
import { v4 as uuidv4 } from 'uuid';

interface Props {
  palletId: string
  item: DetailObject
  max?: number
  withButton?: boolean
  colorButton?: "green" | "blue" | "red" | "orange"
  isDefect: "minor" | "major"
  setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
  handleCheckDefect: (name: string, check: boolean, defect: "major" | "minor") => void
}

export const InputsPallgrow = ({ palletId, item, max = 10000, setPallets, handleCheckDefect, withButton = false, isDefect, colorButton = "blue" }: Props) => {

  const inputId = uuidv4() + item.name
  const handleChange = (value: string, action?: "add" | "rest") => {

    if (isNaN(Number(value))) return

    const newValue: string =
      action
        ? (action === "add"
          ? (Number(value) + 1).toString()
          : (Number(value) > 0 ? (Number(value) - 1).toString() : value))
        : value

    if (+newValue > max) return

    const regex: RegExp = /^[0-9]+(\.[0-9]{1,2})?$/;
    if (!regex.test(newValue)) return

    if (isDefect) {
      if (+(newValue) > 0) {
        handleCheckDefect(item.name, true, isDefect)
      } else if (+value === 0) {
        handleCheckDefect(item.name, false, isDefect)
      } else { handleCheckDefect(item.name, false, isDefect) }
    }

    setPallets(pallets => pallets.map(pallet => {
      if (pallet.pid === palletId) {

        return {
          ...pallet,
          pallgrow: {
            ...pallet.pallgrow,
            [isDefect]: pallet.pallgrow[isDefect]?.map(inp => {
              if (inp.name === item.name) {
                return {
                  ...inp,
                  value: newValue
                }
              } return inp
            })
          }
        }
      }
      return pallet
    }))
  };


  return (
    <div className='input-pallet'>

      <div className="label-name">
        <input
          id={inputId}
          className='check-on-off'
          type="checkbox"
          checked={item.check}
          onChange={() => handleCheckDefect(item.name, !item.check, isDefect)}
        />
        <label htmlFor={inputId}>{item.label}</label>
      </div>


      <div className="input-type flex">
        <button
          onClick={() => handleChange(item.value as string, "rest")}
          className={`btn-add-rest h-100 ${colorButton}`}>
          -
        </button>


        <input
          name={item.name}
          className={`${withButton ? 'text-center mx-02' : ''}`}
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.currentTarget.value)}
          value={item.value as string}
        />


        <button
          onClick={() => handleChange(item.value as string, "add")}
          className={`btn-add-rest h-100 ${colorButton}`}>
          +
        </button>

      </div>
    </div>
  )
}

