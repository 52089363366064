import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom'
import { dateFormat } from '../helpers/dateFormat'
import { ActiveBtn } from './ui/ActiveBtn'
import { LifeTest } from '../interfaces/interface.lifeTest';

interface Props{
    life: LifeTest
}

export const CardLifeTest = ({ life }:Props) => {

    return (
        <Link to={`/life-test/${life._id}`}>
            <div className="life-item border-b">

                <ActiveBtn status={life.status} test={life.test}/>

                <div className="life-title ml-1">
                    <h4>{life.reportId?.mainData?.pallet_ref || life.palletRef ||"--"}</h4>
                    <div className='flex life-title__data'>
                        <p className="text-muted">{dateFormat(life.date) || "--"}</p>
                        <p className="text-muted">{life.grower || life.reportId?.mainData?.grower ||'--'}</p>
                    </div>
                </div>
                <div className='life-days'>
                    {
                        life.test.length > 0
                            ?
                            life.test.map(() => (
                                <div key={uuidv4()} className="day-on"></div>
                            ))
                            :
                            <div className="day-off"></div>
                    }
                </div>

            </div>
        </Link>
    )
}
