import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Modal } from "../modals/Modal";
import { CardLifeTest } from "../CardLifeTest";
import { Report } from "../../interfaces/intakes.reports";
import { useCreateLifeTest } from "../../api/useFetch";
import { ScoreNum } from "../../interfaces/interfaces";
import { v4 as uuidv4 } from 'uuid';
import { Charging } from "./Charging";

interface Props {
    data: Report
}

interface CreateLife {
    id: string
    check: boolean
    reportId: string
    grower: string
    score: ScoreNum
    palletRef: string
    team: string | null
}

export const LifeButton = ({ data }: Props) => {

    const { mutate, isLoading } = useCreateLifeTest()
    const [activeModal, setActiveModal] = useState<boolean>(false)
    const [createLifetest, setCreateLifetest] = useState<CreateLife[]>([])

    const navigate = useNavigate()

    const goToLifeTest = () => {
        data.lifetest?.length === 1
            ? navigate(`/life-test/${data.lifetest[0]._id}`)
            : setActiveModal(true)
    };

    useEffect(() => {
        const areGrowers = data.pallets.filter(pallet => pallet.addGrower)
        const toSend = (grower: string | undefined, score: ScoreNum) => ({
            id: uuidv4(),
            check: true,
            reportId: data._id,
            grower: grower || data.mainData.grower || "--",
            score: score || '--',
            palletRef: data.palletRef || data.mainData?.pallet_ref || "--",
            team: data.team
        })
        setCreateLifetest(areGrowers.length > 0
            ? areGrowers.map(pallet => (toSend(pallet.addGrower?.grower_variety, pallet.score)))
            : [toSend(data.mainData.grower, data.score)])
    }, [data])


    const createLife = () => {
        const selectedItems = createLifetest.filter(pallet => pallet.check).map( item => { const { check, id, ...rest} = item; return rest})
        mutate(selectedItems, {
            onSuccess: async() => {
                setActiveModal(false)
            }
        })
    };

    return (

        <>
            {
                (activeModal && data.lifetest) &&
                <Modal closeModal={() => setActiveModal(false)} >
                    <div className="lifetest">
                        <div className="lifetest__list">
                            <div className="life-item py-02 mb-05 bg-grey border-round">
                                <p className='life-status font-small bold pl-05'>Status</p>
                                <p className='life-title ml-1 font-small bold'>Report Info</p>
                                <p className='life-days font-small bold'>Days</p>
                            </div>

                            {
                                data.lifetest?.map(life => (
                                    <CardLifeTest key={life._id} life={life} />
                                ))
                            }
                        </div>
                    </div>
                </Modal>
            }
            {
                (activeModal && !data.lifetest) &&
                <Modal closeModal={() => setActiveModal(false)} action={() => createLife()} buttonName="Create" isLoading={isLoading} disabled={createLifetest.every(pallet => !pallet.check) || isLoading}>
                    {
                        isLoading
                        ?
                        <Charging />
                        :<>
                            <h3 className="pb-1 border-b">{createLifetest.length > 1 ? 'Select item to test' : 'Create Life Test'}</h3>
                            {
                                createLifetest.map(life => (
                                    <div className="flex-top border-b py-05" key={life.id}>
    
                                        <input
                                            disabled={createLifetest.length === 1}
                                            type="checkbox"
                                            checked={life.check}
                                            className="mr-05 mt-02"
                                            onChange={() => setCreateLifetest(createLifetest.map(lifeTest => lifeTest.id === life.id ? { ...lifeTest, check: !lifeTest.check } : lifeTest))}
                                        />
    
                                        <div>
                                            <h4>{life.palletRef}</h4>
                                            <p className="text-muted">{life.grower}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    }
                </Modal>
            }

            <button
                className={`btn-lifetest ${data.lifetest ? "deactive" : "active"} flex mb-1`}
                onClick={() => data.lifetest
                    ? goToLifeTest()
                    : setActiveModal(true)
                }
            >
                <img
                    src={`/assets/img/${data.lifetest ? 'nav-life-test-done.svg' : 'nav-life-test-w.svg'}`}
                    alt="pdf-data" width={15}
                    className="mr-05" />
                <p>{data.lifetest ? "Check Life Test" : "Create Life Test"}</p>
            </button>
        </>
    )
}
