import { ChangeEvent, useState } from "react"

const useForm = <T extends Object>( initialState:T ) => {

const [values, setValues] = useState<T>(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleEmpty = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setValues({
        ...values,
        [e.target.name]: 1,
      });
    }
  };

  return { values, handleInputChange, handleEmpty, reset };
}

export default useForm