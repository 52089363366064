import { MainData } from "../interfaces/intakes.reports";
import { dateFormat } from "./dateFormat"

export function mainDataValue(label:string, value:string) {

    const dates: Record<string, string> = {
        "arrival_date": "Arrival Date",
        "loading_date": "Loading Date",
        "departure_date": "Departure Date",
    }

     return (
         value
         ?  dates[label] ? dateFormat(value) : value
         :  "--" 
     )

}


export const mainDataWithoutSupplier = (mainData:MainData|null) => {
    if (!mainData) return null
    const {supplier, ...rest} = mainData
    return rest
};