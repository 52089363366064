import { useQuery } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { SingleReportResponse } from '../interfaces/intakes.reports';



const getSingleReport = async (id: string) => {
    const { data } = await qcareApi.get<SingleReportResponse>(`/report/${id}`);
    return data
};

// ------------------------- HOOKS -------------------------

//OK
export const useReport = (id: string) => {
    return useQuery(
        ['report', id],
        () => getSingleReport(id),
        { 
            select: (data) => { return data.singleReport }
        }
    );
}

