import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { IoCloudUploadOutline } from "react-icons/io5"
import { useTempImages } from "../../hooks/useTempImages"
import toast from "react-hot-toast"
import { ImageTemp, MainData } from "../../interfaces/intakes.reports"
import { SpinnerCustom } from "../ui/SpinnerCustom"
import { ImageSelected } from "../ui/ImageSelected"
import { ModalBtn } from "./ModalBtn"
import Combobox from "../pages/Inputs/Combobox"
import { SupplierList } from "../../interfaces/interfaces.auth"
import { ComboboxOption } from "../../interfaces/interfaces"
import { sortOptionsByLabel } from "../../helpers/sortContacts"

interface Props {
    closeModal: () => void
    supplierList: SupplierList
    setMainData: Dispatch<SetStateAction<MainData>>
    supplierId: string | null
    setSupplier: Dispatch<SetStateAction<string | null>>
}

export const ModalSelectSupplier = ({ closeModal, supplierList, supplierId, setSupplier, setMainData }: Props) => {

    const [selected, setSelected] = useState<ComboboxOption | null>(null)

    const handleCombobox = () => {
        if (!selected) return toast.error("Select a supplier")
        setSupplier(selected.value)
        setMainData(mainData => ({ ...mainData, supplier: selected.label }))
    closeModal()
    };

    useEffect(() => {
        const value = supplierList.suppliers.find(supp => supp._id === supplierId)
        if (value) setSelected({ value: value._id, label: value.name })
        else setSelected(null)
    }, [supplierId])

    return (
        <div className="modal">

            <div className="modal__card no-overflow">

                <div>

                    <Combobox
                        options={sortOptionsByLabel(supplierList.suppliers.map(supp => ({ value: supp._id, label: supp.name })) || [])}
                        value={selected}
                        onChange={(option: ComboboxOption) => setSelected(option)}
                        defaultValue="Select a Supplier"
                    />

                    <ModalBtn
                        closeModal={closeModal}
                        action={handleCombobox}
                    />
                </div>
            </div>
        </div>
    )
}
