import { ActiveBtn } from './ActiveBtn'
import { useUpdateStatus } from '../../api/useLifeTest'
import { useQueryClient } from '@tanstack/react-query'

export const Status = ({id, status, test}) => {

    const queryClient = useQueryClient()
    const { mutate, isLoading: isStatus } = useUpdateStatus()

    const handleStatus = () => {
        mutate(id, {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['lifetest', id])
            }
        })
    };

    return (
        <button
            disabled={isStatus}
            onClick={handleStatus}
        >
            <ActiveBtn status={status} test={test} />
        </button>
    )
}
