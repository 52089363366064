import { useState } from "react"
import { IoCloudUploadOutline } from "react-icons/io5"
import { useTempImages } from "../../hooks/useTempImages"
import toast from "react-hot-toast"
import { ImageTemp } from "../../interfaces/intakes.reports"
import { SpinnerCustom } from "../ui/SpinnerCustom"
import { ImageSelected } from "../ui/ImageSelected"
import { ModalBtn } from "./ModalBtn"

interface Props {
    closeModal: () => void
    limit?: number
    handleImages: (images: ImageTemp[]) => Promise<void>
    isLoading?: boolean
}

export const ModalImages = ({ closeModal, limit = 10, handleImages, isLoading }: Props) => {

    const [images, setImages] = useState<ImageTemp[]>([])
    const { addImages, isLoading: isAddingTemp } = useTempImages()

    const addTempFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {

        const files = e.currentTarget.files;

        if (files && files.length > 0) {
            if (( images.length + (files).length) > limit) {
                return toast.error(limit === 0 ? `You upload all the permitted images` : `You have ${limit - images.length} images left to upload`)
            }
            const data = await addImages(files)
            setImages( images => [ ...images, ...data ])
        }
    };

    const removeTemp = (id: string) => {
        setImages(images => images.filter(img => img.id !== id))
    };

    return (
        <div className="modal">

            <div className="modal__card">

                <div className="modal__content">

                    {
                        (images.length > 0 || isAddingTemp) &&
                        <div className="mt-1 mb-2">
                            <ImageSelected images={images || []} removeTempFiles={removeTemp} />
                            {isAddingTemp && <SpinnerCustom center size="s" color="green" />}
                        </div>
                    }

                    <label htmlFor="newFiles" className="upload-label mb-1 mt-1">
                        {/* <IoCameraOutline fontSize={20} /> */}
                        <IoCloudUploadOutline fontSize={20}/>

                        <span>Choose Images</span>
                    </label>

                    <input
                        id="newFiles"
                        className="upload-btn"
                        accept=".jpg, .png, .jpeg"
                        multiple
                        name="file"
                        type="file"
                        onChange={addTempFiles}
                    />

                    <ModalBtn
                        closeModal={closeModal}
                        action={async () => {
                            await handleImages(images)
                            closeModal()
                        }}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    )
}
