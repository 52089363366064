import { useSelector } from 'react-redux'

const TeamSelector = ({ setTeam, team }) => {

    const { teams } = useSelector(state => state.auth)

    return (
        <>
            {
                teams.length > 0 &&
                <div className="grid intake__input">
                    <label className="span-5 ">Assign to a Team</label>

                    <div className="span-7 select-input">
                        <select
                            onChange={(e) => setTeam(e.target.value === "" ? null : e.target.value)}
                            value={team === null ? "" : team}
                        >
                            <option value="">No team</option>
                            {
                                teams.map(btn => (
                                    <option
                                        key={btn._id}
                                        value={btn._id}>{btn.name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            }
        </>
    )
}

export default TeamSelector