import { ImageTemp, InitialPallets } from '../interfaces/intakes.reports';
import { IoCameraOutline } from 'react-icons/io5';
import { useImageToPallet } from '../api/useFetchImages';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { ModalImages } from './modals/ModalImages';

interface Props {
    reportId: string
    setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
    pallet: InitialPallets
}

export const AddImages = ({ pallet, setPallets, reportId }: Props) => {

    const { mutateAsync, isLoading } = useImageToPallet()
    const [openImagesModal, setOpenImagesModal] = useState<boolean>(false)


    const handleImages = async (images: ImageTemp[]) => {
        await mutateAsync({
            reportId,
            palletId: pallet.pid,
            images
        }, {
            onSuccess: async (data) => {
                setPallets(pallets => pallets.map(pall => {
                    if (pall.pid === pallet.pid) {
                        return {
                            ...pall,
                            images: [ ...pall.images, ...data.images]
                        }
                    } return pall
                }))
            },
            onError: () => toast.error('Something went wrong, try later')
        })
    };

    return (
        <>
            {
                openImagesModal &&
                <ModalImages
                    closeModal={() => setOpenImagesModal(false)}
                    handleImages={handleImages}
                    isLoading={isLoading}
                    limit={10 - (pallet.images.length || 0)}
                />
            }

            <button className="upload-label mt-1" onClick={() => setOpenImagesModal(true)}>
                <IoCameraOutline fontSize={22} />
                <span>Additional Images</span>
            </button>
        </>
    )
}
