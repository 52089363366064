import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import qcareApi from '../../api/qcareApi'
import { PDFScreen } from './PDFScreen';


export const ViewPdf = () => {

    const [allData, setAllData] = useState(null)
    const { id } = useParams()

    useEffect(() => {
        async function fetchData() {
            const { data } = await qcareApi.get(`/report/${id}`)
            setAllData([data.singleReport, true])
        }
        fetchData()
    }, [id])

    return (
        <>
            {
                (allData) &&
                <PDFScreen allData={allData}/>
            }
        </>

    );
}
