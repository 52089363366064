import { useQuery } from "@tanstack/react-query";
import qcareApi from "./qcareApi";


const getSingleIntake = async (id) => {
    const { data } = await qcareApi.get(`/prereport/new-report/${id}`);
    return data
};

const getSinglePrereport = async (id) => {
    const { data } = await qcareApi.get(`/report/new-report/${id}`);
    return data
};



// ------------------------- HOOKS -------------------------

//OK
export const useIntake = (id, create) => {

    return useQuery(
        ['intakeData', id],
        () =>
            create === ("intake" || "prereport")
                ? getSingleIntake(id)
                : getSinglePrereport(id),
        { select: (data) => { return data.intakeReport } }
    )
}

