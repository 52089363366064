import { useState, ChangeEvent } from 'react'
import { v4 as uuidv4 } from 'uuid';

import { CardLifeTest } from '../CardLifeTest'
import { Charging } from '../ui/Charging'
import { Pagination } from '../ui/Pagination'
import { useLifeTests } from '../../api/useLifeTests';
import { SelectTeam } from '../ui/SelectTeam';
import { selectValue } from '../../helpers/eliminarEs';
import { FilterWindow } from '../ui/FilterWindow';

export const LifeTestPage = () => {

  const [page, setPage] = useState(1)
  const [toggle, setToggle] = useState<string | 0>(0)

  const { data, isLoading, isFetching } = useLifeTests(page, toggle)

  // -------------------------------- React Query --------------------------------

  const handleToggle = (e:ChangeEvent<HTMLSelectElement>) => {
    setToggle(selectValue(e.target.value))
    setPage(1)
};

  return (
    <div className="content">

      {
        isLoading || isFetching
          ? <Charging />
          :
          <main className="container lifetest">
            <div className='mb-2 flex-space-between'>
              <h2 className="main-title">Shelf Life Test</h2>

              <FilterWindow isSearchPage={false}/>

            </div>

            <SelectTeam
              toggle={toggle}
              handleToggle={handleToggle}
              title='All Life Tests'
            />

            {
              data && data?.allLifeTest.length === 0
                ? <p className="text-center">No Life Tests</p>
                :
                <div>
                  <div className="lifetest__list">
                    <div className="life-item py-02 mb-05 bg-grey border-round">
                      <p className='life-status font-small bold pl-05'>Status</p>
                      <p className='life-title ml-1 font-small bold'>Report Info</p>
                      <p className='life-days font-small bold'>Days</p>
                    </div>
                    {
                      data?.allLifeTest.map( life => 
                        (
                        <CardLifeTest
                          key={uuidv4()}
                          life={life}
                        />
                      ))
                    }
                  </div>

                  {
                    data?.totalPages && data?.totalPages > 1 &&
                    <Pagination
                      totalPages={data?.totalPages}
                      page={page}
                      setPage={setPage}
                    />
                  }
                </div>

            }
          </main>
      }

    </div>
  )
}
