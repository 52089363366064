import { headerToJSON } from "./eliminarEs";


function objToArray(objeto) {
    const restArray = Object.entries(objeto)
    return restArray
}

export default objToArray


export const objectToCheckArray = ( object ) => {

    const array = [];
    const exclude = [ "supplier", "grower", "gln_ggn", "samples", "pallet_ref", "unit_label" ]

    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        array.push({check: exclude.includes(key) ? false : true, key, value: object[key]});
      }
    }
    return array;
  };

  export const objectToArray = ( object ) => {
    const array = [];
    for (const key in object) {
      if( !object[key] ) continue;
      if( object.hasOwnProperty(key) ) {
        array.push({ key, value: object[key] || "" });
      }
    }
    return array;
  };
