import { useState } from 'react'
import toast from 'react-hot-toast';

import { useEditScore } from '../../api/useEditScore';
import { Charging } from './Charging';
import { scoreComments } from '../../data/comments';

export const ModalEditScore = ({ oldDate, reportId, pid, setOpenModalScore, score, grade, action, item = "score", prereport = false, isReportPrereport = false }) => {

    const [newScore, setNewScore] = useState(score)
    const [newGrade, setNewGrade] = useState(grade)
    const [newAction, setNewAction] = useState(action)

    const { mutateAsync, isLoading } = useEditScore()

    const handleSelect = (e, item) => {
        item === "score" && setNewScore(e.target.value)
        item === "grade" && setNewGrade(e.target.value)
        item === "action" && setNewAction(e.target.value)
    }

    const closeModal = () => {
        setOpenModalScore(false)
    }

    const sendEditItem = async () => {

        let newValue = item === "score" ? newScore
            : item === "grade" ? newGrade
                : item === "action" ? newAction
                    : null

        if ((item === "score" && newScore === score)
            || (item === "grade" && newGrade === grade)
            || (item === "action" && newAction === action)
        ) return setOpenModalScore(false)

        let dataBody = {
            reportId,
            palletId: pid,
        }

        if (prereport && !isReportPrereport) {
            dataBody.item = item;
            dataBody.value = newValue
        }

        if (!prereport && !isReportPrereport) { 
            dataBody.score = newValue;
            if(oldDate){
                dataBody.comments = scoreComments(newValue);
            }
        }

        if (!prereport && isReportPrereport) {
            dataBody.item = item;
            dataBody.value = newValue
        }

        await mutateAsync({ dataBody, prereport, isReportPrereport }, {
            onSuccess: () => { toast.success('Edited successfully'); setOpenModalScore(false) },
            onError: () => toast.error('Something went wrong'),
        })
    };

    return (
        <div className="modal">

            <div className="modal__card">
                {
                    isLoading
                        ? <Charging />
                        :
                        <div className="modal__content pallet-comments">

                            {
                                item === 'score' &&
                                <>
                                    <label className="modal-label mb-2">Score</label>

                                    {
                                        oldDate
                                            ?
                                            <select onChange={(e) => handleSelect(e, "score")} value={newScore}>
                                                <option value="0">Select a Score</option>
                                                <option value="1">1 - Bad</option>
                                                <option value="2">2 - Insufficient</option>
                                                <option value="3">3 - Fair Quality</option>
                                                <option value="4">4 - Acceptable Quality</option>
                                                <option value="5">5 - Good Quality</option>
                                            </select>
                                            :
                                            <select onChange={(e) => handleSelect(e, "score")} value={newScore}>
                                                <option value="0">Select a Score</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                            </select>
                                    }
                                </>
                            }
                            {
                                item === 'grade' &&
                                <>
                                    <label className="modal-label mb-2">QC Appreciation</label>

                                    <select onChange={(e) => handleSelect(e, "grade")} value={newGrade}>
                                        <option value="0">Select a Grade</option>
                                        <option value="1">Industry</option>
                                        <option value="2">Borderline CAT 2</option>
                                        <option value="3">CAT 2</option>
                                        <option value="4">Borderline CAT 1</option>
                                        <option value="5">CAT 1</option>
                                        <option value="6">Extra</option>
                                    </select>
                                </>
                            }

                            {
                                item === 'action' &&
                                <>
                                    <label className="modal-label mb-2">Action</label>

                                    <select onChange={(e) => handleSelect(e, "action")} value={newAction}>
                                        <option value="0">Select an Action</option>
                                        <option value="1">Dump</option>
                                        <option value="2">Rejected</option>
                                        <option value="3">Hold</option>
                                        <option value="4">Priority for Departure</option>
                                        <option value="5">Suitable for QC check/REPACK</option>
                                        <option value="6">Suitable for departure</option>
                                        <option value="7">Suitable for Storage</option>
                                    </select>
                                </>

                            }



                            <div className="modal__botones mt-2">
                                <button
                                    disabled={isLoading}
                                    className="modal-btn btn-close mr-05"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                                <button
                                    disabled={isLoading}
                                    className="modal-btn btn-add ml-05"
                                    onClick={sendEditItem}
                                >
                                    Edit
                                </button>
                            </div>
                        </div>
                }
            </div>



        </div>
    )
}
