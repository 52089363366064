import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import useForm from '../../hooks/useForm';
import { useState } from 'react';
import { InitialPallets, NewGrower } from '../../interfaces/intakes.reports';
import { IoAddCircle, IoRemoveCircle } from 'react-icons/io5';
import { useEditSplit } from '../../api/useEditGrower';
import { ModalBtn } from '../modals/ModalBtn';

interface Props {
    closeModal: () => void
    pallet: InitialPallets
    reportId: string
    palletIndex: number
    splitPallet: (palletNumber: number, palletId: string, split: NewGrower, palletIndex: number) => Promise<void>
}

export interface SplitPallet extends NewGrower {
    id: string
}

export const ModalSplit = ({ closeModal, splitPallet, reportId, pallet, palletIndex }: Props) => {

    const { mutateAsync, isLoading } = useEditSplit()
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

    const { values, handleInputChange } = useForm({
        grower_variety: pallet.splitPallet?.grower_variety || "",
        boxes: pallet.splitPallet?.boxes || "",
    })

    const { grower_variety, boxes } = values

    const [morePallets, setMorePallets] = useState<SplitPallet[]>([])

    const sendSplitPallets = () => {

        const someEmpty = morePallets.some(pallet => pallet.grower_variety.length === 0 || pallet.boxes.length === 0)
        if (grower_variety.length === 0 || boxes.length === 0 || someEmpty) return toast.error('All field are required')

        setIsLoadingMore(true)
        mutateAsync({
            reportId,
            palletId: pallet.pid,
            splitPallet: {
                grower_variety,
                boxes
            }
        })
            .then(async () => {

                for (const item of morePallets) {
                    await splitPallet(pallet.pallet, pallet.pid, {
                        grower_variety: item.grower_variety,
                        boxes: item.boxes
                    }, palletIndex = palletIndex + 1)
                }

                closeModal()

            })
            .catch((error) => {
                console.log(error)
                toast.error('Something went wrong, try later')
            })
            .finally(() => setIsLoadingMore(false))
    };


    const add = () => setMorePallets(prev => [...prev, { id: uuidv4(), grower_variety: "", boxes: "", }]);
    const rest = (id: string) => setMorePallets(prev => prev.filter(item => item.id !== id));

    const editSplit = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const { name, value } = e.target;
        setMorePallets(prev => prev.map(item => item.id === id ? { ...item, [name]: value } : item))
    };

    return (
        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">
                    <label className="modal-label mb-2">Split Pallet</label>

                    <div className='flex flex-gap mb-1'>
                        <div className='flex-1'>
                            <p className='text-left font-normal bold mb-02'>Grower/Variety</p>
                            <input
                                className="input100"
                                type='text'
                                value={grower_variety}
                                name="grower_variety"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='flex-1'>
                            <p className='text-left font-normal bold mb-02'>Boxes</p>
                            <input
                                className="input100"
                                type='number'
                                value={boxes}
                                name="boxes"
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    {
                        morePallets.length > 0 &&
                        morePallets.map(split => (
                            <div key={split.id} className='flex flex-gap mb-1'>
                                <div className='flex-1'>
                                    <p className='text-left font-normal mb-02'>Grower/Variety</p>
                                    <input
                                        className="input100"
                                        type='text'
                                        name="grower_variety"
                                        value={split.grower_variety}
                                        onChange={(e) => editSplit(e, split.id)}
                                    />
                                </div>
                                <div className='flex-1'>
                                    <p className='text-left font-normal mb-02'>Boxes</p>
                                    <div className='flex'>
                                        <input
                                            className="input100"
                                            type='number'
                                            name="boxes"
                                            value={split.boxes}
                                            onChange={(e) => editSplit(e, split.id)}
                                        />
                                        <button onClick={() => rest(split.id)} className='ml-05'>
                                            <IoRemoveCircle color="#FF4D4D" size={25} />
                                        </button>
                                    </div>


                                </div>
                            </div>
                        ))
                    }

                    <button onClick={() => add()} className='text-center mt-1'>
                        <IoAddCircle color="#1A73E8" size={30} />
                    </button>

                    <ModalBtn
                        actionTitle='Add Split'
                        closeModal={closeModal}
                        action={sendSplitPallets}
                        isLoading={ isLoading || isLoadingMore}
                    />
                </div>
            </div>



        </div>
    )
}