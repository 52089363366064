import { ImageTemp } from "../../interfaces/intakes.reports"

interface Props{
    images: ImageTemp[]
    removeTempFiles:(id:string) => void
    fullwidth?: boolean
}

export const ImageSelected = ({ images, removeTempFiles, fullwidth=false }:Props) => {

    return (
        <div className={`image-selected-grid ${fullwidth ? "image-grid-full" : null}`}>

            {
                images.length > 0 &&
                images.map((img) => (
                    <div key={img.url}
                        className="selected-container"
                    >
                        <div className="selected-image">
                            <img src={img.url} alt="selected" loading="lazy" />
                        </div>

                        <button
                            className='remove-img-btn'
                            onClick={  () => removeTempFiles(img.id)}
                        >
                            <img src='../assets/img/close-icon.svg' alt='close-icon' />
                        </button>
                    </div>
                ))
            }
        </div>
    )
}


