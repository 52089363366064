import { useQuery } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { AllLifeTestResponse } from "../interfaces/interface.lifeTest";
import { ParsedQuery } from "query-string";


const getFilterReports = async ( page:number, query:ParsedQuery<string> ) => {

    const params = { page, ...query } 
    
    const { data } = await qcareApi.get<AllLifeTestResponse>(`/life-test/filter/search`, { params })
    // const { data } = await qcareApi.get(`/life-test/filter/search?page=${page}${query}`)
    return data
};

// ------------------------- HOOKS -------------------------

export const useFilterLife = ( page:number, query:ParsedQuery<string> ) => {
    const data = useQuery(
        ['filterLife', page, query],
        () => getFilterReports( page, query ),
        { 
            keepPreviousData: true,
            staleTime: 1000 * 60 * 10
        }
    );
    return data
}