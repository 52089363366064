import { ChangeEvent, Dispatch, SetStateAction } from "react";
import MainInput from "../Inputs/MainInput";
import { jsonKeyToString } from "../../../helpers/jsonToString";

interface Props {
    data: Record<string, any>
    setData: Dispatch<SetStateAction<Record<string, any>>>
    className?: string
    type: "pre" | "post"
}

export const RepackPrePost = ({ data, setData, type, className }: Props) => {

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setData((prev: Record<string, any>) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    };

    return (
        <div className={`card-accordeon p-1 ${className}`}>

            <h4 className='mb-2'>{type === "pre" ? "Pre Repack" : "Post Repack"}</h4>

            {
                data &&
                Object.entries(data).map(item => (

                    <div key={item[0]} className="grid intake__input">
                        <label className="span-5 ">{jsonKeyToString(item[0])}</label>
                        <MainInput
                            item={item}
                            handleChange={handleChange}
                        />
                    </div>
                ))
            }

        </div>
    )
}
