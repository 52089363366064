import { InitialRepackPallets } from "../interfaces/intakes.repack";
import { InitialPallets } from "../interfaces/intakes.reports";
import { DetailName } from "../interfaces/interfaces";


export const handleCheck = <T extends InitialPallets | InitialRepackPallets>(
    pallets: T[],
    pid: string,
    detailName: DetailName,
    name: string
  ): T[] => {
    const newPallets = pallets.map((pallet) => {
      if (pallet.pid === pid) {
        if ( detailName in pallet ) {
          return {
            ...pallet,
            [detailName]: {
              ...pallet[detailName],
              data: pallet[detailName]?.data.map((item) => {
                if (item.name === name) {
                  return {
                    ...item,
                    check: !item.check,
                  };
                }
                return item;
              }),
            },
          };
        }
      }
      return pallet;
    });
  
    return newPallets;
  };