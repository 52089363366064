import { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { isEmail } from '../../helpers/isEmail';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useGP } from '../../hooks/useGP';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useSendEmail } from '../../api/useSharePDF';
import EmailFormBtn from './EmailFormBtn';
import { IoSendOutline } from 'react-icons/io5';
import { TempContact } from './ModalSendPDF';

interface Props {
    mailTo: TempContact[]
    // setMailTo: (val: TempContact[]) => void
    ccTo: TempContact[]
    // setCcTo: (val: TempContact[]) => void

    message: string
    setMessage: (val: string) => void

    subject: string
    setSubject: (val: string) => void
    setStep: React.Dispatch<React.SetStateAction<number>>

    link: string
    closeModal: () => void
}

export const MailForm = ({ mailTo:mailToPrev, ccTo:ccToPrev, message, setStep, setMessage, link, closeModal, subject, setSubject }: Props) => {

    const [mailTo, setMailTo] = useState<string[]>([])
    const [ccTo, setCcTo] = useState<string[]>([])

    useEffect(() => {
        setMailTo( mailToPrev.filter( mail => mail.check ).map( mail => mail.email ) )
        setCcTo( ccToPrev.filter( mail => mail.check ).map( mail => mail.email ) )
    }, [mailToPrev, ccToPrev])
    

    const [signature, setSignature] = useState<boolean>(true)

    const { name, lastname, phone, email } = useTypedSelector(state => state.auth)
    const { isGP } = useGP(email)

    const { mutate, isLoading } = useSendEmail()

    // ------------------- TAGS -------------------

    const [theMail, setTheMail] = useState<string>("")
    const [cc, setCc] = useState<string>("")

    const addTags = (val: string, addTo: "mailTo" | "ccTo") => {

        if (!isEmail(val)) { return toast.error('Invalid Email') }

        const existMailTo = mailTo.find(mail => mail === val)
        const existTag = ccTo.find(mail => mail === val)


        if (existMailTo || existTag) return (setCc(""), setTheMail(""))


        if (addTo === "mailTo") {
            setMailTo([...mailTo, val]);
            setTheMail("");
        } else {
            setCcTo([...ccTo, val]);
            setCc("");
        }
    };

    // -------------------

    const removeMailTo = (indexToRemove: number) => {
        setMailTo([...mailTo.filter((_, index) => index !== indexToRemove)]);
    };

    const removeTags = (indexToRemove: number) => {
        setCcTo([...ccTo.filter((_, index) => index !== indexToRemove)]);
    };

    //-------------------

    const sendMail = async () => {

        if (subject.length < 1) { return toast.error('Add a Subject') }
        if (message.length < 1) { return toast.error('Add a Message') }
        if (mailTo.length < 1) { return toast.error('Add a Mail To') }

        mutate({
            mailTo: mailTo.length > 0 ? mailTo.join(", ") : "",
            cc: ccTo.length > 0 ? ccTo.join(", ") : "",
            subject,
            message,
            link,
            signature
        },{
            onSuccess: () => {
                closeModal()
                toast.success('Email Sent')
            },
            onError: async(error) => {
                toast.error('Error sending email')
                console.log(error)
            }
        })
    };

    return (

        <>
            <div className="email-container">

                <div className='flex-space-between mb-2 mt-1'>
                    <h3 className='ml-02'>Send by mail</h3>
                    <CopyToClipboard text={link}>
                        <button onClick={() => { toast.success('copied to clipboard') }} className="btn-copy flex">
                            <img src='../assets/img/copy.svg' alt='copy-icon' width={14} className="mr-05" />
                            <p>Copy link</p>
                        </button>
                    </CopyToClipboard>
                </div>


                <div className="share-email mb-1">
                    <p className='ml-05'>Email to:</p>
                    <div className="cc-container">
                        <div className="tags-input bg">
                            <ul id="mailTo">
                                {mailTo.map((mail, index) => (
                                    <li key={index} className="tag">
                                        <span className='tag-title'>{mail}</span>
                                        <span className='tag-close-icon'
                                            onClick={() => removeMailTo(index)}
                                        >
                                            &#10005;
                                        </span>
                                    </li>
                                ))}
                            </ul>
                            <input
                                value={theMail}
                                onChange={(e) => setTheMail(e.target.value)}
                                type="text"
                                onKeyUp={event => event.key === "Enter" ? addTags(theMail, "mailTo") : null}
                                placeholder="Press enter to add email"
                                onBlur={() => {
                                    if (isEmail(theMail)) addTags(theMail, "mailTo")
                                }}
                            />
                        </div>
                        <button
                            onClick={() => addTags(theMail, "mailTo")}
                            className="ml-05 cc-btn"
                        >+</button>

                    </div>
                </div>


                <div className="share-email mb-1">
                    <p className='ml-05'>Cc:</p>
                    <div className="cc-container">
                        <div className="tags-input bg">
                            <ul id="tags">
                                {ccTo.map((cc, index) => (
                                    <li key={index} className="tag">
                                        <span className='tag-title'>{cc}</span>
                                        <span className='tag-close-icon'
                                            onClick={() => removeTags(index)}
                                        >
                                            &#10005;
                                        </span>
                                    </li>
                                ))}
                            </ul>
                            <input
                                value={cc}
                                onChange={(e) => setCc(e.target.value)}
                                type="text"
                                onKeyUp={event => event.key === "Enter" ? addTags(cc, "ccTo") : null}
                                placeholder="Press enter to add email"
                            />
                        </div>
                        <button
                            onClick={() => addTags(cc, "ccTo")}
                            className="ml-05 cc-btn"
                        >+</button>

                    </div>
                </div>
                <div className="share-email mb-1">
                    <p className='ml-05'>Subject:</p>
                    <input
                        type='text'
                        placeholder='Add a subject'
                        name={subject}
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </div>
                <div className="share-email">
                    <p className='ml-05'>Message:</p>

                    <ReactQuill className='editor-size' theme="snow" value={message} onChange={setMessage} />

                </div>
            </div>
            {
                signature &&
                <div className="flex mt-1 ml-1">
                    <img src='../assets/img/qc-logo-color.png' width={70} alt="qc-logo" />
                    <div className="ml-1">
                        <p className="font-small bold">{isGP ? "QC" : name} {isGP ? "Team" : lastname}</p>
                        <p className="font-small">{isGP ? "+31 610009939" : phone}</p>
                        <p className="font-small">{isGP ? "quality@growerspackers.com" : email}</p>
                    </div>
                </div>
            }

            <div className="flex mt-1 ml-1">
                <input
                    type="checkbox"
                    className='check-on-off'
                    checked={signature}
                    onChange={(e) => setSignature(e.target.checked)}
                />
                <p className="font-normal text-mute">Add signature</p>
            </div>

            <EmailFormBtn
                isLoading={isLoading}
                actionNext={sendMail}
                actionPrevious={() => setStep(step => step - 1)}
                closeModal={closeModal}
                textNext='Send'
                iconNext={ <IoSendOutline color='white'/> }
            />
        </>
    )
}
