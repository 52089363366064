import { useState } from 'react'
import { CardPreReport } from '../CardPreReport'
import { Charging } from '../ui/Charging'
import { ModalConfirmation } from '../ui/ModalConfirmation'
import { Pagination } from '../ui/Pagination'
import { usePrereports, useRemovePrereport } from '../../api/usePrereports'
import { SelectTeam } from '../ui/SelectTeam'
import { optionValue } from '../../helpers/eliminarEs'
import toast from 'react-hot-toast'

export const PreReports = () => {

    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [currentId, setCurrentId] = useState(null)

    const [page, setPage] = useState(1)
    const [toggle, setToggle] = useState(0)

    const { data, isLoading } = usePrereports(page, toggle)
    const { mutateAsync, isLoading: isDeleting } = useRemovePrereport()

    const handleRemove = async (currentId) => {

        if (currentId === null) return

        await mutateAsync(currentId, {
            onError: () => {
                toast.error("Something went wrong")
            },
        })
        setOpenConfirmation(false)
    }

    const handleToggle = (e) => {
        setToggle(optionValue(e.target.value))
        setPage(1)
    };

    return (
        <div className="reports content">

            {
                isLoading
                    ? <Charging />
                    :
                    <main className="container">

                        {
                            openConfirmation &&
                            <ModalConfirmation
                                msg="Are you sure you want to remove this Pre Report?"
                                closeConfirmation={setOpenConfirmation}
                                action={() => handleRemove(currentId)}
                                isLoading={isDeleting}
                            />
                        }

                        <div className="reports__title mb-2">
                            <h2 className="main-title">Pre Reports</h2>
                        </div>

                        <SelectTeam
                            toggle={toggle}
                            handleToggle={handleToggle}
                            title='All Pre Reports'
                        />

                        {
                            data?.prereports?.length === 0
                                ? <p className="text-center">No Pre Reports</p>
                                :
                                <div>
                                    <div className="reports__list">
                                        {
                                            data?.prereports?.map(prereport => (
                                                <CardPreReport
                                                    key={prereport._id}
                                                    setCurrentId={setCurrentId}
                                                    setOpenConfirmation={setOpenConfirmation}
                                                    currentId={currentId}
                                                    {...prereport}
                                                />
                                            )
                                            )
                                        }
                                    </div>

                                    {
                                        data.totalPages && data.totalPages > 1 &&
                                        <Pagination
                                            totalPages={data.totalPages}
                                            page={page}
                                            setPage={setPage}
                                        />
                                    }
                                </div>
                        }

                    </main>
            }


        </div>
    );
}
