import React from 'react'

interface Props{
    children: React.ReactNode
    columns?: 1 | 2
}

const ColumnsLayout = ({ children, columns=2 }:Props) => {

    const midPoint = Math.ceil(React.Children.count(children) / columns);
    const childrenArray = React.Children.toArray(children);
    const leftColumn = childrenArray.slice(0, midPoint);
    const rightColumn = childrenArray.slice(midPoint);
  
    return (
      <div className={`${ columns === 1 ? 'flex-1' : 'two-columns'}`}>
      {/* <div className="two-columns"> */}
        <div className="flex-1">
          {leftColumn.map((child, index) => (
            <div key={index} className="column-item">
              {child}
            </div>
          ))}
        </div>
        <div className="flex-1">
          {rightColumn.map((child, index) => (
            <div key={index} className="column-item">
              {child}
            </div>
          ))}
        </div>
      </div>
    );
  };

  export default ColumnsLayout;