import { useState } from "react"
import { ImageType, InitialPallets } from "../../../interfaces/intakes.reports"
import { Fruit } from "../../../interfaces/interfaces"
import { CommentsPallet } from "../../ui/CommentsPallet"
import { AddPictures } from "../../ui/AddPictures"
import { ScoreEdit } from "../../scores/ScoreEdit"
import { PalletSection } from "../../pallet/PalletSection"
import { Gallery } from "../../Gallery"
import { useDeleteImage } from "../../../api/useDeleteImage"
import toast from "react-hot-toast"
import { IoChevronDownOutline } from "react-icons/io5"
import { SplitButton } from "../../pallet/SplitButton"

interface Props {
    pallet: InitialPallets
    reportId: string
    fruit: Fruit
    samples: number
    index: number
}

export const PalletReport = ({ pallet, reportId }: Props) => {

    const [activeAccordeon, setActiveAccordeon] = useState(true)
    const { mutate } = useDeleteImage()


    const deleteImage = async (img: ImageType) => {
        mutate({
            reportId,
            palletId: pallet.pid,
            keyName: img.key,
            keyLow: img.key_low
        },
            {
                onSuccess: () => toast.success("Image deleted"),
                onError: () => toast.error("Something went wrong"),
            }
        )
    };

    return (
        <div className={`${activeAccordeon ? "accordeon show" : "accordeon"} card-accordeon p-1 mb-1`}>

            <div className="pallet-ref mb-2 accordeon-button">
                <div className="accordeon-pallet">
                    <div className="flex cursor" onClick={() => setActiveAccordeon(!activeAccordeon)}>
                        <h3 className="mr-1">Pallet</h3> <span>{pallet.pallet}</span>
                    </div>
                    <SplitButton pallet={pallet} reportId={reportId} />
                </div>
                <IoChevronDownOutline className={`${activeAccordeon ? "chevron-down" : "chevron-up"} cursor`} onClick={() => setActiveAccordeon(!activeAccordeon)} />
            </div>

            {
                pallet.labels.data.length > 0 &&
                <PalletSection detailName="labels" pallet={pallet} reportId={reportId} />
            }

            {
                pallet.appearance.data.length > 0 &&
                <PalletSection detailName="appearance" pallet={pallet} reportId={reportId} />
            }

            {
                pallet.pallgrow.data.length > 0 &&
                <PalletSection detailName="pallgrow" pallet={pallet} reportId={reportId} />
            }


            {
                pallet.images && pallet.images.length > 0 &&
                <Gallery images={pallet.images} deleteImage={deleteImage} />
            }

            <AddPictures pid={pallet.pid} reportId={reportId} />

            <div className="border-b" />

            <ScoreEdit pallet={pallet} reportId={reportId} status="score" />
            <ScoreEdit pallet={pallet} reportId={reportId} status="grade" />
            <ScoreEdit pallet={pallet} reportId={reportId} status="action" />


            <CommentsPallet pid={pallet.pid} reportId={reportId} comments={pallet.comments || "No comments"} />


        </div>
    )
}
