import { useState } from 'react'
import toast from 'react-hot-toast'
import { Charging } from './Charging'
import { useEditMainData } from '../../api/useMainData'
import { MainData } from '../pages/Inputs/MainData';
import { MainDataAdd } from '../pages/Inputs/MainDataAdd';
import { ModalBtn } from '../modals/ModalBtn';
import { MainData as MainDataType } from '../../interfaces/intakes.reports';
import { Create } from '../../interfaces/interfaces';

interface Props {
    closeModal: () => void
    reportId: string
    mainData: MainDataType
    model: Create
    handleMainData?: (mainData: MainDataType) => void
}

export const ModalEditMainData = ({ closeModal,
    reportId,
    mainData,
    model,
    handleMainData
}: Props) => {

    const [newMainData, setNewMainData] = useState<MainDataType>(mainData)

    const { mutate, isLoading } = useEditMainData()

    const sendEditItem = async () => {

        mutate({
            reportId,
            mainData: newMainData,
            model
        }, {
            onSuccess: async (data) => {
                handleMainData && handleMainData(data.data.editMain.mainData)
                closeModal()
            },
            onError: (err) => { console.log(err); toast.error("Something went wrong") }
        })
    };

    return (
        <div className="modal">

            <div className="modal__card">

                {
                    isLoading
                        ? <Charging />
                        :
                        <div className="modal__content">

                            <h3 className='mb-1'>Batch Info</h3>
                            <MainData mainData={newMainData} setMainData={setNewMainData} isList={false} />

                            {
                                model === "report" &&
                                <MainDataAdd
                                    mainData={newMainData}
                                    setMainData={setNewMainData}
                                    fast
                                />
                            }

                            <ModalBtn
                                action={sendEditItem}
                                closeModal={() => closeModal()}
                                isLoading={isLoading}
                            />
                        </div>
                }
            </div>

        </div>
    )
}