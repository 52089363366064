import { PDFViewer } from "@react-pdf/renderer";
import { useState } from 'react';
import { useDownloadPdf } from '../../hooks/useDownloadPdf';
import { ZipDownload } from '../ZipDownload';
import { SpinnerSimple } from '../ui/SpinnerSimple';
// import { isMobile } from 'react-device-detect';
import useMediaQuery from '../../hooks/useMediaQuery';
import { DocuPdf } from "../pdf/DocuPdf";


export const PDFScreen = ({ allData }) => {

    const [nextView, setNextView] = useState(true)
    const { generatePDF, isGenerating } = useDownloadPdf()

    const matches = useMediaQuery("(min-width: 768px)");

    return (
        <>
            {
                nextView
                    ?
                    <PDFViewer style={{ minWidth: "100%", height: "100vh" }}>
                        <DocuPdf allData={allData} />
                    </PDFViewer>
                    :

                    <div className='pdf-page'>


                        <div className='pdf-page__container'>
                            <img className="mb-4" src='../assets/img/qc-report-title.png' alt='title' width={200} />

                            {
                                matches &&
                                <button
                                    className="share-card"
                                    onClick={() => setNextView(true)}
                                >
                                    <img src='../assets/img/report-icon.png' alt='title' width={40} />
                                    <p>See report</p>
                                    <div className="spinner-container mr-1">
                                        <img src='../assets/img/download-icon-move.png' alt='d-icon' width={30} style={{ opacity: .2 }} />
                                    </div>
                                </button>}

                            <button
                                className="share-card"
                                onClick={() => generatePDF(allData, `${allData[0].mainData.pallet_ref || "report"}.pdf`)}
                            >
                                <img src='../assets/img/pdf-icon.png' alt='pdf-icon' width={40} />
                                <p className="pdf-text">Download PDF</p>
                                <div className="spinner-container mr-1">
                                    {
                                        isGenerating
                                            ?
                                            <SpinnerSimple />
                                            :
                                            <img src='../assets/img/download-icon.png' alt='d-icon' width={30} style={{ opacity: .2 }} />
                                    }
                                </div>

                            </button>
                            {!matches && <p className="font-normal mt-05" style={{ textAlign: "center" }}>{`${allData[0].mainData.pallet_ref || "report"}.pdf`}</p>}


                            {
                                matches &&
                                <ZipDownload refName={allData[0].mainData.pallet_ref} pallets={allData[0].pallets} />
                            }
                        </div>



                    </div>
            }

        </>
    )
}
