import toast from 'react-hot-toast';

import { formatSplit, totalSamples } from '../../helpers/formatSplit';
import ButtonSubmit from '../ui/ButtonSubmit';
import { Pallets } from '../Pallets';
import { InitialPallets, MainData, NewGrower } from '../../interfaces/intakes.reports';
import { Fruit } from '../../interfaces/interfaces';
import { useAddNewPallet, useFinishReport, useSplitPallet } from '../../api/useReportTemp';
import { SpinnerCustom } from '../ui/SpinnerCustom';
import { palletData } from '../../data/pallet-steps';
import { ModalConfirmation } from '../ui/ModalConfirmation';
import { useState } from 'react';
import useOnlineStatus from '../../hooks/useOnlineStatus';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
    mainData: MainData
    fruit: Fruit
    reportId: string
    pallets: InitialPallets[]
    setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
}

const CreateReportSteps = ({ mainData, fruit, reportId, setPallets, pallets }: Props) => {

    const samples = totalSamples(mainData.format) || 1
    const formatGr = formatSplit(mainData?.format) || 1
    const { mutate, isLoading, isError } = useAddNewPallet()
    const { mutateAsync } = useSplitPallet()
    const { mutate: mutateFinishReport, isLoading: isFinishing } = useFinishReport()
    const isOnline = useOnlineStatus()

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const [openConfirmation, setOpenConfirmation] = useState(false)

    const comments = `The results contained within this report were obtained from random samples taken throughout the delivery. Progressive defects that are present may exceed the eventual defect level specified. Sampling does not represents 100% of the real Quality and condition of the fruit and it is only an approximation to reality.
    
If, or on further processing, the product requires further selection to meet the customer specification, you will be informed of any losses in due course. However, if you would like the product returned or dealt with in any other manner please contact the relevant commercial contact within Growers Packers.`;

    const addNewPallet = () => {
        const palletNumber: number = pallets.length === 0 ? 1 : pallets[pallets.length - 1].pallet + 1
        const newPallet: InitialPallets = palletData(fruit, samples, formatGr, palletNumber)
        mutate({ reportId, newPallet }, {
            onSuccess: (data) => setPallets(pallets => [...pallets, data.newPallet])
        })
    };

    const splitPallet = async (palletNumber: number, palletId: string, split: NewGrower, palletIndex: number) => {
        const newPallet: InitialPallets = palletData(fruit, samples, formatGr, palletNumber)

        try {
            await mutateAsync({
                palletId,
                reportId,
                palletIndex,
                newPallet: {
                    ...newPallet,
                    splitPallet: split
                }
            })
        }
        catch (error) { console.log(error) }
    };

    //SEND Report
    const handleSend = async () => {

        if (!isOnline) return toast.error("You are offline, please check your connection")

        mutateFinishReport({ reportId, comments }, {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries(['reports'])
                navigate(`/report/${data.reportId}`)
            },
            onError: (error) => console.log(error)
        })
    }

    if (isError) toast.error("Something went wrong, try again")

    return (
        <>
            {
                openConfirmation &&
                <ModalConfirmation
                    action={handleSend}
                    msg='Some pallets are incomplete. Would you like to finish the report anyway?'
                    closeConfirmation={() => setOpenConfirmation(false)}
                    buttonName='Finish Report'
                    isLoading={isFinishing}
                />
            }

            <div>
                {
                    pallets.length > 0
                        ?
                        pallets.map((pallet, index) => (
                            <Pallets
                                key={pallet.pid}
                                reportId={reportId}
                                index={index}
                                setPallets={setPallets}
                                pallets={pallets}
                                pallet={pallet}
                                isLastPallet={index === pallets.length - 1}
                                splitPallet={splitPallet}
                            />
                        ))
                        : <p className="text-center bold mb-1">No pallets</p>
                }
            </div>

            {isLoading
                ? <SpinnerCustom center size='s' />
                : <div className='flex-center'><button onClick={() => addNewPallet()} className='btn-round'>+</button> </div>
            }

            <form className="pallet-comments mb-2 mt-3">
                <div className="mb-05">
                    <p className='mb-05 ml-05 bold'>General comments</p>
                    <textarea name="comments" id="comments" cols={30} rows={5} defaultValue={comments} />
                </div>
            </form>

            <ButtonSubmit
                center
                title="Finish Report"
                action={() => {
                    if (pallets.some(pallet => pallet.complete === false || pallet.complete === null))
                        return setOpenConfirmation(true)
                    else handleSend()
                }}
            />
        </>
    )
}

export default CreateReportSteps