import { ModalBtn } from '../modals/ModalBtn'

interface Props {
    closeConfirmation: (close: boolean) => void
    msg: string
    action: () => void
    buttonName?: string
    isLoading?: boolean
}

export const ModalConfirmation = ({ closeConfirmation, msg, action, buttonName = "Remove", isLoading = false }: Props) => {

    return (
        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">

                    {
                        <>
                            <label className="modal-label mb-1">Confirmation</label>
                            {
                                msg &&
                                <p className='mb-1 font-medium'>{msg}</p>
                            }

                            <ModalBtn
                                action={action}
                                closeModal={() => closeConfirmation(false)}
                                isLoading={isLoading}
                                actionTitle={buttonName}
                                isConfirmation
                            />
                        </>
                    }

                </div>
            </div>



        </div>
    )
}
