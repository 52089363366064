import { InitialPallets } from '../../../interfaces/intakes.reports'
import { Fruit } from '../../../interfaces/interfaces'
import { PalletReport } from './PalletReport'


interface Props{
    pallets: InitialPallets[]
    reportId: string
    fruit: Fruit
    samples: number
}

export const ReportPalletNew = ({ pallets, reportId, fruit, samples }:Props) => {

    return (
        <div className="pallets-container">
            {
                pallets.map((pallet, index) =>  (
                        <PalletReport
                            key={pallet.pid}
                            pallet={pallet}
                            reportId={reportId}
                            fruit={fruit}
                            samples={samples}
                            index={index}
                        />

                ))
            }
        </div>
    )
}
