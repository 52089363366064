import { useMutation } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { PDFLink } from "../components/ui/ModalSendPDF";

interface MailProps{
    mailTo: string
    cc: string
    subject: string
    message: string
    link: string
    signature: boolean
}



const sendEmail = async ( body:MailProps ) => {
    const { data } = await qcareApi.post('/pdf/send-mail', body);
    return data
}

const createLink = async ( fetchData:PDFLink ) => {
    const { data } = await qcareApi.post('/pdf/create-pdf', fetchData)
    return data
}

// ------------------------- HOOKS -------------------------

export const useSendEmail = () => {
    return useMutation(sendEmail)
}

export const useCreateLink = () => {
    return useMutation(createLink)
}