import { colorEU } from "./colorEU";

export function tituloEs(texto) {
    let text = (texto.charAt(0).toUpperCase() + texto.slice(1)).normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    return text.split('_').join(' ');
}

export function valorEs(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}


// Convierte Texto a formato name (ej: Hola Mundo => "hola_mundo")
export function inputJson(txt) {
    return txt.trim().split(" ").join("_").toLowerCase()
}

// Convierte Texto a formato supplier (ej: Hola-Mundo => "hola mundo")
export function supplierVal(txt) {
    return txt.trim().toLowerCase().split("-").join(" ")
}


// Convierte cualquier valor (string, number, boolean) a String y reemplaza vacio por "--"
export function itemValor(valor, tipe) {

    if (typeof valor === "boolean") {

        if (valor === true) { return "Yes" }
        else { return "No" }

    }
    if (tipe === "select") {
        return colorEU(valor)
    }
    else { return valor || "--" }

}

export function valorPallgrow(valor) {

    if (Array.isArray(valor)) {

        let sum = 0;

        for (let i = 0; i < valor.length; i++) {
            if (!isNaN(valor[i])) {
                sum += Number(valor[i]);
            } else sum += 0
        }

        if (sum / (valor.length) === 0 || isNaN(sum / (valor.length))) { return "0" }
        else { return (sum / valor.length).toFixed(2) }
    }

    else {
        if (isNaN(valor)) { return "0" }
        else { return valor }
    }
}


export function capitalize(text) {
    const lower = text.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
}


// Solo numeros en input de tipo "number"
export const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

export const optionValue = (value) => {
    return value === "0" ? 0 : value
};

export const selectValue = (value) => {
    return value.length === 0 ? undefined : value
};


export const kilosToLb = (value) => {
    if (isNaN(Number(value))) return "--"
    return (Number(value) * 2.205).toFixed(2)
}