import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";


const getSinglePrereports = async ( id ) => {
    const { data } = await qcareApi.get(`/prereport/${id}`)
    return data
};


// const uploadPallet = async ( pallet ) => {
//     const { data } = await qcareApi.post(`/prereport/add-pallet`, pallet)
//     return data
// }

const uploadImages = async ( allData ) => {

    const preId = allData.pid

    const formData = new FormData();

    for (const img of allData.pallets[0].images) {
        formData.append('uploady', img)
    }

    formData.append('preId', allData.repId)
    formData.append('palletId', preId)

    const { data } = await qcareApi.post('/prereport/images-prereport', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })

    return data
}


// ------------------------- HOOKS -------------------------

export const usePrereport = ( id ) => {
    return useQuery(
        ['prereport', id],
        () => getSinglePrereports(id),
        { select: (data) => { return data.singlePreReport } }
    );
}


export const useUploadImages = () => {
    const queryClient = useQueryClient()
    return useMutation(uploadImages, {
        onSuccess: async() => { await queryClient.invalidateQueries(['prereport']) }
    })
}

