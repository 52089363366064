import useForm from '../../hooks/useForm'
import { ModalBtn } from '../modals/ModalBtn'

export const ModalAdd = ({ setOpenModal, addPallItem, palletItem, samples }) => {

    const closeModal = () => {
        setOpenModal(false)
    }

    const { values, handleInputChange } = useForm({
        labelName: "",
        valorName: "",
    })

    const { labelName, valorName } = values

    return (
        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">
                    <label>Input Name</label>
                    <input
                        className="mb-2"
                        type="text"
                        name="labelName"
                        value={labelName}
                        onChange={handleInputChange}
                    />
                    {
                        palletItem !== 'pallgrow' &&
                        <label>Value</label>
                    }
                    {
                        palletItem !== 'pallgrow' &&
                            <input
                                className="mb-2"
                                type="text"
                                name="valorName"
                                value={valorName}
                                onChange={handleInputChange}
                            />
                    }

                    <ModalBtn
                        closeModal={closeModal}
                        action={() => addPallItem(palletItem, labelName.trim(), valorName.trim(), samples)}
                        actionTitle='Add'
                    />
                </div>
            </div>



        </div>
    )
}
