import { useState } from 'react'
import { ModalBtn } from './ModalBtn'
import { useCreateContact, useCreateSupplier } from '../../api/useSupplier'
import { Supplier, SupplierList } from '../../interfaces/interfaces.auth'
import { useDispatch } from 'react-redux'
import { startChecking } from '../../store/asyncMethods/authMethods'
import { hasLength } from '../../helpers/validators'
import toast from 'react-hot-toast'
import { isEmail } from '../../helpers/isEmail'

interface Props {
    target: SupplierList
    supplier: Supplier
    closeModal: () => void
}

export const ModalAddContact = ({ closeModal, supplier, target }: Props) => {

    const dispatch = useDispatch()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")

    const { mutate, isLoading } = useCreateContact()

    const addContact = () => {

        const cleanName = name.trim()
        const cleanEmail = email.trim()

        if (supplier.contacts.find(sup => sup.email.trim() === cleanEmail)) return toast.error("Contact already exists")

        if (!hasLength(cleanEmail) || !hasLength(cleanName)) return toast.error("Please fill in all fields")
        if (cleanName.length < 3) return toast.error("Name must be at least 3 characters")
        if (!isEmail(cleanEmail)) return toast.error('Please enter a valid Email')

        mutate({
            id: target.id,
            model: target.model,
            supplierId: supplier._id,
            name: cleanName,
            email: cleanEmail,
        }, {
            onSuccess: async () => {
                dispatch(startChecking())
                closeModal()
            },
            onError: (error) => console.log(error)
        })
    };

    return (

        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">
                    <>
                        <p className='font-normal mb-02 ml-02'>Name</p>
                        <input
                            type="text"
                            value={name}
                            maxLength={30}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </>


                    <p className='mt-2 font-normal mb-02 ml-02'>Email</p>
                    <input
                        className="mb-1"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <ModalBtn
                        action={addContact}
                        closeModal={closeModal}
                        actionTitle='Add Contact'
                        isLoading={isLoading}
                    />

                </div>
            </div>
        </div>

    )
}
