import { Document, Text, View, StyleSheet, Font } from "@react-pdf/renderer";

import HelveticaFont from '../../assets/fonts/Helvetica.ttf'
import HelveticaFontBold from '../../assets/fonts/HelveticaMedium.ttf'
import { PDFPallets } from "./PDFPallets";
import { PDFPage } from "./PDFPage";

export const DocuPdf = ({ allData }) => {

    const [data] = allData

    Font.register({
        family: "HelveticaFont",
        src: HelveticaFont,
        fonts: [
            { src: HelveticaFont },
            { src: HelveticaFontBold, fontWeight: 700, fontStyle: 'bold' },
        ]
    })

    return (
        <Document>
            {
                data &&
                data.pallets.length > 0
                    ?
                    data.pallets.map((pallet, index) => (
                        <PDFPallets key={pallet.pid} data={data} pallet={pallet} index={index} />
                    ))

                    :
                    <PDFPage data={data}>
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                            <Text style={globalStyles.h2}>No Pallets</Text>
                        </View>
                    </PDFPage>
            }
        </Document>
    )

}

export const globalStyles = StyleSheet.create({

    pageFormat: {
        flex: 1,
        flexDirection: "column",
        paddingHorizontal: 30,
        paddingVertical: 20,
        fontFamily: "HelveticaFont"
    },
    h2: {
        fontSize: "12px",
        fontStyle: "bold",
        color: '#2fac66',
        lineHeight: '1px',
        marginBottom: 4
    },
    title: {
        color: "gray",
        fontSize: "10px",
    },
    text: {
        fontStyle: 'bold',
        color: '#353644',
        fontSize: "10px",
        alignSelf: 'flex-end'
    },
    bold: { fontStyle: 'bold' },
    textColor: { color: '#353644' },

    item: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        alignContent: "center",
        paddingTop: 2.4,
        paddingBottom: 1.2,
    },
    itemBorder: {
        borderBottomColor: '#d8d8d8',
        borderBottomWidth: .5,
    },
    comments: {
        backgroundColor: '#f7f9fb',
        minHeight: "80px",
        borderRadius: 12,
        padding: 8,
        fontSize: "10.5px",
        lineHeight: "1.6px",
    },
    boxes: {
        minWidth: "70px",
        minHeight: "22px",
        paddingVertical: 1,
        paddingHorizontal: 6,
        borderRadius: 50,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        marginRight: '5px'
    },
    flexRow: {
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
    },
    flexCenter: {
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
    },

});