// import { v4 as uuidv4 } from 'uuid';
import { jsonKeyToString } from '../../../helpers/jsonToString';
import MainInput from './MainInput';
import { mainDataValue } from '../../../helpers/mainDataConvert';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import ColumnsLayout from '../../ui/ColumnsLayout';
import { MainData as MainDataType} from '../../../interfaces/intakes.reports';
import MainDates from './MainDates';
import { datesInputs } from '../../../data/mainData';

interface Props {
    mainData: Record<string, any> | null
    setMainData?: Dispatch<SetStateAction<MainDataType>>
    isList?: boolean
    className?: string
    allDisabled?: boolean
}

export const MainData = ({ mainData, setMainData, isList = false, className, allDisabled=false }: Props) => {

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (setMainData) {
            setMainData((prev: Record<string, any>) => {
                return { ...prev, [e.target.name]: e.target.value }
            })
        }
    };

    const handleDate = (name: string, date: Date) => {

        if (setMainData) {
            setMainData((prev: Record<string, any>) => {
                return { ...prev, [name]: date }
            })
        }
    };

    return (
        <div className={className}>
            {
                mainData &&
                <ColumnsLayout columns={isList ? 2 : 1}>
                    {
                        Object.entries(mainData).map(item => (
                            isList
                                ?
                                <div key={item[0]} className="main-info__item mb-05">
                                    <p>{jsonKeyToString(item[0])}</p>
                                    <p className="bold">{mainDataValue(item[0], item[1])}</p>
                                </div>
                                :

                                <div key={item[0]} className="grid intake__input">
                                    <label className="span-5 ">{jsonKeyToString(item[0])}</label>

                                    {
                                        !datesInputs.includes(item[0])
                                            ?
                                            <MainInput
                                                item={item}
                                                handleChange={handleChange}
                                                disabled={allDisabled}
                                            />
                                            :
                                            <MainDates
                                                item={item}
                                                handleDate={handleDate}
                                                disabled={allDisabled}
                                            />
                                    }
                                </div>
                        ))
                    }
                </ColumnsLayout>
            }
        </div>
    )
}