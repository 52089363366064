import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';

import { ModalDiscrepancy } from './ModalDiscrepancy'
import { ModalEditDiscrepancy } from './ModalEditDiscrepancy'

import { objectToArray } from '../../helpers/objToArray';
import { jsonKeyToString } from '../../helpers/jsonToString';

export const Discrepancies = ({ reportId, mainData, discrepancies, setDiscrepancies, dbDiscrepancy=false, prereport=false }) => {

    const [singleDiscrepancy, setSingleDiscrepancy] = useState(null)
    const [modalDiscrepancy, setModalDiscrepancy] = useState(false)
    const [modalEditDiscrepancy, setModalEditDiscrepancy] = useState(false)

    const editDiscrepancy = (discrepancy) => {
        setSingleDiscrepancy(discrepancy)
        setModalEditDiscrepancy(true)
    };

    return (
        <>
            {
                modalDiscrepancy &&
                <ModalDiscrepancy
                    closeModal={() => setModalDiscrepancy(false)}
                    mainData={mainData}
                    discrepancies={discrepancies}
                    setDiscrepancies={setDiscrepancies}
                    dbDiscrepancy={dbDiscrepancy}
                    prereport={prereport}
                    reportId={reportId}
                />
            }

            {
                modalEditDiscrepancy &&
                <ModalEditDiscrepancy
                    closeModal={() => setModalEditDiscrepancy(false)}
                    singleDiscrepancy={singleDiscrepancy}
                    discrepancies={discrepancies}
                    setDiscrepancies={setDiscrepancies}
                    dbDiscrepancy={dbDiscrepancy}
                    prereport={prereport}
                    reportId={reportId}
                />
            }

            <div className="flex mb-05">
                <label className="bold mr-05">Discrepancies</label>

                <button
                    onClick={() => setModalDiscrepancy(true)}
                    className="modal-add"
                >
                    <div className='red'>+</div>
                </button>
            </div>

            <div className='discrepancy'>
                {
                    discrepancies &&
                    objectToArray(discrepancies).map(discrepancy => (
                        <div onClick={() => editDiscrepancy(discrepancy)} key={uuidv4()} className="discrepancy__container shadow">
                            <p className="font-small discrepancy__text"><span className="discrepancy__label bold">{jsonKeyToString(discrepancy.key) || "--"}: </span>{" " + discrepancy.value || "--"}</p>
                        </div>
                    ))
                }
            </div>
        </>
    )
}
