import { LifeTest } from './interface.lifeTest';
import { ActionNum, DetailName, Fruit, GradeNum, ScoreNum } from './interfaces';
import { TeamsName } from "./interfaces.auth";

export interface InitialReport {
    mainData: Record<string, any> | null
    fruit: Fruit
    intakeId?: string
    team: string | null
    supplier: string | null
    pallets: InitialPallets[]
}

export interface InitialPallets {
    complete?: boolean | null
    pid: string,
    pallet: number,
    score: ScoreNum,
    grade: GradeNum,
    action: ActionNum,
    images: ImageType[],
    addGrower?: NewGrower | null
    newGrower?: NewGrower | null,
    splitPallet: NewGrower | null,

    labels: PalletSection,
    appearance: PalletSection,
    appareance?: PalletSection,
    pallgrow: PalletSection,
    prereport?: PrereportPallet | null;
    details?: Details;

    comments?: string;
}

export interface Pallet {
    pid: string;
    details: Details;
    score: ScoreNum;
    images: ImageType[];
    prereport?: PrereportPallet | null;
    addGrower: NewGrower | null;
    comments?: string;
}

export interface PalletSection {
    data: DetailObject[],
    images: ImageType[],
    major?: DetailObject[],
    minor?: DetailObject[],
    imagesTemp?: ImageTemp[],
}

export interface ObjectType {
    [key: string]: string
}

export interface ObjectArray {
    key: string,
    value: string
}

export type MainData = Record<string, any>

export interface IntakeCSV {
    id: string;
    inCharge: string | null;
    team: string | null;
    data: MainData;
}

export interface IntakesResponse {
    ok: boolean;
    page: number;
    totalPages: number;
    intakes: Intake[];
}

export interface Intake {
    _id: string;
    data: MainData;
    user: string;
    inCharge: InCharge | null
    team: TeamsName | null
    date?: Date;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface InCharge {
    uid: string;
    name: string;
    lastname: string;
}

export interface EntriesResponse {
    ok: boolean;
    msg: string;
    entries: Intake[];
}

export interface SingleIntake {
    ok: boolean;
    msg: string;
    intakeReport: Intake;
}



// Responde to Finish Report --------------------------------------

export interface IntakeSingleResponse {
    ok?: boolean;
    msg: string;
    intakeReport: IntakeData;
}

export interface IntakeData {
    _id: string;
    discrepancies: ObjectType | null;
    palletRef: string;
    fruit: Fruit;
    pallets: PrereportPallet[];
    formatGr: string;
    mainData: MainData;
    user: UserInCharge;
    score: string;
    grade: string;
    action: string;
    startDate: Date;
    endDate: Date;
    team: string | null;
}

export interface UserInCharge {
    uid: string;
    name: string;
    lastname: string;
}

// END Responde to Finish Report --------------------------------------

export interface UserPop {
    name: string,
    uid: string
}

export interface AllReports {
    msg: string;
    singleReport: SingleReport;
}

export interface PalletNew {
    pid: string;
    labels: Details;
    appearance: Details;
    pallgrow: Details;
    score: ScoreNum;
    images: ImageType[];
    addGrower: NewGrower | null;
    comments?: string;
}

export interface PrereportDone {
    score: ScoreNum;
    grade: GradeNum;
    action: ActionNum;
    details: Details;
    images: ImageType[];
}

export interface SingleReport {
    _id: string;
    unfinished?: boolean;
    palletRef: string;
    comments: string;
    fruit: Fruit;
    pallets: InitialPallets[];
    formatGr: number;
    score: ScoreNum;
    mainData: MainData;
    date: Date;
    team: string | null;
    inCharge: string | null;
}


export interface AllReportsResponse {
    ok: boolean;
    page: number;
    totalPages: number;
    reports: SingleReport[];
}

export interface FilterResponse {
    ok: boolean;
    page: number;
    totalPages: number;
    result: SingleReport[];
    fruit: Fruit,
    score: ScoreNum,
    total: number
    pageSize: number
}

export interface SingleReportResponse {
    msg: string;
    singleReport: Report;
}

export interface Report {
    _id: string;
    palletRef: string;
    comments: string;
    fruit: Fruit;
    pallets: InitialPallets[];
    formatGr: number;
    score: ScoreNum;
    supplier?: string | null;
    mainData: MainData;
    user: UserData;
    team: string | null;
    unfinished?: boolean;
    lifetest: LifeTest[] | null;
    date: Date;
}

export interface UserData {
    name: string;
    lastname: string;
    uid: string
}


export interface Details {
    labels: DetailObject[];
    appareance: DetailObject[];
    appearance: DetailObject[];
    pallgrow: DetailObject[];
}

export interface DetailObject {
    check?: boolean;
    tipe?: "checkbox" | "text" | "range" | "number" | "arrays" | "select";
    type?: "checkbox" | "text" | "range" | "number" | "arrays" | "select";
    label: string;
    name: string;
    valor?: string | boolean | number | string[];
    value?: string | boolean | number | string[];
    minVal?: number;
    maxVal?: number;
    arrays?: object[] | number;
    photo?: ImageTemp | ImageType | null;

    percentage?: number | string;
    average?: number | string;
}


//Pre Reports----------------------------------------------------

export interface NewGrower {
    grower_variety: string,
    boxes: string,
}


export interface PrereportsResponse {
    ok: boolean;
    page: number;
    totalPages: number;
    prereports: Prereport[];
}

export interface SinglePreReportResponse {
    ok: boolean;
    msg: string;
    singlePreReport: Prereport;
}


export interface Prereport {
    _id: string;
    discrepancies: ObjectType | null;
    palletRef: string;
    fruit: Fruit;
    pallets: PrereportPallet[];
    formatGr: string;
    mainData: MainData;
    user: UserPop;
    score: ScoreNum;
    grade: GradeNum;
    action: ActionNum;
    startDate: Date;
    endDate: Date;
    team?: string | null;
}

export interface PrereportPallet {
    pid: string;
    details: Details;
    score: ScoreNum;
    grade: GradeNum;
    action: ActionNum;
    images: ImageType[];
    addGrower: NewGrower | null;
}

export interface ImageType {
    imgURL: string;
    imgURL_low: string;
    key: string;
    key_low: string;
    label?: string;
}

export interface ImageTemp {
    id: string;
    url: string | undefined;
    file: File
}


// DATA ----------------------------------------------

export enum Labels {
    labels = "Labels",
    appareance = "Appearance",
    appearance = "Appearance",
    pallgrow = "Pall/Grower",
}

export interface PallGrowDefects {
    weight10: string
    major: DetailObject[]
    minor: DetailObject[]
    totalMajor: number
    totalMinor: number
    totalDefects: number
    totalRest: number
}

export interface StepSection {
    detailName: DetailName
    sectionStep: number
}



// export interface PalletSection {
//     data: DetailObject[],
//     images: ImageType[],
// }

// export interface InitialPallets {
//     labels: PalletSection,
//     pallgrow: PalletSection,
// }

// export interface InitialRepack {
//     labels: PalletSection,
// }