import { Fruit } from "../interfaces/interfaces"


export const major = (fruit: Fruit) => {

    if (fruit === "blueberries") {
        return [
            "crushed",
            "chrushed",
            "cuts",
            "decay",
            "freezing_damage",
            "insect_damage",
            "insect_presence",
            "juicing_exudated_berry",
            "leaking",
            "mold",
            "mould",
            "mold_hotspot",
            "overripe",
            "rot",
            "sensitive",
            "shriveling",
            "soft",
            "poorly_color",
            "wet_fruit",
            "wounds",
        ]
    }
    if (fruit === "raspberries") {
        return [
            "crushed",
            "chrushed",
            "decay",
            "freezing_damage",
            "insect_damage",
            "insect_presence",
            "juicing_exudated_berry",
            "leaking",
            "mold",
            "mould",
            "mold_hotspot",
            "overripe",
            "sensitive",
            "shriveling",
            "soft",
            "sun_burn",
            "rot_hotspot",
            "rot",
            "roya",
            "wet_fruit",
            "white_cells",
        ]
    }
    if (fruit === "strawberries") {
        return [
            "bronzing",
            "cracking",
            "crown_mold",
            "crushed",
            "chrushed",
            "cuts",
            "decay",
            "dry_calyx",
            "freezing_damage",
            "heavy_bruises",
            "insect_damage",
            "insect_presence",
            "juicing_exudated_berry",
            "leaking",
            "mechanical_damage",
            "misshapen",
            "mold",
            "mould",
            "no_calyx",
            "poorly_color",
            "sensitive",
            "shriveling",
            "soft",
            "rot",
            "overripe",
            "wet_fruit",
        ]
    }

    if (fruit === "blackberries") {
        return [
            "crushed",
            "chrushed",
            "decay",
            "dry_cells",
            "juicing_exudated_berry",
            "freezing_damage",
            "insect_damage",
            "insect_presence",
            "leaking",
            "overripe",
            "mold",
            "mould",
            "mold_hotspot",
            "red_cells",
            "rot",
            "rot_hotspot",
            "roya",
            "sensitive",
            "shriveling",
            "soft",
            "sun_burn",
            "white_cells",
            "wet_punnet",
            "wet_fruit",
        ]
    }

    if (fruit === "red currants") {
        return [
            "chrushed",
            "crushed",
            "cuts",
            "decay",
            "freezing_damage",
            "inconsistent_brunch",
            "insect_damage",
            "insect_presence",
            "mold",
            "mould",
            "overripe",
            "rot",
            "sensitive",
            "severe_stem_dehydration",
            "shriveling",
            "soft",
            "splits",
            "wet_fruit",
            "wet_punnet",
            "wounds",
        ]
    }
    if (fruit === "pears") {
        return [
            "severe_mechanicalDamage",
            "severe_bruising",
            "severe_russeting",
            "severe_bronzeColor",
            "rotting",
            "sunburn",
            "brown_heartCavities",
            "core_breakdowns",
            "scald",
            "grittiness",
            "hail_damage",
            "severe_hailDamage",
            "scab",
            "severe_scab",
            "insect_damage",
            "animal_damage",
            "insect"
        ]
    }
    if (fruit === "apples") {

        return [
            "rot",
            "bitterpit",
            "brown_core",
            "bruising",
            "decay",
            "freezing_injury",
            "insect_injuries",
            "insects",
            "internal_browning",
            "ltd",
            "lenticel_spots",
            "mold",
            "open_injuries",
            "residue",
            "ruseting",
            "scald",
            "shriveling_heavy",
            "sooty_mold",
            "water_core"
        ]
    }
    if (fruit === "cherries") {

        return [
            "mold",
            "rot",
            "botrytis",
            "chilling_injury",
            "concentric_cracking",
            "freezing_injury",
            "insects",
            "internal_breakdown",
            "internal_browning",
            "ltd",
            "open_injuries",
            "residue",
            "ruseting",
            "cracking",
            "crushed"
        ]
    }
    else {
        return [
            "chrushed",
            "crushed",
            "mold",
            "mould",
            "mold_hotspot",
            "decay",
            "juicing_exudated_berry",
            "juicing",
            "insect_damage",
            "insect_presence",
            "leaking",
            "overripe",
            "rot",
            "split",
            "soft"
        ]
    }

} 