import{ useState } from 'react'
import { ModalPhotoGallery } from './ui/ModalPhotoGallery';
import { useDeletePhoto } from '../api/useDeleteImage';
import toast from 'react-hot-toast';

export const PhotoGallery = ({ photos, pid, reportId, prereport = false, }) => {

    const [modalImg, setModalImg] = useState(false)
    const [currentPhoto, setCurrentPhoto] = useState(null)

    const { mutate, isLoading } = useDeletePhoto()

    const removeDefectPhoto = async (photo) => {

        const image = {
            reportId,
            palletId: pid,
            detailName: photo.detailName,
            itemName: photo.name,
            keyName: photo.photo.key,
            keyLow: photo.photo.key_low,
        }

        mutate(
            { image, reportId, prereport },
            {
                onSuccess: () => {
                    toast.success("Image deleted")
                    setModalImg(false)
                },
                onError: () => { toast.error("Something went wrong") },
            })
    };

    return (
        <>
            {
                modalImg &&
                <ModalPhotoGallery
                    photo={currentPhoto}
                    openModal={setModalImg}
                    action={removeDefectPhoto}
                    isLoading={isLoading}
                />
            }

            <div className='gallery-photos mb-1'>
                {
                    photos.map(photo => (
                        <button
                            key={photo.name}
                            className='gallery-photos__container'
                            onClick={() => {
                                setCurrentPhoto(photo)
                                setModalImg(true)
                            }}
                        >
                            {photo.name &&
                                <div className='photo-tag'>
                                    <p>{photo.label}</p>
                                </div>
                            }
                            <img src={photo.photo.imgURL_low || photo.photo.imgURL} alt={photo.name} />
                        </button>
                    ))
                }
            </div>
        </>
    )
}
