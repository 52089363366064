import { useEffect, useRef } from 'react';

interface Props {
    onClickOutside: () => void
    show: boolean
    children: React.ReactNode
}

export const Popup = ({ onClickOutside, show, children }: Props) => {

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);

    if (!show)
        return null;

    return (
        <div
            className='popup-window'
            ref={ref}
        >
            {children}
        </div>
    )
}
