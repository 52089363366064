import { useEffect } from 'react'
import { useState } from 'react'

export const ImagesPDF = ({ img, images, setImages, pid }) => {

    const [checkRef, setCheckRef] = useState(true)

    useEffect(() => {
        const pallet = images.find(pall => pall.pid === pid)
        setCheckRef(pallet.images.some(i => i.imgURL === img.imgURL))
        return () => { setCheckRef(true) }
    }, [images, img, pid])


    const addImage = (url) => {

        setCheckRef(!checkRef) 

        const elPallet = images.find(pall => pall.pid === pid)
        const elResto = images.filter(pall => pall.pid !== pid)

        if (!checkRef) {
            elPallet.images.push(url)
            setImages([...elResto, elPallet])
        } else {
            const newP = elPallet.images.filter(im => im.imgURL !== url.imgURL)
            elPallet.images = newP
            setImages([...elResto, elPallet])
        }
    };

    return (

        <label className="modalPDF">
            <input
                type="checkbox"
                checked={checkRef}
                onChange={() => addImage(img)}
            />
            <div className="modalPDF__container">
                <img
                    className={`${checkRef ? null : "img-trans"}`}
                    src={img.imgURL_low}
                    alt={img.key_low}
                />
                <span className={`box-checked ${checkRef ? "check-on" : "check-off"}`}>
                    {
                        checkRef &&
                        <img
                            src='/assets/img/check.svg'
                            alt='check-icon'
                        />}
                </span>

                {
                    img.label &&
                    <div className='photo-tag-pdf'>
                        <p>{img.label}</p>
                    </div>
                }
            </div>
        </label>

    )
}
