import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { DetailObject, InitialPallets, Report } from "../../interfaces/intakes.reports"
import { pallgrowDefects } from '../../helpers/defectsTable';
import { globalStyles } from './DocuPdf';

interface Props {
  pallet: InitialPallets
  data: Report
}

interface DefectProps {
  defect: DetailObject
  isMajor: boolean
}

export const PDFListDefectsNew = ({ pallet, data }: Props) => {

  const { major, minor, totalMajor, totalMinor, totalDefects } = pallgrowDefects(pallet.pallgrow.data, (pallet.pallgrow.major || []), (pallet.pallgrow.minor || []))

  const Defects = ({ defect, isMajor }: DefectProps) => {
    return (
      <View style={[globalStyles.item, globalStyles.itemBorder]} key={defect.name}>
        <Text style={[globalStyles.title, globalStyles.bold, { width: '40%' }]}>{defect.label}</Text>
        <View style={{ flex: 1 }}>
          <Text style={[globalStyles.text, { width: '50%', alignSelf: isMajor ? 'flex-start' : 'flex-end', textAlign: 'center' }, isMajor ? { ...styles.colorMajor } : { ...styles.colorMinor }]}>{defect.percentage ? `${defect.percentage}%` : "0%"}</Text>
        </View>
      </View>
    )
  };

  return (
    <>
      <Text style={globalStyles.h2}>Pall/Grower</Text>
      <View>
        {
          pallet.pallgrow.data.map(item => (
            <View key={item.name} style={[globalStyles.item, globalStyles.itemBorder]}>
              <Text style={[globalStyles.title, globalStyles.bold]}>{ item.label }</Text>
              <Text style={globalStyles.text}>{item.value}</Text>
            </View>
          ))
        }

        <View style={{ marginBottom: 15 }}/>

        {
          (major.length + minor.length) > 0 &&

          <>
            <View style={[globalStyles.item, styles.defectsHeader]}>
              <Text style={[globalStyles.text, globalStyles.bold, { width: '40%' }]}>Defects</Text>
              <View style={[globalStyles.item, { flex: 1 }]}>
                <Text style={[globalStyles.text, { width: '50%', textAlign: 'center', color: 'red' }]}>Major Defect (Critical)</Text>
                <Text style={[globalStyles.text, { width: '50%', textAlign: 'center', color: 'orange' }]}>Minor Defect</Text>
              </View>
            </View>

            {minor.map(defect => (<Defects key={defect.name} defect={defect} isMajor={false} />))}
            {major.map(defect => (<Defects key={defect.name} defect={defect} isMajor />))}

            <View style={[globalStyles.item, globalStyles.itemBorder]}>
              <Text style={[globalStyles.title, globalStyles.bold, { width: '40%' }]}>Total</Text>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={[globalStyles.text, { width: '50%', textAlign: 'center' }, { ...styles.colorMajor }]}>{totalMajor}%</Text>
                <Text style={[globalStyles.text, { width: '50%', textAlign: 'center' }, { ...styles.colorMinor }]}>{totalMinor}%</Text>
              </View>
            </View>

            <View style={[globalStyles.item, globalStyles.itemBorder]}>
              <Text style={[globalStyles.title, globalStyles.bold, { width: '40%' }]}>Total Defects</Text>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <Text style={[globalStyles.text, globalStyles.bold, { width: '100%', textAlign: 'center' }]}>{totalDefects}%</Text>
              </View>
            </View>
          </>

        }
      </View>
    </>
  )
}

export const styles = StyleSheet.create({
  defectsHeader: {
    borderBottomColor: '#353644',
    borderBottomWidth: .5,
    paddingBottom: 2,
    marginBottom: 2,
  },
  colorMajor: { color: "red" },
  colorMinor: { color: "orange" },
})
