import { PagButtonSearch } from './PagButtonSearch'
import { useNavigate } from 'react-router-dom';

export const PaginationSearch = ({ totalPages, page, setPage, query }) => {

    const navigate = useNavigate();

    const allPages = new Array(totalPages).fill(null).map((n, i) => i + 1)
    const excede = [1, 2, 3, 4, 5, " ", totalPages]

    const previousBtn = () => {
        if (page > 1) {
            localStorage.setItem('filter', `/search/page=${page - 1}&${query}`)
            navigate(`/search/page=${page - 1}&${query}`)
        }
    };

    const nextBtn = () => {
        if (page < totalPages) {
            localStorage.setItem('filter', `/search/page=${page + 1}&${query}`)
            navigate(`/search/page=${page + 1}&${query}`)
        }
    };

    return (
        <div className="pagination">
            <div className="pagination__container">
                <button
                    className="pag-btn pag-g"
                    onClick={previousBtn}
                >
                    <p>←</p>
                </button>
                {totalPages &&
                    (totalPages <= 6)
                    ? allPages.map((number, index) => (
                        <PagButtonSearch
                            key={index}
                            num={number}
                            page={page}
                            query={query}
                        />
                    ))

                    :
                    page < 5

                        ? excede.splice(0, 7).map((number, index) => (
                            <PagButtonSearch
                                key={index}
                                num={number}
                                page={page}
                                query={query}
                            />
                        ))
                        : <>
                            <button
                                className="pag-btn pag-w"
                                onClick={() => {
                                    localStorage.setItem('filter', `/search/page=1&${query}`)
                                    navigate(`/search/page=1&${query}`)
                                }}
                            >
                                <p>1</p>
                            </button>
                            <p className='symbols'>&#8249;</p>
                            {
                                allPages.splice(page - 3, 5).map((number, index) => (
                                    <PagButtonSearch
                                        key={index}
                                        num={number}
                                        page={page}
                                        query={query}
                                    />
                                ))
                            }
                            {
                                page < totalPages - 2 &&
                                <>
                                    <p className='symbols'>&#8250;</p>
                                    <button
                                        className="pag-btn pag-w"
                                        onClick={() => setPage(totalPages)}
                                    >
                                        <p>{totalPages}</p>
                                    </button>
                                </>
                            }

                        </>

                }
                {

                }
                <button
                    className="pag-btn pag-g"
                    onClick={nextBtn}
                >
                    <p>→</p>
                </button>

            </div>
        </div>
    )
}
