import { useEffect, useState } from 'react'
import qcareApi from '../../api/qcareApi'
import { onlyLettersString } from '../../helpers/inputValidation'
import toast from 'react-hot-toast'
import { capitalize, inputJson } from '../../helpers/eliminarEs'
import { resizeFile } from '../../helpers/resizeFile'
import { tempFile } from '../../helpers/photoToUpload'
import { Charging } from './Charging'
import { useQueryClient } from '@tanstack/react-query'
import { ModalBtn } from '../modals/ModalBtn'


export const ModalAddItem = ({ pallgrow, closeModal, samples, pid, reportId }) => {

    const [label, setLabel] = useState("")
    const [valor, setValor] = useState([])
    const [photo, setPhoto] = useState(null)
    const [tempPhoto, setTempPhoto] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const queryClient = useQueryClient()

    useEffect(() => {
        setValor(new Array(pallgrow[1]?.valor?.length || +samples || 1).fill("0"))
        return () => setValor([])
    }, [pallgrow, samples])

    const handleValor = (index, e) => {
        const newValor = [...valor]
        newValor[index] = e.target.value
        setValor(newValor)
    };

    const sendAddItem = async () => {

        if (pallgrow.some(item => item.name === inputJson(label))) return toast.error("This input already exist")
        if (label.trim().length > 20 || label.trim().length < 3) return toast.error("Label name must be between 3 and 20 characters")
        if (!onlyLettersString(label)) return toast.error("Label name must be only letters")

        setIsLoading(true)
        try {

            const formData = new FormData()
            formData.append('uploady', photo)
            formData.append('reportId', reportId)
            formData.append('palletId', pid)
            formData.append('item', JSON.stringify({
                tipe: "arrays",
                label: capitalize(label.trim()),
                name: inputJson(label.trim()),
                valor,
            }))

            await qcareApi.put("/report/add-item", formData)

            await queryClient.invalidateQueries(['report', reportId])
            closeModal()
        } catch (error) {
            console.log(error)
        } finally { setIsLoading(false) }
    };

    const addSample = () => setValor(c => [...c, "0"])

    const removeSample = () => {
        if (valor.length === 1) return
        let newArr = [...valor]
        newArr.pop()
        setValor(newArr)
    }

    const addTempFile = async (e) => {
        const resizedImage = await resizeFile(e.target.files[0])
        setPhoto(resizedImage)
        setTempPhoto(await tempFile(resizedImage))
    };

    return (
        <div className="modal">
            <div className="modal__card">
                {
                    isLoading
                        ?
                        <Charging />
                        :
                        <div className="modal__content">
                            <label className='bold'>Label Name</label>
                            <input
                                className="mb-2"
                                type="text"
                                value={label}
                                onChange={(e) => setLabel(e.target.value)}
                            />

                            <div className='input-columns'>
                                {
                                    valor.map((val, i) => (
                                        <div className='input-columns__input mb-05' key={i}>
                                            <p>{i + 1}.</p>
                                            <input
                                                type="number"
                                                value={val}
                                                onChange={(e) => handleValor(i, e)}
                                                onFocus={(e) => e.target.select()}
                                            />
                                        </div>
                                    )
                                    )
                                }
                            </div>

                            <div className='plus-add'>
                                <button
                                    className="plus-btn mr-1 mt-05"
                                    onClick={removeSample}
                                >-</button>

                                <button
                                    className="plus-btn mt-05"
                                    onClick={addSample}
                                >+</button>
                            </div>


                            <div className='mb-1'>
                                <label htmlFor="photoFile" className="upload-label mb-1 mt-2">
                                    <img src="/assets/img/upload-life.svg" alt="img-icon" />
                                    <span>Add photo</span>
                                </label>
                                <input
                                    id="photoFile"
                                    className="upload-btn"
                                    accept=".jpg, .png, .jpeg"
                                    name="file"
                                    type="file"
                                    onChange={addTempFile}
                                />
                            </div>

                            {
                                tempPhoto &&
                                <div className="tempPhoto">
                                    <img className='tempPhoto__img' src={tempPhoto.url} alt="selected" loading="lazy" />

                                    <button
                                        className='tempPhoto__close'
                                        onClick={() => setTempPhoto(null)}
                                    >
                                        <img src='../assets/img/close-icon.svg' alt='close-icon' />
                                    </button>
                                </div>
                            }

                            <ModalBtn
                                closeModal={closeModal}
                                action={sendAddItem}
                                actionTitle='Add'
                                isLoading={isLoading}
                            />

                        </div>
                }

            </div>



        </div >
    )
}