import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from 'react-hot-toast';
import qcareApi from "./qcareApi";
import { photoToUpload, uploadPhoto } from "../helpers/photoToUpload";
import { useNavigate } from 'react-router-dom';


const fetchDefectImages = async( files, prereport=false ) => {
    const uploadPromises = files.map(file => uploadPhoto(file, prereport));
    await Promise.all(uploadPromises)
};

// ------------------------- FUNCTIONS -------------------------

const fetchPrereport = async ({ fetchData, pallets }) => {

    await qcareApi.post(`/prereport/main-prereport`, fetchData)
        .then(async (res) => {

            const files = photoToUpload(res.data.preId, pallets)

            if (files.length > 0) {
                fetchDefectImages(files, true)
            }
            return res.data.preId
        })
        .then(async (res) => {

            const preId = res
            for (let i = 0; i < pallets.length; i++) {

                if (pallets[i].images.length === 0) { continue; }

                const formData = new FormData();

                for (const img of pallets[i].images) {
                    formData.append('uploady', img.file)
                }

                formData.append('preId', preId)
                formData.append('palletId', pallets[i].id)

                await qcareApi.post('/prereport/images-prereport', formData)
            }
        })
};

const fetchReport = async ({ fetchData, pallets }) => {

    await qcareApi.post(`/report/report`, fetchData )

        .then(async (res) => {

            const files = photoToUpload(res.data.rid, pallets)

            if (files.length > 0) {
                await fetchDefectImages(files, false)
            }

            return res.data.rid
        })

        .then(async (res) => {
            const savePalletInfo = async () => {

                const reportId = res

                for (const pall of pallets) {

                    if (pall.images.length === 0) { continue; }

                    const formData = new FormData();

                    for (const img of pall.images) {
                        formData.append('uploady', img.file)
                    }

                    formData.append('reportId', reportId)
                    formData.append('palletId', pall.id)

                    await qcareApi.post('/report/upload-images', formData)
                }
            };

            await savePalletInfo()
        })
};

const saveLifeTest = async (reportId, mainData, pallets, team) => {

    const newScore = () => {
        const scores = pallets.map(sc => Number(sc.score || 0))
        const scoreFiltered = scores.filter(oneScore => oneScore > 0) || [0]

        if (scoreFiltered.length === 0) return 0
        else { return Math.min(...scoreFiltered) || 0 }
    };

    const allGrowers = pallets.filter(p => p.addGrower).map(p => { return { grower: p.addGrower.grower_variety, score: p.score } })
    const growers = allGrowers.length > 0 ? allGrowers : [{ grower: (mainData?.grower || "--"), score: newScore().toString() }]

    for (const gro of growers) {
        await qcareApi.post('/life-test/create-life-test', {
            reportId,
            grower: gro.grower,
            score: gro.score,
            palletRef: mainData?.pallet_ref || "--",
            team: team || null
        })
    }
}

const createLifeTest = async ( pallets ) => {

    for (const pallet of pallets) {
        await qcareApi.post('/life-test/create-life-test', pallet)
    }

    return pallets[0].reportId
}

// ------------------------- HOOKS -------------------------

export const useFetchPreReport = () => {

    const navigate = useNavigate()

    const queryClient = useQueryClient()
    return useMutation(fetchPrereport, {
        onSuccess: async () => {

            await queryClient.invalidateQueries(['intakes'])
            await queryClient.invalidateQueries(['prereports'])
            toast.success('Pre Report sent successfully')
            navigate('/prereports')
        }
    })
}

export const useFetchReport = () => {

    const navigate = useNavigate()

    const queryClient = useQueryClient()
    return useMutation(fetchReport, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['reports'])
            toast.success('Report sent successfully')
            navigate('/')
        }
    })
}

export const useFetchLifeTest = () => {

    const queryClient = useQueryClient()
    return useMutation(saveLifeTest, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['lifeTests'])
            toast.success('Life test created successfully')
        }
    })
}

export const useCreateLifeTest = () => {

    const queryClient = useQueryClient()
    return useMutation(createLifeTest, {
        onSuccess: async ( reportId ) => {
            await queryClient.invalidateQueries(['lifeTests'])
            await queryClient.invalidateQueries(['report', reportId])
            toast.success('Life test created successfully')
        }
    })
}