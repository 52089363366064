import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DatePicker from "react-datepicker";


const MainDates = ({item, handleDate, disabled=false}) => {

    dayjs.extend(customParseFormat);
    // const newDate = dayjs(item[1], "DD-MM-YY").isValid() ? dayjs(item[1]).toDate() : null

    return (
        <div className='span-7 select-date'>
            <DatePicker
                className="input-date"
                dateFormat="dd / MM / yyyy"
                selected={ dayjs(item[1]).isValid() ? dayjs(item[1]).toDate() : null }
                onChange={ (date) => handleDate( item[0], date ) }
                // maxDate={ new Date() }
                placeholderText="Select a date"
                disabled={disabled}
            />
        </div>
    )
}

export default MainDates