import { IoGitMergeOutline } from "react-icons/io5"
import { InitialPallets } from "../../interfaces/intakes.reports"
import { useState } from "react";
import { ModalEditSplit } from "../ui/ModalEditSplit";

interface Props {
    pallet: InitialPallets
    reportId: string
}

export const SplitButton = ({ pallet, reportId }: Props) => {

    const [openSplit, setOpenSplit] = useState<boolean>(false)

    if(!pallet.splitPallet) return
    
    return  (
        <>
            {
                openSplit &&
                <ModalEditSplit
                    closeModal={() => setOpenSplit(false)}
                    pallet={pallet}
                    reportId={reportId}
                />
            }

            <button className="flex ml-1 split-badge" onClick={() => setOpenSplit(true)}>
                <IoGitMergeOutline className="mr-02" size={15} />
                <p className="font-small mr-02">{pallet.splitPallet?.grower_variety}:</p>
                <p className="font-small bold">{pallet.splitPallet?.boxes}</p>
            </button>
        </>
    )
}
