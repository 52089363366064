interface Props {
    action: () => void
    text:string
    iconBefore?: React.ReactNode
    iconAfter?: React.ReactNode
}
export const ButtonCustom = ({ action, text, iconBefore, iconAfter }: Props) => {
    return (
        <button
            onClick={action}
            className={`btn-new`}>
            <div className="flex flex-gap">
                {iconBefore && iconBefore}
                {text}
                {iconAfter && iconAfter}
            </div>
        </button>
    )
}
