import { useState } from 'react'

import { onlyLettersString } from '../../helpers/inputValidation'
import toast from 'react-hot-toast'
import { capitalize, inputJson } from '../../helpers/eliminarEs'

import { ModalBtn } from '../modals/ModalBtn'
import { CounterInput } from '../pages/Inputs/CounterInput'
import { useAddNewItem } from '../../api/useEditItem'
import { DetailName } from '../../interfaces/interfaces'
import { DetailObject, InitialPallets } from '../../interfaces/intakes.reports'
import { ToggleBtn } from '../ToggleBtn'

interface Props {
    closeModal: () => void
    reportId: string
    palletId: string
    isDefect?: boolean
    detailName: DetailName
    pallet: InitialPallets
}

export const ModalAddNewItem = ({ closeModal, reportId, detailName, pallet, palletId, isDefect = false }: Props) => {

    const { mutate, isLoading } = useAddNewItem()

    const [newCounter, setNewCounter] = useState<string>("0")
    const [label, setLabel] = useState("")
    const [isMajor, setIsMajor] = useState<boolean>(false)

    const sendAddItem = async () => {

        const dataArray: DetailObject[] = isDefect ? [...(pallet.pallgrow.minor || []), ...(pallet.pallgrow.major || [])] : pallet[detailName]?.data || []

        if (dataArray.some(item => item.name === inputJson(label))) return toast.error("This input already exist")
        if (!onlyLettersString(label)) return toast.error("Label name must be only letters")

        mutate({
            reportId,
            palletId,
            detailName,
            isDefect: isDefect ? isMajor ? "major" : "minor" : undefined,
            newItem: {
                label: capitalize(label.trim()),
                name: inputJson(label.trim()),
                value: newCounter
            },
        }, {
            onSuccess: () => closeModal(),
            onError: () => toast.error('Something went wrong, try later')
        })
    };

    const handleCounter = (value: string) => {
        setNewCounter(value)
    };


    return (
        <div className="modal">

            <div className="modal__card">
                {
                    <div className="modal__content">
                        <label className='bold'>Name</label>
                        <input
                            className="mb-2"
                            type="text"
                            value={label}
                            maxLength={20}
                            onChange={(e) => setLabel(e.target.value)}
                        />

                        {
                            isDefect &&
                            <>
                                <CounterInput
                                    handleInput={handleCounter}
                                    value={newCounter}
                                    max={100}
                                    className='mb-2'
                                />
                                <div className='flex-end flex-center'>
                                    <p className={`font-small ${isMajor ? "text-major" : "text-minor"} mr-05`}>{ isMajor ? "Major defect" : "Minor defect" }</p>
                                    <ToggleBtn state={isMajor} action={() => setIsMajor(item => !item)} isDefect/>
                                </div>
                            </>
                        }

                        <ModalBtn
                            closeModal={closeModal}
                            action={sendAddItem}
                            actionTitle='Add'
                            isLoading={isLoading}
                            disabled={newCounter === "0" || label.length === 0}
                        />

                    </div>
                }

            </div>



        </div >
    )
}