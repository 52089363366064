import { Suspense, useEffect, useState } from 'react'

import { ModalConfirmation } from './ModalConfirmation';
import { Charging } from './Charging';


export const ModalPhotoGallery = ({ photo, openModal, action, isLoading }) => {

    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [currentImg, setCurrentImg] = useState(null)

    useEffect(() => {
        setCurrentImg(photo.photo.imgURL || photo.photo.imgURL_low)
        return () => setCurrentImg(null)
    }, [photo])


    return (
        <div className="model open">

            {
                modalConfirmation &&
                <ModalConfirmation
                    action={() => action(photo)}
                    closeConfirmation={setModalConfirmation}
                    msg="Delete this Image?"
                    buttonName='Delete'
                    isLoading={isLoading}
                />
            }

            <button
                onClick={() => setModalConfirmation(true)}
                className="delete-img mr-1">Delete
            </button>

            {
                photo &&
                    <Suspense fallback={<Charging />}>
                        <img src={currentImg} alt="stageImg" />
                    </Suspense>
            }

            <button>
                <img
                    className="svg-icon"
                    src="/assets/img/close-icon.svg"
                    alt="close-icon"
                    onClick={() => {
                        openModal(false)
                        setCurrentImg(null)
                    }}
                />
            </button>


        </div>
    )

}
