import toast from 'react-hot-toast';
import useForm from '../../hooks/useForm';
import { InitialPallets } from '../../interfaces/intakes.reports';
import { useEditSplit } from '../../api/useEditGrower';
import { ModalBtn } from '../modals/ModalBtn';

interface Props {
    closeModal: () => void
    pallet: InitialPallets
    reportId: string
}


export const ModalEditSplit = ({ closeModal, reportId, pallet }: Props) => {

    const { mutate, isLoading } = useEditSplit()

    const { values, handleInputChange } = useForm({
        grower_variety: pallet.splitPallet?.grower_variety || '',
        boxes: pallet.splitPallet?.boxes || '',
    })

    const { grower_variety, boxes } = values

    const sendPallets = () => {

        if (grower_variety === pallet.splitPallet?.grower_variety && boxes === pallet.splitPallet?.boxes) return closeModal()

        mutate({
            reportId,
            palletId: pallet.pid,
            splitPallet: {
                grower_variety,
                boxes
            }
        }, {
            onSuccess: async () => {
                toast.success("Split pallet updated")
                closeModal()
            }
        })
    };


    return (
        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">
                    <label className="modal-label mb-2">Split Pallet</label>

                    <div className='flex flex-gap mb-1'>
                        <div className='flex-1'>
                            <p className='text-left font-normal bold mb-02'>Grower/Variety</p>
                            <input
                                className="input100"
                                type='text'
                                value={grower_variety}
                                name="grower_variety"
                                onChange={handleInputChange}
                                onFocus={(e) => e.target.select()}
                            />
                        </div>
                        <div className='flex-1'>
                            <p className='text-left font-normal bold mb-02'>Boxes</p>
                            <input
                                className="input100"
                                type='number'
                                value={boxes}
                                name="boxes"
                                onChange={handleInputChange}
                                onFocus={(e) => e.target.select()}
                            />
                        </div>
                    </div>

                    <ModalBtn
                        closeModal={closeModal}
                        isLoading={isLoading}
                        action={sendPallets}
                        actionTitle='Edit'
                    />

                </div>
            </div>



        </div>
    )
}