import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { useNavigate } from "react-router-dom";
import { DetailObject, ImageTemp, InitialPallets, SingleReportResponse } from '../interfaces/intakes.reports';
import { ActionNum, DetailName, GradeNum, ScoreNum } from "../interfaces/interfaces";
import { SingleRepackResponse } from "../interfaces/interface.responses";
// import { sleep } from "../helpers/sleep";




const getTempRepack = async (id: string) => {
    const { data } = await qcareApi.get<SingleRepackResponse>(`/repack/${id}`);
    return data
};



//------------------------- HOOKS --------------------------------

export const useTempRepack = (id: string) => {
    return useQuery(
        ['repackTemp', id],
        () => getTempRepack(id),
        { 
            staleTime: 1000 * 60 * 10,
            select: (data) => { return data.singleRepack }
        }
    );
}
