import qcareApi from '../../api/qcareApi';
import { allSuppliers } from '../../helpers/suppliersList';
import { activeMembersAllTeams, allTeamsMembers } from '../../helpers/teamFilter';


export const teams = data => (
    {
        teamsOwner: allTeamsMembers(data.teamsOwner) || [],
        teamsAdmin: activeMembersAllTeams(data.teamsAdmin) || [],
        teamsUser: activeMembersAllTeams(data.teamsUser) || [],
        teams: [...data.teamsAdmin.map(r => ({ _id: r._id, name: r.name })), ...data.teamsUser.map(r => ({ _id: r._id, name: r.name }))] || [],
    }
)

export const startRegister = (name, email, password, toast) => {
    return async (dispatch, getState) => {

        try {

            const { data } = await qcareApi.post('/auth/register', { name, email, password })

            localStorage.setItem('token', data.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            toast.success('Register Ok!')

            let user = {
                uid: data.uid,
                name: data.name,
                lastname: data.lastname,
                profile: data.profile,
                email: data.email,
                company: data.company,
                phone: data.phone,
                contacts: data.contacts || [],
                // suppliers: data.suppliers || [],
                suppliers: allSuppliers(data.uid, data.suppliers || [], teams(data).teamsAdmin, teams(data).teamsUser),
                ...teams(data)
            }

            dispatch(login(user))

        } catch (errors) {

            dispatch({ type: 'REGISTER_ERROR', payload: errors.response.data.errors })
            const err = getState().auth.registerErrors

            err.map(erry => (
                toast.error(erry.msg)
            ))
        }
    }
}

export const startLogin = (email, password, toast) => {
    return async (dispatch) => {

        try {

            const { data } = await qcareApi.post('/auth/login', { email, password })

            localStorage.setItem('token', data.token);
            localStorage.setItem('token-init-date', new Date().getTime());

            let user = {
                uid: data.uid,
                name: data.name,
                lastname: data.lastname,
                email: data.email,
                profile: data.profile,
                company: data.company,
                phone: data.phone,
                contacts: data.contacts || [],
                suppliers: allSuppliers(data.uid, data.suppliers || [], teams(data).teamsAdmin, teams(data).teamsUser),

                ...teams(data)

            }

            dispatch(login(user))

            toast.success('Login Ok!')

        } catch (errors) {
            toast.error('Email or password incorrect')
        }
    }
}

export const startChecking = () => {
    return async (dispatch) => {

        // if(!localStorage.getItem('token')){return dispatch(logout())}

        try {
            const { data } = await qcareApi.get('/auth/me')

            let user = {
                uid: data.uid,
                name: data.name,
                email: data.email,
                lastname: data.lastname,
                profile: data.profile,
                phone: data.phone,
                company: data.company,
                contacts: data.contacts || [],
                suppliers: allSuppliers(data.uid, data.suppliers || [], teams(data).teamsAdmin, teams(data).teamsUser),

                ...teams(data)
            }

            // user.suppliers = allSuppliers(user.uid, data.suppliers || [], user.teamsAdmin, user.teamsUser)

            dispatch(login(user))


        } catch (errors) {
            dispatch(checkingFinish())
            dispatch(logout())
        }
    }
}

export const updateState = (payload) => {
    return async (dispatch) => {
        dispatch(login(payload))
    }
}

export const startLogout = () => {

    return (dispatch) => {
        localStorage.clear()
        dispatch(logout())
    }
}


const checkingFinish = () => ({ type: "CHECKING_OFF" });


const login = (user) => ({
    type: 'START_LOGIN',
    payload: user
})


const logout = () => ({
    type: 'LOGOUT',
})

