
// export function totalSamples(format = "0*0") {

//   if (format.includes("*")) {
//     let samples = format.split('*')[0] || 0

//     if (samples > 1 && !isNaN(samples)) { return Number(samples) }
//     else { return 1 }

//   } else { return 1 }
// };

export function formatSplit(value:string = "0*0"): number {

  const regex = /(?:\d+\s*[x*]\s*)?(\d+)\s*(g|gr|k|kg)/i;
    const match = value.trim().match(regex);

    if (!match) { return 1 }

    const [, weight, unit] = match;

    // Convertir el valor a gramos
    let weightInGrams = parseInt(weight, 10);

    if (unit.toLowerCase().startsWith('k')) {
        weightInGrams *= 1000;
    }

    return weightInGrams;
}
// export function formatSplit(valor = "0*0"): number {

//   if (valor.includes("k")) {
//     let res = valor.split(/[k*]+/)
//     return isNaN( +res[res.length - 2] * 1000 ) ? 0 : +res[res.length - 2] * 1000

//   } else {
//     let res = valor.split(/[g*]+/)
//     return isNaN(+res[res.length - 2]) ? 0 : +res[res.length - 2]
//   }
// }

export function totalKilos(format: string = "0*0", totalBoxes: string = "0") {

  if (!format || !totalBoxes) return 1

  if (format.includes("*")) {
    let num1: number = Number(format.split('*')[0]) || 1
    let num2: number

    if (format.includes("k")) num2 = parseInt((format.split('*')[1]).split("k")[0]) * 1000 || 1
    else num2 = parseInt((format.split('*')[1]).split('g')[0]) || 1

    return ((num1 * num2 * Number(totalBoxes)) / 1000)
  } else {
    let num2 = parseInt(format.split("k")[0]) * 1000 || 1
    return ((1 * num2 * Number(totalBoxes)) / 1000)
  }
};

export function totalSamples(format: string = "0*0") {

  if (format.includes("*")) {
    let samples = Number(format.split('*')[0]) || 0

    if (samples > 1 && !isNaN(samples)) { return Number(samples) }
    else { return 1 }

  } else { return 1 }
};