import toast from 'react-hot-toast';

import qcareApi from '../../api/qcareApi'
import useForm from '../../hooks/useForm'

export const ModalEditContact = ({ theContact, setOpenEditContact, update }) => {

        const { values, handleInputChange } = useForm({
            supplier: theContact.supplier || "",
        ref: theContact.ref || "",
        contactName: theContact.contactName || "",
        email: theContact.email || ""
    })
    const { supplier, ref, contactName, email } = values

    const closeModal = () => {
        setOpenEditContact(false)
    }

    const sendEditItem = async () => {

        try {
            await qcareApi.post('/auth/edit-contact', { id: theContact.id, supplier, ref, contactName, email: email })
            toast.success(`Contact edited`)

        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        } finally {
            setOpenEditContact(false)
            update()
        }
    };

    return (
        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">
                    <label className="modal-label mb-2">Edit Contact</label>

                    <div className='mb-1'>
                        <p className='text-left mb-02'>Supplier</p>
                        <input
                            className='input100'
                            type='text'
                            value={supplier}
                            name="supplier"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='mb-1'>
                        <p className='text-left mb-02'>Ref</p>
                        <input
                            className='input100'
                            type='text'
                            value={ref}
                            name="ref"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='mb-1'>
                        <p className='text-left mb-02'>Contact name</p>
                        <input
                            className='input100'
                            type='text'
                            value={contactName}
                            name="contactName"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='mb-1'>
                        <p className='text-left mb-02'>Email</p>
                        <input
                            className='input100'
                            type='text'
                            value={email}
                            name="email"
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="modal__botones mt-2">
                        <button
                            className="modal-btn btn-close mr-05"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                        <button
                            className="modal-btn btn-add ml-05"
                            onClick={sendEditItem}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>



        </div>
    )
}