import { Image, Text, View } from "@react-pdf/renderer"
import { InitialPallets } from "../../interfaces/intakes.reports"
import { ScoreBG, ScoreColor, ScoreNum } from "../../interfaces/interfaces"
import { globalStyles } from "./DocuPdf"
import { useOldDate } from "../../hooks/useOldDate"

interface Props {
    pallet: InitialPallets
    index: number
    date: Date
    isNewReport: boolean
}

interface DataBox {
    label?: string
    value: string
    icon?: string
    score?: boolean
}

export const PDFPalletData = ({ pallet, index, date, isNewReport }: Props) => {

    const { oldDate, scoreNumber } = useOldDate(date, pallet.score)

    const split = isNewReport ? "splitPallet" : "addGrower"

    const temperature = 
            pallet.prereport?.details?.appareance.find(item => item.name === "temperature_c")?.valor?.toString()
            || pallet.prereport?.details?.appareance.find(item => item.name === "temperature_c")?.value?.toString()
            || pallet.details?.appareance.find(item => item.name === "temperature_c")?.valor?.toString()
            || pallet.appearance?.data.find(item => item.name === "temperature_c")?.value?.toString()
            || ""


    const DataBox = ({ label, value, score = false, icon }: DataBox) => {

        const bgColor = score ? ScoreBG[`score${scoreNumber as ScoreNum}`] : "#f7f9fb"
        const scoreColor = score ? ScoreColor[`score${scoreNumber as ScoreNum}`] : "#353644"

        return (
            <View style={[globalStyles.flexCenter, { marginLeft: 10, height: 28, width: 90, borderRadius: 8, backgroundColor: bgColor }]}>
                {
                    score
                        ? <Text style={[globalStyles.bold, { fontSize: '12px', color: scoreColor }]}>
                            {`${value as ScoreNum} of ${oldDate ? "5" : "8"}`}
                        </Text>
                        :
                        <View style={globalStyles.flexRow}>
                            {icon &&
                                <Image
                                    src={`../../assets/img/${icon}.png`}
                                    style={{ width: "15px", height: "15px", objectFit: 'contain', marginRight: 5 }}
                                />
                            }
                            <View>
                                <Text style={{ fontSize: '8px', color: '#808291' }}>{label || '--'}</Text>
                                <Text style={[globalStyles.bold, globalStyles.textColor, { fontSize: '10px' }]}>{value || '--'}</Text>
                            </View>
                        </View>
                }
            </View>
        )
    };


    return (
        <View style={[globalStyles.item, { marginBottom: 5 }]}>

            <View style={globalStyles.flexRow}>
                <Text style={[globalStyles.bold, { marginRight: 20, fontSize: "16px", color: "#353644", paddingTop: 2 }]}>Pallet #{isNewReport ? pallet.pallet : index + 1}</Text>
                {
                    pallet[split] &&
                    <View style={[globalStyles.flexRow]}>
                        <Image
                            src="../../assets/img/pdf-box.png"
                            style={{ width: "15px", height: "15px", objectFit: 'contain', marginRight: 5 }}
                        />
                        <View style={{ marginRight: 15 }}>
                            <Text style={{ fontSize: '8px', color: '#808291' }}>Grower / Variety</Text>
                            <Text style={[globalStyles.bold, { fontSize: '10px' }]}>{pallet[split]?.grower_variety ?? "--"}</Text>
                        </View>
                        <View>
                            <Text style={{ fontSize: '8px', color: '#808291' }}>Boxes</Text>
                            <Text style={[globalStyles.bold, { fontSize: '10px' }]}>{pallet[split]?.boxes ?? "--"}</Text>
                        </View>
                    </View>
                }
            </View>

            <View style={globalStyles.flexRow}>
                <DataBox value={temperature ? `${temperature}°C` : '--'} label="Temperature" icon="pdf-temperature" />
                <DataBox value={pallet.score} score />
            </View>


        </View>
    )
}
