import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import useMediaQuery from '../../hooks/useMediaQuery';
import { colorsArray } from '../../helpers/colorsArray';
import { valorPallgrow } from '../../helpers/eliminarEs';

ChartJS.register(ArcElement, Tooltip, Legend);

export const ChartPie = ({ pallgrow = [] }) => {

    const matches = useMediaQuery("(min-width: 768px)");

    let labels = []
    let dataFinal = []

    const noWeight = pallgrow.filter(p => p.name !== "weight_10") || []
    const pallgrowArray = noWeight.filter(p => p.valor !== "0" && p.valor !== "" && valorPallgrow(p.valor) > 0) || []

    for (let item of pallgrowArray) {
        labels.push(item.label)
        Array.isArray(item.valor)
            ? dataFinal.push(valorPallgrow(item.valor) * 100)
            : dataFinal.push(item.valor)
    }

    let colors = colorsArray.slice(0, (pallgrowArray.length)) || ["#d6534c"]


    const chartdata = {
        labels: labels || "No Data",
        datasets: [
            {
                label: "Markets Monitored",
                data: dataFinal || 0,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        // responsive: true,
        // maintainAspectRatio: true,
        plugins: {
            legend: {
                position: matches ? 'left' : 'top',
            },
            labels: {
                render: 'labels'
            }
        },

        datalabels: {
            display: true,
            color: "white",
        },
        tooltips: {
            backgroundColor: "#5a6e7f",
        },
    }

    return (
        <div>
            <Doughnut
                options={options}
                data={chartdata}
            />
        </div>
    )
}
