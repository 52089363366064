import { useMutation } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { ModelSupplier } from "../interfaces/interfaces";

interface ModelId{
    id: string,
    model: ModelSupplier,
}

interface CreateSupplier extends ModelId{
    name: string,
    ref: string,
}

interface EditSupplier extends ModelId{
    supplierId: string,
    name: string,
    ref: string,
}

interface EditContact extends ModelId{
    supplierId: string,
    contactId: string,
    name: string,
    email: string,
}

interface CreateContact extends ModelId{
    supplierId: string,
    name: string,
    email: string,
}

interface RemoveSupplier extends ModelId{
    supplierId: string,
}

interface RemoveContact extends ModelId{
    supplierId: string,
    contactId: string,
}

const createSupplier = async ( query:CreateSupplier ) => {
    const { id, ...body } = query
    const { data } = await qcareApi.post(`/supplier/create-supplier/${id}`, body);
    return data
}

const editSupplier = async ( query:EditSupplier ) => {
    const { id, ...body } = query
    const { data } = await qcareApi.put(`/supplier/edit-supplier/${id}`, body);
    return data
}

const createContact = async ( query:CreateContact ) => {
    const { id, ...body } = query
    const { data } = await qcareApi.post(`/supplier/add-contact/${id}`, body);
    return data
}

const editContact = async ( query:EditContact ) => {
    const { id, ...body } = query
    const { data } = await qcareApi.put(`/supplier/edit-contact/${id}`, body);
    return data
}

const removeSupplier = async ( query:RemoveSupplier ) => {
    const { id, ...body } = query
    const { data } = await qcareApi.put(`/supplier/remove-supplier/${id}`, body);
    return data
}

const removeContact = async ( query:RemoveContact ) => {
    const { id, ...body } = query
    const { data } = await qcareApi.put(`/supplier/remove-contact/${id}`, body);
    return data
}

// ------------------------- HOOKS -------------------------

export const useCreateSupplier = () => {
    return useMutation(createSupplier)
}

export const useEditSupplier = () => {
    return useMutation(editSupplier)
}

export const useCreateContact = () => {
    return useMutation(createContact)
}

export const useEditContact = () => {
    return useMutation(editContact)
}

export const useRemoveSupplier = () => {
    return useMutation(removeSupplier)
}

export const useRemoveContact = () => {
    return useMutation(removeContact)
}
// export const useCreateSupplier = () => {
//     const queryClient = useQueryClient()
//     return useMutation(createSupplier, {
//         onSuccess: async () => {
//             await queryClient.invalidateQueries(['reports'])
//             await queryClient.invalidateQueries((['lifeTests']))
//         }
//     })
// }