import { useNavigate } from "react-router-dom"
import ButtonSubmit from "./ButtonSubmit"

export const ErrorFound = () => {

    const navigate = useNavigate();

    return (
        <section className="flex-center flex-col w-100 vh-100">
            <h1 className="text-center mb-1">Error 404</h1>
            <ButtonSubmit title="Back to Home" action={() => navigate('/')} />
        </section>
    )
}
