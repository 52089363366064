import { useEffect, useState } from "react";
import { InitialPallets } from "../../interfaces/intakes.reports";

interface Props {
    setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
    pallet: InitialPallets
}

export const PalletComments = ({ pallet, setPallets }:Props) => {

    const [newComments, setNewComments] = useState<string>("")

    useEffect(() => {
        setNewComments(pallet.comments || "")
    }, [pallet.comments])
    

    const handleComments = (e:React.ChangeEvent<HTMLTextAreaElement>) => {

        setPallets(pallets => pallets.map(pall => {
            if (pall.pid === pallet.pid) {
                return {
                    ...pall,
                    comments: e.target.value
                }
            }
            return pall
        }))
    }

    return (
        <form className="pallet-comments">
            <div className="mb-05">
                <p className='mb-05 bold'>Pallet comments</p>
                <textarea onChange={handleComments} name="comments" className="comments-report" cols={30} rows={3} defaultValue={newComments} />
            </div>
        </form>
    )
}
