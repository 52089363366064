import { useState } from 'react'
import { Carousel } from './ui/Carousel'
import { ImageTemp, ImageType } from '../interfaces/intakes.reports'

interface Props {
    images: ImageType[]
    deleteImage: (image: ImageType) => Promise<void>
    fullwidth?: boolean
    imagesTemp?: ImageTemp[]
    removeTempImage?: (id: string) => void
}

export const Gallery = ({ images, deleteImage, fullwidth, imagesTemp, removeTempImage }: Props) => {

    const [index, setIndex] = useState(0)
    const [modalGallery, setModalGallery] = useState(false)

    const handleImage = (i: number) => {
        setIndex(i)
        setModalGallery(true)
    };

    return (
        <div className={`grid-gallery ${fullwidth ? "gallery-full" : null} mt-1`}>

            {
                modalGallery &&
                <Carousel
                    closeModal={() => setModalGallery(false)}
                    deleteImage={deleteImage}
                    images={images}
                    index={index}
                />
            }

            {
                images.map((image, index) => (
                    <div key={image.key} className="grid-gallery__box">
                        <img src={image.imgURL_low} alt={image.key}
                            onClick={() => handleImage(index)}
                        />
                    </div>
                ))
            }

            {
                (imagesTemp && removeTempImage) && imagesTemp.length > 0 &&

                imagesTemp.map((img) => (
                    <div key={img.url}
                        className="selected-container"
                    >
                        <div className="selected-image">
                            <img src={img.url} alt="selected" loading="lazy" />
                        </div>

                        <button
                            className='remove-img-btn'
                            onClick={() => removeTempImage(img.id)}
                        >
                            <img src='../assets/img/close-icon.svg' alt='close-icon' />

                        </button>
                    </div>
                ))
            }


        </div>
    )
}
