import { SpinnerCustom } from "./SpinnerCustom"


interface Props {
    title?: string,
    action: () => void,
    color?: "green" | "blue",
    isLoading?: boolean,
    center?: boolean
    small?: boolean
    disabled?: boolean
}

const ButtonSubmit = ({ disabled, title = "Send", action, color, isLoading = false, center = false, small = false }: Props) => {

    const btnColor = {
        green: "btn-green",
        blue: "btn-blue"
    }

    return (
        <div className={`${center ? "btn-center" : null}`}>
            <button
                disabled={isLoading || disabled}
                onClick={() => action()}
                className={`btn ${color ? btnColor[color] : ""} ${isLoading && "btn-disabled"} ${small ? "btn-small" : null} flex`}
            >
                <div className="flex">
                    { title }
                    { isLoading && <SpinnerCustom center size="xxs" color="white" className="ml-05"/> }
                </div>
            </button>
        </div>
    )
}

export default ButtonSubmit

