import { useState } from "react"
import { ScoreBtn } from "./ScoreBtn"
import { InitialPallets } from "../../interfaces/intakes.reports"
import { colorGrade } from "../../helpers/colorGrade"
import { colorAction } from "../../helpers/colorAction"
import { Status } from "../../interfaces/interfaces"
import { ModalEditStatus } from "../ui/ModalEditStatus"

interface Props {
    pallet: InitialPallets
    reportId: string
    status: Status
}

export const ScoreEdit = ({ pallet, reportId, status }: Props) => {

    const [openModalScore, setOpenModalScore] = useState<boolean>(false)

    return (

        <>
            {
                openModalScore &&
                <ModalEditStatus
                    closeModal={() => setOpenModalScore(false)}
                    reportId={reportId}
                    status={status}
                    pallet={pallet}
                />
            }

            <div className='mt-1'>

                {
                    status === "score" &&
                    <div className="score-wraper">
                        <p className="bold">Score</p>
                        <button
                            onClick={() => setOpenModalScore(true)}
                            className="score-btn"
                        ><ScoreBtn score={pallet.score || "0"} />
                        </button>
                    </div>
                }

                {
                    status === "grade" &&

                    <div className='score-wraper'>
                        <p className="bold">QC Appreciation</p>
                        <button
                            onClick={() => setOpenModalScore(true)}
                            className="score-btn"
                        > {colorGrade(pallet.grade)}
                        </button>
                    </div>
                }

                {
                    status === "action" &&

                    <div className='score-wraper'>
                        <p className="bold">Suggested commercial action</p>
                        <button
                            onClick={() => setOpenModalScore(true)}
                            className="score-btn"
                        > {colorAction(pallet.action)}
                        </button>
                    </div>
                }


            </div>
        </>
    )
}
