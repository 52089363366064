import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { RepacksResponse } from "../interfaces/interface.responses";


interface Params {
    page: number;
    team?: string;
  }

const getAllRepacks = async (page:number, team:string|undefined) => {

    let params:Params = { page: page }
    if (team) { params.team = team }

    const { data } = await qcareApi.get<RepacksResponse>("/repack", { params });
    return data
};

const removeRepack = async (id:string) => {
    const { data } = await qcareApi.get(`/repack/delete/${id}`);
    return data
}


// ------------------------- HOOKS -------------------------

export const useRepacks = (page:number, team:string|undefined) => {
    const data = useQuery(
        ['repacks', page, team],
        () => getAllRepacks(page, team),
        { 
            keepPreviousData: true,
            staleTime: 1000 * 60 * 30
        }
    );

    return data
}


export const useRemoveRepack = () => {
    const queryClient = useQueryClient()
    return useMutation(removeRepack, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['repacks'])
        }
    })
}