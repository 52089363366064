import { useNavigate } from 'react-router-dom';

export const PagButtonSearch = ({ num = 1, page, query }) => {
   
    const navigate = useNavigate();

    return (
        <>
            {
                num === " "
                    ? <p className='symbols'>&#8250;</p>
                    : <button
                        className={`pag-btn ${(num === page) ? "pag-hl" : "pag-w"}`}
                        onClick={ () => {
                            localStorage.setItem('filter', `/search/page=${num}&${query}`)
                            navigate(`/search/page=${num}&${query}`)
                        } }
                    >
                        <p>{num}</p>
                    </button>
            }
        </>
    )
}
