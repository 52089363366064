import { useMutation, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { Create } from "../interfaces/interfaces";

interface RemoveProps {
    id:string,
    model: Create
}

const remove = async ({ id, model } : RemoveProps) => {
    const { data } = await qcareApi.put(`/update/remove/${id}`, {model});
    return data
}

// ------------------------- HOOKS -------------------------

export const useRemove = () => {
    const queryClient = useQueryClient()
    return useMutation(remove, {
        onSuccess: async (data) => {
            await queryClient.invalidateQueries([data.query])
        }
    })
}