import qcareApi from "../api/qcareApi";
import { v4 as uuidv4 } from 'uuid';
import { resizeFile } from "./resizeFile";


const newArr = (repId, pid, detailName, arr) => {
    return arr.map(item => (
        {
            repId,
            pid,
            photo: item.photo,
            nameId: item.name,
            detailName
        }
    ))
}

export const photoToUpload = (repId, pallets) => {

    let allPhotos = []

    for (const pallet of pallets) {

        if (pallet.labels) {
            allPhotos.push(...newArr(repId, pallet.id, "labels", pallet.labels.filter(lab => lab.photo)))
        }

        if (pallet.appareance) {
            allPhotos.push(...newArr(repId, pallet.id, "appareance", pallet.appareance.filter(app => app.photo)))
        }

        if (pallet.pallgrow) {
            allPhotos.push(...newArr(repId, pallet.id, "pallgrow", pallet.pallgrow.filter(pall => pall.photo)))
        }
    }

    return allPhotos
};

export const uploadPhoto = async (file, prereport=false) => {
    const formData = new FormData();

    formData.append('preId', file.repId)
    formData.append('pid', file.pid)
    formData.append('nameId', file.nameId)
    formData.append('detailName', file.detailName)
    formData.append('uploady', file.photo)

    try {
        await qcareApi.post(`/${prereport ? "prereport" : "report"}/photos`, formData)
    } catch (error) {
        throw new Error('Something went wrong')
    }
}


export const tempFile = async (file) => {

    try {
        return {
            id: uuidv4(),
            url: URL.createObjectURL(file),
            file: await resizeFile(file),
        }
    } catch (error) {
        throw new Error('Something went wrong')
    }
}

export const tempFileLS = async (file) => {

    try {
        return {
            id: uuidv4(),
            url: URL.createObjectURL(file),
            file
        }
    } catch (error) {
        throw new Error('Something went wrong')
    }
}
