import { v4 as uuidv4 } from 'uuid';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useFindSuppliers } from '../../hooks/useFindSuppliers';
import { useEffect, useState } from 'react';
import EmailFormBtn from './EmailFormBtn';
import { IoAddCircle, IoMailOutline } from 'react-icons/io5';
import { TempContact } from './ModalSendPDF';
import { ModalEmailContacts } from '../modals/ModalEmailContacts';

interface Props {
    reportSupplier: string | null
    mainDataSupplier: string
    mailTo: TempContact[]
    setMailTo: React.Dispatch<React.SetStateAction<TempContact[]>>
    team: string | null
    closeModal: () => void
    setStep: React.Dispatch<React.SetStateAction<number>>
}

export const StepOne = ({ reportSupplier, mainDataSupplier, team, mailTo, setMailTo, closeModal, setStep }: Props) => {

    const [modalContacts, setModalContacts] = useState<boolean>(false)

    const { supplier, supplierList } = useFindSuppliers({ team, reportSupplier, mainDataSupplier })

    useEffect(() => {

        if (supplier) {
            let supplierContacts: TempContact[] = supplier.contacts.map(contact => (
                {
                    id: contact._id,
                    check: true,
                    name: contact.name,
                    email: contact.email
                }
            ))
            setMailTo(mails => [...mails, ...supplierContacts])
        }
    }, [supplier])

    return (
        <>
            {
                modalContacts &&
                <ModalEmailContacts
                    supplierList={supplierList!}
                    closeModal={() => setModalContacts(false)}
                    setMailTo={setMailTo}
                />
            }
            <div className='supplier-emails mb-1'>
                <h4 className='mb-1'>Supplier Contacts</h4>


                <div className='flex mb-1'>
                    <p className='font-small'>Contacts</p>
                    <button className='flex ml-02' onClick={() => setModalContacts(true)}><IoAddCircle /></button>
                </div>


                {
                    mailTo.length > 0
                        ?
                        mailTo.map(contact => (
                            <div key={contact.id} className="supplier-contact">
                                <input
                                    type="checkbox"
                                    checked={contact.check}
                                    onChange={() => setMailTo(mailTo.map(item => {
                                        if (item.id === contact.id) {
                                            return {
                                                ...item,
                                                check: !item.check
                                            }
                                        } else {
                                            return item
                                        }
                                    }))}
                                />

                                <div className="ml-1">
                                    <p className='font-small bold'>{contact.name}</p>
                                    <p className='font-small text-mute'>{contact.email}</p>
                                </div>
                            </div>
                        ))
                        : <div className='flex-center p-1 mt-1'><p className='font-small'>No contacts found</p></div>
                }
            </div>

            <EmailFormBtn
                actionNext={() => setStep(step => step + 1)}
                closeModal={closeModal}
            />
        </>
    )
}
