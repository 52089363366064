
export const colorEU = (value='0') => {

    switch (value) {
        case "0": return "--"
        case "1": return "--"
        case "2": return "--"
        case "3": return "--"
        case "4": return "--"
        case "5": return "--"
        case "": return "--"
                
        default:
            return value
    }
};


