import { useState } from 'react'
import { ModalBtn } from './ModalBtn'
import { useCreateSupplier, useEditSupplier } from '../../api/useSupplier'
import { Supplier, SupplierList } from '../../interfaces/interfaces.auth'
import { useDispatch } from 'react-redux'
import { startChecking } from '../../store/asyncMethods/authMethods'
import { hasLength } from '../../helpers/validators'
import toast from 'react-hot-toast'

interface Props {
    target: SupplierList
    supplier: Supplier
    closeModal: () => void
}


export const ModalEditSupplier = ({ closeModal, target, supplier }: Props) => {

    const dispatch = useDispatch()

    const [newRef, setNewRef] = useState<string>(supplier.ref)
    const [newName, setNewName] = useState<string>(supplier.name)

    const { mutate, isLoading } = useEditSupplier()

    const addSupplier = () => {

        const cleanRef = newRef.trim()
        const cleanName = newName.trim()
        
        if( cleanRef === supplier.ref && cleanName === supplier.name) return closeModal()
        if(target.suppliers.filter(sup => sup._id !== supplier._id).find(sup => sup.ref === cleanRef || sup.name === cleanName)) return toast.error("Supplier already exists")

        if(!hasLength(cleanRef) || !hasLength(cleanName)) return toast.error("Please fill in all fields")
        if(cleanName.length < 3) return toast.error("Name should be at least 3 characters")
        if( cleanRef.length < 2 || cleanRef.length > 6) return toast.error("Ref should be between 2 and 5 characters")
        
        mutate({
            id: target.id,
            model: target.model,
            supplierId: supplier._id,
            ref: cleanRef,
            name: cleanName
        }, {
            onSuccess: () => {
                dispatch(startChecking())
                closeModal()
            },
            onError: (error) => console.log(error)
        })
    };

    return (

        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">

                    <p className='font-normal mb-02 ml-02'>Ref</p>
                    <input
                        type="text"
                        value={newRef}
                        maxLength={6}
                        onChange={(e) => setNewRef(e.target.value.toUpperCase())}
                    />

                    <>
                        <p className='mt-2 font-normal mb-02 ml-02'>Name</p>
                        <input
                            className="mb-1"
                            type="text"
                            value={newName}
                            maxLength={40}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </>

                    <ModalBtn
                        action={addSupplier}
                        closeModal={closeModal}
                        actionTitle='Edit Supplier'
                        isLoading={isLoading}
                    />

                </div>
            </div>
        </div>

    )
}
