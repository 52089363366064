import { major } from "../data/major";
import { DetailObject, PallGrowDefects } from "../interfaces/intakes.reports";
import { Fruit } from "../interfaces/interfaces";
import { valorPallgrow } from "./eliminarEs";
import { percentageNew, percentageNum } from "./percentage";



export const defectsTable = (pallgrow: DetailObject[], fruit: Fruit = 'other', formatGr: any, weight_format: any): PallGrowDefects => {

    let weight10 = ''
    let majorDefects: DetailObject[] = []
    let minorDefects: DetailObject[] = []

    const totalValue = (values: DetailObject) => {
        return {
            ...values,
            average: valorPallgrow(values.valor),
            percentage: percentageNum(formatGr, weight_format, values.valor as string[])
        }
    };

    pallgrow.forEach((defect) => {
        const value = percentageNum(formatGr, weight_format, defect.valor as string[])

        if (defect.name === "weight_10") return weight10 = defect.valor as string
        if (major(fruit).includes(defect.name) || +value >= 5) majorDefects.push(totalValue(defect))
        else minorDefects.push(totalValue(defect))
    })

    const totalMajor = parseFloat(majorDefects.reduce((a, b) => a + (+(b.percentage ?? 0)), 0).toFixed(1))
    const totalMinor = parseFloat(minorDefects.reduce((a, b) => a + (+(b.percentage ?? 0)), 0).toFixed(1))
    const totalRest = Number((100 - (totalMajor + totalMinor)).toFixed(1))
    const totalDefects = Number((totalMajor + totalMinor).toFixed(1))

    return {
        weight10,
        major: majorDefects,
        minor: minorDefects,
        totalMajor,
        totalMinor,
        totalDefects,
        totalRest
    }

};

export const pallgrowDefects = (data: DetailObject[], major: DetailObject[], minor: DetailObject[]) => {

    const weight = data.find(item => item.name === "weight_10")?.value
    const formatGr = data.find(item => item.name === "format_gr")?.value
    const samples = data.find(item => item.name === "samples")?.value

    let majorDefects: DetailObject[] = []
    let minorDefects: DetailObject[] = []

    const totalValue = (values: DetailObject) => {
        return {
            ...values,
            average: valorPallgrow(values.valor),
            percentage: percentageNew(Number(formatGr), Number(weight), Number(values.value) as number, Number(samples))
        }
    };

    major.forEach((defect) => majorDefects.push(totalValue(defect)))
    minor.forEach((defect) => minorDefects.push(totalValue(defect)))

    const totalMajor = parseFloat(majorDefects.reduce((a, b) => a + (+(b.percentage ?? 0)), 0).toFixed(1))
    const totalMinor = parseFloat(minorDefects.reduce((a, b) => a + (+(b.percentage ?? 0)), 0).toFixed(1))
    const totalRest = Number((100 - (totalMajor + totalMinor)).toFixed(1))
    const totalDefects = Number((totalMajor + totalMinor).toFixed(1))

    return {
        major: majorDefects,
        minor: minorDefects,
        totalMajor,
        totalMinor,
        totalDefects,
        totalRest
    }

}