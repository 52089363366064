import { useState } from 'react'
import { IoEllipsisVertical } from 'react-icons/io5'
import { Popup } from './Popup'

interface Props {
    children: React.ReactNode
}

function PopUpButton({ children }: Props) {

    const [openActions, setOpenActions] = useState<boolean>(false)

    return (
        <div className='position-relative'>
            <button
                className='accordion-open-btn ml-05'
                onClick={() => setOpenActions(action => !action)}>
                <IoEllipsisVertical />
            </button>

            <Popup
                show={openActions}
                onClickOutside={() => { setOpenActions(false) }}
            >
                {children}
            </Popup>


        </div>

    )
}

// function PopUpItem({ title }) {
//     return (
//         <div className="list-item">
//             {title}
//         </div>
//     );
// }

// PopUpButton.Item = PopUpItem;

export default PopUpButton;
