import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';

import toast from 'react-hot-toast';
import { tituloEs } from '../../helpers/eliminarEs'
import { ModalEditDay } from './ModalEditDay';
import { ModalLifeImage } from './ModalLifeImage';
import { Gallery } from '../Gallery';
import { useDeleteLifeImage } from '../../api/useLifeTest';


export const AccordeonLife = ({ lifeId, _id, conditions, temperature, images, index }) => {

    const [activeAccordeon, setActiveAccordeon] = useState(true)

    const [openModal, setOpenModal] = useState(false)
    const [openModalFile, setOpenModalFile] = useState(false)

    const { mutateAsync } = useDeleteLifeImage()

    const deleteImage = async (image) => {
        await mutateAsync({
            lifeId,
            testId: _id,
            key: image.key,
            key_low: image.key_low
        }, {
            onSuccess: () => toast.success("Image deleted") ,
            onError: () => toast.error('Something went wrong')
        })
    };

    return (
        <div className={`${activeAccordeon ? "accordeon show" : "accordeon"} card-accordeon p-1 mb-1`}>

            {
                openModal &&
                <ModalEditDay
                    closeModal={() => setOpenModal(false)}
                    conditions={conditions}
                    temperature={temperature}
                    lifeId={lifeId}
                    dayId={_id}
                    dayNum={index + 1}
                />
            }

            {
                openModalFile &&
                <ModalLifeImage
                    closeModal={() => setOpenModalFile(false)}
                    lifeId={lifeId}
                    dayId={_id}
                />
            }

            <button
                className="pallet-ref mb-1 accordeon-button"
                onClick={() => setActiveAccordeon(!activeAccordeon)}
            >
                <h3 className="mr-1">Day {index + 1}</h3>
                <img src="/assets/img/chevron-down.svg" alt="chevron-down" className={activeAccordeon ? "chevron-down" : "chevron-up"} />
            </button>

            <div className='edit-day mb-05'>
                <div className='flex'>
                    <p className='bold mr-05'>Temperature:</p>
                    <p className='bold'>{temperature || '--'}°</p>
                </div>
                <button
                    onClick={() => setOpenModal(true)}
                >
                    <img src="/assets/img/edit.svg" alt="edit-day" />
                </button>
            </div>


            <div>
                <p className='bold mb-02'>Conditions:</p>

                {
                    conditions.length > 0
                        ?
                        <div className='modal-conditions'>
                            {conditions.map(con => (
                                <button key={uuidv4()} className="mr-05 mb-05 modal-conditions__item">
                                    {tituloEs(con)}
                                </button>
                            ))
                            }
                        </div>
                        :
                        <p className='font-small text-muted mb-2 pb-02'>(None)</p>
                }
            </div>

            {
                images.length > 0 &&
                <Gallery images={images} deleteImage={deleteImage} />
            }

            <div>
                <button
                    onClick={() => setOpenModalFile(true)}
                    className="add-file">
                    <img src="/assets/img/upload-life.svg" alt="img-icon" />
                    <span>Add Pictures</span>
                </button>
            </div>

        </div>

    )
}