import { createContext as repackContext, useState } from 'react'
// import qcareApi from '../api/qcareApi';
// import { newMainData } from '../data/mainData';
// import { palletData } from '../data/pallet';
// import { palletPrereport } from '../data/prereport';
// import { alertMsg } from '../helpers/alertMsg';
// import { formatSplit, totalKilos, totalSamples } from '../helpers/formatSplit';
import { fruitType } from '../helpers/fruitType';
import { 
    // DetailObject, ImageTemp,
    ImageType, InitialPallets, MainData } from '../interfaces/intakes.reports';
import { DetailName, Fruit, Status } from '../interfaces/interfaces';
import { InitialRepackPallets, PrePostRepack, Repack } from '../interfaces/intakes.repack';
import { handleCheck } from '../helpers/handlers';
import { ObjectType } from 'typescript';
// import { imagesLength } from '../helpers/imagesLength';
// import { useTempReport } from '../api/useReportTemp';

interface Props { children: JSX.Element | JSX.Element[] }


interface RepackContextProps {
    mainData: MainData | null,
    prePostRepack: [PrePostRepack, PrePostRepack] | null,
    fruit: Fruit,
    reportId: string | null,
    pallets: InitialPallets[] | InitialRepackPallets[],
    team: string | null,

    getInitialData: (data: Repack) => void;
    handleMain: ( newMainData:MainData ) => void;
    handleChecked: (pid: string, detailName: DetailName, name: string) => void;
    handleInput: (pid: string, detailName: DetailName, name: string, value: string | boolean | string[],arrayIndex?: number ) => void;
    removePallet: (pid: string) => void;
    addItem: (label:string, name:string, value: string, detailName: DetailName) => void;
    addImages: (pid: string, images: ImageType[], detailName?: DetailName) => void;
    removeImage: (pid: string, key: string, detailName?:DetailName) => void;
    updatePallet: ( pallet: InitialPallets ) => void;
}

export const RepackContext = repackContext({} as RepackContextProps)

export const RepackProvider = ({ children }: Props) => {

    const [pallets, setPallets] = useState<InitialPallets[] | InitialRepackPallets[]>([])
    const [mainData, setMainData] = useState<MainData | null>(null)
    const [prePostRepack, setPrePostRepack] = useState<[PrePostRepack, PrePostRepack] | null>(null)
    const [fruit, setFruit] = useState<Fruit>('other')
    const [reportId, setReportId] = useState<string | null>(null)
    const [team, setTeam] = useState<string | null>(null)

    // MAIND DATA ------------------------------------------------------------------------  

    const getInitialData = (data: Repack) => {
        if (data) {
            setTeam(data.team || null)
            setReportId(data._id || null)
            setFruit(fruitType(data.mainData?.product) || "other")
            setMainData(data.mainData)
            setPallets(data.pallets)
            setPrePostRepack([data.preRepack, data.postRepack])
        }
    };

    const handleMain = (newMainData:MainData) => {
        setMainData(newMainData)
    }

    // HANDLE DATA ------------------------------------------------------------------------  

    // CHECK / UNCHECK ITEM
    const handleChecked = (pid: string, detailName: DetailName, name: string) => {
        setPallets( pallets => handleCheck(pallets, pid, detailName, name) )  
    };

    // ADD ADDITIONAL PALLET
    const handleInput = (pid: string, detailName: DetailName, name: string, value: string | boolean | string[], arrayIndex?: number) => {
                
        setPallets(pallets => pallets.map(pall => {
            if (pall.pid === pid) {
                return {
                    ...pall,
                    [detailName]: {
                        ...pall[detailName],
                        data: pall[detailName]?.data.map(inp => {
                            if (inp.name === name) {
                                return {
                                    ...inp,
                                    value: typeof arrayIndex === 'number'
                                        ? (inp.value as string[]).map((val, i) => { return i === arrayIndex ? value : val })
                                        : value
                                }
                            } return inp 
                        })
                    }
                }
            }
            return pall
        }))
    };

    const addItem = (label:string, name:string, value: string, detailName: DetailName) => {
        setPallets( pallets => pallets.map(pallet => {
            return {
                ...pallet,
                [detailName]: {
                    ...pallet[detailName],
                    data: [ ...(pallet[detailName]?.data || []), { check: true, label, name, value }]
                }
            }
        }))
    };

    const removeImage = (pid: string, key: string, detailName?:DetailName) => {
        setPallets(pallets => pallets.map(pallet => {
            if (pallet.pid === pid) {
                return detailName
                ?{
                    ...pallet,
                    [detailName]: {
                        ...pallet[detailName],
                        images: pallet[detailName]?.images.filter(img => img.key !== key) || []
                    }
                }
                :{
                    ...pallet,
                    images: pallet.images.filter(img => img.key !== key) || []
                }
            } return pallet
        }))
    };

    const addImages = (pid: string, images: ImageType[], detailName?: DetailName) => {

        setPallets(pallets => pallets.map(pallet => {
            if (pallet.pid === pid) {
                return detailName
                ?{
                    ...pallet,
                    [detailName]: {
                        ...pallet[detailName],
                        images: [...pallet[detailName]?.images || [], ...images ]
                    }
                }
                :{
                    ...pallet,
                    images: [...pallet.images, ...images ]
                }
            } return pallet
        }))
    };

    const updatePallet = ( pallet: InitialPallets ) => {
        console.log(pallet)
    };


    const addPallet = (grower = false) => {

        // setPallets(c => [...c as InitialPallets[], {
        //     ...palletPrereport(),
        //     newGrower: grower
        //         ? { grower_variety: "", boxes: "" }
        //         : null
        // }])
    };

    // REMOVE PALLET
    const removePallet = (pid: string) => {
        const newPallets = pallets.filter(pall => pall.pid !== pid)
        if (newPallets) setPallets(newPallets)
        else setPallets(pallets)
    };


    // CHANGE FRUIT
    const handleFruit = (val: string) => {
        setFruit(val as Fruit)
    };

    // CHANGE STATUS
    const handleStatus = (pid: string, statusName: Status, value: string) => {
        const newPallet = pallets.map(pall => {
            if (pall.pid === pid) {
                return { ...pall, [statusName]: value };
            }
            return pall;
        });
        setPallets(newPallet)
    };


    // CHANGE GROWER-BOXES
    // const handleGrower = (pid: string, item: keyof NewGrower, value: string | number) => {
    //     const newPallet: InitialPallets[] = pallets.map(pall => {
    //         if (pall.id === pid) {
    //             return {
    //                 ...pall,
    //                 newGrower: {
    //                     ...pall.newGrower!,
    //                     [item]: value
    //                 }
    //             };
    //         }
    //         return pall;
    //     });
    //     setPallets(newPallet)
    // };


    // const handleSwitch = (pid: string, detailName: DetailName, name: string) => {

    //     const newPallet = pallets.map(newItem => {
    //         if (newItem.id === pid) {
    //             return {
    //                 ...newItem,
    //                 [detailName]: newItem[detailName].map(item => {
    //                     if (item.name === name)
    //                         return { ...item, valor: !item.valor }
    //                     return item
    //                 })
    //             }
    //         }
    //         else return newItem

    //     })
    //     setPallets(newPallet)
    // };

    // const handleInputText = (pid: string, detailName: DetailName, name: string, valor: string | number) => {

    //     const newPallet = pallets.map(newItem => {
    //         if (newItem.id === pid) {
    //             return {
    //                 ...newItem,
    //                 [detailName]: newItem[detailName].map(item => {
    //                     if (item.name === name)
    //                         return { ...item, valor }
    //                     return item
    //                 })
    //             }
    //         }
    //         else return newItem

    //     })
    //     setPallets(newPallet)
    // };

    // const handleInputArray = (pid: string, detailName: DetailName, name: string, index: number, value: string) => {

    //     const newPallet = pallets.map(newItem => {
    //         if (newItem.id === pid) {
    //             return {
    //                 ...newItem,
    //                 [detailName]: newItem[detailName].map(item => {

    //                     if (item.name === name && Array.isArray(item.valor)) {
    //                         let newVal = []
    //                         for (let i = 0; i < item.valor.length; i++) {
    //                             if (i === index) { newVal.push(value) }
    //                             else newVal.push(item.valor[i])
    //                         }

    //                         return { ...item, valor: newVal }
    //                     } else {
    //                         return item
    //                     }
    //                 })
    //             }
    //         }
    //         else return newItem

    //     })
    //     setPallets(newPallet)
    // };



    // const addTempFiles = (pid: string, files: ImageTemp[]) => {

    //     const imagesTotal = imagesLength(pallets)
    //     if(imagesTotal + files.length > 30){
    //         alertMsg("Max. Images","You completed the limit of 30 images in total")
    //         return
    //     }

    //     const newPallet = pallets.map(pall => {
    //         if (pall.id === pid) {
    //             return {
    //                 ...pall, images: [...pall.images, ...files]
    //             };
    //         }
    //         return pall;
    //     });
    //     setPallets(newPallet)
    // };


    //-------------------------------------------------------

    // const addTempPhoto = (pid: string, file: ImageTemp, detailName: DetailName, nameId: string) => {

    //     const newPallet = pallets.map(pall => {
    //         if (pall.id === pid) {
    //             return {
    //                 ...pall,
    //                 [detailName]: pall[detailName].map( w => {
    //                     if( w.name === nameId ){
    //                         return { ...w, photo: file }
    //                     }
    //                     return w
    //                 } )
    //             };
    //         }
    //         return pall;
    //     });

    //     setPallets(newPallet)
    // };

    //-------------------------------------------------------

    // const removeTempFiles = (pid: string, name: string) => {

    //     const newPallet = pallets.map(pall => {
    //         if (pall.id === pid) {
    //             const newImages = pall.images.filter( img => img.name !== name )
    //             return {
    //                 ...pall, images: newImages
    //             };
    //         }
    //         return pall;
    //     });
    //     setPallets(newPallet)
    // };

    //-------------------------------------------------------

    // const removeTempPhoto = (pid: string, detailName: DetailName, name: string) => {
    //     const newPallet = pallets.map(pall => {
    //         if (pall.id === pid) {
    //             return {
    //                 ...pall,
    //                 [detailName]: pall[detailName].map( w => {
    //                     if( w.name === name ){
    //                         return { ...w, photo: null }
    //                     }
    //                     return w
    //                 } )
    //             };
    //         }
    //         return pall;
    //     });

    //     setPallets(newPallet)
    // };


    // const addItem = (pid: string, detailName: DetailName, item: DetailObject) => {

    //     const newPallet = pallets.map(newItem => {
    //         if (newItem.id === pid) {
    //             return {
    //                 ...newItem,
    //                 [detailName]: [
    //                     ...newItem[detailName],
    //                     item
    //                 ]
    //             }
    //         }
    //         else return newItem

    //     })
    //     setPallets(newPallet)
    // }

    // const addRemoveSample = (action:"add" | "remove", pid:string ) => {

    //     const newPallet = pallets.map(newItem => {
    //         if (newItem.id === pid) {
    //             return {
    //                 ...newItem,
    //                 pallgrow: newItem.pallgrow.map(item => {
    //                     if (item.tipe === "arrays" && action === "add") return { ...item, valor: Array.isArray(item.valor) ? [...item.valor, "0"] : item.valor }
    //                     if (item.tipe === "arrays" && action === "remove") {
    //                         Array.isArray(item.valor) && item.valor.pop()
    //                         return { ...item, valor: item.valor }
    //                     } return item
    //                 })
    //             }
    //         }
    //         else return newItem

    //     })
    //     setPallets(newPallet)
    // };


    return (
        <RepackContext.Provider value={{
            mainData,
            prePostRepack,
            fruit,
            reportId,
            pallets,
            team,
            getInitialData,
            handleChecked,
            handleInput,
            handleMain,
            removePallet,
            addItem,
            addImages,
            removeImage,
            updatePallet
            // getMainDataReport,
            // getMainDataNew,
            // handleMain,
            // setNewPallets,
            // addPallet,
            // addTempFiles,
            // addTempPhoto,
            // handleFruit,

            // handleSwitch,
            // handleInputText,
            // handleInputArray,
            // handleStatus,
            // handleGrower,
            // addGrower,
            // backGrower,
            // removeTempFiles,
            // removeTempPhoto,
            // addRemoveSample,

            // cleanAll,
        }}>
            {children}
        </RepackContext.Provider>
    )
};