import { Supplier, SupplierList, Teams } from "../interfaces/interfaces.auth";


const teamToSuppliers = (teams: Teams[]): SupplierList[] =>
    teams.map(team => ({
        id: team._id,
        name: team.name,
        model: 'team',
        suppliers: team.suppliers,
        isAdmin: true
    }))


export const allSuppliers = (uid: string, userSuppliers: Supplier[], teamsAdmin: Teams[], teamsUser: Teams[]): SupplierList[] => {

    const allSuppliers: SupplierList[] = [{
        id: uid,
        name: 'My suppliers',
        model: 'user',
        suppliers: userSuppliers,
        isAdmin: true
    }]

    if (teamsAdmin.length > 0) {
        allSuppliers.push(...teamToSuppliers(teamsAdmin))
    }
    if (teamsUser.length > 0) {
        allSuppliers.push(...teamToSuppliers(teamsUser))
    }

    return allSuppliers
}

