import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainData } from './Inputs/MainData'
import { totalSamples } from '../../helpers/formatSplit'
import { fruitType } from '../../helpers/fruitType'
import { Charging } from '../ui/Charging'
import CreateReportSteps from './CreateReportSteps'
import { AccordionCard } from '../cards/AccordionCard'
import { InitialPallets, MainData as MainType } from '../../interfaces/intakes.reports'
import { Fruit } from '../../interfaces/interfaces'
import { useTempReport } from '../../api/useReportTemp'
import { BatchInfo } from '../ui/BatchInfo'

export const CreatePageSteps = () => {

    const navigate = useNavigate()
    const { id } = useParams()

    if (!id) { navigate("/") }

    const { data, isLoading, isError } = useTempReport(id!)

    const [mainData, setMainData] = useState<MainType | null>(null)
    const [pallets, setPallets] = useState<InitialPallets[]>([])
    const [fruit, setFruit] = useState<Fruit>("other")

    useEffect(() => {
        if (data) {
            setFruit(fruitType(data.mainData?.product) || "other")
            setMainData({
                pallet_ref: data.mainData.pallet_ref || "",
                ...data.mainData,
                total_pallets: data.mainData.total_pallets || "1",
                // total_kilos: data.mainData.total_kilos || data.mainData.kilos || totalKilos(data.mainData.format, data.mainData.total_boxes).toString() || "",
                // kilos: data.mainData.kilos || totalKilos(data.mainData.format, data.mainData.total_boxes).toString() || "",
                samples: totalSamples(data.mainData.format || "0*0").toString() || "1",
            })
            setPallets(data.pallets)
        }
        if (data && !data.unfinished) navigate("/")

    }, [data, navigate])

    if (isError) navigate("/")

    const handleMainData = (mainData: MainType) => setMainData(mainData)

    return (
        <div className="content intake">
            <main className="container">
                {
                    isLoading
                        ? <Charging />
                        :
                        <>
                            <h2 className="main-title mb-2">Report</h2>
                            {
                                mainData &&
                                <AccordionCard
                                    afterTitle={
                                        <div className="flex ml-1 split-badge" >
                                            <p className="font-small">{mainData?.pallet_ref || "--"}</p>
                                        </div>
                                    }
                                    title='Batch Info' className='mb-2' defaultOpen={false}
                                    additionalBtn={
                                        <BatchInfo mainData={mainData} reportId={id!} editBtn handleMainData={handleMainData} />
                                    }
                                >
                                    <MainData
                                        mainData={mainData}
                                        setMainData={setMainData}
                                        isList
                                    />
                                </AccordionCard>
                            }

                            {
                                mainData &&
                                <CreateReportSteps
                                    mainData={mainData}
                                    fruit={fruit}
                                    reportId={id!}
                                    pallets={pallets}
                                    setPallets={setPallets}
                                />
                            }
                        </>
                }
            </main>

        </div>
    )
}
