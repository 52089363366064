import { useEffect } from 'react'
import { useState } from 'react'
import { allMainData } from '../../data/batchData'
import { jsonKeyToString } from '../../helpers/jsonToString'
import { ModalBtn } from '../modals/ModalBtn'

export const ModalAddMainData = ({ mainData, closeModal, handleAddItem, fast }) => {

    const [mainOptions, setMainOptions] = useState([])
    const inmutables =
        fast ? [
            "pallet_ref",
            "product",
        ]
            : [
                "pallet_ref",
                "product",
                "format",
                "supplier",
                "grower",
                "total_kilos"
            ]

    useEffect(() => {
        const allMain = []
        Object.entries(allMainData).forEach(([key, value]) => {
            allMain.push(
                {
                    check: mainData.hasOwnProperty(key) ? true : false,
                    key,
                    value: mainData[key] ? mainData[key] : value,
                }
            )
        })
        setMainOptions(allMain)
    }, [mainData])


    const handleCheck = (e) => {
        const { name } = e.target
        const checkItem = mainOptions.map(item => {
            if (item.key === name) {
                return {
                    ...item,
                    check: !item.check
                }
            }
            return item
        })
        setMainOptions(checkItem)
    }

    const handleSend = () => {
        const newMainData = {}
        mainOptions.forEach(item => {
            if (item.check) {
                newMainData[item.key] = item.value
            }
        })
        handleAddItem(newMainData)
        closeModal()
    };


    return (
        <div className="modal">

            <div className="modal__card mobile-version">
                <div className="modal__content">

                    {
                        mainOptions.map(item => (
                            <div key={item.key} className="modal__check mb-05">
                                {
                                    <input
                                        disabled={inmutables.includes(item.key)}
                                        className="modal__input mr-05"
                                        type="checkbox"
                                        name={item.key}
                                        checked={item.check}
                                        onChange={handleCheck}
                                    />
                                }
                                <label className="modal__label">{jsonKeyToString(item.key)}</label>
                            </div>

                        ))
                    }

                    <ModalBtn
                        closeModal={() => closeModal()}
                        action={() => handleSend()}
                    />
                </div>
            </div>

        </div>
    )
}