import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";

const getAllReports = async (page, team) => {

    const params = { page }
    if (team) { params.team = team }

    const { data } = await qcareApi.get("/report", { params });
    return data
};

const removeReport = async (id) => {
    const { data } = await qcareApi.get(`/report/delete/${id}`);
    return data
}


// ------------------------- HOOKS -------------------------

export const useReports = (page, team) => {
    const data = useQuery(
        ['reports', page, team],
        () => getAllReports(page, team),
        { 
            keepPreviousData: true,
            staleTime: 1000 * 60 * 30
        }
    );

    return data
}


export const useRemoveReport = () => {
    const queryClient = useQueryClient()
    return useMutation(removeReport, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(['reports'])
            await queryClient.invalidateQueries((['lifeTests']))
        }
    })
}