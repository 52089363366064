import { useState } from 'react'
import toast from 'react-hot-toast'
import { ModalConfirmation } from './ModalConfirmation'
import { DetailObject } from '../../interfaces/intakes.reports'
import { typeData } from '../../data/type-data';
import { useDeleteNewItem, useEditNewItem } from '../../api/useEditNewItem'
import { DetailName } from '../../interfaces/interfaces'
import { ModalBtn } from '../modals/ModalBtn'
import { CounterInput } from '../pages/Inputs/CounterInput'
import { kilosToLb } from '../../helpers/eliminarEs';
import { IoAddCircleSharp, IoRemoveCircle } from 'react-icons/io5';
import { validateDecimals } from '../../helpers/validators';

interface Props {
    closeModal: () => void
    reportId: string
    palletId: string
    detailName: DetailName
    item: DetailObject
    isCounter?: boolean
    isDefect?: "major" | "minor"
}

export const ModalEditItem = ({ closeModal, reportId, palletId, detailName, item, isCounter, isDefect }: Props) => {

    // const queryClient = useQueryClient()
    const [newValue, setNewValue] = useState(item.value)

    const { mutate, isLoading } = useEditNewItem()
    const { mutate: mutateDelete, isLoading: isDeleting } = useDeleteNewItem()

    const [confirmation, setConfirmation] = useState(false)
    const type: any = isCounter ? "counter" : typeData(item.name)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setNewValue(e.target.value as string | number | boolean)
    }

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewValue(e.target.checked as boolean)
    }

    const handleChangeArray = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {

        const { sanitizedValue, isValid } = validateDecimals(e.target.value);

        if (isValid) {

            let newArr = [...newValue as string[]]
            newArr[index] = sanitizedValue
            setNewValue(newArr)
        }
    }

    const handleAddRest = (action: "rest" | "add") => {
        setNewValue(prev => {
            return action === "add"
                ? [...prev as string[], ""]
                : (prev as string[])?.length === 1 ? prev : (prev as string[])?.slice(0, -1)
        }
        )
    };

    const handleDefect = (value: string) => {
        setNewValue(value)
    };

    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => e.target.select()

    const sendEditItem = async () => {

        if (item.value === newValue) return closeModal()

        mutate({
            reportId,
            palletId,
            detailName,
            name: item.name,
            value: newValue,
            defect: isDefect
        }, {
            onSuccess: () => closeModal(),
            onError: (err) => { console.log(err); toast.error("Something went wrong") }
        })
    };

    const deleteItem = async () => {

        mutateDelete({
            reportId,
            palletId,
            detailName,
            name: item.name,
            defect: isDefect
        }, {
            onSuccess: () => closeModal(),
            onError: (err) => { console.log(err); toast.error("Something went wrong") }
        })
    };

    return (
        <div className="modal">

            <div className="modal__card">

                {
                    <div className="modal__content">

                        {
                            confirmation &&
                            <ModalConfirmation
                                buttonName='Delete'
                                msg={`Are you sure you want to delete ${item.label}?`}
                                closeConfirmation={() => setConfirmation(false)}
                                isLoading={isDeleting}
                                action={() => deleteItem()}
                            />
                        }

                        <div className='flex-space-between mb-2'>
                            <label className="modal-label">{item.label}</label>
                            <button onClick={() => setConfirmation(true)} className='flex-center remove-btn mb-05'>
                                <img src='../../assets/img/edit-remove.svg' alt='remove-icon' width={18} />
                            </button>
                        </div>

                        {
                            type === "checkbox" &&
                            <div className="input-range">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={newValue as boolean}
                                        name={item.name}
                                        onChange={handleCheck}
                                    />
                                    <span className="slider"></span>
                                </label>
                                <span className="span-checkbox">
                                    {newValue ? "Yes" : "No"}
                                </span>

                            </div>
                        }
                        {
                            type === "text" &&
                            <input
                                type="text"
                                value={newValue as string}
                                name={item.name}
                                onChange={handleChange}
                                onFocus={handleSelect}
                            />
                        }
                        {
                            type === "range" &&
                            <div className="input-range">
                                <input
                                    type="range"
                                    value={newValue as number}
                                    min={1}
                                    max={10}
                                    step="1"
                                    name={item.name}
                                    onChange={handleChange}
                                />
                                <span className="span-range">{newValue}</span>
                            </div>
                        }
                        {
                            type === "number" &&
                            <input
                                type="number"
                                value={newValue as number}
                                min="0"
                                name={item.name}
                                onChange={handleChange}
                                onFocus={handleSelect}
                            />
                        }
                        {
                            type === "select" &&
                            <div className='pallet-comments'>
                                <select onChange={handleChange} value={newValue as string}>
                                    <option value="">Select a Pallet type</option>
                                    <option value="Wooden Block pallet">Wooden Block pallet</option>
                                    <option value="Wooden Euro pallet">Wooden Euro pallet</option>
                                    <option value="Wooden Air pallet">Wooden Air pallet</option>
                                    <option value="Plastic Block pallet">Plastic Block pallet</option>
                                    <option value="Plastic Euro pallet">Plastic Euro pallet</option>
                                    <option value="Plastic Air pallet">Plastic Air pallet</option>
                                    <option value="One Way Block pallet">One Way Block pallet</option>
                                    <option value="One Way Euro pallet">One Way Euro pallet</option>
                                    <option value="One Way Air pallet">One Way Air pallet</option>
                                </select>
                            </div>
                        }
                        {
                            type === "arrays" &&
                            <>
                                <div className='input-columns'>
                                    {
                                        (newValue as string[])?.map((val, index) => (
                                            <div className='input-columns__input mb-05' key={index}>
                                                <p>{index + 1}.</p>
                                                <input
                                                    type="number"
                                                    value={val}
                                                    onChange={(e) => handleChangeArray(e, index)}
                                                    onFocus={handleSelect}
                                                />
                                            </div>
                                        )
                                        )
                                    }
                                </div>
                            </>
                        }

                        {
                            type === "counter" &&
                            <CounterInput
                                value={newValue?.toString() as string}
                                handleInput={handleDefect}
                            />
                        }

                        {
                            item.name === "fruit_pressure" &&
                            <div className='flex-1'>
                                {
                                    (newValue as string[]).map((oneVal, index) => (
                                        <div key={index} className="flex flex-gap flex-1 mb-02">
                                            <div className={`flex flex-1`} >
                                                <input
                                                    type="number"
                                                    min="0"
                                                    value={oneVal as string}
                                                    onChange={(e) => handleChangeArray(e, index)}
                                                    maxLength={20}
                                                />
                                                <p className='bold font-small ml-02'>kg.</p>
                                            </div>
                                            <div className={`flex flex-1`} >
                                                <input
                                                    disabled
                                                    type="number"
                                                    min="0"
                                                    value={(oneVal as string).length === 0 ? "" : kilosToLb(oneVal)}
                                                />
                                                <p className='bold font-small ml-02'>lb.</p>
                                            </div>
                                        </div>
                                    ))
                                }

                                <div className='flex-end mt-1'>
                                    <button onClick={() => handleAddRest('rest')} ><IoRemoveCircle size={26} /></button>
                                    <button onClick={() => handleAddRest('add')} className='ml-05'><IoAddCircleSharp size={26} /></button>
                                </div>
                            </div>
                        }

                        <ModalBtn
                            closeModal={() => closeModal()}
                            action={sendEditItem}
                            isLoading={isLoading}
                        />

                    </div>
                }


            </div>



        </div>
    )
}