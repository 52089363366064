import { ChangeEvent, ReactElement, useEffect, useRef } from "react"

interface Props {
    iconBefore?: ReactElement<any, any>
    iconAfter?: ReactElement<any, any>
    value: string
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    onClick?: () => void
}

export const InputCustom = ({ value, onChange, iconBefore, iconAfter }: Props) => {

    const inputRef = useRef<HTMLInputElement>(null)

    const handleFocus = () => inputRef.current?.focus()

    return (
        <label htmlFor="custom-input" className="icon-input flex" onClick={handleFocus}>
           
           {iconBefore && <div className="mr-05 flex">{iconBefore}</div>}
            <input
                ref={inputRef}
                id="custom-input"
                type="text"
                name="supplier"
                className="font-normal w-100"
                value={value}
                onChange={(e) => onChange(e)}
                onFocus={(e) => e.target.select()}
                maxLength={35}
            />
            {iconAfter && <div className="ml-05 flex">{iconAfter}</div>}

        </label>
    )
}
