import { useState } from 'react'
import toast from 'react-hot-toast';
import { LabelsInput } from './Inputs/LabelsInput'
import { AppareanceInput } from './Inputs/AppareanceInput'
import { ModalAdd } from '../ui/ModalAdd'
import { inputJson } from '../../helpers/eliminarEs';
import { ScoreItem } from '../ScoreItem';
import { Pallgrow } from './Inputs/Pallgrow';
import { AddImages } from '../AddImages';


export const AddPallet = ({ oldDate, pallet, pallets, setPallets, prereport = false }) => {

    const [openModal, setOpenModal] = useState(false)
    const [palletItem, setPalletItem] = useState("")

    const [newScore, setNewScore] = useState(pallet.score || "0")
    const [newGrade, setNewGrade] = useState(pallet.grade || "0")
    const [newAction, setNewAction] = useState(pallet.action || "0")

    const [pallSamples, setPallSamples] = useState(1);

    const handleModal = (itemName) => {
        setPalletItem(itemName)
        setOpenModal(!openModal)
    }


    const handleSelect = (e, item) => {

        switch (item) {
            case 'score':
                setNewScore(e.target.value)
                pallet.score = e.target.value
                break;

            case 'grade':
                setNewGrade(e.target.value)
                pallet.grade = e.target.value
                break;

            case 'action':
                setNewAction(e.target.value)
                pallet.action = e.target.value
                break;

            default:
                break;
        }
    }

    const addPallItem = (labappal, labelNew, valorNew = "0") => {

        if (labelNew.length <= 0) {
            toast.error('Input Name incomplete')
            return
        }

        // if (/^[A-Za-z0-9 -]*$/.test(labelNew) === false) {
        if (/^[a-zA-Z_ ]*$/.test(labelNew) === false) {
            toast.error('Input Name should only contain letters')
            return
        }

        if (labappal === "labels") {

            const labelExist = pallet.labels.find(labl => labl.label === labelNew)
            const nameExist = pallet.labels.find(labl => labl.name === inputJson(labelNew))

            if (labelExist || nameExist) {
                toast.error('This input already exist')
                return
            }

            pallet.labels.push(
                {
                    check: true, tipe: "text", label: labelNew, name: inputJson(labelNew), valor: valorNew
                },
            )
        } else if (labappal === "appareance") {

            const labelExist = pallet.appareance.find(labl => labl.label === labelNew)
            const nameExist = pallet.appareance.find(labl => labl.name === inputJson(labelNew))

            if (labelExist || nameExist) {
                toast.error('This input already exist')
                return
            }

            pallet.appareance.push(
                {
                    check: true, tipe: "text", label: labelNew, name: inputJson(labelNew), valor: valorNew
                },
            )
        }

        setOpenModal(false)
    }

    const addSample = () => {

        setPallSamples(pallSamples + 1)
        pallet.samples = pallSamples + 1

        const fry = pallet.pallgrow.filter(pal => pal.tipe === "arrays")

        for (let val of fry) {
            val.valor.push("0")
        }

        // setPallets((c) => [...c])

        // let myDiv = document.getElementById(`pal-${pallet.id}`);
        // setTimeout(function () {
        //     myDiv.scrollLeft = myDiv.scrollWidth;
        // }, 0);
    }

    const restSample = () => {

        setPallSamples(pallSamples - 1)
        pallet.samples = pallSamples - 1

        const fry = pallet.pallgrow.filter(pal => pal.tipe === "arrays")

        for (let val of fry) {
            val.valor.pop()
        }


        // let myDiv = document.getElementById(`pal-${pallet.id}`);
        // setTimeout(function () {
        //     myDiv.scrollLeft = myDiv.scrollWidth;
        // }, 0);
    }


    return (
        <div className="mb-1">

            {
                openModal &&
                <ModalAdd
                    setOpenModal={setOpenModal}
                    addPallItem={addPallItem}
                    palletItem={palletItem}
                />
            }

            {
                pallet.labels.length > 0 &&
                <>
                    <p className='input-title bold mb-05'>Labels</p>

                    {
                        pallet.labels.map((inp, i) => (
                            <LabelsInput
                                key={i}
                                id={i}
                                tipe={inp.tipe}
                                label={inp.label}
                                name={inp.name}
                                minVal={inp.minVal}
                                maxVal={inp.maxVal}
                                valor={inp.valor}
                                check={inp.check}
                                pallet={pallet}
                                inpu={inp}

                                photo={inp.photo}
                                pallets={pallets}
                                setPallets={setPallets}
                            />
                        ))
                    }
                    <button
                        onClick={() => handleModal("labels")}
                        className="modal-add mb-05"
                    >
                        <div className="mr-05"><span>+</span></div>
                        <span>Add item</span>
                    </button>

                    <hr />
                </>
            }

            <p className='input-title bold mb-05 mt-1'>Appearance</p>

            {
                pallet.appareance.map((inp, i) => (
                    <AppareanceInput
                        key={i}
                        id={i}
                        tipe={inp.tipe}
                        label={inp.label}
                        name={inp.name}
                        minVal={inp.minVal}
                        maxVal={inp.maxVal}
                        valor={inp.valor}
                        check={inp.check}
                        pallet={pallet}
                        arrays={inp.arrays}

                        photo={inp.photo}
                        pallets={pallets}
                        setPallets={setPallets}
                    />
                ))
            }
            <button
                onClick={() => handleModal("appareance")}
                className="modal-add mb-1"
            >
                <div className="mr-05"><span>+</span></div>
                <span>Add item</span>
            </button>

            <hr />

            {
                pallet.pallgrow?.length > 0 &&
                <>
                    <p className='input-title bold mt-1'>Pall/Grower</p>

                    <div className="flex-h py-05" id={`pal-${pallet.id}`}>

                        {
                            pallet.pallgrow?.map((inp, i) => (
                                <Pallgrow
                                    key={i}
                                    id={i}
                                    tipe={inp.tipe}
                                    label={inp.label}
                                    name={inp.name}
                                    minVal={inp.minVal}
                                    maxVal={inp.maxVal}
                                    valor={inp.valor}
                                    check={inp.check}
                                    pallet={pallet}

                                    sam={pallSamples}
                                    addSample={addSample}
                                    restSample={restSample}

                                    photo={inp.photo}
                                    pallets={pallets}
                                    setPallets={setPallets}

                                />

                            ))
                        }
                    </div>

                    <button
                        onClick={() => handleModal("pallgrow")}
                        className="modal-add mb-2"
                    >
                        <div className="mr-05"><span>+</span></div>
                        <span>Add item</span>
                    </button>
                </>
            }


            {
                prereport &&
                <>
                    <div className='score-wraper mt-2'>
                        <p>QC Appreciation</p>
                        <select onChange={(e) => handleSelect(e, "grade")} value={newGrade}>
                            <option value="0">Select a Grade</option>
                            <option value="1">Industry</option>
                            <option value="2">Borderline CAT 2</option>
                            <option value="3">CAT 2</option>
                            <option value="4">Borderline CAT 1</option>
                            <option value="5">CAT 1</option>
                            <option value="6">Extra</option>
                        </select>
                    </div>

                    <div className='score-wraper py-05'>
                        <p>Suggested commercial action</p>
                        <select onChange={(e) => handleSelect(e, "action")} value={newAction}>
                            <option value="0">Select an Action</option>
                            <option value="1">Dump</option>
                            <option value="2">Rejected</option>
                            <option value="3">Hold</option>
                            <option value="4">Priority for Departure</option>
                            <option value="5">Suitable for QC check/REPACK</option>
                            <option value="6">Suitable for departure</option>
                            <option value="7">Suitable for Storage</option>
                        </select>
                    </div>
                </>
            }

            <div className="score-wraper pb-05">
                <p>Score</p>
                {
                    oldDate
                        ?
                        <select onChange={(e) => handleSelect(e, "score")} value={newScore}>
                            <option value="0">Select a Score</option>
                            <option value="1">1 - Bad</option>
                            <option value="2">2 - Insufficient</option>
                            <option value="3">3 - Fair Quality</option>
                            <option value="4">4 - Acceptable Quality</option>
                            <option value="5">5 - Good Quality</option>
                        </select>
                        :
                        <select onChange={(e) => handleSelect(e, "score")} value={newScore}>
                            <option value="0">Select a Score</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </select>
                }

            </div>

            {
                pallet.newGrower !== null &&
                <div className="score-wraper pb-2">

                    <div className='w-100 mt-1'>

                        <div className='score-grid score-header mt-1'>
                            <p className='text-center'>Grower/Variety</p>
                            <p className='text-center'>Boxes</p>
                        </div>

                        <ScoreItem
                            pallet={pallet}
                            grower={pallet.newGrower.grower_variety}
                            boxes={pallet.newGrower.boxes}
                        />
                    </div>
                </div>
            }

            <div className='mt-2'>
                <AddImages pallet={pallet} pallets={pallets} setPallets={setPallets} limit={10} />
            </div>
        </div>
    )
}
