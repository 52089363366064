import { useMutation, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { DetailName } from "../interfaces/interfaces";

interface EditItem{
    reportId: string
    palletId: string
    detailName: DetailName
    name: string
    value: any
    defect?: "major" | "minor"
}

interface DeleteItem{
    reportId: string
    palletId: string
    detailName: DetailName
    name: string
    defect?: "major" | "minor"
}

const editNewItem = async ({ reportId, ...fetchData }:EditItem) => {
    const { data } = await qcareApi.put(`/report/edit-item-report/${reportId}`, fetchData)
    return data
};


const deleteNewItem = async ({ reportId, ...fetchData }:DeleteItem) => {
    const { data } = await qcareApi.put(`/report/delete-item-report/${reportId}`, fetchData)
    return data
}

// ------------------------- HOOKS -------------------------

export const useEditNewItem = () => {
    const queryClient = useQueryClient()
    return useMutation( editNewItem, {
        onSuccess: async( data ) => { await queryClient.invalidateQueries(['report', data.reportId],) }
    } )
}

export const useDeleteNewItem = () => {
    const queryClient = useQueryClient()
    return useMutation( deleteNewItem, {
        onSuccess: async( data ) => { await queryClient.invalidateQueries(['report', data.reportId],) }
    } )
}