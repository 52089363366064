import { ChangeEvent, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import Papa from 'papaparse'
import { Charging } from '../ui/Charging'
import { NewIntakeCard } from '../cards/NewIntakeCard';
import { useAddCSV } from '../../api/useIntakes';
import { ToggleBtn } from '../ToggleBtn';
import { ModalTeam } from '../ui/ModalTeam';
import { useNavigate } from 'react-router-dom';
import { csvToJson } from '../../data/mainData';
import toast from 'react-hot-toast';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { IntakeCSV } from '../../interfaces/intakes.reports';


export const NewIntake = () => {

    const { teamsAdmin, teamsUser, teams } = useTypedSelector(state => state.auth)
    const [intakes, setIntakes] = useState<IntakeCSV[]>([])
    const [asign, setAsign] = useState(false)
    const [modalTeam, setModalTeam] = useState(false)

    const navigate = useNavigate();

    const { mutate, isLoading } = useAddCSV()

    const baseURL = process.env.REACT_APP_API_URL

    const handleCvs = (e:ChangeEvent<HTMLInputElement>) => {

        const file = e.target.files?.[0]

        if (!file) return

        Papa.parse(file, {
            header: true,
            encoding: 'UTF-8',
            transformHeader: (headerName:string) => headerName.toLowerCase(),
            complete: function (results) {

                let csvFile = []
                let asignOneTeam = teams.length === 1 ? true : false

                for (let item of results.data as Record<string, any>[] ) {

                    const { pallet, pallet_ref, pallet_reference, supplier, ...rest } = csvToJson(item)

                    csvFile.push({
                        id: uuidv4(),
                        data: {
                            pallet_ref: pallet || pallet_ref || pallet_reference || "",
                            supplier: supplier || "",
                            ...rest,
                            // kilos: kilos || total_kilos || "",
                        },
                        inCharge: null,
                        team: asignOneTeam ? teams[0]._id : null
                    })
                }

                setIntakes(csvFile)
                setAsign(asignOneTeam)
            }
        });
    }

    const handleSubmit = async () => {
        mutate(intakes, {
            onSuccess: () => navigate('/intakes'),
            onError: () => toast.error('Error creating intakes')
        })
    }

    const handleToggle = () => {
        if (asign) {
            setIntakes(intakes.map(intake => ({ ...intake, team: null })))
            setAsign(false)
        } else {
            if (teams.length === 1) {
                setIntakes(intakes.map(intake => ({ ...intake, team: teams[0]._id })))
                setAsign(true)
            } else {
                setModalTeam(true)
            }
        }
    };

    const assignToAll = (someTeam:string|null) => {
        setIntakes(intakes.map(intake => ({ ...intake, team: someTeam, inCharge: null })))
        setAsign(true)
        setModalTeam(false)
    };

    return (
        <div className="content intake">

            {
                isLoading
                    ? <Charging />
                    :
                    <main className="container">

                        {
                            modalTeam &&
                            <ModalTeam closeModal={() => setModalTeam(false)} assignToAll={assignToAll} />
                        }

                        <h2 className="main-title mb-2">New Intake</h2>

                        <label className="upload-label mb-2 excel">
                            <img src="/assets/img/csv.svg" alt="excel-logo" />

                            <span>Choose a CSV File</span>
                            <input
                                type="file"
                                className="upload-btn"
                                accept=".csv"
                                id="cvsFile"
                                onChange={ handleCvs }
                            />
                        </label>

                        <div className="btn-center mt-1 mb-2">
                            <a className="link" href={`${baseURL}/intakes/template`}>Download intake template</a>
                        </div>

                        {
                            teams.length > 0 && intakes.length > 0 &&
                            <div className='flex mb-2'>
                                <ToggleBtn state={asign} action={handleToggle} />
                                <p className='ml-05 font-normal'>Assign {`${teams.length === 1 ? teams[0].name : "Team"}`} to all</p>
                            </div>
                        }

                        <div className='intake__grid'>
                            {
                                intakes.length > 0 &&
                                intakes.map((intake, index) => (
                                    <NewIntakeCard
                                        index={index}
                                        key={intake.id}
                                        intake={intake}
                                        intakes={intakes}
                                        setIntakes={setIntakes}
                                        hasTeams={teamsAdmin.length > 0 || teamsUser.length > 0}
                                    />
                                ))
                            }
                        </div>

                        {
                            intakes.length > 0 &&
                            <div className="btn-center mt-3">
                                <button
                                    disabled={isLoading}
                                    className="btn-primary"
                                    onClick={handleSubmit}>Submit Intakes</button>
                            </div>
                        }

                    </main>
            }
        </div>
    )
}
