import { Link } from 'react-router-dom'
import { IoLeafOutline, IoListOutline, IoLogInOutline, IoRepeatOutline } from "react-icons/io5";


export const Bar = () => {

    return (
        <div className="bar">
            <div className="bar__grid">
                <Link to="/" className="bar__container mt-05">
                    <IoListOutline size={25} />
                    <p>All reports</p>
                </Link>
                <Link to="/intakes" className="bar__container mt-05">
                    <IoLogInOutline size={25} />
                    <p>Intakes</p>
                </Link>
                <Link to="/new" className="bar__container">
                    <img src="/assets/img/nav-mas.svg" alt="nav-report" className="mas-icon" />
                    {/* <IoAdd size={25} className="mas-icon"/> */}

                </Link>
                <Link to="/repacks" className="bar__container mt-05">
                    <IoRepeatOutline size={25} />
                    <p>Repack</p>
                </Link>
                {/* <Link to="/prereports" className="bar__container mt-05">
                    <img className="img-icon" src="/assets/img/nav-prereports.svg" alt="nav-report" />
                    <p>Pre Reports</p>
                </Link> */}
                <Link to="/life" className="bar__container mt-05">
                    <IoLeafOutline size={25} />
                    <p>Life Test</p>
                </Link>

            </div>
        </div>
    )
}
