import { ImageType, InitialPallets } from "../interfaces/intakes.reports";
import { DetailName } from "../interfaces/interfaces";

export interface PhotoToShow {
    label: string;
    name: string;
    photo: ImageType;
    detailName: DetailName;
  
}

export const photosToShow = (pallet:InitialPallets):PhotoToShow[] => {
      
    let photos = []

    if (pallet?.details?.labels) {
      photos.push(...(pallet.details.labels.filter(item => item.photo).map( un => ({...un, detailName: "labels" as DetailName}) )))
    }
    if (pallet?.details?.appareance) {
      photos.push(...(pallet.details.appareance.filter(item => item.photo).map( un => ({...un, detailName: "appareance" as DetailName}) )))

    }
    if (pallet?.details?.pallgrow) {
      photos.push(...(pallet.details.pallgrow.filter(item => item.photo).map( un => ({...un, detailName: "pallgrow" as DetailName}) )))
    }

    return photos.map( photo => (
      {
        label: photo.label,
        name: photo.name,
        photo: photo.photo as ImageType,
        detailName: photo.detailName 
      }
    ) )
  };

  export const photosSavePDF = (pallet:InitialPallets) => {
      
    let photos = []

    if (pallet?.details?.labels) {
      photos.push(...pallet.details.labels.filter(item => item.photo))
    }
    if (pallet?.details?.appareance) {
      photos.push(...pallet.details.appareance.filter(item => item.photo))

    }
    if (pallet?.details?.pallgrow) {
      photos.push(...pallet.details.pallgrow.filter(item => item.photo))
    }

    return photos.map( photo => ({ label: photo.label, ...photo.photo }) ) 
  };

