import { Fragment } from "react"
import { SpinnerCustom } from "../ui/SpinnerCustom"

interface Props {
    step: number
    setStep: React.Dispatch<React.SetStateAction<number>>
    actionPrev?: () => void
    actionNext: () => void
    isLoading?: boolean
}
export const Steps = ({ step, setStep, actionNext, isLoading = false }: Props) => {

    const steps = [
        { sectionName: "labels", sectionStep: 1 },
        { sectionName: "appearance", sectionStep: 2 },
        { sectionName: "pallgrow", sectionStep: 3 },
        { sectionName: "finish", sectionStep: 4 },
    ]

    const isLastStep = step === 4

    return (
        <div className="flex-center border-b pb-1 mb-2">
            <section className="multisteps mb-02">

                {
                    step > 1
                        ?
                        <div className='multisteps__action flex-center previous'>
                            <button
                                disabled={isLoading}
                                onClick={() => setStep(step => step - 1)}
                            >
                                <p>Previous</p>
                            </button>
                        </div>
                        : <div />
                }

                <div className="multisteps__steps">
                    {
                        steps.map((section, index) => (
                            <Fragment key={section.sectionName}>
                                <div className={`step-item ${step === section.sectionStep ? "" : step > section.sectionStep ? "active" : "deactive"}`}>
                                    <p>{section.sectionStep}</p>
                                </div>
                                {
                                    index < 3 &&
                                    <div className={`step-line ${step > section.sectionStep ? "active" : null}`} />
                                }
                            </Fragment>
                        ))
                    }
                </div>

                <div className={`multisteps__action flex-center next ${isLastStep && (isLoading ? "next-blue-disabled" : "next-blue")}`}>
                    <button
                        disabled={isLoading}
                        onClick={() => actionNext()}
                        className="flex flex-gap px-1"
                    >
                        <p>{isLastStep ? "Save " : "Save & Next"}</p>
                        {isLoading && <SpinnerCustom center size="xxs" color={isLastStep ? "white" : "green"} />}
                    </button>
                </div>

            
            </section>
        </div>
    )
}
