import { useMutation, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { MainData } from "../interfaces/intakes.reports";
import { Create } from "../interfaces/interfaces";

interface EditMainDataProps {
    reportId: string,
    mainData: MainData,
    model: Create
}

const editMainData = async ({reportId, mainData, model}:EditMainDataProps) => {

    let revalidate = model
    
    const {data} = await qcareApi.put(`/update/edit-main-data/${reportId}`, {
        mainData,
        model
    })
    return {data, revalidate, reportId}
};

// ------------------------- HOOKS -------------------------

export const useEditMainData = ( ) => {
    const queryClient = useQueryClient()

    return useMutation(editMainData, {
        onSuccess: async(item) => {
            await queryClient.invalidateQueries([item.revalidate, item.reportId])
            await queryClient.invalidateQueries([item.revalidate+"s"])
        }
    })
}