
export const GrowerTable = ({ addGrower }) => {

  return (
    <div className='w-100'>
      <div className='score-grid score-header'>
        <p className='text-center'>Grower/Variety</p>
        <p className='text-center'>Boxes</p>
      </div>

      <div
        className='score-grid my-1'>
        <p className='text-center'>{addGrower.grower_variety || "--"}</p>
        <p className='text-center'>{addGrower.boxes || "--"}</p>
      </div>
    </div>
  )
}
