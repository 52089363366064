import React from "react"
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5"
import { SpinnerCustom } from "./SpinnerCustom"

interface Props {
    isLoading?: boolean
    textNext?: string
    iconNext?: React.ReactNode
    actionNext: () => void
    actionPrevious?: () => void
    closeModal: () => void
}

export default function EmailFormBtn({ isLoading, textNext="Next", iconNext, actionPrevious, actionNext, closeModal }: Props) {

    return (
        <div className="email-btn-container mt-2">

            <button
                onClick={closeModal} className="email-btn red">
                <div>
                    <p>Close</p>
                </div>
            </button>

            {
                actionPrevious &&
                <button
                disabled={isLoading}
                className="email-btn blue flex-center"
                onClick={actionPrevious}>
                <div className="flex flex-gap">
                    <IoChevronBackOutline color="white"/>
                    <p>Previous</p>
                </div>
            </button>
            }

            <button
                disabled={isLoading}
                className="email-btn blue flex-center"
                onClick={actionNext}>
                <div className="flex flex-gap">
                    <p>{textNext}</p>
                    {
                        isLoading
                        ?
                        <SpinnerCustom color="white" size="xxs"/>
                        :
                        iconNext 
                        ? iconNext
                        : <IoChevronForwardOutline color="white"/>
                    }
                    
                </div>
            </button>

        </div>
    )
}
