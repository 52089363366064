

export const validateDecimals = (value: string) => {
    const regex = /^\d+([.,]\d{0,2})?$/;
    const sanitizedValue = value.replace(',', '.');
    const isValid = sanitizedValue === '' || regex.test(sanitizedValue);

    return { sanitizedValue, isValid };
};

export const hasLength = (value: string):boolean => {
    return value.trim().length > 0;
};