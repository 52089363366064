import { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { jsonKeyToString } from '../../helpers/jsonToString';
import { useAddDiscrepancy } from '../../api/useDiscrepancy';

export const ModalEditDiscrepancy = ({ reportId, closeModal, singleDiscrepancy, discrepancies, setDiscrepancies, dbDiscrepancy = false, prereport = false }) => {

    const [description, setDescription] = useState("")
    const [item, setItem] = useState("")
    const [discrepanciesClon, setDiscrepanciesClon] = useState(null)
    const { mutate, isLoading } = useAddDiscrepancy()


    useEffect(() => {
        setDescription(singleDiscrepancy.value || "--")
        setItem(singleDiscrepancy.key || "--")
        setDiscrepanciesClon(discrepancies || null)

        return () => { setDescription("") }
    }, [singleDiscrepancy, discrepancies])


    const handleEditItem = async () => {
        if (description.trim().length === 0 || item === 0) {
            return toast.error('Please enter a description or discrepancy')
        }

        dbDiscrepancy
            ?
            mutate({ dataBody: { reportId, discrepancies: { ...discrepancies, [item]: description } }, prereport }, {
                onError: () => toast.error('Error adding discrepancy'),
            })
            :
            setDiscrepancies({ ...discrepancies, [item]: description })
        closeModal()
    };


    const removeDiscrepancy = (key) => {
        
        delete discrepanciesClon[key]

        const finalDisc = Object.keys(discrepanciesClon).length === 0
            ? null
            : discrepanciesClon

        dbDiscrepancy
            ?
            mutate({ dataBody: { reportId, discrepancies:finalDisc }, prereport }, {
                onError: () => toast.error('Error removing discrepancy'),
            })
            :
            setDiscrepancies(finalDisc)

            closeModal()
    };


    return (
        <div className="modal">

            {
                singleDiscrepancy &&
                <div className="modal__card">
                    <div className="modal__content">

                        <button
                            className='absolute-item top-2 right-2 flex btn-remove-trash'
                            onClick={() => removeDiscrepancy(item)}
                        >
                            <img src='../assets/img/edit-remove.svg' alt='team-icon' width={18} />
                        </button>

                        <label className="modal-label mb-2">{jsonKeyToString(item)}</label>

                        <div className="pallet-comments">
                            <div className="mb-05">
                                <p className='bold mb-02'>Discrepancy</p>
                                <textarea
                                    maxLength={100}
                                    resize="vertical"
                                    name="discrepancy"
                                    id="discrepancy"
                                    cols="30"
                                    rows="4"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)} />
                                <p className='text-mute font-small mt-02 ml-05'>*100 characters maximum</p>
                            </div>
                        </div>

                        <div className="modal__botones mt-2">
                            <button
                                disabled={isLoading}
                                className="modal-btn btn-close mr-05"
                                onClick={() => closeModal()}
                            >
                                Close
                            </button>
                            <button
                                disabled={isLoading}
                                className="modal-btn btn-add ml-05"
                                onClick={handleEditItem}
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}