import { useState } from 'react'
import toast from 'react-hot-toast';

import { ModalPhoto } from './ui/ModalPhoto';
import { resizeFile } from '../helpers/resizeFile';

export const PhotoAdd = ({ photo, nameId, palletId, setPallets, item }) => {

    const [modalImg, setModalImg] = useState(false)
    let alignTop = [ "weight_check" ]

    const addTempFile = async(e) => {

        const resizedImage = await resizeFile(e.target.files[0])

        setPallets( pallets => pallets.map(pall => {
            if (pall.id === palletId) {
                return {
                    ...pall,
                    [item]: pall[item].map( w => {
                        if( w.name === nameId ){
                            // return { ...w, photo: e.target.files[0] }
                            return { ...w, photo: resizedImage }
                        }
                        return w
                    } )
                };
            }
            return pall;
        }))
    };

    const removeTempFile = () => {

        setPallets(pallets => pallets.map(pall => {
            if (pall.id === palletId) {
                return {
                    ...pall,
                    [item]: pall[item].map( w => {
                        if( w.name === nameId ){
                            return { ...w, photo: null }
                        }
                        return w
                    } )
                };
            }
            return pall;
        }))

        setModalImg(false)
        toast.success("Photo has been deleted")
    }

    return (
        <>
        {
            modalImg &&
            <ModalPhoto 
                photo={photo}
                openModal={setModalImg}
                action={ removeTempFile }
            />
        }
            {
                photo
                    ?
                    <>
                        <label htmlFor={palletId+nameId} className={`photo ml-05 photo-active ${alignTop.includes(nameId) && "alignTop"}`}>
                            <img src="../assets/img/camera-ok.svg" alt="camera" width={18} />
                        </label>

                        <button
                            id={palletId+nameId}
                            className="upload-btn"
                            onClick={() => setModalImg(true)}
                        />
                    </>
                    :
                    <>
                        <label htmlFor={palletId+nameId} className={`photo ml-05 photo-inactive ${alignTop.includes(nameId) && "alignTop"}`}>
                            <img src="../assets/img/camera.svg" alt="camera" width={18} />
                        </label>
                        <input
                            id={palletId+nameId}
                            className="upload-btn"
                            accept=".jpg, .png, .jpeg"
                            name="file"
                            type="file"
                            onChange={addTempFile}
                        />
                    </>
            }

        </>
    )
}
