import { useState } from 'react'
import { Link } from 'react-router-dom'
import { dateFormat } from '../helpers/dateFormat'
import { InfoBox } from './ui/InfoBox'
import Badge from './ui/Badge'
import { CoverImage } from './cards/CoverImage'
import { useRemove } from '../api/useDelete'
import toast from 'react-hot-toast'
import { ModalConfirmation } from './ui/ModalConfirmation'
import { ReducedRepackResponse } from '../interfaces/interface.responses'

interface Props{
    repack: ReducedRepackResponse
}

export const CardRepack = ({ repack }:Props) => {

    const [activeDots, setActiveDots] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const { mutate, isLoading } = useRemove()

    const linkTo = repack.unfinished ? `/create-repack/${repack._id}` : `/repack/${repack._id}`

    const handleRemove = async () => {
        mutate({id: repack._id, model:'repack'}, {
            onSuccess: () => {setOpenConfirmation(false)},
            onError: () => {
                toast.error(("Something went wrong"))
            }
        })
    };

    return (
        <div className="card mb-1">

            {
                openConfirmation &&
                <ModalConfirmation
                    msg="Are you sure you want to remove this Repack?"
                    closeConfirmation={setOpenConfirmation}
                    action={handleRemove}
                    isLoading={isLoading}
                />
            }

            <Link
                to={linkTo}
                className="card__image">

                <CoverImage pallets={repack.pallets} />

            </Link>

            <Link
                to={linkTo}
                className="card__content">
                <div className="card__body">
                    <div className="card__title flex">
                        <h4>{repack.mainData.pallet_ref}</h4>
                        {repack.unfinished && <Badge small ml color='red'>Unfinished</Badge>}

                    </div>
                    <p className="card__text">{repack.mainData.supplier || "--"}</p>
                    <p className="card__text">{repack.mainData.total_pallets || "--"}</p>
                    <p className="card__text"><small className="text-muted">{dateFormat(repack.date) || "--"}</small></p>
                </div>
            </Link>

            <button
                className="remove"
                onClick={() => {
                    setActiveDots(true)
                }}>
                <img src='../assets/img/dots-icon.svg' alt="dots-icon" />
            </button>


            <InfoBox
                show={activeDots}
                onClickOutside={() => { setActiveDots(false) }}
            >
                {/* {
                    !unfinished &&
                    <p className='font-normal mb-05'
                        onClick={() => {
                            setCurrentId(id)
                            setOpenSendPDF(true)
                        }}
                    >Send report</p>
                } */}
                <p className='font-normal'
                    onClick={() => setOpenConfirmation(true)}
                >Delete</p>
            </InfoBox>

            {/* <span
                className={`circle-score circle-score${scoreNumber}`}
            ></span> */}

        </div>
    )
}
