
export const fruitType = ( fruit = "other" ) => {
    
    const fruitLow = fruit.toLowerCase()

    if(fruitLow.includes('blueberr')){return "blueberries"}
    else if(fruitLow.includes('strawberr')){return "strawberries"}
    else if(fruitLow.includes('raspberr')){return "raspberries"}
    else if(fruitLow.includes('blackberr')){return "blackberries"}
    else if(fruitLow.includes('pear')){return "pears"}
    else if(fruitLow.includes('fig')){return "figs"}
    else if(fruitLow.includes('kiwi')){return "kiwiberries"}
    else if(fruitLow.includes('apple')){return "apples"}
    else if(fruitLow.includes('cherr')){return "cherries"}
    else if(fruitLow.includes('red')){return "red currants"}
    else {return "other"}

};