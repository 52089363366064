import { Text, View } from "@react-pdf/renderer";

import { DetailObject, InitialPallets, Labels } from "../../interfaces/intakes.reports"
import { DetailName, DetailNameOld } from "../../interfaces/interfaces"
import { itemValor, kilosToLb } from "../../helpers/eliminarEs";
import { globalStyles } from "./DocuPdf";

interface Props {
    pallet: InitialPallets
    detailName: DetailName | DetailNameOld
    isNewReport: boolean
}

export const PDFList = ({ pallet, detailName, isNewReport }: Props) => {

    const palletsDetail: DetailObject[] =
        isNewReport
            ? pallet[detailName]?.data ?? []
            : detailName === 'labels'
                ? [...(pallet.prereport?.details[detailName] ?? []), ...(pallet.details?.[detailName] ?? [])]
                : pallet.details?.[detailName] ?? [];

    const newValue = isNewReport ? "value" : "valor";


    return (
        <>
            {
                palletsDetail.length > 0 &&
                <View>
                    <Text style={globalStyles.h2}>{Labels[detailName]}</Text>
                    {
                        palletsDetail.map(item => (
                            <View style={[globalStyles.item, globalStyles.itemBorder]} key={item.name}>
                                <Text style={[globalStyles.title, globalStyles.bold, { width: '40%', alignSelf:'flex-start' }]}>{item.label}</Text>
                                {
                                    Array.isArray(item[newValue])
                                        ? item.name === "fruit_pressure"
                                            ?
                                            <View style={{ flex:1, flexDirection: 'row', flexWrap: "wrap", justifyContent:'flex-end' }}>
                                                    {(item[newValue] as string[] | number[] || []).map((value, i) => (
                                                        <View key={i} style={{ marginBottom:2, marginLeft:5, paddingHorizontal:5, paddingTop:2, flexDirection: 'row', borderRadius: 10, borderWidth:.5, borderColor: '#d8d8d8'}}>
                                                            <Text style={{ ...globalStyles.text }}>{value || '--'} kg.  /  </Text>
                                                            <Text style={{ ...globalStyles.text, ...globalStyles.title }}>{ kilosToLb(value) } lb.</Text>
                                                        </View>
                                                    ))}
                                            </View>
                                            :
                                            <View style={{ flexDirection: 'row' }}>
                                                {(item[newValue] as string[] | number[] || []).map((value, i) => (
                                                    <Text key={i} style={{ ...globalStyles.text, marginLeft: 15 }}>{value || '--'}</Text>
                                                ))}
                                            </View>
                                        : <Text style={globalStyles.text}>{itemValor(item[newValue], item.tipe)}</Text>
                                }
                            </View>
                        ))
                    }
                </View>
            }

        </>
    )
}

//backgroundColor: '#f7f9fb',