import { useState } from "react"
import { Supplier, SupplierList } from "../../interfaces/interfaces.auth"
import { IoAddCircle, IoChevronDown, IoChevronUp, IoCreateOutline, IoMailOutline, IoPersonAddOutline, IoPersonOutline, IoTrashOutline } from "react-icons/io5";
import PopUpButton from "../modals/PopUpButton";
import { ModalAddContact } from "../modals/ModalAddContact";
import { ModalConfirmation } from "../ui/ModalConfirmation";
import { useRemoveSupplier } from "../../api/useSupplier";
import { useDispatch } from "react-redux";
import { startChecking } from "../../store/asyncMethods/authMethods";
import { ModalEditSupplier } from "../modals/ModalEditSupplier";
import { Contact } from "../profile/Contact";


interface Props {
    supplier: Supplier
    target: SupplierList
}

export const CardSupplier = ({ supplier, target }: Props) => {

    const dispatch = useDispatch()
    const [activeAccordeon, setActiveAccordeon] = useState<boolean>(false)
    const [modalContact, setModalContact] = useState<boolean>(false)
    const [modalEditSupplier, setModalEditSupplier] = useState<boolean>(false)
    const [confirmation, setConfirmation] = useState<boolean>(false)
    const { mutate, isLoading } = useRemoveSupplier()

    const PopSupplierBtn = () => {
        return (
            <PopUpButton>
                <p className='font-normal text-no-wrap py-05 flex flex-gap mr-05' onClick={() => setModalEditSupplier(true)}><IoCreateOutline /> Edit Supplier</p>
                <p className='font-normal text-no-wrap py-05 flex flex-gap mr-05' onClick={() => setModalContact(true)}><IoPersonAddOutline /> Add Contact</p>
                <p className='font-normal text-no-wrap py-05 flex flex-gap text-red mr-05' onClick={() => setConfirmation(true)}><IoTrashOutline /> Remove Supplier</p>
            </PopUpButton>
        )
    };

    const removeSupplier = () => {
        mutate({
            id: target.id,
            model: target.model,
            supplierId: supplier._id
        }, {
            onSuccess: async () => {
                dispatch(startChecking())
                setConfirmation(false)
            },
            onError: (error) => console.log(error)
        })
    };

    return (
        <div className="w-100 supplier-card mb-1">
            {
                modalContact &&
                <ModalAddContact
                    supplier={supplier}
                    closeModal={() => setModalContact(false)}
                    target={target}
                />
            }

            {
                modalEditSupplier &&
                <ModalEditSupplier
                    supplier={supplier}
                    closeModal={() => setModalEditSupplier(false)}
                    target={target}
                />
            }

            {
                confirmation &&
                <ModalConfirmation
                    action={removeSupplier}
                    closeConfirmation={() => setConfirmation(false)}
                    msg="Are you sure you want to remove this supplier?"
                    buttonName="Remove"
                    isLoading={isLoading}
                />
            }

            {/* <div className={`supplier-grid p-05`}> */}
            <div className={`supplier-main ${activeAccordeon ? "border-b" : ""}`}>
                <p className="bold font-normal">{supplier.ref}</p>
                <div className="flex">
                    <p className="font-normal flex-1">{supplier.name}</p>
                    <div className="flex">
                        <PopSupplierBtn />
                        <button
                            className="ml-1 flex"
                            onClick={() => setActiveAccordeon(!activeAccordeon)}
                        >
                            {activeAccordeon ? <IoChevronUp /> : <IoChevronDown />}
                        </button>
                    </div>
                </div>
            </div>

            <div className={`supplier-wrapper ${activeAccordeon && 'active'}`}>
                {
                    supplier.contacts.length > 0
                        ?
                        supplier.contacts.map(contact => (
                            <Contact
                                key={contact._id}
                                contact={contact}
                                supplier={supplier}
                                target={target}
                            />
                        ))
                        :
                        <div className="flex-center my-1">
                            <p className="text-center font-small bold">No contacts</p>
                            <button
                                className="flex-center ml-02 color-blue"
                                onClick={() => setModalContact(true)}>
                                <IoAddCircle size={20} />
                            </button>
                        </div>
                }
            </div>
        </div>
    )
}
