import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";

const getAllIntakes = async (page, team) => {
    const params = { page }
    if (team) { params.team = team }

    const { data } = await qcareApi.get('/intakes', { params });
    return data
};


const removeIntake = async (id) => {
    const { data } = await qcareApi.delete(`/intakes/delete/${id}`);
    return data
}

const addCSV = async (csv) => {
    const { data } = await qcareApi.post('/intakes/new', csv);
    return data
}


// ------------------------- HOOKS -------------------------

export const useIntakes = (page, team) => {
    return useQuery(
        ['intakes', page, team],
        () => getAllIntakes(page, team),
    );
}

export const useRemoveIntake = () => {
    const queryClient = useQueryClient()
    return useMutation(removeIntake, {
        onSuccess: async () => { 
            await queryClient.invalidateQueries(['intakes']) 
        },
    })
}

export const useAddCSV = () => {
    return useMutation( addCSV )
}