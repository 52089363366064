import { v4 as uuidv4 } from 'uuid';
import { pallgrowDefects } from './pallgrow-steps';
import { Fruit } from '../interfaces/interfaces';
import { DetailObject, InitialPallets } from '../interfaces/intakes.reports';

const coloration:Fruit[] = ["blueberries", "cherries", "pears"]
const undersize:Fruit[] = ["pears"]
const fruitPressure:Fruit[] = ["pears"]

export const palletData = (fruit: Fruit, samples: number=1, formatGr: number, palletNumber: number): InitialPallets => {
    return {
        pallet: palletNumber,
        complete: null,
        pid: uuidv4(),
        score: "0",
        grade: "0",
        action: "0",
        images: [],
        splitPallet: null,
        labels: { data: addLabels(), images: [] },
        appearance: { data: addAppearance(fruit), images: [] },
        pallgrow: { data: pallgrowData(samples, formatGr), major: pallgrowDefects(fruit, "major"), minor: pallgrowDefects(fruit, "minor"), images: [], },
        comments: ""
    }
}

export const addLabels = (): DetailObject[] => [
    { check: true, label: "Box Label", name: "box_label", value: true },
    { check: true, label: "Pallet Type", name: "pallet_type", value: "" },
    { check: true, label: "Punnet Label", name: "punnet_label", value: true },

    { check: true, label: "Name & Weight", name: "name_weight", value: true },
    { check: true, label: "L Code", name: "l_code", value: "" },
    { check: true, label: "T Code", name: "t_code", value: "" },
    { check: true, label: "EAN Code", name: "ean_code", value: "" },
    { check: true, label: "Variety", name: "variety", value: "" },
]

export const addAppearance = (fruit: Fruit): DetailObject[] => {
    let appearance = [
        { check: true, label: "Pallet Integrity", name: "pallet_integrity", minVal: 1, maxVal: 10, value: "1" },
        { check: true, label: "Temperature C", name: "temperature_c", value: "" },

        { check: true, label: "Insect", name: "insect", value: true },
        { check: true, label: "Fresh", name: "fresh", minVal: 1, maxVal: 10, value: "1" },
        { check: true, label: "Size", name: "size", minVal: 1, maxVal: 10, value: "1" },
        { check: true, label: "Coloration", name: "coloration", minVal: 1, maxVal: 10, value: "1" },
        { check: true, label: "Taste", name: "taste", minVal: 1, maxVal: 10, value: "1" },
        { check: true, label: "Pollution", name: "pollution", value: true },
        { check: true, label: "Humidity", name: "humidity", value: true },
        { check: true, label: "Juicing", name: "juicing", value: true },
        { check: true, label: "Wet Punnets", name: "wet_punnets", value: true },

        { check: true, label: "Brix", name: "brix", value: ["", ""] },
        { check: true, label: "Weight Check", name: "weight_check", value: ["", "", "", ""] },
    ]
    let index = appearance.findIndex(item => item.label === "Coloration");

    if (index !== -1 && coloration.includes(fruit)) {
        appearance.splice(index + 1, 0, { check: true, label: "Internal coloration", name: "internal_coloration", minVal: 1, maxVal: 10, value: "1" });
    }
    if( undersize.includes(fruit) ){ 
        appearance.push({ check: true, label: "Undersize", name: "undersize", value: "" })
    }
    if( fruitPressure.includes(fruit) ){ 
        appearance.push({ check: true, label: "Fruit pressure", name: "fruit_pressure", value: [ "" ]})
    }

    return appearance
}

export const pallgrowData = (samples: number, formatGr: number) => [
    { check: true, label: "Weight 10 Fruits", name: "weight_10", value: "" },
    { check: true, label: "Format Gr.", name: "format_gr", value: formatGr.toString() },
    { check: true, label: "Samples", name: "samples", value: samples.toString() },
]

// export const addPallgrow = (fruit: Fruit, defect: "minor" | "major") => {
//     if (fruit === "pears") { return pallgrowPears(defect) }
//     else if (fruit === "apples") { return pallgrowApples(defect) }
//     else if (fruit === "cherries") { return pallgrowCherries(defect) }
//     else if (fruit === "blueberries") { return pallgrowBlueberries(defect) }
//     else if (fruit === "blackberries") { return pallgrowBlackberries(defect) }
//     else if (fruit === "raspberries") { return pallgrowRaspberries(defect) }
//     else if (fruit === "strawberries") { return pallgrowStrawberries(defect) }
//     else if (fruit === "red currants") { return pallgrowRedCurrants(defect) }
//     else { return pallgrowBerries() }
// };
