import { useEffect, useState } from "react"
import { Supplier, SupplierList } from "../../interfaces/interfaces.auth"
import { CardSupplier } from "../cards/CardSupplier"
import { sortSuppliersByName } from "../../helpers/sortContacts"
import { AddButton } from "../ui/AddButton"
import { ModalAddSupplier } from "../modals/ModalAddSupplier"
import { IoAdd, IoSearchOutline } from "react-icons/io5"
import { ButtonCustom } from "../ui/ButtonCustom"

interface Props {
    suppliers: SupplierList
}

export const Suppliers = ({ suppliers }: Props) => {

    const [modalAddSupplier, setModalAddSupplier] = useState<boolean>(false)

    const [searcher, setSearcher] = useState<string>("")
    const [result, setResult] = useState<Supplier[]>([])

    useEffect(() => {
        if (searcher.length > 0) {
            const searching = suppliers.suppliers.filter(supplier => (supplier.name).toLowerCase().includes(searcher.toLowerCase()))
            setResult(searching)
        } else {
            setResult(suppliers.suppliers || [])
        }
    }, [searcher, suppliers])

    useEffect(() => {
        setResult(suppliers.suppliers || [])
    }, [suppliers])


    return (
        <>
            {
                modalAddSupplier &&
                <ModalAddSupplier
                    closeModal={() => setModalAddSupplier(false)}
                    target={suppliers}
                />
            }

            <section>
                <div className="flex-space-between mb-2">
                    <ButtonCustom text="Add Supplier" iconBefore={<IoAdd color="white" size={15} />} action={() => setModalAddSupplier(true)} />
                    <div className="flex">
                        <IoSearchOutline />

                        <input
                            id="supplier-search"
                            className="input-search ml-05"
                            type="text"
                            value={searcher}
                            onChange={(e) => setSearcher(e.target.value)}
                        />
                    </div>
                </div>

                <div>
                    {
                        suppliers.suppliers.length === 0
                            ? <p className="text-center my-1 font-normal bold">No suppliers</p>
                            :
                            <>
                                {
                                    sortSuppliersByName(result).map(supplier => (
                                        <CardSupplier
                                            key={supplier._id}
                                            supplier={supplier}
                                            target={suppliers}
                                        />
                                    ))
                                }
                            </>
                    }
                </div>



            </section>
        </>
    )
}
