import { Qualify } from "./pallet/Qualify"
import { Section } from "./pallet/Section"
import { AddImages } from "./AddImages"
import { PalletComments } from "./pallet/PalletComments"
import { ImageTemp, ImageType, InitialPallets, NewGrower, StepSection } from "../interfaces/intakes.reports"
import { AccordionCard } from './cards/AccordionCard';
import { useEffect, useState } from "react"
import { Steps } from "./pallet/Steps"
import toast from "react-hot-toast"
import { useFetchImagesSection, useFetchPalletSection, useFinishPallet, useRemovePallet } from "../api/useReportTemp"
import { useQueryClient } from "@tanstack/react-query"
import { ModalConfirmation } from "./ui/ModalConfirmation"
import { Gallery } from "./Gallery"
import { useDeleteImage } from "../api/useDeleteImage"
import { IoCaretForwardCircleSharp, IoCheckmarkCircle } from "react-icons/io5"
import { ModalSplit } from "./ui/ModalSplit"
import { SplitButton } from "./pallet/SplitButton"
import PopUpButton from "./modals/PopUpButton"
import { useEditSplit } from "../api/useEditGrower"
import useOnlineStatus from "../hooks/useOnlineStatus"
import { DetailName } from "../interfaces/interfaces"


interface Props {
    reportId: string
    index: number
    setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
    pallet: InitialPallets
    pallets: InitialPallets[]
    isLastPallet: boolean
    splitPallet: (palletNumber: number, palletId: string, split: NewGrower, palletIndex: number) => Promise<void>
}

interface PalletTitleProps {
    isDisabled?: boolean
}

export const Pallets = ({ setPallets, pallet, reportId, splitPallet, index, isLastPallet, pallets }: Props) => {

    const sectionSteps: StepSection[] = [
        { detailName: "labels", sectionStep: 1 },
        { detailName: "appearance", sectionStep: 2 },
        { detailName: "pallgrow", sectionStep: 3 },
    ]

    const [step, setStep] = useState<number>(1)

    const { mutateAsync, isLoading } = useFetchPalletSection()
    const { mutateAsync: mutateImages, isLoading: isLoadingImages } = useFetchImagesSection()
    const { mutateAsync: mutateFinish, isLoading: isLoadingFinish } = useFinishPallet()
    const { mutate: mutateRemovePallet, isLoading: isRemoving } = useRemovePallet()
    const { mutateAsync: mutateDeleteImage } = useDeleteImage()
    const { mutate: mutateEditSplit } = useEditSplit()
    const isOnline = useOnlineStatus();

    const [accordion, setAccordion] = useState<boolean>(false)
    const [openSplit, setOpenSplit] = useState<boolean>(false)
    const [confirmation, setConfirmation] = useState<boolean>(false)

    const [hasTwins, setHasTwins] = useState<boolean>(false)

    const queryClient = useQueryClient()

    useEffect(() => {
        pallet.complete === null && setStep(0)
        setPallets(pallet => pallet.map(pall => {
            return {
                ...pall,
                labels: { ...pall.labels, imagesTemp: [] },
                appearance: { ...pall.appearance, imagesTemp: [] },
                pallgrow: { ...pall.pallgrow, imagesTemp: [] },
            }
        }))

        pallet.complete && setAccordion(false)
    }, [pallet.complete, setPallets])


    useEffect(() => {
        const hasTwins = pallets.filter(pall => pall.pallet === pallet.pallet).length > 1
        setHasTwins(hasTwins)
    }, [pallets, pallet.pallet])

    const saveAndNext = async (detailName: DetailName, images: ImageTemp[]) => {

        const { data, minor, major } = pallet[detailName]!

        if (!isOnline) return toast.error("You are offline, please check your connection")
        if (!data) return

        const basicData = {
            reportId,
            palletId: pallet.pid,
            detailName,
        }

        const fetchData = mutateAsync({ ...basicData, palletData: data, palletMajor: major, palletMinor: minor })
        const fetchImages = mutateImages({ ...basicData, imagesTemp: images })

        await Promise.all([fetchData, fetchImages])
            .then(([data, images]) => {

                const updatedPallets = pallets.map(pallet => {
                    if (pallet.pid === data.pallet.pid) {
                        return images
                            ? {
                                ...data.pallet,
                                [detailName]: {
                                    ...data.pallet[detailName],
                                    images: [ ...data.pallet[detailName].images , ...images.images]
                                }
                            }
                            : data.pallet
                    } return pallet
                })

                setPallets(updatedPallets)

                queryClient.setQueryData(['reportTemp', reportId], (old: any) => {
                    return {
                        ...old,
                        singleReport: {
                            ...old.singleReport,
                            pallets: updatedPallets
                        }
                    }
                })
                setStep(step => step + 1)

            })
            .catch((error) => console.log(error))

        // const updatedPallets = pallets.map(pallet => {
        //     if (pallet.pid === data.pallet.pid) {
        //         return data.pallet
        //     } return pallet
        // })

        // setPallets(updatedPallets)

        // queryClient.setQueryData(['reportTemp', reportId], (old: any) => {
        //     return {
        //         ...old,
        //         singleReport: {
        //             ...old.singleReport,
        //             pallets: updatedPallets
        //         }
        //     }
        // })
        // setStep(step => step + 1)
    }

    const finishPallet = async () => {

        if (!isOnline) return toast.error("You are offline, please check your connection")

        await mutateFinish({
            reportId,
            palletId: pallet.pid,
            score: pallet.score,
            action: pallet.action,
            grade: pallet.grade,
            comments: pallet.comments || ""
        }, {
            onSuccess: async (data) => {

                const updatedPallets = pallets.map(pallet => {
                    if (pallet.pid === data.pallet.pid) {
                        return data.pallet
                    } return pallet
                })

                setPallets(updatedPallets)

                queryClient.setQueryData(['reportTemp', reportId], (old: any) => {
                    return {
                        ...old,
                        singleReport: {
                            ...old.singleReport,
                            pallets: updatedPallets
                        }
                    }
                })
                setAccordion(false)
                setStep(1)

            }
        })

    }

    const removePallet = () => {
        mutateRemovePallet({ palletId: pallet.pid, reportId }, {
            onSuccess: () => setPallets(pallets => pallets.filter(pall => pall.pid !== pallet.pid))
        })
    };

    const deletePalletImage = async (image: ImageType) => {
        await mutateDeleteImage({
            keyName: image.key,
            keyLow: image.key_low,
            reportId,
            palletId: pallet.pid
        }, {
            onSuccess: () => {
                setPallets(pallets => pallets.map(pall => {
                    if (pall.pid === pallet.pid) {
                        return {
                            ...pall,
                            images: pallet.images.filter(img => img.key !== image.key)
                        }
                    } return pall
                }))
            }
        })
    };

    const startPallet = () => { setStep(step => step + 1); setAccordion(true) }
    const removeConfirmation = () => setConfirmation(true)
    const cleanSplitData = () => mutateEditSplit({ reportId, palletId: pallet.pid, splitPallet: null })

    const PopBtn = () => {
        return (
            <PopUpButton>
                {step === 0 && <p className='py-02' onClick={() => startPallet()} >Start Pallet</p>}
                {(pallet.splitPallet === null || !hasTwins) && <p className='py-02' onClick={() => setOpenSplit(true)}>Split Pallet</p>}
                {(pallet.splitPallet && !hasTwins) && <p className='py-02' onClick={() => cleanSplitData()}>Remove Split Data</p>}
                {(isLastPallet || hasTwins) && <p className="text-red py-02" onClick={() => removeConfirmation()} >Remove Pallet</p>}
            </PopUpButton>
        )
    };

    const PalletTitle = ({ isDisabled }: PalletTitleProps) => {
        return (
            <div className='flex'>
                <div className='flex'>
                    <h3 className={`mr-05 ${isDisabled && "text-mute"}`}>Pallet</h3> {pallet.pallet && <span className={`index-number ${isDisabled && "index-deactivate"}`}>{pallet.pallet}</span>}
                </div>
                {pallet.complete &&
                    <IoCheckmarkCircle color="#5ac85d" size={22} className="ml-05" />
                }
                <SplitButton pallet={pallet} reportId={reportId} />
            </div>
        )
    };

    return (

        <>
            {
                confirmation &&
                <ModalConfirmation
                    closeConfirmation={() => setConfirmation(false)}
                    buttonName="Remove Pallet"
                    msg="Are you sure you want to remove this pallet?"
                    action={() => removePallet()}
                    isLoading={isRemoving}
                />
            }

            {
                openSplit &&
                <ModalSplit
                    closeModal={() => setOpenSplit(false)}
                    splitPallet={splitPallet}
                    reportId={reportId}
                    pallet={pallet}
                    palletIndex={index}
                />
            }

            {
                step === 0
                    ?
                    <div className={`mb-2 card-pallet deactive-pallet`}>

                        <div className='flex-space-between'>
                            <PalletTitle isDisabled />
                            <div className="flex flex-gap position-relative">
                                <PopBtn />
                                <button
                                    className='accordion-open-btn blue'
                                    onClick={() => startPallet()}>
                                    <IoCaretForwardCircleSharp color="#fff" />
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    <AccordionCard
                        defaultOpen={accordion}
                        setAccordion={setAccordion}
                        additionalBtn={<PopBtn />}
                        fullWidth
                        className="mb-2"
                        title={<PalletTitle />}
                    >

                        {
                            sectionSteps.map(section =>
                                (step === section.sectionStep && pallet[section.detailName]) &&
                                <Section
                                    key={section.detailName}
                                    reportId={reportId}
                                    pallet={pallet}
                                    setPallets={setPallets}
                                    detailName={section.detailName}
                                    saveAndNext={saveAndNext}
                                    step={step}
                                    setStep={setStep}
                                    isLoading={isLoading || isLoadingImages}
                                />
                            )
                        }

                        {
                            step === 4 &&

                            <div className={`pallet-step ${step === 4 ? "active-step" : "pt-2 pb-2 border-b"}`}>
                                <Steps
                                    step={step}
                                    setStep={setStep}
                                    actionNext={finishPallet}
                                    isLoading={isLoadingFinish}
                                />
                                <p className='bold font-medium mb-05 text-green'>Score</p>

                                <Qualify setPallets={setPallets} pallet={pallet} />
                                <PalletComments setPallets={setPallets} pallet={pallet} />

                                {
                                    pallet.images.length > 0 &&
                                    <Gallery images={pallet.images} deleteImage={deletePalletImage} fullwidth />
                                }

                                <AddImages
                                    reportId={reportId}
                                    pallet={pallet}
                                    setPallets={setPallets}
                                />
                            </div>
                        }

                    </AccordionCard>

            }
        </>
    )
}

