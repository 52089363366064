import { useEffect, useState } from 'react'

import { itemValor } from '../../helpers/eliminarEs'
import { ModalEdit } from './ModalEdit'
import { ModalEditScore } from './ModalEditScore'
import { ModalEditGrower } from './ModalEditGrower';

import toast from 'react-hot-toast'
import { ChartPie } from './ChartPie'
import { colorGrade } from '../../helpers/colorGrade'
import { colorAction } from '../../helpers/colorAction'
import { photosToShow } from '../../helpers/photosToShow';
import { PhotoGallery } from '../PhotoGallery';
import { AddItemBtn } from './AddItemBtn';
import { ModalAddItem } from './ModalAddItem';
import { Gallery } from '../Gallery'
import { useDeleteImage } from '../../api/useDeleteImage'
import { ChartPieDefects } from './ChartPieDefects'
import { defectsTable } from '../../helpers/defectsTable'
import { PallgrowPallet } from '../pallet/PallgrowPallet'
import { AddPictures } from './AddPictures'
import { ScoreBtn } from '../scores/ScoreBtn'
import { useOldDate } from '../../hooks/useOldDate'
import { CommentsPallet } from './CommentsPallet';


export const Accordeon = ({ date, prereport, format, labels, appareance, pallgrow, weight_format, i, pallet, pid, reportId, score = "0", addGrower, samples, fruit }) => {

    const [activeAccordeon, setActiveAccordeon] = useState(true)
    const [photos, setPhotos] = useState([])

    const [item, setItem] = useState('score')
    const [isReportPrereport, setIsReportPrereport] = useState(false)
    const { oldDate } = useOldDate(date ?? new Date())

    const { mutateAsync } = useDeleteImage()

    /* Modal */
    const [openModal, setOpenModal] = useState(false)
    const [openGroweModal, setOpenGrowerModal] = useState(false)
    const [openModalScore, setOpenModalScore] = useState(false)
    const [openModalAddItem, setOpenModalAddItem] = useState(false)
    const nameAppreciation = prereport?.grade || "0"

    const [editGrower, setEditGrower] = useState(undefined)
    const [editContent, setEditContent] = useState({
        reportId: "",
        pid: "",
        label: "",
        itemName: "",
        detailItem: "",
        tipo: "",
        currentVal: ""
    })

    useEffect(() => {
        setPhotos(photosToShow(pallet))
        return () => setPhotos([])
    }, [pallet])

    const activeGrowerModal = (grower) => {
        setEditGrower({ ...grower, pid, reportId })
        setOpenGrowerModal(true)
    };

    const activeModal = (tipo, itemName, detailName, label, currentVal, isRepPre = false) => {

        setIsReportPrereport(isRepPre)
        setEditContent({
            reportId,
            pid,
            label,
            itemName,
            detailName,
            tipo,
            currentVal
        })

        setOpenModal(true)
    };

    const activeModalScore = (itemName, isRepPre = false) => {
        setItem(itemName)
        setIsReportPrereport(isRepPre)
        setOpenModalScore(true)
    };


    const deleteImage = async (img) => {
        const data = {
            reportId,
            palletId: pid,
            keyName: img.key,
            keyLow: img.key_low
        }
        await mutateAsync(
            data,
            {
                onSuccess: () => toast.success("Image deleted"),
                onError: () => toast.error("Something went wrong"),
            }
        )
    };

    return (
        <div className={`${activeAccordeon ? "accordeon show" : "accordeon"} card-accordeon p-1 mb-1`}>

            {
                openGroweModal &&
                <ModalEditGrower
                    {...editGrower}
                    closeModal={() => setOpenGrowerModal(false)}
                />
            }

            {
                openModal &&
                <ModalEdit
                    closeModal={() => setOpenModal(false)}
                    isReportPrereport={isReportPrereport}
                    {...editContent}
                />
            }

            {
                openModalScore &&
                <ModalEditScore
                    setOpenModalScore={setOpenModalScore}

                    reportId={reportId}
                    pid={pid}
                    oldDate={oldDate}

                    item={item}
                    isReportPrereport={isReportPrereport}

                    score={isReportPrereport ? prereport?.score : score}
                    action={prereport?.action}
                    grade={prereport?.grade}
                />
            }

            {
                openModalAddItem &&
                <ModalAddItem
                    pallgrow={pallgrow}
                    closeModal={() => setOpenModalAddItem(false)}
                    samples={samples}
                    pid={pid}
                    reportId={reportId}
                />
            }

            <button
                className="pallet-ref mb-2 accordeon-button"
                onClick={() => setActiveAccordeon(!activeAccordeon)}
            >
                <div className="accordeon-pallet"><h3 className="mr-1">Pallet</h3> <span>{i + 1}</span></div>
                <img src="/assets/img/chevron-down.svg" alt="chevron-down" className={activeAccordeon ? "chevron-down" : "chevron-up"} />
            </button>

            {
                addGrower &&

                <div className='flex mb-3'>
                    <div className='score-header w-100 flex-space-between px-1'>
                        <p>Grower/Var: </p>
                        <p>{addGrower.grower_variety || "--"}</p>
                    </div>

                    <div className='score-header w-100 flex-space-between ml-05 px-1 mr-05'>
                        <p>Boxes: </p>
                        <p>{addGrower.boxes || "--"}</p>
                    </div>
                    <div className='accordeon-table ml-2'>
                        <button
                            className="accordeon-edit custom-edit-report"
                            onClick={() => activeGrowerModal(addGrower)}
                        >
                            <img src="/assets/img/edit-grey.svg" alt="edit-item" />
                        </button>
                    </div>

                </div>
            }

            {
                prereport &&
                <div className="pallet-prereport mb-2 pt-2">
                    <div className='font-small'>Pre report</div>
                    <div className='mb-2'>
                        <h4 className='mb-1'>Labels</h4>
                        {
                            prereport?.details?.labels.map((lab, i) => (
                                <div key={i} className="flex-space-between border-b">
                                    <p>{lab.label}</p>
                                    <div className='flex'>
                                        <p>{itemValor(lab.valor, lab.tipe) || "--"}</p>
                                        <button
                                            className="prereport-edit-btn ml-1"
                                            onClick={() => activeModal(lab.tipe, lab.name, 'labels', lab.label, lab.valor, true)}
                                        >
                                            <img src="/assets/img/edit-grey.svg" alt="edit-item" />
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='mb-2'>
                        <h4 className='mb-1'>Appearance</h4>
                        {
                            prereport?.details?.appareance.map((app, i) => (
                                <div key={i} className="flex-space-between border-b">
                                    <p>{app.label}</p>
                                    <div className='flex'>
                                        <p>{itemValor(app.valor) || "--"}</p>
                                        <button
                                            className="prereport-edit-btn ml-1"
                                            onClick={() => activeModal(app.tipe, app.name, 'appareance', app.label, app.valor, true)}
                                        >
                                            <img src="/assets/img/edit-grey.svg" alt="edit-item" />
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>


                    <div className='mt-1'>

                        <div className="score-wraper">
                            <p>Score</p>
                            <button
                                onClick={() => activeModalScore("score", true)}
                                className="score-btn"
                            ><ScoreBtn date={date} score={prereport?.score || "0"} />
                            </button>
                        </div>

                        <div className='score-wraper py-1'>
                            <p>QC Appreciation</p>
                            <button
                                onClick={() => activeModalScore("grade", true)}
                                className="score-btn"
                            > {colorGrade(prereport?.grade)}
                            </button>
                        </div>

                        <div className='score-wraper wraper-up'>
                            <p>Suggested commercial action</p>
                            <button
                                onClick={() => activeModalScore("action", true)}
                                className="score-btn"
                            > {colorAction(prereport?.action)}
                            </button>
                        </div>

                    </div>
                </div>
            }

            {
                labels.length > 0 &&
                <>
                    <h3 className="mb-05 pallet-item">Labels</h3>
                    <div className="grid-table mb-2">
                        {
                            labels.map((itemLab, i) => (
                                <div className="table accordeon-table" key={i}>
                                    <p>{itemLab.label}</p>
                                    <strong>{itemValor(itemLab.valor)}</strong>
                                    <button
                                        className="accordeon-edit"
                                        onClick={() => activeModal(itemLab.tipe, itemLab.name, 'labels', itemLab.label, itemLab.valor)}
                                    >
                                        <img src="/assets/img/edit-grey.svg" alt="edit-item" />
                                    </button>
                                </div>
                            ))
                        }
                    </div>
                </>
            }


            {
                appareance.length > 0 &&
                <>
                    <h3 className="mb-05 pallet-item">Appearance</h3>
                    <div className="grid-table mb-2">
                        {
                            appareance.map((itemApp, i) => (
                                <div className="table accordeon-table" key={i}>
                                    <p>{itemApp.label}</p>

                                    <div className="grid">
                                        {
                                            itemApp.tipe !== "arrays"
                                                ? <strong className="span-6">{itemValor(itemApp.valor)}</strong>
                                                : <>
                                                    {
                                                        itemApp.valor.map((item, g) => (
                                                            <strong className="span-6" key={g}>{itemValor(item)}</strong>
                                                        ))
                                                    }
                                                </>
                                        }
                                        <button
                                            className="accordeon-edit"
                                            onClick={() => activeModal(itemApp.tipe, itemApp.name, 'appareance', itemApp.label, itemApp.valor)}
                                        >
                                            <img src="/assets/img/edit-grey.svg" alt="edit-item" />
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </>
            }


            {
                pallgrow.length > 0 &&
                <>
                    <h3 className="mb-05 pallet-item">Pall/Grower</h3>

                    <PallgrowPallet pallgrow={defectsTable(pallgrow, fruit, format, weight_format)} activeModal={activeModal} />

                    <AddItemBtn action={() => setOpenModalAddItem(true)} />
                    {
                        (pallgrow.filter(item => item.name !== "weight_10").length > 0 && (format && weight_format))
                            ? <>
                                <ChartPie
                                    pallgrow={pallgrow}
                                />
                                <ChartPieDefects
                                    pallgrow={defectsTable(pallgrow, fruit, format, weight_format)}
                                    nameAppreciation={nameAppreciation}
                                />
                            </>
                            : <></>
                    }


                </>
            }

            {
                photos.length > 0 &&
                <div className='mt-3'>
                    <PhotoGallery
                        photos={photos}
                        pid={pid}
                        reportId={reportId}
                        update={false}
                    />
                </div>
            }

            {
                pallet.images && pallet.images.length > 0 &&
                <Gallery images={pallet.images} deleteImage={deleteImage} />
            }

            <AddPictures pid={pid} reportId={reportId} />

            <div className="score-container mt-1">
                <strong>Score</strong>
                <button
                    className="score-btn"
                    onClick={() => activeModalScore("score")}
                >
                    <ScoreBtn date={date} score={score || "0"} />
                </button>
            </div>

            {
                (pallet.comments && pallet.comments.length > 0) &&
                <CommentsPallet pid={pid} reportId={reportId} comments={pallet.comments} />
            }

        </div>

    )
}