import { Page, View } from "@react-pdf/renderer"
import { PDFHeader } from "./PDFHeader"
import { Report } from "../../interfaces/intakes.reports"
import { globalStyles } from "./DocuPdf"

interface Props {
    children: React.ReactNode
    data: Report
    wrap?: boolean
}

export const PDFPage = ({ children, data, wrap=true }: Props) => {
    return (
        <Page
            size="A4"
            orientation="landscape"
            wrap={wrap}
            style={{...globalStyles.pageFormat, minHeight:595.28}}
        >
            <View fixed style={{ marginBottom: 10 }}><PDFHeader data={data}/></View>
            {children}
        </Page>
    )
}
