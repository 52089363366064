import { useState } from 'react'
import { Defects, DetailName } from '../../interfaces/interfaces'
import { ModalBtn } from './ModalBtn'
import { ToggleBtn } from '../ToggleBtn'

interface Props {
    closeModal: () => void
    detailName: DetailName
    addPallItem: (newLabel: string, newValue: string, isDefect?: Defects) => void
}

export const ModalAddItem = ({ closeModal, detailName, addPallItem }: Props) => {

    const [newLabel, setNewLabel] = useState("")
    const [newValue, setNewValue] = useState("")
    const [isMajor, setIsMajor] = useState<boolean>(false)

    const addItem = () => {
        addPallItem(newLabel, newValue, detailName === 'pallgrow' ? isMajor ? 'major' : 'minor' : undefined)
    };

    return (

        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">

                    <label className='bold mb-05'>Name</label>
                    <input
                        type="text"
                        value={newLabel}
                        onChange={(e) => setNewLabel(e.target.value)}
                    />
                    {
                        detailName !== 'pallgrow' &&
                        <>
                            <label className='mt-2 bold'>Value</label>
                            <input
                                className="mb-1"
                                type="text"
                                value={newValue}
                                onChange={(e) => setNewValue(e.target.value)}
                            />
                        </>
                    }
                    {
                        detailName === 'pallgrow' &&
                        <div className='flex-end flex-center'>
                            <p className={`font-small ${isMajor ? "text-major" : "text-minor"} mr-05`}>{isMajor ? "Major defect" : "Minor defect"}</p>
                            <ToggleBtn state={isMajor} action={() => setIsMajor(item => !item)} isDefect />
                        </div>
                    }

                    <ModalBtn
                        action={addItem}
                        closeModal={closeModal}
                        actionTitle='Add item'
                    />

                </div>
            </div>
        </div>

    )
}
