import { useEffect } from 'react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import qcareApi from '../../api/qcareApi'
import { palletPrereport } from '../../data/prereports'
import { AddPallet } from '../pages/AddPallet'
import { Charging } from './Charging'
import { photoToUpload, uploadPhoto } from '../../helpers/photoToUpload'
import { useQueryClient } from '@tanstack/react-query'
import { useOldDate } from '../../hooks/useOldDate'
import { ModalBtn } from '../modals/ModalBtn'


export const ModalAddPallet = ({ setOpenModal,
    reportId,
    update,
    hasGrower,
    date
}) => {

    const { oldDate } = useOldDate(date)

    const queryClient = useQueryClient()

    const closeModal = () => {
        setOpenModal(false)
    }

    useEffect(() => {
        if (hasGrower) {
            setPallets([{
                ...palletPrereport(), newGrower: {
                    grower_variety: "",
                    boxes: 0,
                }
            }])
        } else {
            setPallets([palletPrereport()])
        }

    }, [hasGrower])

    const [loo, setLoo] = useState(false)
    const [pallets, setPallets] = useState([])

    const sendNewPallet = async () => {

        const pallet = pallets[0]

        if (pallet.grade === "0") return toast.error(`QC Appreciation is pending`)
        if (pallet.action === "0") return toast.error(`Suggested commercial action is pending`)
        if (pallet.score === "0") return toast.error(`Score is pending`)
        if (hasGrower && pallet.newGrower.grower_variety.length === 0) return toast.error(`Grower name is pending`)
        if (hasGrower && pallet.newGrower.boxes === 0) return toast.error(`Boxes need to have a value`)

        setLoo(true)

        try {


            const details = {
                labels: (pallet.labels.filter(p => p.check === true)).map(lab => { const { check, ...rest } = lab; return rest }),
                appareance: (pallet.appareance.filter(p => p.check === true)).map(app => { const { check, ...rest } = app; return rest })
            }


            const newPallet = {
                pid: pallet.id,
                details,
                score: pallet.score,
                grade: pallet.grade,
                action: pallet.action,
                images: [],
                addGrower: pallet.newGrower || null,
            }

            //-----------------------------------------------------------

            await qcareApi.post(`/prereport/add-pallet`, {
                repId: reportId,
                pallet: newPallet,
            })

                .then(async () => {

                    const files = photoToUpload(reportId, pallets)

                    if (files.length > 0) {
                        const uploadPromises = files.map(file => uploadPhoto(file, true));
                        await Promise.all(uploadPromises)
                    }
                })

                .then(async () => {

                    if (pallet.images.length === 0) return

                    const preId = newPallet.pid

                    const formData = new FormData();

                    for (const img of pallet.images) {
                        formData.append('uploady', img.file)
                    }

                    formData.append('preId', reportId)
                    formData.append('palletId', preId)

                    await qcareApi.post('/prereport/images-prereport', formData)

                })

            toast.success('Pallet added successfully')
            await queryClient.invalidateQueries(['prereport'])


        } catch (error) {
            console.log(error)
        }
        finally {
            setLoo(false)
            update()
            closeModal()
        }
    };

    return (
        <div className="modal">

            <div className={`modal__card ${loo ? null : 'mobile-version'}`}>
                <div className="modal__content">
                    {
                        loo
                            ?
                            <Charging />
                            :
                            <>
                                <h3 className='mb-1'>Additional Pallet</h3>
                                {
                                    pallets.length > 0 &&
                                    pallets.map(pallet => (
                                        <AddPallet
                                            key={pallet.id}
                                            prereport={true}
                                            pallet={pallet}
                                            pallets={pallets}
                                            setPallets={setPallets}
                                            oldDate={ oldDate }
                                        />
                                    ))
                                }

                                <ModalBtn
                                    action={sendNewPallet}
                                    closeModal={closeModal}
                                />
                            </>
                    }
                </div>
            </div>

        </div>
    )
}