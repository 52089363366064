import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import qcareApi from '../../api/qcareApi'
import { sleep } from '../../helpers/sleep'
import { useDownloadPdf } from '../../hooks/useDownloadPdf'

export const DownloadPdf = () => {

    const { id } = useParams()
    const { generatePDF, isFinish, isGenerating } = useDownloadPdf()

    useEffect(() => {
        const close = async () => {
            if (isFinish) {
                await sleep(1)
                window.close();
            }
        }
        close()
    }, [isFinish])

    const startDownload = async () => {
        const { data } = await qcareApi.get(`/pdf/get-pdf/${id}`)
        await generatePDF([data.allData, false], `${data.allData.palletRef || "report"}.pdf`)
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh" }}>
            {
                !isFinish
                    ? 
                    <div>
                        <div className='flex-center mb-1'><img src='../../assets/img/qc-logo-color.png' width={80} alt="qc-logo" className='flex-center text-center'/></div>
                        <button disabled={isGenerating} className={`btn-primary ${ isGenerating ? "desactive" : null }`} onClick={startDownload}>{isGenerating ? "Creating Report..." :"Click to download"}</button>
                    </div>
                    : <p>Done</p>
            }
        </div>
    )
}
