import React from "react"

interface Props{
    icon?: string,
    small?: boolean,
    color?: "grey" | "green" | "red",
    mr?: boolean,
    ml?: boolean,
    children: React.ReactNode
}

function Badge({ small, color="green", icon, mr, ml, children }:Props) {

    const bgColor = {
        grey: "badge-grey",
        green: "badge-green",
        red: "badge-red"
    }

    const textColor = {
        grey: "text-mute",
        green: "text-done",
        red: "text-red"
    }


    return (
        <div className={`team-info
        ${ bgColor[color] }
        ${mr ? "mr-05" : ""}
        ${ml ? "ml-05" : ""}
        ${small ? "badge-small" : ""}
        `}
        >
            {
                icon &&
                <img className='mr-05' src={`../assets/img/${icon}.svg`} alt='team-icon' width={16} />
            }
            <p className={`font-small 
            ${ textColor[color] }`}
            >{children}</p>
        </div>
    )
}

export default Badge