import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Charging } from '../ui/Charging'
import { ModalConfirmation } from '../ui/ModalConfirmation'
import { useIntakes, useRemoveIntake } from '../../api/useIntakes';
import { SelectTeam } from '../ui/SelectTeam'
import { Pagination } from '../ui/Pagination'
import { optionValue } from '../../helpers/eliminarEs'
import Badge from '../ui/Badge'

export const Intakes = () => {

    const [page, setPage] = useState(1)
    const [toggle, setToggle] = useState(0)

    const { isLoading, data } = useIntakes(page, toggle)

    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [currentId, setCurrentId] = useState(null)

    const { mutateAsync, isLoading: isDeleting } = useRemoveIntake()

    const handleRemove = async (id) => {
        await mutateAsync(id)
        setOpenConfirmation(false)
    }

    const handleToggle = (e) => {
        setToggle(optionValue(e.target.value))
        setPage(1)
    };

    // const apiCall = async () => {
    //     fetch(`${process.env.REACT_APP_API_URL}/v1/intake`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'x-api-key': 'cjwdlrIf10E2PO0KLe8NVCcP0CLTMAy0'
    //         },
    //         body: JSON.stringify({
    //             data: {
    //                 pallet_ref: 'Pallet Reference Name',
    //                 supplier: 'Supplier Name',
    //                 format: '10*125g',
    //                 total_kilos: 1,
    //                 total_boxes: 2,
    //                 total_pallets: 3,
    //                 product_temperature: 4,
    //                 samples: "5",
    //                 format_gr: 6,
    //                 discarded_fruit_kilos: 7,
    //                 discarded_fruit_boxes: 8,
    //                 arrival_date: "1/12/23",
    //             },
    //             team: "6459890711a97e13d81cbc1e"
    //         })
    //     })
    //         .then(res => res.json())
    //         .then(console.log)
    //         .catch(console.error);
    // };

    // const apiCallAll = async () => {
    //     fetch(`${process.env.REACT_APP_API_URL}/v1/reports?limit=10`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'x-api-key': 'cjwdlrIf10E2PO0KLe8NVCcP0CLTMAy0'
    //         }
    //     })
    //     // fetch(`${process.env.REACT_APP_API_URL}/v1/intakes?limit=10`, {
    //     //     method: 'GET',
    //     //     headers: {
    //     //         'Content-Type': 'application/json',
    //     //         'x-api-key': 'cjwdlrIf10E2PO0KLe8NVCcP0CLTMAy0'
    //     //     }
    //     // })
    //         .then(res => res.json())
    //         .then(console.log)
    //         .catch(console.error);
    // };

    return (
        <div className="content intake">

            {
                isLoading || !data
                    ? <Charging />
                    :
                    <main className="container">
                        <h2 className="main-title mb-2">Intakes</h2>

                        {/* <button
                            onClick={apiCallAll}
                        >API</button> */}

                        <Link to="/newintake" className="card-intake mb-2">
                            <svg version="1.1" viewBox="0 0 100 100">
                                <g>
                                    <path d="M37.1,60.5c-1.5,1.5-1.5,3.8,0,5.3c0.7,0.7,1.7,1.1,2.7,1.1s1.9-0.4,2.7-1.1l14.7-14.7
c0.3-0.3,0.6-0.7,0.8-1.3c0.4-0.9,0.4-1.9,0-2.8c-0.2-0.5-0.5-0.8-0.8-1.3L42.5,31.1c-1.5-1.5-3.8-1.5-5.3,0s-1.5,3.8,0,5.3
l8.2,8.2H8.9c-2,0.2-3.8,1.9-3.8,3.9s1.7,3.8,3.8,3.8h36.3L37.1,60.5z"/>
                                    <path d="M80.2,62.4v-30c0-12.7-10.4-23-23-23H37.4c-12.7,0-23,10.3-23,23v0.9c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8
v-0.9c0-8.5,6.9-15.4,15.4-15.4h19.8c8.5,0,15.4,6.9,15.4,15.4v29.9c-8,1.6-14.3,8.2-15.2,16.4H37.4c-8.5,0-15.4-6.9-15.4-15.4
c0-2.1-1.7-3.8-3.8-3.8s-3.8,1.7-3.8,3.8c0,12.7,10.3,23,23,23h19.8c0.2,0,0.4,0,0.6,0c2.3,7.9,9.6,13.7,18.2,13.7
c10.5,0,19-8.5,19-19C95.1,71.9,88.7,64.4,80.2,62.4z M86.5,85h-7.1v7.1c0,1.5-1.2,2.7-2.7,2.7S74,93.6,74,92.1V85h-7.1
c-0.5,0-0.8-0.1-1.2-0.3c-0.8-0.5-1.5-1.3-1.5-2.3c0-1.5,1.2-2.7,2.7-2.7h6.4H74V79v-6.4c0-1.5,1.2-2.7,2.7-2.7
c0.9,0,1.6,0.5,2.2,1.1c0.3,0.5,0.5,0.9,0.5,1.6v7.1h7.1c1.5,0,2.7,1.2,2.7,2.7S87.9,85,86.5,85z"/>
                                </g>
                            </svg>
                            <h4 className="fruit__title">Add New Intake</h4>
                        </Link>

                        {
                            data?.intakes?.length > 0 &&
                            <SelectTeam
                                toggle={toggle}
                                handleToggle={handleToggle}
                                title="All Intakes"
                            />
                        }

                        {
                            data?.intakes?.length === 0
                                ? <div className="boton-intake">
                                    <p className="text-center">No intakes</p>
                                    <Link to="/newintake" className="mt-1">
                                        <svg viewBox="0 0 200 200">
                                            <polygon points="180.1,91.7 108.3,91.7 108.3,19.9 91.7,19.9 91.7,91.7 19.9,91.7 19.9,108.3 91.7,108.3 91.7,180.1 108.3,180.1 108.3,108.3 180.1,108.3 " />
                                        </svg>
                                    </Link>
                                </div>
                                :
                                <>

                                    {
                                        openConfirmation &&
                                        <ModalConfirmation
                                            msg="Are you sure you want to remove this Intake?"
                                            closeConfirmation={setOpenConfirmation}
                                            action={() => handleRemove(currentId)}
                                            isLoading={isDeleting}
                                        />
                                    }
                                    <div className="intake__list">
                                        {
                                            data?.intakes &&
                                            data?.intakes.map(info => {

                                                return (
                                                    <div key={info._id} className="intake__container">

                                                        {
                                                            info.team &&
                                                            <div className='absolute-item top left flex'>
                                                                <img className='mr-02' src='../assets/img/team.svg' alt='team-icon' width={16} />
                                                                {
                                                                    info.inCharge &&
                                                                    <div><Badge small>{(info.inCharge?.name || "--") + " " + (info.inCharge?.lastname.charAt(0).toUpperCase() + "." || "")}</Badge></div>
                                                                }
                                                            </div>
                                                        }

                                                        <Link to={`/intake/${info._id}`} className="intake__card">
                                                            {/* <Link to={`/new-prereport/${info._id}`} className="intake__card"> */}

                                                            <div className="grid">
                                                                <div className="intake__left span-6">
                                                                    <p className='p-ref'>{info.data?.pallet || info.data?.pallet_ref || "--"}</p>
                                                                    <p>{info.data?.product || "--"}</p>
                                                                </div>
                                                                <div className="intake__right span-6">
                                                                    <p>{info.data?.supplier || "--"}</p>
                                                                    <p>{info.data?.format || "--"}</p>
                                                                    <p>{info.data?.total_pallets || "--"}</p>
                                                                    <p>{info.data?.total_boxes || "--"}</p>
                                                                    <p>{info.data?.transport || "--"}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <button
                                                            className="remove-circle"
                                                            onClick={
                                                                () => {
                                                                    setCurrentId(info._id)
                                                                    setOpenConfirmation(true)
                                                                }
                                                            }>
                                                            <svg version="1.1" viewBox="0 0 200 200">
                                                                <polygon points="194.7,17 183,5.3 100,88.2 17,5.3 5.3,17 88.2,100 5.3,183 17,194.7 100,111.8 183,194.7 194.7,183 111.8,100 " />
                                                            </svg>
                                                        </button>



                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {
                                        data.totalPages && data.totalPages > 1 &&
                                        <Pagination
                                            totalPages={data.totalPages}
                                            page={page}
                                            setPage={setPage}
                                        />
                                    }
                                </>
                        }

                    </main>
            }


        </div>
    )
}
