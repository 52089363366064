import { useEffect, useState } from 'react'
import qcareApi from '../../api/qcareApi'

import { useDispatch } from 'react-redux'
import { startChecking } from '../../store/asyncMethods/authMethods'
import { ModalBtn } from '../modals/ModalBtn'


export const ModalTeamMember = ({ setCloseModal, member, team }) => {

    const [active, setActive] = useState(true)
    const [role, setRole] = useState("ADMIN")

    useEffect(() => {
        setActive(member.active)
        setRole(member.rol)
    }, [member])

    const dispatch = useDispatch()

    const editMember = async () => {

        if (active === member.active && role === member.rol) {
            setCloseModal(false)
        }

        try {
            await qcareApi.post(`/team/edit-member/${team._id}`,
                {
                    memberId: member.uid,
                    rolStatus: role,
                    activeStatus: active
                })

            dispatch(startChecking())
        } catch (error) {
            console.log(error)
        } finally {
            setCloseModal(false)
        }

    };

    return (
        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">
                    <div className='mb-2 text-center'>
                        <label className="font-large bold">{member.name} {member.lastname}</label>
                        <p className="text-mute font-medium ">{member.email}</p>
                    </div>

                    <div className='mb-1'>
                        <div className="input-range">
                            <label className="switch">
                                <input
                                    type='checkbox'
                                    checked={active}
                                    onChange={() => setActive(!active)}
                                />
                                <span className="slider active-btn"></span>
                            </label>

                            <div className={`team-list__active ${active ? 'team-list__active-on' : 'team-list__active-off'} active-big`}>{active ? "ACTIVE" : "INACTIVE"}</div>

                        </div>
                    </div>

                    <div className='mb-1'>
                        <div className="input-range">
                            <label className="switch">
                                <input
                                    type='checkbox'
                                    checked={role === "ADMIN" ? true : false}
                                    onChange={() => setRole(role === "ADMIN" ? "USER" : "ADMIN")}
                                />
                                <span className="slider rol-btn"></span>
                            </label>

                            <div className={`team-list__active ${role === "ADMIN" ? 'team-list__admin' : 'team-list__user'} active-big`}>{role === "ADMIN" ? "ADMIN" : "USER"}</div>

                        </div>
                    </div>

                    <ModalBtn
                        action={editMember}
                        closeModal={() => setCloseModal(false)}
                    />
                </div>
            </div>



        </div>
    )
}