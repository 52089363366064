import { useState, useEffect } from 'react'
import { MailForm } from './MailForm'
import { StepOne } from './StepOne';
import { subjectString } from '../../helpers/subjectString';
import { objectToCheckArray } from '../../helpers/objToArray';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { photosSavePDF } from '../../helpers/photosToShow';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { InitialPallets, MainData } from '../../interfaces/intakes.reports';
import { useCreateLink } from '../../api/useSharePDF';
import { v4 as uuidv4 } from 'uuid';

export interface TempContact {
    id: string
    check: boolean
    name: string
    email: string
}

interface PDFProps {
    reportId: string
    mainData: MainData
    pallets: InitialPallets[]
    team: string | null
    supplier: string | null
    date: Date
    closeModal: () => void
}

export interface PDFMainData {
    check: boolean;
    key: string;
    value: string;
}

export interface PDFImages {
    pid: string;
    images: {
        imgURL: string;
        imgURL_low?: string;
    }[];
}

export interface PDFLink {
    reportId: string
    pdfImages: PDFImages[]
    avoidData?: string[]
}

export const ModalSendPDF = ({ reportId, mainData: dataMainData, pallets, team, supplier, date, closeModal }: PDFProps) => {

    const { email } = useTypedSelector(state => state.auth)

    const [mailTo, setMailTo] = useState<TempContact[]>([])
    const [ccTo, setCcTo] = useState<TempContact[]>([]);
    const [message, setMessage] = useState<string>("")
    const [subject, setSubject] = useState<string>(subjectString(dataMainData, date))

    const [mainData, setMainData] = useState<PDFMainData[]>(objectToCheckArray(dataMainData))
    const [images, setImages] = useState<PDFImages[]>([])

    const [link, setLink] = useState<string | null>(null)
    const [step, setStep] = useState<number>(1)

    const { mutate, isLoading } = useCreateLink()

    useEffect(() => {
        let allImages: PDFImages[] = []
        let defectImages = []
        for (const paly of pallets) {
            allImages.push({
                pid: paly.pid,
                images: paly.images.map(imOne => { return { imgURL: imOne.imgURL, imgURL_low: imOne.imgURL_low } })
            })
        }
        for (const pall of pallets) {
            defectImages.push({
                pid: pall.pid,
                images: photosSavePDF(pall)
            })
        }

        setImages(allImages)
    }, [pallets])


    useEffect(() => {
        if (email.includes("@growerspackers.com")) {
            setCcTo([{
                id: uuidv4(),
                check: true,
                name: "Quality GP",
                email: "quality@growerspackers.com"
            }, {
                id: uuidv4(),
                check: true,
                name: "Quality GP",
                email: "quality.gp3@growerspackers.com"
            }])
        }
    }, [email])


    //Save the PDF Data in the Database
    const createLink = async () => {

        const fetchData: PDFLink = {
            reportId: reportId,
            pdfImages: images.map(pal => (
                {
                    pid: pal.pid,
                    images: pal.images.map(img => ({ imgURL: img.imgURL }))
                }
            )),
            avoidData: mainData.filter(data => data.check === false).map(item => item.key)
        }

        mutate(fetchData, {
            onSuccess: (data) => {
                setLink(`https://q-care.info/share-report-qc/${data.pdfId}`)
                setMessage(`<p>Download this report in PDF format by clicking this link: https://q-care.info/share-report-qc/${data.pdfId}</p><p>Download the images of this inspection in a ZIP file by clicking this link: https://q-care.info/zipped-images/${data.pdfId}</p>`)
                setStep(4)
            },
            onError: (error) => {
                console.log(error)
                setLink(null)
            }
        })
    }


    //Send the PDF by mail

    //--------------------------------------------------------

    return (
        <div className="modal">

            <div className="modal__cardPDF">

                {/* <Multisteps
                    link={link}
                    step={step}
                /> */}

                {
                    step === 1 &&
                    <StepOne
                        reportSupplier={supplier}
                        mainDataSupplier={dataMainData?.supplier || ""}
                        mailTo={mailTo}
                        setMailTo={setMailTo}
                        team={team}
                        closeModal={closeModal}
                        setStep={setStep}
                    />
                }

                {
                    step === 2 &&
                    <StepTwo
                        mainData={mainData || []}
                        setMainData={setMainData}
                        setStep={setStep}
                        closeModal={closeModal}
                    />
                }

                {
                    step === 3 &&
                    <StepThree
                        pallets={pallets}
                        images={images}
                        setImages={setImages}
                        createLink={createLink}
                        closeModal={closeModal}
                        setStep={setStep}
                        isLoading={isLoading}
                    />
                }

                {
                    (step === 4 && link) &&
                    <MailForm
                        mailTo={mailTo}
                        // setMailTo={setMailTo}
                        ccTo={ccTo}
                        // setCcTo={setCcTo}
                        message={message}
                        setMessage={setMessage}

                        subject={subject}
                        setSubject={setSubject}
                        setStep={setStep}
                        link={link}
                        closeModal={closeModal}
                    />
                }

            </div>
        </div>
    )
}