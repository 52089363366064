import { useState, useEffect } from 'react'

import { useSelector } from 'react-redux';

//-------------------------------------------------------------

const membersRol = (allTeam, teamId, rol) => {
    return allTeam.find( team => team._id ===  teamId ).members.filter(m => m.rol === rol)
};

//-------------------------------------------------------------

export const ModalInCharge = ({ setModal, addInCharge, intake }) => {

    const { teamsAdmin, teamsUser } = useSelector(state => state.auth);
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [selectedUser, setSelectedUser] = useState(0)
    const [allTeams, setAllTeams] = useState([])

    useEffect(() => {
        const all = [...teamsAdmin, ...teamsUser]
        setAllTeams(all || [])

        if (all.length > 0) {
            setSelectedTeam(intake.team || all[0]._id)
            setSelectedUser(intake.inCharge ?? 0)
        }
    }, [teamsAdmin, teamsUser, intake])


    const handleTeam = (e) => {
        setSelectedTeam( e.target.value )
        setSelectedUser( 0 )
    };

    const handleUser = (e) => {
        setSelectedUser( e.target.value )
    };

    return (
        <div className="modal">

            <div className="modal__card">

                {
                    allTeams.length > 0
                        ?
                        <div className="modal__content">
                            <div className='mb-1'>
                                <p className='text-left mb-02 bold'>Team</p>
                                <div className='select-input'>
                                    <select onChange={handleTeam} value={selectedTeam || 0}>
                                        {
                                            allTeams.map(team => (
                                                <option key={team._id} value={team._id}>{team.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            {
                                selectedTeam &&
                                <div className='mb-1'>
                                    <p className='text-left mb-02 bold'>Member</p>
                                    <div className='select-input'>
                                        
                                        <select onChange={handleUser} value={selectedUser || 0}>
                                            <option value={0}>All members</option>
                                            <optgroup label="Admin">
                                                {
                                                    membersRol(allTeams, selectedTeam, "ADMIN").map(user => (
                                                        <option key={user.uid} value={`${user.uid}`}>{user.name} {user.lastname} | {user.email}</option>
                                                    ))
                                                }
                                            </optgroup>

                                            {
                                                membersRol(allTeams, selectedTeam, "USER").length > 0 &&
                                                <optgroup label="Members">
                                                    {
                                                        membersRol(allTeams, selectedTeam, "USER").map(user => (
                                                            <option key={user.uid} value={`${user.uid}`}>{user.name} {user.lastname} | {user.email}</option>
                                                        )
                                                        )
                                                    }
                                                </optgroup>
                                            }

                                        </select>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <p className='text-center'>No Teams to select</p>
                }

                <div className="modal__botones mt-2">
                    <button
                        className="modal-btn btn-close mr-05"
                        onClick={() => setModal(false)}
                    >
                        Close
                    </button>
                    <button
                        className="modal-btn btn-add ml-05"
                        onClick={() => addInCharge(selectedTeam, selectedUser) }
                    >
                        Assign
                    </button>
                </div>

            </div>



        </div>
    )
}