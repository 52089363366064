import { useState } from 'react'
import { Link } from 'react-router-dom'
import { dateFormat } from '../helpers/dateFormat'
import { InfoBox } from './ui/InfoBox'
import { useOldDate } from '../hooks/useOldDate'
import Badge from './ui/Badge'
import { CoverImage } from './cards/CoverImage'

export const CardReport = ({ setOpenConfirmation, setCurrentId, setOpenSendPDF, pallets, _id: id, mainData, date, score, unfinished = false }) => {

    const { scoreNumber } = useOldDate(date, score)
    const [activeDots, setActiveDots] = useState(false)

    return (
        <div className="card mb-1">

            <Link
                to={unfinished ? `/create-report/${id}` : `/report/${id}`}
                className="card__image">
                <CoverImage pallets={pallets} />
            </Link>

            <Link
                to={unfinished ? `/create-report/${id}` : `/report/${id}`}
                className="card__content">
                <div className="card__body">
                    <div className="card__title flex">
                        <h4>{mainData.pallet_ref}</h4>
                        {unfinished && <Badge small ml color='red'>Unfinished</Badge>}

                    </div>
                    <p className="card__text">{mainData.supplier || "--"}</p>
                    <p className="card__text">{mainData.total_pallets || "--"}</p>
                    <p className="card__text"><small className="text-muted">{dateFormat(date) || "--"}</small></p>
                </div>
            </Link>

            <button
                className="remove"
                onClick={() => {
                    setActiveDots(true)
                }}>
                <img src='../assets/img/dots-icon.svg' alt="dots-icon" />
            </button>


            <InfoBox
                show={activeDots}
                onClickOutside={() => { setActiveDots(false) }}
            >
                {
                    !unfinished &&
                    <p className='font-normal mb-05'
                        onClick={() => {
                            setCurrentId(id)
                            setOpenSendPDF(true)
                        }}
                    >Send report</p>
                }
                <p className='font-normal'
                    onClick={() => {
                        setCurrentId(id)
                        setOpenConfirmation(true)
                    }}
                >Delete</p>
            </InfoBox>

            <span
                className={`circle-score circle-score${scoreNumber}`}
            ></span>

        </div>
    )
}
