//format  -  weight 10  -  valor

import { valorPallgrow } from "./eliminarEs"

export function percentage( formatGr, weight, value ){
    const total = (Math.round(((((weight/10) * valorPallgrow(value)) / formatGr)*100 ) * 10) / 10)
    // const total = ((((((weig/10)*valorPallgrow(fru))/form)*100) * 10) / 10).toFixed(2)

    if(total === Infinity){
        return "--"
    } else if (total.toString() === "0.00"){
        return `0%`
    } else if (total){
        return `${total}%`
    } else {
        return "0%"
    }
}

export function percentageNum( formatGr=0, weight=0, value=[] ){

    const total = (Math.round(((((weight/10) * valorPallgrow(value)) / formatGr)*100 ) * 10) / 10)
    // const total = ((((((weig/10)*valorPallgrow(fru))/form)*100) * 10) / 10).toFixed(2)

    if(total === Infinity){
        return 0
    } else if (total.toString() === "0.00"){
        return 0
    } else if (total){
        return total
    } else {
        return 0
    }
}

export function percentageNew( formatGr=0, weight=0, value=0, samples=1 ){

    const total = (Math.round(((((weight/10) * (value / samples).toFixed(2)) / formatGr)*100 ) * 10) / 10)
    // const total = ((((((weig/10)*valorPallgrow(fru))/form)*100) * 10) / 10).toFixed(2)

    if(total === Infinity){
        return 0
    } else if (total.toString() === "0.00"){
        return 0
    } else if (total){
        return total
    } else {
        return 0
    }
}
