import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk';

import { authReducer } from './reducers/authReducer'
import { AuthState } from '../interfaces/redux.interface';

export interface RootState {
    auth: AuthState;
}

const reducers = combineReducers<RootState>({
    auth: authReducer
})

const composeEnhancers = (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;


export const store = createStore(
    reducers,
    composeEnhancers
        (applyMiddleware(thunk))
)