import { useMutation, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { NewGrower } from "../interfaces/intakes.reports";

interface EditSplit{
    reportId: string
    palletId: string
    splitPallet: NewGrower | null
}

const editGrower = async ({ grower, prereport=false }:any) => {

    let query
    let revalidate
    if (prereport) {query = '/prereport/edit-grower'; revalidate = 'prereport'}
    else {query = '/report/edit-grower'; revalidate = 'report'}

    const { data } = await qcareApi.put(query, grower)

    return {data, revalidate}
};

const editSplit = async ({ reportId, ...fetchData }:EditSplit) => {
    const { data } = await qcareApi.put(`/report/edit-split/${reportId}`, fetchData)
    return data
};



// ------------------------- HOOKS -------------------------

export const useEditGrower = () => {
    const queryClient = useQueryClient()
    return useMutation(editGrower, {
        onSuccess: async(item) => { await queryClient.invalidateQueries([item.revalidate]) }
    })
}

export const useEditSplit = () => {
    const queryClient = useQueryClient()
    return useMutation(editSplit, {
        onSuccess: async (data) => {
            await queryClient.invalidateQueries(['reportTemp', data.reportId])
            await queryClient.invalidateQueries(['report', data.reportId])
        }
    })
}

