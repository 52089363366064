import { useRef } from 'react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDeleteItem, useEditItem } from '../../api/useEditItem'
// import { useQueryClient } from '@tanstack/react-query'
import { Charging } from './Charging'
import { ModalConfirmation } from './ModalConfirmation'
import { ModalBtn } from '../modals/ModalBtn'

export const ModalEdit = ({ closeModal,
    reportId,
    pid,
    label,
    itemName,
    detailName,
    tipo,
    currentVal,
    prereport = false,
    isReportPrereport = false
}) => {

    // const queryClient = useQueryClient()

    const { mutate, isLoading } = useEditItem()
    const { mutate: mutateDelete, isLoading: isDeleting } = useDeleteItem()
    const [valRef, setValRef] = useState(currentVal)
    const [confirmation, setConfirmation] = useState(false)

    const inputRef = useRef()

    const handleSelect = () => {
        const input = inputRef.current
        input.select()
    }

    const handleChange = (e) => {
        if (tipo === "range") {
            setValRef(e.target.value)
        } else if (tipo === "checkbox") {
            setValRef(e.target.checked)
        } else if (tipo === "text") {
            setValRef(e.target.value)
        } else if (tipo === "number") {
            setValRef(e.target.value)
        } else if (tipo === "select") {
            setValRef(e.target.value)
        }
    }

    const handlePalletType = (e) => {
        if (tipo === "range") {
            setValRef(e.target.value)
        } else if (tipo === "checkbox") {
            setValRef(e.target.checked)
        } else if (tipo === "text") {
            setValRef(e.target.value)
        } else if (tipo === "number") {
            setValRef(e.target.value)
        } else if (tipo === "select") {
            if (e.target.value === "") return
            setValRef(e.target.value)
        }
    }

    const handleChangeArray = (i, e) => {
        let newArr = [...valRef]
        newArr[i] = e.target.value
        setValRef(newArr)
    }

    const sendEditItem = async () => {

        if (tipo === "checkbox" && typeof currentVal !== "boolean") return
        if (currentVal === valRef) {
            closeModal()
            return
        }

        let dataBody = {
            reportId,
            palletId: pid,
            detailName,
            itemName,
            itemValue: valRef
        }

        mutate({ dataBody, prereport, isReportPrereport }, {
            onSuccess: () => closeModal(),
            onError: (err) => { console.log(err); toast.error("Something went wrong") }
        })
    };

    const addSample = () => setValRef(c => [...c, "0"])

    const removeSample = () => {
        if (valRef.length === 1) return
        let newArr = [...valRef]
        newArr.pop()
        setValRef(newArr)
    }

    const deleteItem = async () => {
        let dataBody = {
            reportId,
            palletId: pid,
            detailName,
            itemName,
        }

        mutateDelete({ dataBody, prereport, isReportPrereport }, {
            onSuccess: () => closeModal(),
            onError: (err) => { console.log(err); toast.error("Something went wrong") }
        })
    };

    return (
        <div className="modal">

            <div className="modal__card">

                {
                    isLoading
                        ? <Charging />
                        :
                        <div className="modal__content">

                            {
                                confirmation &&
                                <ModalConfirmation
                                    buttonName='Delete'
                                    msg={`Are you sure you want to delete ${label}?`}
                                    closeConfirmation={() => setConfirmation(false)}
                                    isLoading={isDeleting}
                                    action={() => {
                                        deleteItem()
                                        setConfirmation(false)
                                    }}
                                />
                            }

                            <div className='flex-space-between mb-2'>
                                <label className="modal-label">{label}</label>
                                <button onClick={() => setConfirmation(true)} className='flex-center remove-btn mb-05'>
                                    <img src='../../assets/img/edit-remove.svg' alt='remove-icon' width={18} />
                                </button>
                            </div>

                            {
                                tipo === "checkbox" &&
                                <div className="input-range">
                                    <label className="switch">
                                        <input
                                            type={tipo}
                                            checked={valRef}
                                            name={itemName}
                                            onChange={handleChange}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                    <span className="span-checkbox">
                                        {valRef ? "Yes" : "No"}
                                    </span>

                                </div>
                            }
                            {
                                tipo === "text" &&
                                <input
                                    type={tipo}
                                    ref={inputRef}
                                    value={valRef}
                                    name={itemName}
                                    onChange={handleChange}
                                    onClick={handleSelect}
                                />
                            }
                            {
                                tipo === "range" &&
                                <div className="input-range">
                                    <input
                                        type={tipo}
                                        value={valRef}
                                        min={1}
                                        max={10}
                                        step="1"
                                        name={itemName}
                                        onChange={handleChange}
                                    />
                                    <span className="span-range">{valRef}</span>
                                </div>
                            }
                            {
                                tipo === "number" &&
                                <input
                                    type={tipo}
                                    ref={inputRef}
                                    value={valRef}
                                    min="0"
                                    name={itemName}
                                    onChange={handleChange}
                                    onClick={handleSelect}
                                />
                            }
                            {
                                tipo === "select" &&
                                <div className='pallet-comments'>
                                    <select onChange={handlePalletType} value={valRef}>
                                        <option value="">Select a Pallet type</option>
                                        <option value="Wooden Block pallet">Wooden Block pallet</option>
                                        <option value="Wooden Euro pallet">Wooden Euro pallet</option>
                                        <option value="Wooden Air pallet">Wooden Air pallet</option>
                                        <option value="Plastic Block pallet">Plastic Block pallet</option>
                                        <option value="Plastic Euro pallet">Plastic Euro pallet</option>
                                        <option value="Plastic Air pallet">Plastic Air pallet</option>
                                        <option value="One Way Block pallet">One Way Block pallet</option>
                                        <option value="One Way Euro pallet">One Way Euro pallet</option>
                                        <option value="One Way Air pallet">One Way Air pallet</option>
                                    </select>
                                </div>
                            }
                            {
                                tipo === "arrays" &&
                                <>
                                    <div className='input-columns'>
                                        {
                                            valRef.map((val, i) => (
                                                <div className='input-columns__input mb-05' key={i}>
                                                    <p>{i + 1}.</p>
                                                    <input
                                                        type="number"
                                                        ref={inputRef}
                                                        value={val}
                                                        onChange={(e) => handleChangeArray(i, e)}
                                                        onFocus={(e) => e.target.select()}
                                                    />
                                                </div>
                                            )
                                            )
                                        }
                                    </div>


                                    {
                                        detailName === "pallgrow" &&
                                        <div className='plus-add'>
                                            <button
                                                className="plus-btn mr-1 mt-05"
                                                onClick={removeSample}
                                            >-</button>

                                            <button
                                                className="plus-btn mt-05"
                                                onClick={addSample}
                                            >+</button>
                                        </div>
                                    }
                                </>

                            }

                            <ModalBtn
                                isLoading={isLoading}
                                action={sendEditItem}
                                closeModal={() => closeModal()}
                                actionTitle='Assign'
                            />
                        </div>
                }


            </div>



        </div>
    )
}