import React, { useState } from 'react'
import { ModalEditSupplierContact } from '../modals/ModalEditSupplierContact'
import { Supplier, SupplierContact, SupplierList } from '../../interfaces/interfaces.auth'
import { IoCreateOutline, IoMailOutline, IoPersonOutline } from 'react-icons/io5'

interface Props {
    supplier: Supplier
    target: SupplierList
    contact: SupplierContact
}

export const Contact = ({supplier, target, contact}: Props) => {

    const [modalEditContact, setModalEditContact] = useState<boolean>(false)

    return (
        <>
            {
                modalEditContact &&
                <ModalEditSupplierContact
                    supplier={supplier}
                    contact={contact}
                    closeModal={() => setModalEditContact(false)}
                    target={target}
                />
            }

            <div className="supplier-contact-name" key={contact._id}>
                <p className="font-normal flex flex-gap"><IoPersonOutline /> {contact.name}</p>
                <div className="flex-space-between">
                    <p className="font-normal text-mute flex flex-gap"><IoMailOutline /> {contact.email}</p>
                    <button
                        className="flex"
                        onClick={() => setModalEditContact(true)}
                    ><IoCreateOutline />
                    </button>
                </div>
            </div>
        </>
    )
}
