
interface Props {
    state: boolean
    action: () => void
    isDefect?: boolean
    small?: boolean
}

export const ToggleBtn = ({ state, isDefect, small, action }: Props) => {
    return (
        <label className={`toggle-switch ${small &&"toggle-small"} mt-02`}>
            <input
                className='toggle-input-two'
                type="checkbox"
                checked={state}
                onChange={() => action()}
            />
            <span className={`toggle-slider ${isDefect && "isDefect"} ${small &&"toggle-small"}`}></span>
        </label>
    )
}
