import { AppRoutes } from './routes/AppRoutes'
import { Provider } from 'react-redux'
import { store } from './store/store'
import './styles/styles.scss'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export const ReportApp = () => {

    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <Toaster position="top-center" reverseOrder={false} />
                <AppRoutes />
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}
