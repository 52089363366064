import { useState } from 'react'
import { ModalConfirmation } from './ModalConfirmation'

export const Carousel = ({ closeModal, deleteImage, images, index }) => {

    const [confirmation, setConfirmation] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(index)
    const [isLoading, setIsLoading] = useState(false)

    const handleNext = () => {
        if (currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1)
        } else {
            setCurrentIndex(0)
        }
    }

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1)
        } else {
            setCurrentIndex(images.length - 1)
        }
    }

    const removingImage = async (image) => {
        setIsLoading(true)
        try {
            await deleteImage(image)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
            setConfirmation(false)
            closeModal()
        }

    };

    return (
        <div className="model open">

            {
                confirmation &&
                <ModalConfirmation
                    action={ () => removingImage(images[currentIndex])}
                    closeConfirmation={setConfirmation}
                    msg="Delete this Image?"
                    buttonName='Delete'
                    isLoading={isLoading}
                />
            }

            <button
                onClick={() => setConfirmation(true)}
                className="delete-img mr-1">Delete
            </button>

            {
                images.length > 0 &&
                images.map((image, index) => {
                    if (index === currentIndex) return (<img key={image.key} src={image.imgURL || image.imgURL_low} alt="stageImg" />)
                    else return null
                })
            }

            <button>
                <img
                    className="svg-icon"
                    src="/assets/img/close-icon.svg"
                    alt="close-icon"
                    width={10}
                    onClick={() => {
                        closeModal()
                    }}
                />
            </button>

            <div className="next-prev">
                <img src="/assets/img/image-prev.svg" alt="imageprev" width={10} onClick={() => handlePrev()} />
                <img src="/assets/img/image-next.svg" alt="imagenext" width={10} onClick={() => handleNext()} />
            </div>

            <div className="index-float">
                <p className='font-normal'>{currentIndex + 1} / {images.length}</p>
            </div>

        </div>
    )
}
