import { useMutation, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { InitialReport } from "../interfaces/intakes.reports";
import { InitialRepack } from "../interfaces/intakes.repack";


const fetchInitialReport = async ( initialReport:InitialReport ) => {
    const { data } = await qcareApi.post('report/initial-report', initialReport )
    return data
};

const fetchInitialRepack = async ( initialRepack:InitialRepack ) => {
    const { data } = await qcareApi.post('repack/initial-repack', initialRepack )
    return data
};

// ------------------------------------------------------------

export const useFetchInitialReport = () => {

    const queryClient = useQueryClient()
    return useMutation(fetchInitialReport, {
        onSuccess: async () => { 
            await queryClient.invalidateQueries(['reports'])
        }
    })
}

export const useFetchInitialRepack = () => {

    const queryClient = useQueryClient()
    return useMutation(fetchInitialRepack, {
        onSuccess: async () => { 
            await queryClient.invalidateQueries(['repacks'])
        }
    })
}
