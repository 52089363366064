import { useState } from "react";
import { ModalAddMainData } from "../../ui/ModalAddMainData";

export const MainDataAdd = ({ mainData, setMainData, fast=false }) => {

    const [openModal, setOpenModal] = useState(false)

    const handleAddItem = ( newMainData ) => {
        setMainData(newMainData)
    };

    return (
        <>
            {
                openModal &&
                <ModalAddMainData
                    mainData={mainData}
                    closeModal={() => setOpenModal(false)}
                    handleAddItem={handleAddItem}
                    fast={fast}
                />
            }

            <div className="grid intake__input">
                <div className="span-4" />

                <div
                    onClick={() => setOpenModal(true)}
                    className="span-4 btn-mainData cursor-pointer">
                    <p className='text-center text-blue'>Add Items</p>
                </div>

            </div>
        </>
    )
}
