export type Create = "intake" | "report" | "repack"
export type ModelSupplier = "user" | "team"


export type Fruit =
| "blueberries" 
| "raspberries" 
| "blackberries" 
| "strawberries" 
| "pears" 
| "figs" 
| "kiwiberries" 
| "apples" 
| "cherries" 
| "red currants" 
| "other"

export type Grade =
| "Select a Grade"
| "Industry"
| "Borderline CAT 2"
| "CAT 2"
| "Borderline CAT 1"
| "CAT 1"
| "Extra"

export type Action =
| "Select an Action"
| "Dump"
| "Rejected"
| "Hold"
| "Priority for Departure"
| "Suitable for QC check/REPACK"
| "Suitable for Departure"
| "Suitable for Storage"

export type Status =
| "grade"
| "action"
| "score"

export type QueryType =
| "fruit"
| "score"
| "grower"
| "status"
| "supplier"
| "palletRef"
| "deliveryNote"
| "startDate"
| "endDate"


export type DetailNameOld = "labels" | "appareance" | "appearance" | "pallgrow"
export type DetailName = "labels"  |"appearance" | "pallgrow"
export type Defects = "minor" | "major"

export type GradeNum = "0" | "1" | "2" | "3" | "4" | "5" | "6"
export type ActionNum = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7"
export type ScoreNum = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8"

export enum ScoreBG{
    score0 = "#C2C2C2",
    score1 = "#b20016",
    score2 = "#ed1c24",
    score3 = "#ef7100",
    score4 = "#f9b233",
    score5 = "#fcee21",
    score6 = "#b4dd22",
    score7 = "#39b54a",
    score8 = "#007f2d",
}

export enum ScoreColor{
    score0 = "#353644",
    score1 = "white",
    score2 = "white",
    score3 = "white",
    score4 = "#353644",
    score5 = "#353644",
    score6 = "white",
    score7 = "white",
    score8 = "white",
}

export enum GradeResult{
    grade0 = "Commercial CAT",
    grade1 = "Industry",
    grade2 = "Borderline CAT 2",
    grade3 = "CAT 2",
    grade4 = "Borderline CAT 1",
    grade5 = "CAT 1",
    grade6 = "Extra",

}

export interface ComboboxOption {
    value: string
    label: string
}
//----------------------------------

