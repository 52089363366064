import { SpinnerCustom } from "../ui/SpinnerCustom"

interface Props {
    closeModal: () => void
    action?: () => void
    closeTitle?: string
    actionTitle?: string
    disabled?: boolean
    isLoading?: boolean
    isConfirmation?: boolean
}

export const ModalBtn = ({ closeModal, action, disabled = false, closeTitle, actionTitle, isConfirmation, isLoading }: Props) => {
    return (
        <div className="modal__botones mt-2">
            <button
                className={`modal-btn ${isConfirmation ? "btn-muted" : "btn-close"}`}
                onClick={closeModal}
                disabled={isLoading}
            >
                {closeTitle ? closeTitle : "Close"}
            </button>
            {
                action &&
                <button
                    disabled={disabled || isLoading}
                    className={`modal-btn ${isConfirmation ? "btn-close" : disabled ? 'btn-add btn-disabled' : 'btn-add'} } flex-center`}
                    onClick={() => action()}
                >
                    <p>{actionTitle ? actionTitle : "Save"}</p>
                    {isLoading && <SpinnerCustom center size="xs" color={isConfirmation ? "red" : "white"} className="ml-05" />}
                </button>
            }
        </div>
    )
}
