import { useState } from 'react'
import { Charging } from '../ui/Charging'
import { ModalConfirmation } from '../ui/ModalConfirmation';
import { Pagination } from '../ui/Pagination';
import { SelectTeam } from '../ui/SelectTeam';
import { optionValue, selectValue } from '../../helpers/eliminarEs';
import { CardRepack } from '../CardRepack';
import { useRepacks } from '../../api/useRepacks';

export const Repacks = () => {

    const [page, setPage] = useState(1)
    const [toggle, setToggle] = useState<string | undefined>(undefined)

    const { data, isLoading } = useRepacks(page, toggle)

    const handleToggle = (e:React.ChangeEvent<HTMLSelectElement>) => {
        setToggle(selectValue(e.target.value))
        setPage(1)
    };

    /* ------------------------- FILTER ------------------------- */

    return (
        <div className="reports content">

            {
                isLoading
                    ? <Charging />
                    :
                    <main className="container">

                        <div className="reports__title mb-2">
                            <h2 className="main-title">All Repacks</h2>
                        </div>

                        <SelectTeam
                            toggle={toggle}
                            handleToggle={handleToggle}
                            title='All Repacks'
                        />

                        

                        {
                            data &&
                                data.repacks.length === 0
                                ? <p className="text-center">No repacks</p>
                                :
                                <div>
                                    <div className="reports__list">
                                        {
                                            data?.repacks?.map(repack => (
                                                <CardRepack
                                                    key={repack._id}
                                                    repack={repack}
                                                />
                                            ))
                                        }
                                    </div>

                                    {
                                        data?.totalPages && data.totalPages > 1 &&
                                        <Pagination
                                            totalPages={data.totalPages}
                                            page={page}
                                            setPage={setPage}
                                        />
                                    }
                                </div>
                        }
                    </main>
            }


        </div>
    );
}
