import { Fruit, ScoreNum } from "../interfaces/interfaces"

export const fruitList:Fruit[] = [
    "blueberries",
    "raspberries",
    "strawberries",
    "blackberries",
    "cherries",
    "kiwiberries",
    "pears",
    "figs",
    "apples",
    "red currants",
    "other",
]

export const scoreList:ScoreNum[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
]

