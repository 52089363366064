import { useEffect, useState } from 'react'

import { ModalConfirmation } from './ModalConfirmation';
import { Charging } from './Charging';


export const ModalPhoto = ({ photo, openModal, action }) => {

    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [previewImage, setPreviewImage] = useState(null)

    useEffect(() => {
        setPreviewImage({ url: URL.createObjectURL(photo) })
        return () => setPreviewImage(null)
    }, [photo])

    return (
        <div className="model open">

            {
                modalConfirmation &&
                <ModalConfirmation
                    action={action}
                    closeConfirmation={setModalConfirmation}
                    msg="Delete this Image?"
                    buttonName='Delete'
                />
            }

            <button
                onClick={() => setModalConfirmation(true)}
                className="delete-img mr-1">Delete
            </button>

            {
                previewImage
                ? <img src={previewImage.url} alt="stageImg" />
                : <Charging />
            }

            


            <button>
                <img
                    className="svg-icon"
                    src="/assets/img/close-icon.svg"
                    alt="close-icon"
                    onClick={() => openModal(false)}
                />
            </button>


        </div>
    )

}
