import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

import {isEmail} from '../../helpers/isEmail'
import qcareApi from '../../api/qcareApi'
import useForm from '../../hooks/useForm'

export const ModalContact = ({ setOpenUserModal, update }) => {

    const { values, handleInputChange } = useForm({
        supplier: "",
        ref: "",
        contactName: "",
        email: ""
    })
    const { supplier, ref, contactName, email } = values

    const closeModal = () => {
        setOpenUserModal(false)
    }

    const sendEditItem = async () => {

        if(supplier.length === 0 || ref.length === 0 || contactName.length === 0) return toast.error('All fields are required')
        if(!isEmail(email)) return toast.error('Is not a valid Email')
        if(ref.length > 6) return toast.error(`Ref can't be more than 6 characters`)

        try {

            await qcareApi.post('/auth/add-contact', { id: uuidv4(), supplier, ref: ref.toUpperCase(), contactName, email })
            toast.success(`New contact added`)

        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        } finally {
            setOpenUserModal(false)
            update()
        }
    };


    return (
        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">
                    <label className="modal-label mb-2">New Contact</label>

                    <div className='mb-1'>
                        <p className='text-left mb-02'>Supplier</p>
                        <input
                            className="input100"
                            type='text'
                            value={supplier}
                            name="supplier"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='mb-1'>
                        <p className='text-left mb-02'>Ref</p>
                        <input
                            className="input100"
                            type='text'
                            value={ref}
                            name="ref"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='mb-1'>
                        <p className='text-left mb-02'>Contact name</p>
                        <input
                            className="input100"
                            type='text'
                            value={contactName}
                            name="contactName"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='mb-1'>
                        <p className='text-left mb-02'>Email</p>
                        <input
                            className="input100"
                            type='text'
                            value={email}
                            name="email"
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="modal__botones mt-2">
                        <button
                            className="modal-btn btn-close mr-05"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                        <button
                            className="modal-btn btn-add ml-05"
                            onClick={sendEditItem}
                        >
                            Add Contact
                        </button>
                    </div>
                </div>
            </div>



        </div>
    )
}