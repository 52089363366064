import { useState } from "react"
import { tempFile } from "../helpers/photoToUpload"
import { ImageTemp } from "../interfaces/intakes.reports"


export const useTempImages = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [tempImages, setAllImages] = useState<ImageTemp[]>([])

    const removeImage = (id:string):ImageTemp[] => {
        if(!id) return []
        setAllImages( images => images.filter( img => img.id !== id))
        return tempImages
    }

    const addImages = async (files: FileList):Promise<ImageTemp[]> => {

        const arrayImages = [];
        for (let i = 0; i < files.length; i++) {
            arrayImages.push(files.item(i));
        }

        try {
            setIsLoading(true)
            const eTargetFiles = arrayImages.map(file => tempFile(file as File));

            let allImages = await Promise.all(eTargetFiles)
            setAllImages(allImages)
            setIsLoading(false)
            return allImages
        } catch (error) {
            console.log(error)
            setAllImages([])
            setIsLoading(false)
            return []
        } finally { setIsLoading(false) }
    };

    return {
        tempImages,
        isLoading,
        addImages,
        removeImage
    }
}
