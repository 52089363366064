import { useState } from "react"
import { ModalFiles } from "./ModalFiles"

interface Props {
    pid: string
    reportId: string
}

export const AddPictures = ({ pid, reportId }: Props) => {

    const [openModal, setOpenModal] = useState(false)

    return (
        <>
            {
                openModal &&
                <ModalFiles
                    closeModal={() => setOpenModal(false)}
                    pid={pid}
                    reportId={reportId}
                />
            }
            <div>
                <button
                    onClick={() => setOpenModal(true)}
                    className="add-file">
                    <img src="/assets/img/upload-life.svg" alt="img-icon" />

                    <span>Add Pictures</span>
                </button>
            </div>
        </>
    )
}
