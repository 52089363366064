import { Navigate } from "react-router-dom";
import { Navbar } from "../components/ui/Navbar";
import { Bar } from "../components/ui/Bar";

interface Props{
    children: React.ReactNode
    isAuthenticated: boolean
}

export const PrivateRoutes = ({ children, isAuthenticated }:Props) => {

    return (isAuthenticated)
        ?
        <>
            <Navbar />
            <Bar />
            {children}
        </>
        : <Navigate to='/login' />

}