import { Dispatch, SetStateAction } from "react"
import { Fruit } from "../../interfaces/interfaces"

interface Props {
    fruit: Fruit
    setFruit: Dispatch<SetStateAction<Fruit>>
    // setFruit: (fruit:Fruit|string) => void
}

interface Options {
    value: Fruit
    label: string
}


export const SelectFruit = ({ fruit, setFruit }: Props) => {

    const handleFruit = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFruit(e.target.value as Fruit)
    }

    const options: Options[] = [
        { value: "blueberries", label: "Blueberries" },
        { value: "raspberries", label: "Raspberries" },
        { value: "strawberries", label: "Strawberries" },
        { value: "blackberries", label: "Blackberries" },
        { value: "kiwiberries", label: "Kiwiberries" },
        { value: "pears", label: "Pears" },
        { value: "apples", label: "Apples" },
        { value: "cherries", label: "Cherries" },
        { value: "figs", label: "Figs" },
        { value: "red currants", label: "Red Currants" },
        { value: "other", label: "Other" },
    ]

    return (
        <div className="grid intake__input">
            <label className="span-5 ">Fruit to report</label>

            <div className="span-7 select-input">
                <select onChange={handleFruit} value={fruit}>
                    {
                        options.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option> 
                        ))
                    }
                </select>
            </div>
        </div>
    )
}
