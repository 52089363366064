import { useState } from 'react'
import { ModalInCharge } from '../ui/ModalInCharge'
import { ModalConfirmation } from '../ui/ModalConfirmation'
import { jsonKeyToString } from '../../helpers/jsonToString'
import { v4 as uuidv4 } from 'uuid';
import { MainData } from '../pages/Inputs/MainData';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { IntakeCSV } from '../../interfaces/intakes.reports';

interface Props{
    index: number
    intake: IntakeCSV
    intakes: IntakeCSV[]
    hasTeams: boolean
    setIntakes: React.Dispatch<React.SetStateAction<IntakeCSV[]>>
}

export const NewIntakeCard = ({ index, intake, intakes, hasTeams, setIntakes }:Props) => {

    const { teamsAdmin, teamsUser, teams } = useTypedSelector(state => state.auth);

    const [modalInCharge, setModalInCharge] = useState(false)
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const members = [...teamsAdmin, ...teamsUser].map(team => team.members).flat()

    const addInCharge = (team: string, user: string) => {
        const newAssign = intakes.map(item => {
            if (item.id === intake.id) {
                return {
                    ...item,
                    team: (+team === 0) ? null : team,
                    inCharge: (+user === 0) ? null : user
                }
            }
            else return item
        })
        setIntakes(newAssign)
        setModalInCharge(false)
    };

    const cleanInCharge = (cardId:string) => {
        const deletedInCharge = intakes.map(intake => {
            if (intake.id === cardId) {
                return {
                    ...intake,
                    team: null,
                    inCharge: null
                }
            }
            else return intake
        })
        setIntakes(deletedInCharge)
    };

    const deleteIntake = (intakeId:string) => {
        setIntakes(prev => prev.filter(intake => intake.id !== intakeId))
    };

    return (
        <div className="intake__card">

            {
                modalInCharge &&
                <ModalInCharge setModal={setModalInCharge} addInCharge={addInCharge} intake={intake} />
            }

            {
                modalConfirmation &&
                <ModalConfirmation
                    action={() => deleteIntake(intake.id)}
                    msg="Are you sure you want to delete this intake?"
                    closeConfirmation={setModalConfirmation}
                />
            }

            <div className='flex mb-2'>
                <h3 className="mr-05">Intake</h3> <span className='index-number'>{index + 1}</span>
            </div>
            <button
                className="remove-pallet badge-remove"
                onClick={() => setModalConfirmation(true)}
            >remove</button>

            { intake.data && <MainData mainData={intake.data} allDisabled/>  }

            {
                hasTeams && !intake.team
                    ?
                    <div className='mt-2 mb-05'>
                        <button
                            className='btn-copy blue flex mx-auto'
                            onClick={() => setModalInCharge(true)}
                        >
                            <img src="/assets/img/user-plus.svg" alt="pdf-data" width={14} className="mr-1" />
                            <p>Assign to a team</p>
                        </button>
                    </div>

                    :
                    intake.team &&

                    <div className='flex-center mt-2 mb-05'>
                        <button
                            onClick={() => setModalInCharge(true)}
                            className='btn-user-selected flex mr-05'
                        >
                            <p>{teams.find(team => team._id === intake.team)?.name || ""}</p>
                        </button>

                        {
                            intake.inCharge &&

                            <button
                                onClick={() => setModalInCharge(true)}
                                className='btn-user-selected blue flex mr-05'
                            >
                                <p>{members?.find(member => member.uid === intake.inCharge)?.email || ""}</p>
                            </button>
                        }

                        <button
                            className='btn-x'
                            onClick={() => cleanInCharge(intake.id)}
                        >
                            <img src="/assets/img/close-icon.svg" alt="pdf-data" width={11} />
                        </button>

                    </div>


            }
        </div>
    )
}
