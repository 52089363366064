import { IoDuplicateOutline } from 'react-icons/io5'
import { typeData } from '../../data/type-data'
import { DetailObject, InitialPallets } from '../../interfaces/intakes.reports'
import { DetailName } from '../../interfaces/interfaces'
import { ModalPressure } from '../ui/ModalPressure';
import { useState } from 'react'
import { kilosToLb } from '../../helpers/eliminarEs';
import { validateDecimals } from '../../helpers/validators';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  palletId: string
  setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
  detailName: DetailName
  input: DetailObject
  noCheck?: boolean
  afterInput?: React.ReactNode
  handleCheck: () => void
  handleInput: (value: string | number | boolean, name: string) => void
}

export const Inputs = ({ noCheck = false, palletId, detailName, input, setPallets, handleCheck, handleInput, afterInput }: Props) => {

  const dataType = typeData(input.name)
  const labelUp = ["fruit_pressure", "brix", "weight_check"]
  const [modalPressure, setModalPressure] = useState(false)
  const inputId = uuidv4()+input.name

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleInput((e.target.type === "checkbox" && e.target instanceof HTMLInputElement) ? e.target.checked : e.target.value, input.name);
  };

  const handleChangeArray = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {

    const { sanitizedValue, isValid } = validateDecimals(e.target.value);

    if (isValid) {
      setPallets(pallets => pallets.map(pallet => {
        if (pallet.pid === palletId) {
          return {
            ...pallet,
            [detailName]: {
              ...pallet[detailName],
              data: pallet[detailName]?.data.map(inp => {
                if (inp.name === input.name) {
                  return {
                    ...inp,
                    value: (inp.value as string[]).map((val, i) => { return i === index ? sanitizedValue : val })
                  }
                } return inp
              })
            }
          }
        }
        return pallet
      }))
    }
  };


  return (
    <div className="input-pallet">

      {
        modalPressure &&
        <ModalPressure
          palletId={palletId}
          detailName={detailName}
          closeModal={() => setModalPressure(false)}
          item={input}
          handleChangeArray={handleChangeArray}
          setPallets={setPallets}
        />
      }

      <div className={`${labelUp.includes(input.name) ? "label-name-up" : "label-name"} flex-space-between`}>
        <div>
          {
            !noCheck &&
            <input
              id={inputId}
              className='check-on-off'
              type="checkbox"
              checked={input.check}
              onChange={handleCheck}
            />
          }
          <label htmlFor={inputId}>{input.label}</label>
        </div>
        {
          input.name === "fruit_pressure" &&
          <button onClick={() => setModalPressure(true)}>
            <IoDuplicateOutline size={18} color="#1A73E8" />
          </button>
        }
      </div>


      <div className="input-type flex">
        {
          (dataType === "checkbox") &&
          <div className="input-range">
            <label className="switch">
              <input
                type="checkbox"
                checked={input.value as boolean}
                name={input.name}
                onChange={handleChange}
              />
              <span className="slider"></span>
            </label>
            <span className="span-checkbox">
              {input.value ? "Yes" : "No"}
            </span>

          </div>
        }

        {
          (dataType === "text") &&
          <input
            type="text"
            value={input.value as string}
            name={input.name}
            onChange={handleChange}
            maxLength={20}
          />
        }


        {
          (dataType === "select") &&
          <div className="score-wraper column flex-1">
            <select onChange={handleChange} value={input.value as string} className="full" name='pallet-select'>
              <option value="">Select a Pallet type</option>
              <option value="Wooden Block pallet">Wooden Block pallet</option>
              <option value="Wooden Euro pallet">Wooden Euro pallet</option>
              <option value="Wooden Air pallet">Wooden Air pallet</option>
              <option value="Plastic Block pallet">Plastic Block pallet</option>
              <option value="Plastic Euro pallet">Plastic Euro pallet</option>
              <option value="Plastic Air pallet">Plastic Air pallet</option>
              <option value="One Way Block pallet">One Way Block pallet</option>
              <option value="One Way Euro pallet">One Way Euro pallet</option>
              <option value="One Way Air pallet">One Way Air pallet</option>
              <option value="Other">Other (specify)</option>
            </select>

          </div>
        }

        {
          (dataType === "range") &&
          <div className="input-range py-02">
            <input
              type="range"
              value={input.value as number}
              min={input.minVal || 1}
              max={input.maxVal || 10}
              step="1"
              name={input.name}
              onChange={handleChange}
            />
            <span className="span-range">{input.value}</span>
          </div>
        }

        {
          (dataType === "number") &&
          <input
            type="number"
            value={input.value as number}
            name={input.name}
            onChange={handleChange}
            maxLength={20}
          />
        }

        {
          (dataType === "arrays") &&
          <div className="grid--space flex-1">
            {
              (input.value as string[]).map((oneVal, index) => (
                <input
                  key={index}
                  name={ input.name+"-"+index }
                  className={`${detailName === "pallgrow" ? "span-horizontal mr-05" : "span-6"}`}
                  type="text"
                  value={oneVal}
                  onChange={(e) => handleChangeArray(e, index)}
                  // onFocus={(e) => e.target.select()}
                  maxLength={20}
                />
              ))
            }
          </div>
        }

        {
          input.name === "fruit_pressure" &&
          <div className='flex-1'>
            {
              (input.value as string[]).map((oneVal, index) => (
                <div key={index} className="flex flex-gap flex-1 mb-02">
                  <div className={`flex flex-1`} >
                    <input
                      name={ input.name+"-"+index }
                      type="number"
                      min="0"
                      value={oneVal}
                      onChange={(e) => handleChangeArray(e, index)}
                      maxLength={20}
                    />
                    <p className='bold font-small ml-02'>kg.</p>
                  </div>
                  <div className={`flex flex-1`} >
                    <input
                      name={ input.name+"-"+index }
                      disabled
                      type="number"
                      min="0"
                      value={oneVal.length === 0 ? "" : kilosToLb(oneVal)}
                    />
                    <p className='bold font-small ml-02'>lb.</p>
                  </div>
                </div>
              ))
            }
          </div>
        }

        {/* {
          input.name === "fruit_pressure" &&
          <div className="grid--space flex-1">
            {
              (input.value as string[]).map((oneVal, index) => (
                <input
                  key={index}
                  className={`${detailName === "pallgrow" ? "span-horizontal mr-05" : "span-6"}`}
                  type="number"
                  min="0"
                  value={oneVal}
                  onChange={(e) => handleChangeArray(e, index)}
                  onFocus={(e) => e.target.select()}
                  maxLength={20}
                />
              ))
            }
          </div>
        } */}


        {afterInput && afterInput}

      </div>
    </div>
  )
}


// _id: "123456"
// pallets: [
//   {
//     pid: 1,
//     labels: {
//       data: [
//         { check: true, label: "Label 1", name: "label1", value: "value1", },
//         { check: false, label: "Label 2", name: "label2", value: "value2", },
//         { check: false, label: "Label 4", name: "label4", value: true, },
//         { check: true, label: "Label 3", name: "label3", minVal: 1, maxVal: 10, value: 4, },
//       ],
//       images: []
//     },
//   },
//   {
//     pid: 2,
//     labels: {
//       data: [
//         { check: true, label: "Label 1", name: "label1", minVal: 1, maxVal: 10, value: 1, },
//         { check: true, label: "Label 2", name: "label2", value: "value2", },
//         { check: false, label: "Label 3", name: "label3", value: "value3", },
//         { check: false, label: "Label 6", name: "label6", value: false, },
//       ],
//       images: []
//     },
//   }
// ]

