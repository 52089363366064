import { v4 as uuidv4 } from 'uuid';
import toast from "react-hot-toast";
import { ImageTemp } from "../interfaces/intakes.reports";
import { IoCameraOutline } from "react-icons/io5";
import { useTempImages } from '../hooks/useTempImages';
import { SpinnerCustom } from './ui/SpinnerCustom';

interface Props {
    addTempImages: (files: ImageTemp[]) => void
    limit: number
}

export const AddSectionImages = ({ addTempImages, limit = 10 }: Props) => {

    const { addImages, isLoading } = useTempImages()
    const inputId = uuidv4()

    const addTempFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;

        if (files && files.length > 0) {
            if ( files.length > limit) {
                return toast.error(limit === 0 ? `You upload all the permitted images` : `You have ${limit} images left to upload`)
            }
            const data = await addImages(files)
            addTempImages(data)
        }
    };

    return (
        <>
            <label htmlFor={`img-button-${inputId}`} className="btn-input green">
                <IoCameraOutline fontSize={20} />
                <span className="ml-05">Add Images</span>
                { isLoading && <SpinnerCustom color='white' className='ml-05'/> }
            </label>
            <input
                id={`img-button-${inputId}`}
                // id="img-button"
                className="upload-btn"
                accept=".jpg, .png, .jpeg"
                multiple
                name="file"
                type="file"
                onChange={addTempFiles}
            />
        </>
    )
}


