import { useState } from "react"
import { useEditComments } from "../../api/useEditItem"
import { Modal } from "../modals/Modal"

interface Props {
    reportId: string
    comments: string
}
export const Comments = ({ comments, reportId }: Props) => {

    const [openEditComment, setOpenEditComment] = useState(false)
    const [newComments, setNewComments] = useState<string>(comments)
    const { mutateAsync, isLoading } = useEditComments()

    return (

        <>
            {
                openEditComment &&
                <Modal
                    isLoading={isLoading}
                    buttonName="Save"
                    action={ async() => {
                        await mutateAsync({ reportId, comments: newComments })
                        setOpenEditComment(false)
                    }}
                    closeModal={() => setOpenEditComment(false)}>

                    <form className="pallet-comments">
                        <p className='mb-05 bold'>Comments</p>
                        <textarea name="comments" id="comments" cols={30} rows={5} defaultValue={comments} onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => setNewComments(e.target.value)}/>
                    </form>

                </Modal>
            }

            <div className="my-2">
                <div className="flex mb-1">
                    <h3>General Comments</h3>
                    <button className="ml-05 mt-02" onClick={() => setOpenEditComment(true)}>
                        <img src="../../assets/img/edit.svg" width={18} alt="edit-icon"/>
                    </button>
                </div>
                <p className='comments-report'>{comments}</p>
            </div>
        </>
    )
}
