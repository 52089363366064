import { v4 as uuidv4 } from 'uuid';
import { Fruit } from '../interfaces/interfaces';
import { DetailObject } from '../interfaces/intakes.reports';
import { InitialRepackPallets } from '../interfaces/intakes.repack';


export const palletRepack = (fruit: Fruit, palletNumber: number): InitialRepackPallets => {
    return {
        pallet: palletNumber,
        complete: null,
        pid: uuidv4(),
        // grade: "0",
        images: [],
        splitPallet: null,
        labels: { data: addLabels(), images: [] },
        appearance: { data: addAppearance(fruit), images: [] },
    }
}

export const addLabels = (): DetailObject[] => [
    { check: true, label: "Box Label", name: "box_label", value: true },
    { check: true, label: "Pallet Type", name: "pallet_type", value: "" },
    { check: true, label: "Punnet Label", name: "punnet_label", value: true },

    { check: true, label: "Name & Weight", name: "name_weight", value: true },
    { check: true, label: "L Code", name: "l_code", value: "" },
    { check: true, label: "T Code", name: "t_code", value: "" },
    { check: true, label: "EAN Code", name: "ean_code", value: "" },
    { check: true, label: "Variety", name: "variety", value: "" },
]

export const addAppearance = (fruit: Fruit): DetailObject[] => [
    { check: true, label: "Pallet Integrity", name: "pallet_integrity", minVal: 1, maxVal: 10, value: 1 },
    { check: true, label: "Temperature C", name: "temperature_c", value: "" },

    { check: true, label: "Insect", name: "insect", value: true },
    { check: true, label: "Fresh", name: "fresh", minVal: 1, maxVal: 10, value: 1 },
    { check: true, label: "Size", name: "size", minVal: 1, maxVal: 10, value: 1 },
    { check: true, label: "Coloration", name: "coloration", minVal: 1, maxVal: 10, value: 1 },
    { check: true, label: "Taste", name: "taste", minVal: 1, maxVal: 10, value: 1 },
    { check: true, label: "Pollution", name: "pollution", value: true },
    { check: true, label: "Humidity", name: "humidity", value: true },
    { check: true, label: "Juicing", name: "juicing", value: true },
    { check: true, label: "Wet Punnets", name: "wet_punnets", value: true },

    { check: true, label: "Brix", name: "brix", value: ["", ""] },
    { check: true, label: "Weight Check", name: "weight_check", value: ["", "", "", ""] },
]
