import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import useMediaQuery from '../../hooks/useMediaQuery';
import { colorsArray } from '../../helpers/colorsArray';
import { DetailObject } from '../../interfaces/intakes.reports';

interface Props{
    pallgrow: DetailObject[]
}

ChartJS.register(ArcElement, Tooltip, Legend);

export const ChartPieNew = ({ pallgrow = [] }:Props) => {

    const matches = useMediaQuery("(min-width: 768px)");

    let labels = []
    let dataFinal = []

    for (let item of pallgrow) {
        labels.push(item.label)
        dataFinal.push(item.value)
    }

    let colors = colorsArray.slice(0, (pallgrow.length)) || ["#d6534c"]


    const chartdata = {
        labels: labels || "No Data",
        datasets: [
            {
                label: "Markets Monitored",
                data: dataFinal || 0,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
        ],
    };

    return (
        <div>
            <Doughnut
                options={{
                    plugins: {
                        legend: {
                            position: matches ? 'left' : 'top',
                        },
                       
                    },
                }}
                data={chartdata}
            />
        </div>
    )
}
