import { numbersInputs, timeInputs } from "../../../data/mainData"

interface Props {
    item: string[]
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
}

const MainInput = ({ item, handleChange, disabled=false }: Props) => {

    return (
        <>
            {
                numbersInputs.includes(item[0])
                    ?

                    <input
                        className="span-7 d-input"
                        type="number"
                        name={item[0]}
                        value={item[1]}
                        onChange={(e) => handleChange(e)}
                        onFocus={(e) => e.target.select()}
                        min={0}
                        disabled={disabled}
                    />

                    :
                    timeInputs.includes(item[0])
                        ?
                        <input
                            className="span-7 d-input"
                            type="time"
                            name={item[0]}
                            value={item[1]}
                            onChange={(e) => handleChange(e)}
                            onFocus={(e) => e.target.select()}
                            disabled={disabled}
                        />

                        : <input
                            className="span-7 d-input"
                            type="text"
                            name={item[0]}
                            value={item[1]}
                            onChange={(e) => handleChange(e)}
                            onFocus={(e) => e.target.select()}
                            maxLength={20}
                            disabled={disabled}
                        />
            }
        </>
    )
}

export default MainInput