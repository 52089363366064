import React from "react"

interface Props{
    text: string
    action: () => void
    icon?: React.ReactNode
    color?: "blue" | "green"
    small?: boolean
}

export const ButtonInput = ({action, text, icon, color="blue", small=false}:Props) => {
  return (
    <button
    className={`btn-input ${color} ${small && "btn-small"}`}
        onClick={action}
    >
        {icon && <> {icon} <span className="mr-02"/> </> } 
        {text}
    </button>
  )
}
