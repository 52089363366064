import { useState } from 'react'
import toast from 'react-hot-toast'
import { useEditGrower } from '../../api/useEditGrower'


export const ModalEditGrower = ({ closeModal,
    reportId,
    pid,
    grower_variety,
    boxes,
    prereport = false,
}) => {

    const [valGrower, setValGrower] = useState(grower_variety || "")
    const [valBoxes, setValBoxes] = useState(boxes || "")

    const { mutateAsync, isLoading } = useEditGrower()

    const sendEditItem = async () => {

        if (grower_variety === valGrower && boxes === valBoxes) return closeModal()

        const grower = {
            reportId,
            palletId: pid,
            grower: valGrower,
            boxes: valBoxes,
        }

        await mutateAsync({ grower , prereport }, {
            onSuccess : () => {toast.success('Edited successfully'); closeModal() },
            onError: () => toast.error('Something went wrong')
        })
};


return (
    <div className="modal">

        <div className="modal__card">
            <div className="modal__content">
                <label className="mb-02 bold">Grower/Variety</label>

                <input
                    type="text"
                    value={valGrower}
                    onChange={(e) => setValGrower(e.target.value)}
                />

                <label className="mb-02 bold mt-2">Boxes</label>

                <input
                    type="text"
                    value={valBoxes}
                    onChange={(e) => setValBoxes(e.target.value)}
                />

                <div className="modal__botones mt-3">
                    <button
                        disabled={isLoading}
                        className="modal-btn btn-close mr-05"
                        onClick={() => closeModal()}
                    >
                        Close
                    </button>
                    <button
                        disabled={isLoading}
                        className="modal-btn btn-add ml-05"
                        onClick={sendEditItem}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>



    </div>
)
}