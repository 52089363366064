import { useState } from 'react'
import { PhotoAdd } from '../../PhotoAdd'

// export const Range = ({ id, label, name, minVal,maxVal, value}) => {
export const LabelsInput = ({ id, label, tipe, name, minVal, maxVal, valor, check, photo, pallet, setPallets }) => {

    const [valRef, setValRef] = useState(valor)
    const [checkRef, setCheckRef] = useState(check)

    const [palletType, setPalletType] = useState("")

    const handleChange = (e) => {
        if (tipe === "range" || tipe === "number" || tipe === "text") {
            setValRef(e.target.value)
            pallet.labels[id].valor = e.target.value
        } else if (tipe === "checkbox") {
            setValRef(e.target.checked)
            pallet.labels[id].valor = !valRef
        }
    }


    const handleSelect = (e) => {
        setValRef(e.target.value)
        pallet.labels[id].valor = e.target.value
        if (e.target.value !== "Other") {
            setPalletType("")
        }
    }

    const handleText = (e) => {
        setValRef(valRef)
        setPalletType(e.target.value)
        pallet.labels[id].valor = e.target.value
    }

    const handleCheck = (e) => {
        setCheckRef(e.target.checked)
        pallet.labels[id].check = e.target.checked
    }


    return (
        <div className="input-pallet">

            <div className="label-name">
                <input
                    className='check-on-off'
                    type="checkbox"
                    checked={checkRef}
                    onChange={handleCheck}
                />
                <label>{label}</label>
            </div>


            <div className="input-type flex">
                {
                    tipe === "checkbox" &&
                    <div className="input-range">
                        <label className="switch">
                            <input
                                type={tipe}
                                checked={valRef}
                                name={name}
                                onChange={handleChange}
                            />
                            <span className="slider"></span>
                        </label>
                        <span className="span-checkbox">
                            {valRef ? "Yes" : "No"}
                        </span>

                    </div>
                }
                {
                    tipe === "text" &&
                    <input
                        type={tipe}
                        value={valRef}
                        name={name}
                        onChange={handleChange}
                    />
                }
                {
                    tipe === "select" &&

                    <div className="score-wraper column flex-1">
                        <select onChange={handleSelect} value={valRef} className="full">
                            <option value="">Select a Pallet type</option>
                            <option value="Wooden Block pallet">Wooden Block pallet</option>
                            <option value="Wooden Euro pallet">Wooden Euro pallet</option>
                            <option value="Wooden Air pallet">Wooden Air pallet</option>
                            <option value="Plastic Block pallet">Plastic Block pallet</option>
                            <option value="Plastic Euro pallet">Plastic Euro pallet</option>
                            <option value="Plastic Air pallet">Plastic Air pallet</option>
                            <option value="One Way Block pallet">One Way Block pallet</option>
                            <option value="One Way Euro pallet">One Way Euro pallet</option>
                            <option value="One Way Air pallet">One Way Air pallet</option>
                            <option value="Other">Other (specify)</option>
                        </select>

                        {
                            valRef === "Other" &&
                            <div className="input-pallet mt-1">

                                <input
                                    type="text"
                                    placeholder='Pallet type'
                                    value={palletType}
                                    onChange={handleText}
                                />
                            </div>
                        }
                    </div>
                }
                {
                    tipe === "range" &&
                    <div className="input-range">
                        <input
                            type={tipe}
                            value={valRef}
                            min={minVal}
                            max={maxVal}
                            step="1"
                            name={name}
                            onChange={handleChange}
                        />
                        <span className="span-range">{valRef}</span>
                    </div>
                }
                {
                    tipe === "number" &&
                    <input
                        type={tipe}
                        value={valRef}
                        name={name}
                        onChange={handleChange}
                    />
                }


                {
                    photo !== undefined 
                    ?
                    <PhotoAdd
                        photo={photo}
                        nameId={name}
                        palletId={pallet.id}
                        setPallets={setPallets}
                        item="labels"
                    />
                    :
                    <div className="photo-empty-space" />
                }

            </div>
        </div>
    )
}
