import { useState } from 'react'
import toast from 'react-hot-toast'
import { useAddLifeImage } from '../../api/useLifeTest'
// import { useQueryClient } from '@tanstack/react-query'


export const ModalLifeImage = ({ closeModal, lifeId, dayId }) => {

    const [selectedImage, setSelectedImage] = useState([])

    // const queryClient = useQueryClient()
    const { mutate, isLoading } = useAddLifeImage()

    const handleImages = (e) => {
        setSelectedImage(e.target.files)
    }

    const sendImages = async () => {

        if (!lifeId) return

        if (selectedImage.length === 0) {
            toast.error('Select at least 1 file')
            return
        }

        mutate({
            lifeId,
            dayId,
            images: selectedImage
        }, {
            onSuccess: () => {
                toast.success('Files added successfully');
                closeModal()
            },
            onError: () => toast.error('Something went wrong')
        })
    }

    return (
        <div className="modal">

            <div className="modal__card">

                {
                    isLoading ?
                        <div className="loading">Loading&#8230;</div>
                        :
                        <div className="modal__content">

                            <label htmlFor="newFiles" className="upload-label mb-1 mt-2">

                                <img src="/assets/img/upload-life.svg" alt="img-icon" />

                                <span>Choose Images</span>
                            </label>
                            <input
                                id="newFiles"
                                className="upload-btn"
                                accept=".jpg, .png, .jpeg"
                                multiple
                                name="file"
                                type="file"
                                onChange={handleImages}
                            />
                            <div className="mb-1 w-100 text-center">
                                {
                                    selectedImage.length > 0 &&
                                    <span>{selectedImage.length} file/s selected</span>
                                }
                            </div>


                            <div className="modal__botones">
                                <button
                                    className="modal-btn btn-close mr-05"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                                <button
                                    className="modal-btn btn-add ml-05"
                                    onClick={sendImages}
                                >
                                    Add
                                </button>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
