import { useEffect } from 'react';
import { useState } from 'react';

export const SearchList = ({ itemName, selectFruit, actives }) => {

    useEffect(() => {

      if(actives){
        actives.includes(itemName) && setActive(true)
      }
    }, [ actives, itemName]);
    

    const [active, setActive] = useState(false);

    const btnActive = () => {
        selectFruit(itemName);
        setActive(!active)
    };

    return (
        <div
            className={`filter-btn ${active && "filter-selected"}`}
            onClick={btnActive}
        >
            {itemName}
        </div>
    )

};
