import toast from "react-hot-toast"
import { ImagesToRemove, useRemoveSectionImage } from "../../api/useReportTemp"
import { DetailObject, ImageTemp, ImageType, InitialPallets, PalletSection } from "../../interfaces/intakes.reports"
import { Defects, DetailName } from "../../interfaces/interfaces"
import { Gallery } from "../Gallery"
import { inputJson } from "../../helpers/eliminarEs"
import { ModalAddItem } from "../modals/ModalAddItem"
import { useEffect, useState } from "react"
import { ButtonInput } from "./ButtonInput"
import { IoAddCircleOutline } from "react-icons/io5"
// import { ModalImages } from "../modals/ModalImages"
import { AddSectionImages } from "../AddSectionImages"
import { useLocalStorageImages } from "../../hooks/useLocalStorageImages"


interface Props {
    reportId: string
    pallet: InitialPallets
    // palletSection: PalletSection | undefined
    detailName: DetailName
    setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
    setImagesTemp: React.Dispatch<React.SetStateAction<ImageTemp[]>>
    imagesTemp: ImageTemp[]
}

export const SectionAdd = ({ reportId, pallet, detailName, setPallets, setImagesTemp, imagesTemp }: Props) => {

    const palletSection = pallet[detailName] as PalletSection
    const { mutateAsync: mutateDeleting } = useRemoveSectionImage()
    const [openModal, setOpenModal] = useState(false)
    const { setLSImages, getLSImages } = useLocalStorageImages()

    useEffect(() => {
        const getImages = async () => {
            const localStorageSection = await getLSImages(pallet.pid);
            if (localStorageSection && localStorageSection.detailName === detailName) {
                setImagesTemp(localStorageSection.images);
            }
        };

        getImages();
    }, []);


    const addPallItem = (newLabel: string, newValue: string, isDefect?: Defects) => {

        const isPallgrow = detailName === 'pallgrow' && isDefect

        if (newLabel.length <= 0) return toast.error('Input Name incomplete')
        if (!/^[a-zA-Z_ ]*$/.test(newLabel)) return toast.error('Input Name should only contain letters')

        const labelExist = palletSection.data.find(item => item.label === newLabel)
        const nameExist = palletSection.data.find(item => item.name === inputJson(newLabel))

        const minorExist = pallet.pallgrow.minor?.find(item => item.name === inputJson(newLabel))
        const majorExist = pallet.pallgrow.major?.find(item => item.name === inputJson(newLabel))

        if (labelExist || nameExist) return toast.error('This input already exist')
        if (minorExist || majorExist) return toast.error('This input already exist')

        const newItem: DetailObject = { check: true, label: newLabel, name: inputJson(newLabel), value: isPallgrow ? 0 : newValue }

        setPallets(pallets => pallets.map(pall => {

            if (pall.pid === pallet.pid) {
                return isPallgrow
                    ? {
                        ...pall,
                        pallgrow: {
                            ...pall.pallgrow,
                            [isDefect]: [...pall.pallgrow[isDefect] || [], newItem]
                        }
                    }
                    : {
                        ...pall,
                        [detailName]: {
                            ...pall[detailName],
                            data: [...pall[detailName]?.data ?? [], newItem]
                        }
                    }
            }
            return pall
        }))
        setOpenModal(false)
    }

    const deleteImage = async (image: ImageType) => {
        const imageToRemove: ImagesToRemove = {
            keyName: image.key,
            keyLow: image.key_low,
            reportId,
            palletId: pallet.pid,
            detailName,
        }
        await mutateDeleting(imageToRemove, {
            onSuccess: async () => {
                setPallets(pallets => pallets.map(pall => {
                    if (pall.pid === pallet.pid) {
                        return {
                            ...pall,
                            [detailName]: {
                                ...pall[detailName],
                                images: palletSection.images.filter(img => img.key !== image.key) || []
                            }
                        }
                    } return pall
                }))
            }
        })
    };

    // const handleImages = async( images:ImageTemp[] ) => {
    //     await mutateAsync({
    //         reportId,
    //         palletId: pallet.pid,
    //         detailName,
    //         imagesTemp: images
    //     }, {
    //         onSuccess: async(data) => {
    //             setPallets(pallets => pallets.map(pall => {
    //                 if (pall.pid === pallet.pid) {
    //                     return {
    //                         ...pall,
    //                         [detailName]: {
    //                             ...pall[detailName],
    //                             images: [ ...(pall[detailName]?.images || []), ...data.images ]
    //                         }
    //                     }
    //                 } return pall
    //             }))
    //         },
    //         onError: () => toast.error('Something went wrong, try later')
    //     })
    // };

    const handleTempImages = async (newImages: ImageTemp[]) => {
        const images = imagesTemp.concat(newImages)
        setImagesTemp(images)
        await setLSImages(pallet.pid, detailName, images)
    };

    const removeTempImage = async (imgId: string) => {
        const updatedImages = imagesTemp.filter(image => image.id !== imgId)
        setImagesTemp(updatedImages)
        await setLSImages(pallet.pid, detailName, updatedImages)
    };

    return (

        <section className='grid-query'>
            {
                openModal &&
                <ModalAddItem
                    closeModal={() => setOpenModal(false)}
                    addPallItem={addPallItem}
                    detailName={detailName}
                />
            }
            {/* {
                openImagesModal &&
                <ModalImages
                    closeModal={() => setOpenImagesModal(false)}
                    handleImages={handleImages}
                    isLoading={isLoading}
                    limit={ 10 - (palletSection.images.length || 0)}
                />
            } */}

            <div />

            <div>
                <div className="flex flex-gap mt-1 mb-1">
                    <ButtonInput icon={<IoAddCircleOutline fontSize={20} />} text="Add Item" action={() => setOpenModal(true)} />
                    {/* <ButtonInput icon={<IoCameraOutline fontSize={20} />} color="green" text="Add Images" action={() => setOpenImagesModal(true)} /> */}
                    <AddSectionImages addTempImages={handleTempImages} limit={10 - ((palletSection.images.length || 0) + imagesTemp.length)} />
                </div>

                {
                    (palletSection.images.length > 0 || imagesTemp.length > 0) &&
                    <Gallery images={palletSection.images} deleteImage={deleteImage} imagesTemp={imagesTemp} removeTempImage={removeTempImage} />
                }
            </div>



        </section>
    )
}
