import { useState } from 'react'
import { Link } from 'react-router-dom'
import { dateFormat } from '../helpers/dateFormat'
import { CardPreReportInfo } from './CardPreReportInfo'
import { InfoBox } from './ui/InfoBox'
import { useSelector } from 'react-redux'

export const CardPreReport = ({ pallets, _id:id, mainData, endDate, palletRef, grade, action, user, team, setCurrentId, setOpenConfirmation, currentId }) => {

    const { uid } = useSelector(state => state.auth);

    const existGrower = pallets.some(pall => pall.addGrower !== null)

    const [activeDots, setActiveDots] = useState(false)

    return (
        <div className="prereport-card mb-1">

            <button
                className="remove"
                onClick={() => {
                    setCurrentId(id)
                    setActiveDots(true)
                }}>
                <img src='../assets/img/dots-icon.svg' alt="dots-icon" />
            </button>

            <InfoBox
                show={activeDots}
                onClickOutside={() => { setActiveDots(false) }}
            >
                <Link
                    // to={`/new-report/${currentId}`}
                    to={`/create/create=report&id=${currentId}`}
                    className='font-normal mb-05'
                >Finish Report</Link>

                <p className='font-normal'
                    onClick={() => {
                        setOpenConfirmation(true)
                    }}
                >Delete</p>
            </InfoBox>

            <Link to={`/prereport/${id}`}>

                <div className="prereport-card__title mb-05 mr-1">
                    <div className='flex'>
                        <h4>{mainData.pallet_ref}</h4>
                        {
                            team && uid === user &&
                            <div className='inCharge-bookmark ml-05'>
                                <img src='../assets/img/bookmark.svg' alt='bookmark-icon' width={12} />
                            </div>
                        }
                    </div>
                    <p className="prereport-card__date mr-02">{dateFormat(endDate) || "--"} </p>
                </div>

                <div className={`${existGrower && 'grower-margin'}`}>
                    {
                        existGrower
                            ?
                            pallets.map(pal => (
                                <CardPreReportInfo
                                    key={pal.pid}
                                    palletRef={mainData.pallet_ref}
                                    mainData={mainData}
                                    {...pal}
                                />
                            ))

                            : <CardPreReportInfo grade={grade} action={action} mainData={mainData} />
                    }
                </div>

            </Link>
        </div>
    )
}
