import { useMutation, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { Defects, DetailName } from "../interfaces/interfaces";

interface BasicData {
    reportId: string
    palletId: string
}

interface NewItem{
    name: string
    label: string
    value: string | number
}

interface AddItemData extends BasicData {
    detailName: DetailName
    newItem: NewItem
    isDefect?: Defects | undefined
}

const editItem = async ({ dataBody, prereport, isReportPrereport }: any) => {

    let query: string
    let revalidate
    if (prereport && !isReportPrereport) { query = '/prereport/edit-item'; revalidate = 'prereport' }
    if (!prereport && !isReportPrereport) { query = '/report/edit-item'; revalidate = 'report' }
    if (!prereport && isReportPrereport) { query = '/report/edit-prereport-data'; revalidate = 'report' }

    const { data } = await qcareApi.put(query!, dataBody)
    return { data, revalidate }
};

const editComments = async ({ reportId, comments }: any) => {
    const { data } = await qcareApi.put('/report/edit-comments', { reportId, comments })
    return data
};

const editPalletComments = async ({ reportId, palletId, comments }: any) => {
    const { data } = await qcareApi.put('/report/edit-pallet-comments', { reportId, palletId, comments })
    return data
};

const deleteItem = async ({ dataBody, prereport, isReportPrereport }: any) => {

    let type
    if (prereport && !isReportPrereport) type = 'prereport'
    if (!prereport && !isReportPrereport) type = 'report'
    if (!prereport && isReportPrereport) type = 'isReportPrereport'

    const { data } = await qcareApi.put('/report/delete-item', { ...dataBody, type })
    return { data, type: type === 'prereport' ? 'prereport' : 'report' }
};

const addNewItem = async ({ reportId, ...fetchData }: AddItemData) => {
    const { data } = await qcareApi.put(`/report/add-item-report/${reportId}`, fetchData)
    return data
};


// ------------------------- HOOKS -------------------------

export const useEditItem = () => {
    const queryClient = useQueryClient()
    return useMutation(editItem, {
        onSuccess: async (item) => { await queryClient.invalidateQueries([item.revalidate]) }
    })
}

export const useEditComments = () => {
    const queryClient = useQueryClient()
    return useMutation(editComments, {
        onSuccess: async (data) => { await queryClient.invalidateQueries(['report', data.reportId],) }
    })
}

export const useEditPalletComments = () => {
    const queryClient = useQueryClient()
    return useMutation(editPalletComments, {
        onSuccess: async (data) => { await queryClient.invalidateQueries(['report', data.reportId],) }
    })
}

export const useDeleteItem = () => {
    const queryClient = useQueryClient()
    return useMutation(deleteItem, {
        onSuccess: async (item) => { await queryClient.invalidateQueries([item.type]) }
    })
}

export const useAddNewItem = () => {
    const queryClient = useQueryClient()
    return useMutation(addNewItem, {
        onSuccess: async (data) => { await queryClient.invalidateQueries(['report', data.reportId],) }
    })
}
