
import { DetailObject, InitialPallets } from '../../../../interfaces/intakes.reports'
import { InputsPallgrow } from '../../../pallet/InputsPallgrow'
import { InputsPallgrowData } from '../../../pallet/InputsPallgrowData'

interface Props {
    setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
    pallet: InitialPallets
    samples?: number
    addSample: () => void
    restSample: () => void
    data: DetailObject[]
    minor: DetailObject[]
    major: DetailObject[]
    handleInput: (value: string | number | boolean, name: string) => void
    // handleCheck: ( name: string ) => void
}

export const PallgrowTable = ({ data, major, minor, pallet, setPallets, handleInput }: Props) => {

    const handleCheckDefect = (name: string, check: boolean, defect: "major" | "minor") => {
        setPallets(pallets => pallets.map(pall => {

            if (pall.pid === pallet.pid) {
                return {
                    ...pall,
                    pallgrow: {
                        ...pall.pallgrow,
                        [defect]: pall.pallgrow?.[defect]?.map(inp => {
                            if (inp?.name === name) {
                                return {
                                    ...inp,
                                    check
                                }
                            } return inp
                        })
                    }
                }

            }
            return pall
        }))
    };

    return (
        <>
            <div className='mb-2'>
                {
                    data.map(input => {
                        const isSample = input.name === "samples"
                        return (
                            <InputsPallgrowData
                                key={input.name}
                                item={input}
                                handleInput={handleInput}
                                afterInput={!isSample && <p className='ml-05'>gr.</p>}
                                withButton={isSample}
                            />
                        )
                    })
                }
            </div>

            {
                (minor.length > 0 || major.length > 0) &&
                <div className='mb-1 border-b'>
                    <h4>Defects</h4>
                </div>
            }

            {
                major.length > 0 &&
                major.map(input => (
                    <InputsPallgrow
                        key={input.name}
                        palletId={pallet.pid}
                        item={input}
                        setPallets={setPallets}
                        handleCheckDefect={handleCheckDefect}
                        colorButton='red'
                        withButton
                        max={100}
                        isDefect='major'
                    />
                ))
            }

            {
                minor.length > 0 &&
                minor.map(input => (
                    <InputsPallgrow
                        key={input.name}
                        palletId={pallet.pid}
                        item={input}
                        setPallets={setPallets}
                        handleCheckDefect={handleCheckDefect}
                        colorButton='orange'
                        withButton
                        max={100}
                        isDefect='minor'
                    />
                ))
            }
        </>
    )
}
