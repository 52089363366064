import { IoAddCircle } from "react-icons/io5"

interface Props {
    action: () => void
    className?: string
    btnColor?: "blue" | "green"
}

export const AddItemBtn = ({ action, className, btnColor="blue" }:Props) => {

    const colorBtn = {
        blue: "#1A73E8",
        green: "#1a9141",
    }

    return (
        <button
            onClick={ action }
            className={ `modal-add ${className && className}` }
        >
            {/* <div className="mr-05"><span>+</span></div> */}
            <IoAddCircle size={22} className="mr-02" color={colorBtn[btnColor]}/>

            <span>Add item</span>
        </button>
    )
}
