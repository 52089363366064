import { ScoreNum } from "../interfaces/interfaces";

export const useOldDate = (date:Date=new Date(), score:ScoreNum="0") => {

  const lastDate = process.env.REACT_APP_LAST_DATE

  const olderDate = new Date("26 June 2022").getTime()
  // const newDate = new Date("06 February 2024").getTime()
  const newDate = new Date(lastDate as string).getTime()
  const currentDate = new Date(date).getTime()

  const oldDate = currentDate < olderDate || currentDate > newDate

  let scoreNumber:ScoreNum="0"

  if(oldDate){
    if(score === "0") scoreNumber = "0"
    if(score === "1") scoreNumber = "2"
    if(score === "2") scoreNumber = "3"
    if(score === "3") scoreNumber = "5"
    if(score === "4") scoreNumber = "6"
    if(score === "5") scoreNumber = "7"
    if(Number(score) > 5) scoreNumber = "0"
} else { scoreNumber = score }

  return { 
    oldDate,
    scoreNumber
  };
}
