import { useEffect, useState } from 'react'
import { IoChevronDownOutline } from "react-icons/io5";
import { IoChevronUpOutline } from "react-icons/io5";

interface Props {
    title: React.ReactNode
    children: React.ReactNode
    defaultOpen?: boolean
    className?: string
    fullWidth?: boolean
    additionalBtn?: React.ReactNode
    afterTitle?: React.ReactNode
    setAccordion?: React.Dispatch<React.SetStateAction<boolean>>
}

export const AccordionCard = ({ title, children, afterTitle, className, defaultOpen = true, additionalBtn, fullWidth = false, setAccordion }: Props) => {

    const [activeAccordeon, setActiveAccordeon] = useState(defaultOpen)

    useEffect(() => {
        setActiveAccordeon(defaultOpen)
        return () => setActiveAccordeon(true)
    }, [defaultOpen])

    const closeModal = () => {
        setActiveAccordeon(!activeAccordeon)
        setAccordion && setAccordion(!activeAccordeon)
    };

    return (
        <div className={`${activeAccordeon ? "accordion-card active" : "accordion-card"} ${className}`}>
            <div className="flex-space-between card-header">
                <div className='flex-center cursor' onClick={() => setActiveAccordeon( active => !active)}>
                    <h3>{title}</h3>
                    {afterTitle && afterTitle}
                </div>
                <div className='flex flex-gap'>
                    {
                        additionalBtn &&
                        additionalBtn
                    }
                    <button
                        className='accordion-open-btn'
                        onClick={() => closeModal()}>
                        {activeAccordeon ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                    </button>
                </div>
            </div>
            <div className={`card-content ${fullWidth ? "" : "container-card"}`}>
                <div className={`${fullWidth ? "" : "pb-1"}`}>

                    {children}
                </div>
            </div>
        </div>
    )
}
