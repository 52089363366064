import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import qcareApi from '../../api/qcareApi'
import { PDFScreen } from './PDFScreen';


export const SharePdf = () => {

    const [allData, setAllData] = useState(null)

    const { id } = useParams()

    useEffect(() => {

        async function fetchData() {
            const { data } = await qcareApi.get(`/report/share-report/${id}`)
            setAllData([data.singleReport, false])
        }
        fetchData()
    }, [id])

    return (
        <>
            {
                (allData) &&
                <PDFScreen allData={allData} />
            }
        </>
    );
}
