import React, { useState } from 'react';
import { capitalize } from '../../helpers/eliminarEs';
import { useNavigate } from 'react-router-dom';
import { IoLogInOutline, IoListOutline, IoRepeatOutline } from "react-icons/io5";
import { Fruit } from '../../interfaces/interfaces';
// import { Link } from 'react-router-dom'

interface FruitList {
    name: Fruit,
    img: string
}

interface CreateList {
    create: string,
    icon: React.ReactNode,
    link: string
}

export const New = () => {

    const [fruit, setFruit] = useState<Fruit | null>(null)
    const navigate = useNavigate()

    const fruits:FruitList[] = [
        { name: "blueberries", img: "/assets/img/new-bb.jpg" },
        { name: "raspberries", img: "/assets/img/new-rb.jpg" },
        { name: "blackberries", img: "/assets/img/new-blb.jpg" },
        { name: "strawberries", img: "/assets/img/new-sb.jpg" },
        { name: "red currants", img: "/assets/img/new-red.jpg" },
        { name: "pears", img: "/assets/img/new-cp.jpg" },
        { name: "cherries", img: "/assets/img/new-ch.jpg" },
        { name: "apples", img: "/assets/img/new-apples.jpg" },
        { name: "kiwiberries", img: "/assets/img/new-kw.jpg" },
    ]

    const createNew:CreateList[] = [
        { create: "intake", icon: <IoLogInOutline size={25}/>, link: `/new/create=intake&fruit=${fruit}` },
        { create: "report", icon: <IoListOutline size={25}/>, link: `/new/create=report&fruit=${fruit}` },
        { create: "repack", icon: <IoRepeatOutline size={25}/>, link: `/new/create=repack&fruit=${fruit}` },
        // { create: "intake", img: "/assets/img/nav-intakes.svg", link: `/new/create=intake&fruit=${fruit}` },
        // { create: "Report", img: "/assets/img/nav-reports.svg", link: `/new/create=report&fruit=${fruit}` },
        // { create: "Fast Report", img: "/assets/img/nav-fast.svg", link: `/new/create=fast&fruit=${fruit}` },
        // { create: "Pre Report", img: "/assets/img/nav-prereports.svg", link: `/new/create=prereport&fruit=${fruit}` },
    ]

    return (
        <div className="upload content">
            <main className="container">
                <h2 className="main-title mb-2 text-center">Create New</h2>

                {
                    fruit
                        ?
                        <>
                            <div className="fruit">
                                {
                                    createNew.map(create => (

                                        <button key={create.create} onClick={() => navigate(create.link)} className="card-fruit">
                                            <div className="fruit__container">
                                                <div className="mb-05">
                                                    {create.icon}
                                                </div>
                                                <h4 className="fruit__title">{capitalize(create.create)}</h4>
                                            </div>
                                        </button>

                                    ))
                                }
                            </div>

                            <div className='flex-center mt-3'>
                                <button onClick={() => setFruit(null)}>
                                    <p>&larr; Go Back</p>
                                </button>
                            </div >
                        </>

                        :
                        <div className="fruit">
                            {
                                fruits.map(fruit => (

                                    <button key={fruit.name} onClick={() => setFruit(fruit.name)} className="card-fruit">
                                        <div className="fruit__container">
                                            <div className="fruit__img mb-1">
                                                <img src={fruit.img} alt={`fruit-${fruit.name}`} />
                                            </div>
                                            <h4 className="fruit__title">{capitalize(fruit.name)}</h4>
                                        </div>
                                    </button>
                                ))
                            }
                        </div>
                }
            </main>

        </div>
    )
}
