
interface Props {
    value: string
    max?: number
    handleInput: (value: string) => void
    className?: string
}

export const CounterInput = ({ value, max = 1000, handleInput, className }: Props) => {

    const handleDefect = (value: string, action?: "add" | "rest") => {

        const newValue: string =
            action
                ? (action === "add"
                    ? (Number(value) + 1).toString()
                    : (Number(value) > 0 ? (Number(value) - 1).toString() : value))
                : value

        if (+newValue > max) return
        // setNewValue(newValue)
        handleInput(newValue)
    };


    return (
        
            <div className={`grid-counter ${className}`}>

                <button
                    onClick={() => handleDefect(value, "rest")}
                    className={`btn-add-rest`}>
                    -
                </button>


                <input
                    className='text-center flex-1'
                    type="number"
                    min={0}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDefect(e.target.value)}
                    value={value as string}
                    onFocus={(e) => e.target.select()}
                />

                <button
                    onClick={() => handleDefect(value, "add")}
                    className={`btn-add-rest`}>
                    +
                </button>

            </div>
    )
}
