import { useState } from 'react'
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Charging } from '../ui/Charging'
import { AccordeonPreReport } from '../ui/AccordeonPreReport';
import { ModalAddPallet } from '../ui/ModalAddPallet';
import { BatchInfo } from '../ui/BatchInfo';
import { usePrereport } from '../../api/usePrereport';
import { Discrepancies } from '../ui/Discrepancies';
import { MainData } from './Inputs/MainData';


export const SinglePreReport = () => {

    const { id } = useParams()
    const navigate = useNavigate()

    const { data, isLoading, isError } = usePrereport(id)
    const [updateVal, setUpdateVal] = useState(false)
    const [openNewPallet, setOpenNewPallet] = useState(false)

    const update = () => setUpdateVal(!updateVal)

    if (isError) navigate('/prereports')


    return (

        <div className="content">

            {
                isLoading
                    ? <Charging />
                    :
                    <main className="container">
                        {
                            openNewPallet &&
                            <ModalAddPallet
                                setOpenModal={setOpenNewPallet}
                                update={update}
                                reportId={id}
                                hasGrower={data?.pallets.some(pall => pall.addGrower !== null)}
                                date={ data?.endDate || new Date() }
                            />
                        }


                        {
                            data &&
                            <>
                                <div className="report">

                                    <div className="report-title">
                                        <div className="report__details mb-2 span-6">
                                            <h2>{data.mainData.pallet_ref}</h2>
                                            <div className='flex my-02'>
                                                <p className='font-small'>Duration:</p>
                                                <p className='duration font-small'>{dayjs(dayjs(data.endDate).diff(dayjs(data.startDate))).format('mm:ss')} min</p>
                                            </div>

                                            {
                                                (data.endDate) &&
                                                <small className="text-muted">{dayjs(data.endDate).format('DD/MM/YYYY')} | {dayjs(data.endDate).format('HH:mm')}</small>
                                            }

                                        </div>
                                    </div>
                                </div>

                                <BatchInfo reportId={id} mainData={data?.mainData} prereport />

                                {
                                    data.mainData &&
                                    <div className='mb-2'>
                                        <MainData mainData={data?.mainData} setMainData={null} isList />

                                        <Discrepancies
                                            mainData={data?.mainData}
                                            discrepancies={data?.discrepancies || null}
                                            reportId={data?._id}
                                            dbDiscrepancy
                                            prereport
                                        />
                                    </div>
                                }


                                {
                                    data.pallets.length > 0

                                        ?
                                        <div className="pallets-container">
                                            {
                                                data.pallets.map((pallet, i) => {

                                                    const labels = pallet.details.labels || []
                                                    const appareance = pallet.details.appareance || []

                                                    return (
                                                        <AccordeonPreReport
                                                            key={pallet.pid}
                                                            pid={pallet.pid}
                                                            score={pallet.score}
                                                            pallet={pallet}
                                                            labels={labels}
                                                            appareance={appareance}
                                                            i={i}
                                                            reportId={id}
                                                            update={update}
                                                            date={data.endDate}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>

                                        : <p>No Pallets</p>
                                }

                                <div className="boton-intake mb-3 mt-1">
                                    <p className="text-center font-small mb-05">Add Pallet</p>
                                    <button
                                        onClick={() => setOpenNewPallet(true)}>
                                        <svg viewBox="0 0 200 200">
                                            <polygon points="180.1,91.7 108.3,91.7 108.3,19.9 91.7,19.9 91.7,91.7 19.9,91.7 19.9,108.3 91.7,108.3 91.7,180.1 108.3,180.1 108.3,108.3 180.1,108.3 " />
                                        </svg>
                                    </button>
                                </div>

                                <div className='btn-center mt-2'>
                                    <Link
                                        className='btn-exports share-blue m-auto text-center'
                                        // to={`/new-report/${id}`}
                                        to={`/create/create=report&id=${id}`}
                                    >
                                        <p>Finish Report <span className='ml-05 font-large'>&#8250;</span></p>
                                    </Link>
                                </div>
                            </>
                        }


                    </main>
            }

        </div>
    );
}
