import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast'
import { conditionsData } from '../../data/conditions';
import { useEditDay } from '../../api/useLifeTest';
import { useQueryClient } from '@tanstack/react-query';


export const ModalEditDay = ({ closeModal,
    conditions,
    temperature,
    lifeId,
    dayId,
    dayNum,

}) => {

    const [newTemperature, setNewTemperature] = useState(temperature)
    const [newConditions, setNewConditions] = useState(conditions)

    const queryClient = useQueryClient()
    const { mutate, isLoading } = useEditDay()

    const sendEditItem = async () => {

        if (temperature === newTemperature && conditions === newConditions) return closeModal()

        mutate({
            lifeId,
            dayId,
            conditions: newConditions,
            temperature: newTemperature
        }, {
            onSuccess: async() => {
                await queryClient.invalidateQueries(['lifetest', lifeId])
                toast.success(`Day ${dayNum} has been updated`)},
            onError: () => toast.error('Something went wrong')
        })
    };


    const handleCondition = (e) => {
        if (!newConditions.some(n => n === e.target.value)) {
            setNewConditions(c => [...c, e.target.value])
        }
    };

    return (
        <div className="modal">

            <div className="modal__card">
                {
                    isLoading
                        ? <div className="loading">Loading&#8230;</div>
                        :
                        <div className="modal__content">

                            <label className="modal-label mb-2">Day {dayNum}</label>


                            <div className='grid mb-1'>
                                <label className="span-6 ">Temperature</label>
                                <input
                                    className="d-input span-6"
                                    type="number"
                                    value={newTemperature}
                                    onChange={(e) => setNewTemperature(e.target.value)}
                                    onFocus={(e) => e.target.select()}
                                />
                            </div>

                            <div className='grid mb-2'>
                                <label className="span-6 ">Conditions</label>
                                <div className="span-6 select-input">
                                    <select
                                        placeholder='Select'
                                        className='select-border'
                                        onChange={handleCondition}
                                        value="Select an option">
                                        <option>Select options</option>
                                        {
                                            conditionsData.sort().map(item => (
                                                <option value={item} key={uuidv4()}>{item}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className='mb-05 modal-conditions'>
                                {
                                    newConditions.length > 0 &&
                                    newConditions.map((condition, i) => (
                                        <button key={i} className="flex mb-1 mr-1 modal-conditions__button">
                                            {condition}
                                            <div
                                                onClick={() => setNewConditions(newConditions.filter(item => item !== condition))}
                                            >&#x2715;</div>
                                        </button>
                                    ))
                                }
                            </div>

                            <div className="modal__botones mt-1">
                                <button
                                    className="modal-btn btn-close mr-05"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                                <button
                                    className="modal-btn btn-add ml-05"
                                    onClick={sendEditItem}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                }
            </div>



        </div>
    )
}