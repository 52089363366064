import { v4 as uuidv4 } from 'uuid';
import { DetailObject } from '../../interfaces/intakes.reports'

interface Props {
  // palletId: string
  // detailName: DetailName
  item: DetailObject
  max?: number
  withButton?: boolean
  colorButton?: "green" | "blue" | "red" | "orange"
  isDefect?: "minor" | "major"
  afterInput?: React.ReactNode
  handleInput: (value: string | number | boolean, name: string) => void
}

export const InputsPallgrowData = ({ item, max = 10000, handleInput, withButton, colorButton, afterInput }: Props) => {

  const handleChange = (value: string, action?: "add" | "rest") => {

    if (isNaN(Number(value))) return

    const newValue: string =
      action
        ? (action === "add"
          ? (Number(value) + 1).toString()
          : (Number(value) > 0 ? (Number(value) - 1).toString() : value))
        : value

    if (+newValue > max) return

    handleInput(newValue, item.name)
  };


  return (
    <div className='input-pallet'>

      <div className="label-name">
        {/* {
          !noCheck &&
          <input
            className='check-on-off'
            type="checkbox"
            checked={item.check}
            onChange={() => handleCheckDefect(item.name, !item.check, isDefect)}
          />
        } */}
        <p style={{ fontSize: ".9rem"}}>{item.label}</p>
      </div>


      <div className="input-type flex">

        {
          withButton &&
          <button
            onClick={() => handleChange(item.value as string, "rest")}
            className={`btn-add-rest h-100 ${colorButton}`}>
            -
          </button>
        }

        <input
          className={`${withButton ? 'text-center mx-02' : ''}`}
          name={uuidv4()}
          type="number"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.currentTarget.value)}
          value={item.value as string}
          // onFocus={(e) => e.target.select()}
        />
        {
          withButton &&
          <button
            onClick={() => handleChange(item.value as string, "add")}
            className={`btn-add-rest h-100 ${colorButton}`}>
            +
          </button>
        }

        {afterInput && afterInput}

      </div>
    </div>
  )
}

