import { useState } from 'react';
import { Inputs } from './Inputs'
import { PallgrowTable } from '../pages/Inputs/Pallgrow/PallgrowTable'
import { ImageTemp, InitialPallets, Labels, PalletSection } from '../../interfaces/intakes.reports';
import { dataPallgrow } from '../../data/pallgrow'

import { SectionAdd } from './SectionAdd'
import { Steps } from './Steps';
import { DetailName } from '../../interfaces/interfaces';
import { useLocalStorageImages } from '../../hooks/useLocalStorageImages';



interface Props {
    reportId: string
    setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
    pallet: InitialPallets
    detailName: DetailName
    saveAndNext: (detailName: DetailName, images: ImageTemp[]) => Promise<string | undefined>
    step: number
    setStep: React.Dispatch<React.SetStateAction<number>>
    isLoading?: boolean
}

export const Section = ({ reportId, pallet, setPallets, detailName, saveAndNext, step, setStep, isLoading }: Props) => {

    const palletSection = pallet[detailName] as PalletSection
    const [imagesTemp, setImagesTemp] = useState<ImageTemp[]>([])
    const { cleanLocalStorage } = useLocalStorageImages()
    
    const handleSample = (addRest: "add" | "rest") => {
        setPallets(pallets => pallets.map(pall => {
            if (pall.pid === pallet.pid) {
                return {
                    ...pall,
                    pallgrow: {
                        data: pall.pallgrow?.data.map(pal => {
                            if (!dataPallgrow.includes(pal.name)) {
                                return {
                                    ...pal,
                                    value: addRest === 'add' ? ([...pal.value as string[], "0"]) : (pal.value as string[]).slice(0, -1)
                                }
                            } return pal
                        }) || [],
                        images: []


                    }
                }
            }
            return pall
        }))
    }

    const handleCheck = (name: string) => {
        setPallets(pallets => pallets.map(pall => {
            if (pall.pid === pallet.pid) {
                return {
                    ...pall,
                    [detailName]: {
                        ...pall[detailName],
                        data: pall[detailName]?.data.map(inp => {
                            if (inp.name === name) {
                                return {
                                    ...inp,
                                    check: !inp.check
                                }
                            } return inp
                        })
                    }
                }
            }
            return pall
        }))
    }

    const handleInput = (value: string | number | boolean, name: string) => {
        setPallets(pallets => pallets.map(pall => {
            if (pall.pid === pallet.pid) {
                return {
                    ...pall,
                    [detailName]: {
                        ...pall[detailName],
                        data: pall[detailName]?.data.map(inp => {
                            if (inp.name === name) {
                                return {
                                    ...inp,
                                    value: value
                                }
                            } return inp
                        })
                    }
                }
            }
            return pall
        }))
    };


    return (
        <div className={`pallet-step active-step`}>
            {/* <div key={detailName} className={`pallet-step ${section.sectionStep === step ? "active-step" : "pt-2 pb-2 border-b"}`}> */}
            <Steps
                step={step}
                setStep={setStep}
                actionNext={ async() => {
                    saveAndNext(detailName, imagesTemp)
                    .then(()=> cleanLocalStorage(pallet.pid, detailName))
                }}
                isLoading={isLoading}
            />

            {palletSection &&
                <>
                    <p className='bold font-medium mb-05 text-green'>{Labels[detailName]}</p>
                    {
                        (detailName !== 'pallgrow'
                            ?
                            <>
                                {
                                    palletSection?.data.map(input => (
                                        <Inputs
                                            key={input.name}
                                            palletId={pallet.pid}
                                            input={input}
                                            setPallets={setPallets}
                                            detailName={detailName}
                                            handleCheck={() => handleCheck(input.name)}
                                            handleInput={handleInput}
                                        />
                                    ))
                                }

                            </>
                            :
                            <>
                                <div className="py-05" id={`pal-${pallet.pid}`}>
                                    <PallgrowTable
                                        data={pallet.pallgrow?.data || []}
                                        minor={pallet.pallgrow?.minor || []}
                                        major={pallet.pallgrow?.major || []}
                                        pallet={pallet}
                                        setPallets={setPallets}
                                        handleInput={handleInput}

                                        addSample={() => handleSample('add')}
                                        restSample={() => handleSample('rest')}
                                    />
                                </div>
                            </>
                        )
                    }

                    <SectionAdd
                        reportId={reportId}
                        pallet={pallet}
                        detailName={detailName}
                        setPallets={setPallets}
                        setImagesTemp={setImagesTemp}
                        imagesTemp={imagesTemp}
                    />
                </>

            }

            {/* {
                active &&
                <ButtonSubmit action={() => saveAndNext()} title='next section' />
            } */}

        </div>

    )
}
