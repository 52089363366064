import { useMutation, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { ScoreNum, Status } from "../interfaces/interfaces";
import { scoreComments } from "../data/comments";

interface EditScore {
    dataBody: any
    prereport: boolean
    isReportPrereport: boolean
}

interface EditStatus {
    reportId: string
    palletId:string
    status: Status
    value: string
    comments?: string
}

const editScore = async ({ dataBody, prereport, isReportPrereport }:EditScore) => {

    let revalidate
    let query

    if (prereport && !isReportPrereport) {query = 'prereport/edit-condition'; revalidate = 'prereport'}
    if (!prereport && !isReportPrereport) {query = '/report/edit-item'; revalidate = 'report'}
    if (!prereport && isReportPrereport) {query = 'report/edit-prereport'; revalidate = 'report'}

    const { data } = await qcareApi.put(query!, dataBody)
    return {data, revalidate}
};

const editStatus = async ({ reportId, ...fetchData }:EditStatus) => {

    if( fetchData.status === "score" ) fetchData.comments = scoreComments(fetchData.value as ScoreNum);

    const { data } = await qcareApi.put(`report/edit-status/${reportId}`, fetchData)
    return data
};

// ------------------------- HOOKS -------------------------

export const useEditScore = () => {
    const queryClient = useQueryClient()
    return useMutation(editScore, {
        onSuccess: async(item) => { 
            await queryClient.invalidateQueries([item.revalidate])
            await queryClient.invalidateQueries([item.revalidate+"s"])
        }
    })
}

export const useEditStatus = () => {
    const queryClient = useQueryClient()
    return useMutation(editStatus, {
        onSuccess: async(data) => { 
            await queryClient.invalidateQueries(["report", data.reportId])
            await queryClient.invalidateQueries(["reports"])
        }
    })
}