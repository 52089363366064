import { useState } from 'react'
import toast from 'react-hot-toast';
import { jsonKeyToString, jsonToArraySelect } from '../../helpers/jsonToString';
import { useAddDiscrepancy } from '../../api/useDiscrepancy';

export const ModalDiscrepancy = ({ reportId, closeModal, mainData, discrepancies, setDiscrepancies, dbDiscrepancy, prereport }) => {

    const [description, setDescription] = useState("")
    const [item, setItem] = useState(0)
    const { mutate, isLoading } = useAddDiscrepancy()

    const addDiscrepancy = async () => {
        if (description.trim().length === 0 || item === 0) {
            return toast.error('Please enter a description or discrepancy')
        }

        if (discrepancies && discrepancies[item]) {
            toast.error('This item already has a discrepancy')
            return
        }

        dbDiscrepancy
            ?
            mutate({ dataBody: { reportId, discrepancies: { ...discrepancies, [item]: description } }, prereport },{
                onError: () => toast.error('Error adding discrepancy'),
            })
            :
            setDiscrepancies({ ...discrepancies, [item]: description })

        closeModal()
    };

    return (
        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">

                    <label className="modal-label mb-2">Add discrepancy</label>
                    <div className="span-7 score-wraper mb-2">
                        <select className='full' onChange={(e) => setItem(e.target.value)} value={item}>
                            <option value={0}>Select an Item</option>
                            {
                                jsonToArraySelect(mainData).map((key, index) => (
                                    <option key={index} value={key}>{jsonKeyToString(key)}</option>
                                ))
                            }

                        </select>
                    </div>

                    <div className="pallet-comments">
                        <div className="mb-05">
                            <p className='bold mb-02'>Discrepancy</p>
                            <textarea
                                maxLength={100}
                                resize="vertical"
                                name="discrepancy"
                                id="discrepancy"
                                cols="30"
                                rows="4"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)} />
                            <p className='text-mute font-small mt-02 ml-05'>*100 characters maximum</p>
                        </div>
                    </div>

                    <div className="modal__botones mt-2">
                        <button
                            disabled={isLoading}
                            className="modal-btn btn-close mr-05"
                            onClick={() => closeModal()}
                        >
                            Close
                        </button>
                        <button
                            disabled={isLoading}
                            className="modal-btn btn-add ml-05"
                            onClick={addDiscrepancy}
                        >
                            Add Discrepancy
                        </button>
                    </div>
                </div>
            </div>



        </div>
    )
}