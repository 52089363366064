import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { createData, fruitsData } from '../../data/create'
import { batchDataProduct, batchDataRepack } from '../../data/batchData'
import TeamSelector from './Inputs/TeamSelector'
import { MainDataAdd } from './Inputs/MainDataAdd'
import { Create, Fruit } from '../../interfaces/interfaces'
import { MainData } from './Inputs/MainData'
import { MainData as MainDataType } from '../../interfaces/intakes.reports'


import CreateButtonIntake from './CreateButtonIntake'
import { capitalize } from '../../helpers/eliminarEs'
// import { Repacks } from './Repacks'
import CreateButtonReport from './CreateButtonReport'
import CreateButtonRepack from './CreateButtonRepack'
import { repackData } from '../../data/repack'
import { RepackPrePost } from './Repack/RepackPrePost'
import { SupplierSelector } from './Inputs/SupplierSelector'
import { mainDataWithoutSupplier } from '../../helpers/mainDataConvert'

interface QueryParams {
    fruit?: Fruit
    create?: Create
}

export const NewPage = () => {

    const navigate = useNavigate()
    const { id } = useParams<string>();

    const { fruit, create } = queryString.parse(id!) as QueryParams

    const [team, setTeam] = useState<string | null>(null)
    const [preRepack, setPreRepack] = useState<MainDataType>(repackData)
    const [postRepack, setPostRepack] = useState<MainDataType>(repackData)
    const [mainData, setMainData] = useState<MainDataType | null>(null)
    const [supplierId, setSupplierId] = useState<string | null>(null)

    useEffect(() => {
        if (!create || !fruit) navigate("/")
        if (!createData.includes(create as Create) || !fruitsData.includes(fruit as Fruit)) navigate("/")
    }, [fruit, create, navigate])

    useEffect(() => {
        create === "repack"
            ? setMainData(batchDataRepack(fruit as Fruit))
            : setMainData(batchDataProduct(fruit as Fruit))
    }, [fruit, create])

    return (
        <div className="content intake">
            <main className="container">
                <h2 className="main-title mb-2">{capitalize(create)}</h2>
                <div className='mb-3'>
                    <TeamSelector setTeam={setTeam} team={team} />

                    {mainData &&
                        <SupplierSelector
                            team={team}
                            mainData={mainData as MainDataType}
                            setMainData={setMainData}
                            supplierId={supplierId}
                            setSupplier={setSupplierId}
                        />
                    }

                    <MainData
                        mainData={mainDataWithoutSupplier(mainData)}
                        setMainData={setMainData}
                        isList={false}
                    />

                    {
                        create !== "repack" &&
                        < MainDataAdd
                            mainData={mainData}
                            setMainData={setMainData}
                        />
                    }
                </div>

                {
                    create === "repack" &&
                    <div className='mb-3 grid-query'>
                        <RepackPrePost
                            data={preRepack}
                            setData={setPreRepack}
                            type='pre'
                        />
                        <RepackPrePost
                            data={postRepack}
                            setData={setPostRepack}
                            type='post'
                        />
                    </div>
                }

                {
                    mainData &&
                    <>
                        {create === "intake" && <CreateButtonIntake mainData={mainData} team={team} />}
                        {create === "report" && <CreateButtonReport mainData={mainData} team={team} supplier={supplierId} fruit={fruit as Fruit} />}
                        {create === "repack" && <CreateButtonRepack mainData={mainData} team={team} fruit={fruit as Fruit} preRepack={preRepack} postRepack={postRepack} />}
                    </>
                }

            </main>

        </div>
    )
}
