import { IoCreateOutline } from "react-icons/io5"
import { typeData } from "../../data/type-data"
import { itemValor, kilosToLb } from "../../helpers/eliminarEs"
import { DetailObject, InitialPallets, Labels } from "../../interfaces/intakes.reports"
import { DetailName } from "../../interfaces/interfaces"
import { ModalEditItem } from "../ui/ModalEditItem"
import { useState } from "react"
import { pallgrowDefects } from "../../helpers/defectsTable"
import { ChartPieNew } from "../ui/ChartPieNew"
import { ChartPieDefects } from "../ui/ChartPieDefects"
import { ModalAddNewItem } from "../ui/ModalAddNewItem"
import { AddItemBtn } from "../ui/AddItemBtn"
import { v4 as uuidv4 } from 'uuid';

interface Props {
    reportId: string
    detailName: DetailName
    pallet: InitialPallets
}

interface EditProps {
    item: DetailObject
    isCounter?: boolean
    isDefect?: "major" | "minor"
}

interface DefectProps {
    defect: DetailObject
    isMajor: boolean
}

export const PalletSection = ({ detailName, pallet, reportId }: Props) => {

    const defects = [...(pallet[detailName]?.major || []), ...(pallet[detailName]?.minor || [])]
    const [openAddModal, setOpenAddModal] = useState<boolean>(false)

    const { major, minor, totalMajor, totalMinor, totalDefects, totalRest } = pallgrowDefects(pallet.pallgrow.data, (pallet.pallgrow.major || []), (pallet.pallgrow.minor || []))

    const EditButton = ({ item, isCounter, isDefect }: EditProps) => {
        const [openEdit, setOpenEdit] = useState<boolean>(false)
        return (
            <>
                {
                    openEdit &&
                    <ModalEditItem
                        closeModal={() => setOpenEdit(false)}
                        item={item}
                        detailName={detailName}
                        palletId={pallet.pid}
                        reportId={reportId}
                        isCounter={isCounter}
                        isDefect={isDefect}
                    />
                }
                <button
                    className="align-up mt-02"
                    onClick={() => setOpenEdit(true)}
                ><IoCreateOutline />
                </button>
            </>
        )
    };

    const Defects = ({ defect, isMajor }: DefectProps) => {
        return (
            <div className="flex flex-gap border-b py-01">
                <p className="flex-1">{defect.label}</p>
                <div className="flex flex-1">
                    <div className="grid-array flex-1">
                        {!isMajor && <div />}
                        <p className={`defect ${isMajor ? "major" : "minor"}`}>{defect.percentage}%</p>
                    </div>
                    <EditButton item={defect} isCounter isDefect={isMajor ? "major" : "minor"} />
                </div>
            </div>
        )
    };

    return (

        <>

            {
                openAddModal &&
                <ModalAddNewItem
                    closeModal={() => setOpenAddModal(false)}
                    palletId={pallet.pid}
                    reportId={reportId}
                    detailName={detailName}
                    pallet={pallet}
                    isDefect
                />
            }

            <h3 className="mb-05 pallet-item">{Labels[detailName]}</h3>
            <div className="mb-2">
                {
                    pallet[detailName]?.data.map(item => (
                        <div className="flex flex-gap border-b py-01" key={item.name}>

                            <p className={ `flex-1 ${Array.isArray(item.value) && "align-up"}` }>{item.label}</p>
                            
                            <div className="flex-space-between flex-1">
                                {
                                    Array.isArray(item.value)
                                        ?
                                        item.name === "fruit_pressure"
                                            ?
                                            <div className="flex-1">
                                                {
                                                    (item.value as string[])?.map(val => (
                                                        <div key={uuidv4()} className="grid-array">
                                                            <p className="bold">{itemValor(val)} kg.</p>
                                                            <p className="text-mute">{kilosToLb(val)} lb.</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            :
                                            <div className="grid-array flex-1">
                                                {
                                                    (item.value as string[])?.map((val, g) => (
                                                        <p className="bold" key={g}>{itemValor(val)}</p>
                                                    ))
                                                }
                                            </div>
                                        : <p className="bold">{itemValor(item.value)}</p>

                                }
                                <EditButton item={item} isCounter={item.name === "samples"} />
                            </div>
                        </div>
                    ))
                }

                {
                    defects.length > 0 &&
                    <>
                        <div className="flex border-b mt-2">
                            <p className="bold flex-1">Defects</p>
                            <div className="flex flex-1">
                                <p className="span-6 defect major mt-02 font-small flex-1">Major</p>
                                <p className="span-6 defect minor mt-02 font-small flex-1">Minor</p>
                            </div>
                        </div>

                        {(minor || []).map(defect => (<Defects key={defect.name} defect={defect} isMajor={false} />))}
                        {(major || []).map(defect => (<Defects key={defect.name} defect={defect} isMajor />))}

                        <div className="flex flex-gap border-b">
                            <p className="bold flex-1">Total</p>
                            <div className="flex flex-1">
                                <p className="span-6 defect major flex-1">{totalMajor}%</p>
                                <p className="span-6 defect minor flex-1">{totalMinor}%</p>
                            </div>
                        </div>

                        <div className="flex flex-gap border-b">
                            <p className="bold flex-1">Total Defects</p>
                            <p className="bold flex-1">{totalDefects}%</p>
                        </div>
                    </>
                }

                {
                    detailName === "pallgrow" &&
                    <AddItemBtn action={() => setOpenAddModal(true)} className="mt-05" btnColor="green" />
                }
            </div>


            {
                (detailName === "pallgrow" && ([...major, ...minor]).length > 0)
                    ? <>
                        <ChartPieNew
                            pallgrow={[...major, ...minor]}
                        />
                        <ChartPieDefects
                            pallgrow={{ totalMajor, totalMinor, totalRest }}
                            nameAppreciation={pallet.grade}
                        />
                    </>
                    : <></>
            }

        </>
    )
}
