import { useEffect, useState } from "react"
import { Supplier, SupplierList } from "../interfaces/interfaces.auth"
import { useTypedSelector } from "./useTypedSelector"
import { cleanString } from "../helpers/stringHelpers"

interface Props{
    team: string | null
    reportSupplier: string | null | undefined
    mainDataSupplier: string
}

export const useFindSuppliers = ({team, reportSupplier, mainDataSupplier}:Props) => {

    const { suppliers, uid } = useTypedSelector(state => state.auth)

    const [supplierList, setSupplierList] = useState<SupplierList | undefined>(undefined)
    const [supplier, setSupplier] = useState<Supplier | undefined>(undefined)

    useEffect(() => {
        if (team) setSupplierList(suppliers.find(supplier => supplier.id === team))
        else setSupplierList(suppliers.find(supplier => supplier.id === uid))

    }, [team, suppliers, uid])

    useEffect(() => {
        const matchSupplier = 
        reportSupplier ? supplierList?.suppliers.find( supp => supp._id === reportSupplier)
                       : supplierList?.suppliers.find( supp => cleanString(supp.name) === cleanString(mainDataSupplier || ""))

        if (matchSupplier) setSupplier(matchSupplier)
        else setSupplier(undefined)

    }, [supplierList, reportSupplier, mainDataSupplier])

  return {
    supplierList,
    supplier
  }
}
