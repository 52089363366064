import { scoreComments } from "../../data/comments"
import { InitialPallets } from "../../interfaces/intakes.reports"
import { ScoreNum, Status } from "../../interfaces/interfaces"

interface Props {
    setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>
    pallet: InitialPallets
}

export const Qualify = ({ setPallets, pallet }:Props) => {

    const handleSelect = (e:React.ChangeEvent<HTMLSelectElement>, item:Status ) => {
        setPallets( pallets => pallets.map( pall => {
            if (pall.pid === pallet.pid) {
                return {
                    ...pall,
                    [item]: e.target.value,
                    comments: item === "score" ? scoreComments(e.target.value as ScoreNum) : pall.comments
                }
            }
            return pall
        }))
    }


    return (

        <>
            {
                pallet.grade &&
                <div className="score-wraper mb-05">
                    <p>QC Appreciation</p>
                    <select onChange={(e) => handleSelect(e, 'grade')} value={ pallet.grade || "0" } name="grade-qualify">
                        <option value="0">Select a Grade</option>
                        <option value="1">Industry</option>
                        <option value="2">Borderline CAT 2</option>
                        <option value="3">CAT 2</option>
                        <option value="4">Borderline CAT 1</option>
                        <option value="5">CAT 1</option>
                        <option value="6">Extra</option>
                    </select>
                </div>
            }

            {
                pallet.action &&
                <div className="score-wraper mb-05">
                    <p>Suggested commercial action</p>
                    <select onChange={(e) => handleSelect(e, 'action')} value={ pallet.action || "0"} name="action-qualify">
                        <option value="0">Select an Action</option>
                        <option value="1">Dump</option>
                        <option value="2">Rejected</option>
                        <option value="3">Hold</option>
                        <option value="4">Priority for Departure</option>
                        <option value="5">Suitable for QC check/REPACK</option>
                        <option value="6">Suitable for departure</option>
                        <option value="7">Suitable for Storage</option>
                    </select>
                </div>
            }

            {
                pallet.score &&
                <div className="score-wraper mb-05">
                    <p>Score</p>
                    <select onChange={(e) => handleSelect(e, 'score')} value={ pallet.score || "0"} name="score-qualify">
                        <option value="0">Select a Score</option>
                        <option value="1">1 - Bad</option>
                        <option value="2">2 - Insufficient</option>
                        <option value="3">3 - Fair Quality</option>
                        <option value="4">4 - Acceptable Quality</option>
                        <option value="5">5 - Good Quality</option>
                    </select>
                </div>
            }


        </ >

    )
}
