import ButtonSubmit from '../ui/ButtonSubmit'
import { useNavigate } from 'react-router-dom';
import { InitialPallets, InitialReport, MainData } from '../../interfaces/intakes.reports';
import toast from 'react-hot-toast';
import { formatSplit, totalSamples } from '../../helpers/formatSplit'
import { palletData } from '../../data/pallet-steps'
import { Fruit } from '../../interfaces/interfaces';
import { useFetchInitialReport } from '../../api/useInitialReport';

interface Props {
    mainData: MainData
    team: string | null
    supplier: string | null
    fruit: Fruit | null
}

const CreateButtonReport = ({ mainData, team, supplier, fruit }: Props) => {

    const navigate = useNavigate();
    const { mutate, isLoading } = useFetchInitialReport()

    const saveInitialReport = () => {

        if (!mainData?.pallet_ref) return toast.error("Please add a pallet reference")

        let fetchPallets: InitialPallets[] = []

        if (+mainData?.total_pallets >= 1 && +mainData?.total_pallets <= 10) {
            for (let index = 0; index < Number(mainData?.total_pallets); index++) {
                fetchPallets.push(palletData(
                    fruit as Fruit,
                    (totalSamples(mainData?.format) || +mainData?.samples || 1),
                    (formatSplit(mainData?.format) || 1),
                    index + 1
                ))
            }
        } else {
            fetchPallets.push(palletData(
                fruit as Fruit,
                (totalSamples(mainData?.format) || +mainData?.samples || 1),
                (formatSplit(mainData?.format) || 1),
                1
            ))
        }

        const fetchData: InitialReport = {
            mainData,
            fruit: fruit as Fruit,
            supplier,
            team,
            pallets: fetchPallets
        }

        mutate(fetchData, {
            onSuccess: async (data) => {
                navigate(`/create-report/${data.reportId}`)
            },
            onError: () => toast.error("Something went wrong, please try again later")
        })
    };

    return (
        <>
            <ButtonSubmit
                center
                title='Start Report'
                action={saveInitialReport}
                color="green"
                isLoading={isLoading}
            />
        </>
    )
}

export default CreateButtonReport