import { DetailObject } from "../interfaces/intakes.reports"
import { Fruit } from "../interfaces/interfaces"

export const dataPallgrow = [
    "weight_10",
    "format_gr",
    "samples"
]

export const pallgrowDefects = (fruit: Fruit, defect: "minor" | "major"): DetailObject[] => {
    if (fruit === "pears") { return defect === "minor" ? pallgrowPears.minor : pallgrowPears.major }
    else if (fruit === "apples") { return defect === "minor" ? pallgrowApples.minor : pallgrowApples.major }
    else if (fruit === "cherries") { return defect === "minor" ? pallgrowCherries.minor : pallgrowCherries.major }
    else if (fruit === "blueberries") { return defect === "minor" ? pallgrowBlueberries.minor : pallgrowBlueberries.major }
    else if (fruit === "blackberries") { return defect === "minor" ? pallgrowBlackberries.minor : pallgrowBlackberries.major }
    else if (fruit === "raspberries") { return defect === "minor" ? pallgrowRaspberries.minor : pallgrowRaspberries.major }
    else if (fruit === "strawberries") { return defect === "minor" ? pallgrowStrawberries.minor : pallgrowStrawberries.major }
    else if (fruit === "red currants") { return defect === "minor" ? pallgrowRedCurrants.minor : pallgrowRedCurrants.major }
    else { return defect === "minor" ? pallgrowBerries.minor : pallgrowBerries.major }
}

export const pallgrowBerries = {
    minor: [
        //Minor Defects
        { check: false, label: "Compression damage", name: "compression_damage", value: "0" },
        { check: false, label: "Damage", name: "damage", value: "0" },
        { check: false, label: "Dehydration", name: "dehydration", value: "0" },
        { check: false, label: "Misformed", name: "misformed", value: "0" },
        { check: false, label: "Sensitive", name: "sensitive", value: "0" },
        { check: false, label: "Undersized", name: "undersized", value: "0" },
        { check: false, label: "Unripe", name: "unripe", value: "0" },
        { check: false, label: "Wet/Open Berries", name: "wet_open", value: "0" },
    ],
    major: [
        //Major Defects
        { check: false, label: "Crushed", name: "crushed", value: "0" },
        { check: false, label: "Mold", name: "mold", value: "0" },
        { check: false, label: "Mold hotspot", name: "mold_hotspot", value: "0" },
        { check: false, label: "Decay", name: "decay", value: "0" },
        { check: false, label: "Juicing/Exudated berry", name: "juicing_exudated_berry", value: "0" },
        { check: false, label: "Juicing", name: "juicing", value: "0" },
        { check: false, label: "Insect damage", name: "insect_damage", value: "0" },
        { check: false, label: "Insect presence", name: "insect_presence", value: "0" },
        { check: false, label: "Leaking", name: "leaking", value: "0" },
        { check: false, label: "Overripe", name: "overripe", value: "0" },
        { check: false, label: "Rot", name: "rot", value: "0" },
        { check: false, label: "Soft", name: "soft", value: "0" },
        { check: false, label: "Split", name: "split", value: "0" },
    ]
}

export const pallgrowBlueberries = {
    minor: [
        //Minor Defects
        { check: false, label: "Sensitive", name: "sensitive", value: "0" },
        { check: false, label: "Bruising", name: "bruising", value: "0" },
        { check: false, label: "Compression damage", name: "compression_damage", value: "0" },
        { check: false, label: "Russet/Scar", name: "russet_scar", value: "0" },
        { check: false, label: "Mechanical damage", name: "mechanical_damage", value: "0" },
        { check: false, label: "Dehydration", name: "dehydration", value: "0" },
        { check: false, label: "Floral remains", name: "floral_remains", value: "0" },
        { check: false, label: "Grub presence", name: "grub_presence", value: "0" },
        { check: false, label: "Hail damage", name: "hail_damage", value: "0" },
        { check: false, label: "Pulled stem", name: "pulled_stem", value: "0" },
        { check: false, label: "Pruineless", name: "pruineless", value: "0" },
        { check: false, label: "Underripe green", name: "underripe_green", value: "0" },
        { check: false, label: "Underripe red", name: "underripe_red", value: "0" },
        { check: false, label: "Undersized", name: "undersized", value: "0" },
        { check: false, label: "Stems", name: "stems", value: "0" },
        { check: false, label: "Insect damage", name: "insect_damage", value: "0" },
    ],
    major: [
        //Major Defects
        { check: false, label: "Mold hotspot", name: "mold_hotspot", value: "0" },
        { check: false, label: "Mold", name: "mold", value: "0" },
        { check: false, label: "Rot", name: "rot", value: "0" },
        { check: false, label: "Decay", name: "decay", value: "0" },
        { check: false, label: "Soft", name: "soft", value: "0" },
        { check: false, label: "Juicing/Exudated berry", name: "juicing_exudated_berry", value: "0" },
        { check: false, label: "Leaking", name: "leaking", value: "0" },
        { check: false, label: "Wet fruit", name: "wet_fruit", value: "0" },
        { check: false, label: "Crushed", name: "crushed", value: "0" },
        { check: false, label: "Cuts", name: "cuts", value: "0" },
        { check: false, label: "Wounds", name: "wounds", value: "0" },
        { check: false, label: "Freezing damage", name: "freezing_damage", value: "0" },
        { check: false, label: "Insect presence", name: "insect_presence", value: "0" },
        { check: false, label: "Overripe", name: "overripe", value: "0" },
        { check: false, label: "Shriveling", name: "shriveling", value: "0" },
    ]
}

export const pallgrowRaspberries = {
    minor: [
        //Minor Defects
        { check: false, label: "Sensitive", name: "sensitive", value: "0" },
        { check: false, label: "Darker fruit", name: "darker_fruit", value: "0" },
        { check: false, label: "Compression damage", name: "compression_damage", value: "0" },
        { check: false, label: "Crumbled berries", name: "crumbled_berries", value: "0" },
        { check: false, label: "Dehydration", name: "dehydration", value: "0" },
        { check: false, label: "Grub presence", name: "grub_presence", value: "0" },
        { check: false, label: "Misformed", name: "misformed", value: "0" },
        { check: false, label: "Residue/Dirt", name: "residue_dirt", value: "0" },
        { check: false, label: "Scars", name: "scars", value: "0" },
        { check: false, label: "Undersized", name: "undersized", value: "0" },
        { check: false, label: "Unripe", name: "unripe", value: "0" },
        { check: false, label: "Water cells", name: "water_cells", value: "0" },
        { check: false, label: "Wounds", name: "wounds", value: "0" },
        { check: false, label: "Sun burn", name: "sun_burn", value: "0" },
    ],
    major: [
        //Major Defects
        { check: false, label: "Mold hotspot", name: "mold_hotspot", value: "0" },
        { check: false, label: "Mold", name: "mold", value: "0" },
        { check: false, label: "Decay", name: "decay", value: "0" },
        { check: false, label: "Rot hotspot", name: "rot_hotspot", value: "0" },
        { check: false, label: "Rot", name: "rot", value: "0" },
        { check: false, label: "Leaking", name: "leaking", value: "0" },
        { check: false, label: "Juicing/Exudated berry", name: "juicing_exudated_berry", value: "0" },
        { check: false, label: "Wet fruit", name: "wet_fruit", value: "0" },
        { check: false, label: "Crushed", name: "crushed", value: "0" },
        { check: false, label: "Overripe", name: "overripe", value: "0" },
        { check: false, label: "Soft", name: "soft", value: "0" },
        { check: false, label: "Freezing damage", name: "freezing_damage", value: "0" },
        { check: false, label: "Insect damage", name: "insect_damage", value: "0" },
        { check: false, label: "Insect presence", name: "insect_presence", value: "0" },
        { check: false, label: "Shriveling", name: "shriveling", value: "0" },
        { check: false, label: "Yellow rust", name: "yellow_rust", value: "0" },
        { check: false, label: "White cells", name: "white_cells", value: "0" },
    ]
}

export const pallgrowStrawberries = {
    minor: [
        //Minor Defects
        { check: false, label: "Small bruises", name: "small_bruises", value: "0" },
        { check: false, label: "Dry bruises", name: "dry_bruises", value: "0" },
        { check: false, label: "Compression damage", name: "compression_damage", value: "0" },
        { check: false, label: "Crown damage", name: "crown_damage", value: "0" },
        { check: false, label: "Dehydration", name: "dehydration", value: "0" },
        { check: false, label: "Dust/Sand", name: "dust_sand", value: "0" },
        { check: false, label: "Flower remains", name: "flower_remains", value: "0" },
        { check: false, label: "Misformed", name: "misformed", value: "0" },
        { check: false, label: "Split", name: "split", value: "0" },
        { check: false, label: "Unripe", name: "unripe", value: "0" },
        { check: false, label: "Undersized", name: "undersized", value: "0" },
        { check: false, label: "Wet/Open Berries", name: "wet_open_berries", value: "0" },
        { check: false, label: "Wounds", name: "wounds", value: "0" },
        { check: false, label: "Insect damage", name: "insect_damage", value: "0" },
        { check: false, label: "No Calyx", name: "no_calyx", value: "0" },
        { check: false, label: "Mechanical damage", name: "mechanical_damage", value: "0" },
        { check: false, label: "Bronzing", name: "bronzing", value: "0" },
        { check: false, label: "Dry Calyx", name: "dry_calyx", value: "0" },
    ],
    major: [
        //Major Defects
        { check: false, label: "Soft", name: "soft", value: "0" },
        { check: false, label: "Heavy bruises", name: "heavy_bruises", value: "0" },
        { check: false, label: "Wet bruises", name: "wet bruises", value: "0" },
        { check: false, label: "Wet fruit", name: "wet_fruit", value: "0" },
        { check: false, label: "Decay", name: "decay", value: "0" },
        { check: false, label: "Rot", name: "rot", value: "0" },
        { check: false, label: "Rot under the crown", name: "rot_under_crown", value: "0" },
        { check: false, label: "Mold", name: "mold", value: "0" },
        { check: false, label: "Moldy crown", name: "moldy_crown", value: "0" },
        { check: false, label: "Crown mold", name: "crown_mold", value: "0" },
        { check: false, label: "Cracking", name: "cracking", value: "0" },
        { check: false, label: "Crushed", name: "crushed", value: "0" },
        { check: false, label: "Cuts", name: "cuts", value: "0" },
        { check: false, label: "Freezing damage", name: "freezing_damage", value: "0" },
        { check: false, label: "Insect presence", name: "insect_presence", value: "0" },
        { check: false, label: "Shriveling", name: "shriveling", value: "0" },
        { check: false, label: "Overripe", name: "overripe", value: "0" },
    ]
}

export const pallgrowBlackberries = {
    minor: [
        //Minor Defects
        { check: false, label: "Sensitive", name: "sensitive", value: "0" },
        { check: false, label: "Compression damage", name: "compression_damage", value: "0" },
        { check: false, label: "Deformed", name: "deformed", value: "0" },
        { check: false, label: "Dry cells", name: "dry_cells", value: "0" },
        { check: false, label: "Dehydration", name: "dehydration", value: "0" },
        { check: false, label: "Grub presence", name: "grub_presence", value: "0" },
        { check: false, label: "Red cells", name: "red_cells", value: "0" },
        { check: false, label: "Residue/Dirt", name: "residue_dirt", value: "0" },
        { check: false, label: "Scars wounds", name: "scars_wounds", value: "0" },
        { check: false, label: "Undersized", name: "undersized", value: "0" },
        { check: false, label: "Unripe", name: "unripe", value: "0" },
        { check: false, label: "Wounds", name: "wounds", value: "0" },
        { check: false, label: "Sun burn", name: "sun_burn", value: "0" },
    ],
    major: [

        //Major Defects
        { check: false, label: "Wet fruit", name: "wet_fruit", value: "0" },
        { check: false, label: "Soft", name: "soft", value: "0" },
        { check: false, label: "Mold hotspot", name: "mold_hotspot", value: "0" },
        { check: false, label: "Mold", name: "mold", value: "0" },
        { check: false, label: "Decay", name: "decay", value: "0" },
        { check: false, label: "Rot hotspot", name: "rot_hotspot", value: "0" },
        { check: false, label: "Rot", name: "rot", value: "0" },
        { check: false, label: "Leaking", name: "leaking", value: "0" },
        { check: false, label: "Juicing/Exudated berry", name: "juicing_exudated_berry", value: "0" },
        { check: false, label: "Crushed", name: "crushed", value: "0" },
        { check: false, label: "Freezing damage", name: "freezing_damage", value: "0" },
        { check: false, label: "Insect damage", name: "insect_damage", value: "0" },
        { check: false, label: "Insect presence", name: "insect_presence", value: "0" },
        { check: false, label: "Overripe", name: "overripe", value: "0" },
        { check: false, label: "Yellow rust", name: "yellow_rust", value: "0" },
        { check: false, label: "Shriveling", name: "shriveling", value: "0" }
    ]
}

export const pallgrowRedCurrants = {
    minor: [
        //Minor Defects
        { check: false, label: "Bruises", name: "bruises", value: "0" },
        { check: false, label: "Immature", name: "immature", value: "0" },
        { check: false, label: "Moderate stem dehydration", name: "moderate_stem_dehydration", value: "0" },
        { check: false, label: "Russet", name: "russet", value: "0" },
        { check: false, label: "Mechanical damage", name: "mechanical_damage", value: "0" },
    ],
    major: [

        //Major Defects
        { check: false, label: "Crushed", name: "crushed", value: "0" },
        { check: false, label: "Cuts", name: "cuts", value: "0" },
        { check: false, label: "Decay", name: "decay", value: "0" },
        { check: false, label: "Freezing damage", name: "freezing_damage", value: "0" },
        { check: false, label: "Inconsistent brunch", name: "inconsistent_brunch", value: "0" },
        { check: false, label: "Insect damage", name: "insect_damage", value: "0" },
        { check: false, label: "Insect presence", name: "insect_presence", value: "0" },
        { check: false, label: "Mold", name: "mold", value: "0" },
        { check: false, label: "Overripe", name: "overripe", value: "0" },
        { check: false, label: "Rot", name: "rot", value: "0" },
        { check: false, label: "Sensitive", name: "sensitive", value: "0" },
        { check: false, label: "Severe stem dehydration", name: "severe_stem_dehydration", value: "0" },
        { check: false, label: "Shriveling", name: "shriveling", value: "0" },
        { check: false, label: "Soft", name: "soft", value: "0" },
        { check: false, label: "Splits", name: "splits", value: "0" },
        { check: false, label: "Wet fruit", name: "wet_fruit", value: "0" },
        { check: false, label: "Wet punnet", name: "wet_punnet", value: "0" },
        { check: false, label: "Wounds", name: "wounds", value: "0" },
    ]
}

export const pallgrowPears = {

    minor: [
        { check: false, label: "Mechanical Damage", name: "mechanical_damage", value: "0" },
        { check: false, label: "Miss Shape", name: "miss_shape", value: "0" },
        { check: false, label: "Russeting", name: "russeting", value: "0" },
        { check: false, label: "Scab", name: "scab", value: "0" },
        { check: false, label: "Scald", name: "scald", value: "0" },
        { check: false, label: "Sensitive", name: "sensitive", value: "0" },
        { check: false, label: "Skin Deffects", name: "skin_deffects", value: "0" }
    ],
    major: [
        { check: false, label: "Animal Damage", name: "animal_damage", value: "0" },
        { check: false, label: "Bronze Color", name: "bronze_color", value: "0" },
        { check: false, label: "Brown Heart/Cavities", name: "brown_heart_cavities", value: "0" },
        { check: false, label: "Bruising", name: "bruising", value: "0" },
        { check: false, label: "Compression damage", name: "compression_damage", value: "0" },
        { check: false, label: "Core Breakdowns", name: "core_breakdowns", value: "0" },
        { check: false, label: "Cracks", name: "cracks", value: "0" },
        { check: false, label: "Dry Crack", name: "dry_crack", value: "0" },
        { check: false, label: "Fresh Crack", name: "fresh_crack", value: "0" },
        { check: false, label: "Grittiness", name: "grittiness", value: "0" },
        { check: false, label: "Hail Damage", name: "hail_damage", value: "0" },
        { check: false, label: "Iniury of the Stalk Cavity", name: "injury_stalk_cavity", value: "0" },
        { check: false, label: "Insect", name: "insect", value: "0" },
        { check: false, label: "Insect Damage", name: "insect_damage", value: "0" },
        { check: false, label: "Rotting", name: "rotting", value: "0" },
        { check: false, label: "Severe Bronze Color", name: "severe_bronze_color", value: "0" },
        { check: false, label: "Severe Bruising", name: "severe_bruising", value: "0" },
        { check: false, label: "Severe Hail Damage", name: "severe_hail_damage", value: "0" },
        { check: false, label: "Severe Mechanical Damage", name: "severe_mechanical_damage", value: "0" },
        { check: false, label: "Severe Russeting", name: "severe_russeting", value: "0" },
        { check: false, label: "Severe Scab", name: "severe_scab", value: "0" },
        { check: false, label: "Sunburn", name: "sunburn", value: "0" },
        { check: false, label: "Undersized", name: "undersized", value: "0" },
    ]
}

export const pallgrowApples = {
    minor: [

        { check: false, label: "Bitterpit", name: "bitterpit", value: "0" },
        { check: false, label: "Brown Core", name: "brown_core", value: "0" },
        { check: false, label: "Bruising", name: "bruising", value: "0" },
        { check: false, label: "Compression damage", name: "compression_damage", value: "0" },
        { check: false, label: "Decay", name: "decay", value: "0" },
        { check: false, label: "Freezing Injury", name: "freezing_injury", value: "0" },
        { check: false, label: "Hail Damage", name: "hail_damage", value: "0" },
        { check: false, label: "Injury of the Stalk Cavity", name: "injury_stalkCavity", value: "0" },
        { check: false, label: "Insect Injuries", name: "insect_injuries", value: "0" },
        { check: false, label: "Insects", name: "insects", value: "0" },
        { check: false, label: "Internal Browning", name: "internal_browning", value: "0" },
        { check: false, label: "Lenticel", name: "lenticel", value: "0" },
        { check: false, label: "Lenticel Spots", name: "lenticel_spots", value: "0" },
        { check: false, label: "Lenticel Spots (Heavy)", name: "lenticel_spots", value: "0" },
        { check: false, label: "LTD", name: "ltd", value: "0" },
        { check: false, label: "Misshaped", name: "misshaped", value: "0" },
        { check: false, label: "Mold", name: "mold", value: "0" },
        { check: false, label: "Mold on blossom end", name: "Mold_blossomEnd", value: "0" },
        { check: false, label: "Mold on stem end", name: "Mold_stemEnd", value: "0" },
        { check: false, label: "Open Injuries", name: "open_injuries", value: "0" },
        { check: false, label: "Punctures", name: "punctures", value: "0" },
        { check: false, label: "Residue", name: "residue", value: "0" },
        { check: false, label: "Rot", name: "rot", value: "0" },
        { check: false, label: "Ruseting", name: "ruseting", value: "0" },
        { check: false, label: "Russeting", name: "russeting", value: "0" },
        { check: false, label: "Scab", name: "scab", value: "0" },
        { check: false, label: "Scald", name: "scald", value: "0" },
        { check: false, label: "Scarring", name: "scarring", value: "0" },
        { check: false, label: "Shrivelling", name: "shrivelling", value: "0" },
        { check: false, label: "Shrivelling (Heavy)", name: "shrivelling_heavy", value: "0" },
        { check: false, label: "Skin Deffects", name: "skin_deffects", value: "0" },
        { check: false, label: "Sooty Mold", name: "sooty_mold", value: "0" },
        { check: false, label: "Sunburn", name: "sunburn", value: "0" },
        { check: false, label: "Water Core", name: "water_core", value: "0" },
    ],
    major: []
}

export const pallgrowCherries = {
    minor: [
        { check: false, label: "Bruising", name: "bruising", value: "0" },
        { check: false, label: "Compression damage", name: "compression_damage", value: "0" },
        { check: false, label: "Concentric Cracking", name: "concentric_cracking", value: "0" },
        { check: false, label: "Doubles", name: "doubles", value: "0" },
        { check: false, label: "Fruit split in cavity", name: "fruit_splitCavity", value: "0" },
        { check: false, label: "Handling Damage", name: "handling_damage", value: "0" },
        { check: false, label: "Hail Damage", name: "hail_damage", value: "0" },
        { check: false, label: "Harvest Damage", name: "harvest_damage", value: "0" },
        { check: false, label: "LTD", name: "ltd", value: "0" },
        { check: false, label: "Mechanical Damage", name: "mechanical_damage", value: "0" },
        { check: false, label: "Open Injuries", name: "open_injuries", value: "0" },
        { check: false, label: "Pitting", name: "pitting", value: "0" },
        { check: false, label: "Residue", name: "residue", value: "0" },
        { check: false, label: "Ruseting", name: "ruseting", value: "0" },
        { check: false, label: "Sensitive", name: "sensitive", value: "0" },
        { check: false, label: "Skin Deffects", name: "skin_deffects", value: "0" },
        { check: false, label: "Undersized", name: "undersized", value: "0" },
        { check: false, label: "Unripe Fruit", name: "unripe_fruit", value: "0" },
        { check: false, label: "Scars", name: "Scars", value: "0" },

    ],
    major: [

        { check: false, label: "Botrytis", name: "botrytis", value: "0" },
        { check: false, label: "Chilling Injury", name: "chilling_injury", value: "0" },
        { check: false, label: "Cracked Fresh", name: "cracked_fresh", value: "0" },
        { check: false, label: "Freezing Injury", name: "freezing_injury", value: "0" },
        { check: false, label: "Pulled stems", name: "Pulled_stems", value: "0" },
        { check: false, label: "Splits", name: "Splits", value: "0" },
        { check: false, label: "Stem brownish", name: "Stem_brownish", value: "0" },
        { check: false, label: "Cuts", name: "Cuts", value: "0" },
        { check: false, label: "Mold", name: "mold", value: "0" },
        { check: false, label: "Rot", name: "rot", value: "0" },
        { check: false, label: "Decay", name: " Decay", value: "0" },
        { check: false, label: "Internal Breakdown", name: "internal_breakdown", value: "0" },
        { check: false, label: "Internal Browning", name: "internal_browning", value: "0" },
        { check: false, label: "Soft", name: "soft", value: "0" },
        { check: false, label: "Insects", name: "insects", value: "0" },
        { check: false, label: "Too Ripe", name: "ripe", value: "0" },
        { check: false, label: "Wounds", name: "Wounds", value: "0" },
        { check: false, label: "Discolored", name: "discolored", value: "0" },

    ]
}