import { v4 as uuidv4 } from 'uuid';
import ButtonSubmit from '../ui/ButtonSubmit'
import { useAddCSV } from '../../api/useIntakes'
import { useNavigate } from 'react-router-dom';
import { MainData } from '../../interfaces/intakes.reports';
import toast from 'react-hot-toast';
// import { AddImagesAll } from '../AddImagesAll'

interface Props{
    mainData: MainData
    team: string | null
}

const CreateButtonIntake = ({ mainData, team }:Props) => {

    const navigate = useNavigate();
    const { mutate } = useAddCSV()

    const handleSubmit = async () => {

        if( mainData.pallet_ref.length === 0 ) return toast.error("Please add a pallet reference")

        mutate([{
            id: uuidv4(),
            data: mainData,
            inCharge: null,
            team,
        }], {
            onSuccess: () => navigate('/intakes')
        })
    };

    return (
        <>
            <ButtonSubmit
                center
                title="Create Intake"
                action={handleSubmit}
            />
        </>
    )
}

export default CreateButtonIntake