import { useEffect, useState } from 'react'

import { itemValor } from '../../helpers/eliminarEs'
import { ModalEdit } from './ModalEdit'

import { ModalEditScore } from './ModalEditScore'
import { colorGrade } from '../../helpers/colorGrade'
import { colorAction } from '../../helpers/colorAction'
import { GrowerTable } from './GrowerTable'
import { ModalEditGrower } from './ModalEditGrower'
import { photosToShow } from '../../helpers/photosToShow'
import { PhotoGallery } from '../PhotoGallery'
import { Gallery } from '../Gallery'
import { useDeleteImage } from '../../api/useDeleteImage';
import toast from 'react-hot-toast'
import { ScoreBtn } from '../scores/ScoreBtn'
import { useOldDate } from '../../hooks/useOldDate'


export const AccordeonPreReport = ({ pallet, pid, date, i, labels, appareance, reportId, score }) => {

    const [activeAccordeon, setActiveAccordeon] = useState(true)
    const [item, setItem] = useState('score')
    const [photos, setPhotos] = useState([])
    const { oldDate } = useOldDate(date)

    const { mutateAsync } = useDeleteImage()

    /* Modal */
    const [openModal, setOpenModal] = useState(false)
    const [openGroweModal, setOpenGrowerModal] = useState(false)
    const [openModalScore, setOpenModalScore] = useState(false)

    const [editGrower, setEditGrower] = useState(undefined)
    const [editContent, setEditContent] = useState({
        reportId: "",
        pid: "",
        label: "",
        itemName: "",
        detailItem: "",
        tipo: "",
        currentVal: ""
    })

    useEffect(() => {
        setPhotos(photosToShow(pallet))
        return () => setPhotos([])
    }, [pallet])

    const activeModal = (tipo, pid, itemName, detailName, label, currentVal) => {

        setEditContent({
            reportId,
            pid,
            label,
            itemName,
            detailName,
            tipo,
            currentVal
        })

        setOpenModal(true)
    };

    const activeGrowerModal = (grower) => {
        setEditGrower({ ...grower, pid, reportId })
        setOpenGrowerModal(true)
    };

    const activeModalScore = (item) => {
        setItem(item)
        setOpenModalScore(true)
    };


    const deleteImage = async( img ) => {
        const data = {
            reportId,
            palletId: pid,
            keyName: img.key,
            keyLow: img.key_low,
            prereport:true
        }
        await mutateAsync(
            data,
            {
                onSuccess: () => toast.success("Image deleted") ,
                onError: () => toast.error("Something went wrong"),
            }
        )
    };

    return (
        <div className={`${activeAccordeon ? "accordeon show" : "accordeon"} card-accordeon p-1 mb-1`}>

            {
                openGroweModal &&
                <ModalEditGrower
                    {...editGrower}
                    closeModal={() => setOpenGrowerModal(false)}
                    prereport
                />
            }
            {
                openModal &&
                <ModalEdit
                    closeModal={() => setOpenModal(false)}
                    prereport={true}
                    {...editContent}
                />
            }
            {
                openModalScore &&
                <ModalEditScore
                    setOpenModalScore={setOpenModalScore}

                    reportId={reportId}
                    pid={pid}
                    score={score}
                    oldDate={oldDate}
                    item={item}
                    action={pallet.action}
                    grade={pallet.grade}
                    prereport
                />
            }

            <button
                className="pallet-ref mb-2 accordeon-button"
                onClick={() => setActiveAccordeon(!activeAccordeon)}
            >
                <div className="accordeon-pallet"><h3 className="mr-1">Pallet</h3> <span>{i + 1}</span></div>
                <img src="/assets/img/chevron-down.svg" alt="chevron-down" className={activeAccordeon ? "chevron-down" : "chevron-up"} />

            </button>

            <h3 className="mb-05 pallet-item">Labels</h3>
            <div className="grid-table mb-2">
                {
                    labels.map((itemLab, i) => (
                        <div className="table accordeon-table" key={i}>
                            <p>{itemLab.label}</p>
                            <strong>{itemValor(itemLab.valor, itemLab.tipe)}</strong>

                            <button
                                className="accordeon-edit"
                                onClick={() => activeModal(itemLab.tipe, pid, itemLab.name, 'labels', itemLab.label, itemLab.valor)}
                            >
                                <img src="/assets/img/edit-grey.svg" alt="edit-item" />
                            </button>
                        </div>
                    ))
                }
            </div>


            <h3 className="mb-05 pallet-item">Appearance</h3>
            <div className="grid-table mb-2">
                {
                    appareance.map((itemApp, i) => (
                        <div className="table accordeon-table" key={i}>
                            <p>{itemApp.label}</p>

                            <div className="grid">
                                {
                                    itemApp.tipe !== "arrays"
                                        ? <strong className="span-6">{itemValor(itemApp.valor)}</strong>
                                        : <>
                                            {
                                                itemApp.valor.map((item, g) => (
                                                    <strong className="span-6" key={g}>{itemValor(item)}</strong>
                                                ))
                                            }
                                        </>
                                }
                                <button
                                    className="accordeon-edit"
                                    onClick={() => activeModal(itemApp.tipe, pid, itemApp.name, 'appareance', itemApp.label, itemApp.valor)}
                                >
                                    <img src="../../assets/img/edit-grey.svg" alt="edit-item" />
                                </button>
                            </div>
                        </div>
                    ))
                }
            </div>

            <div className='w-100 mt-1 mb-2'>

                <div className="score-wraper">
                    <p>Score</p>
                    <button
                        className="score-btn"
                        onClick={() => activeModalScore('score')}
                    > <ScoreBtn date={date} score={pallet.score}/>

                    </button>
                </div>

                <div className='score-wraper py-1'>
                    <p>QC Appreciation</p>
                    <button
                        className="score-btn"
                        onClick={() => activeModalScore('grade')}
                    > {colorGrade(pallet.grade)}

                    </button>
                </div>

                <div className='score-wraper wraper-up pb-05'>
                    <p>Suggested commercial action</p>
                    <button
                        className="score-btn"
                        onClick={() => activeModalScore('action')}
                    > {colorAction(pallet.action)}

                    </button>
                </div>
            </div>


            {
                pallet.addGrower &&
                <div className='accordeon-table mb-2'>

                    <GrowerTable addGrower={pallet.addGrower} />
                    <button
                        className="accordeon-edit custom-edit"
                        onClick={() => activeGrowerModal(pallet.addGrower)}
                    >
                        <img src="/assets/img/edit-grey.svg" alt="edit-item" />
                    </button>
                </div>
            }

            {
                photos.length > 0 &&
                <div className='mt-3'>
                    <PhotoGallery
                        photos={photos}
                        pid={pid}
                        reportId={reportId}
                        prereport
                    />
                </div>
            }


            {
                pallet.images.length > 0 &&
                <Gallery images={pallet.images} deleteImage={deleteImage}/>
            }
        </div>

    )
}