interface Props{
    size?: "xxs" | "xs" | "s" | "m" | "l" 
    color?: "white" | "green" | "blue" | "red"
    center?: boolean
    className?: string
}

export const SpinnerCustom = ({size="xs", color="green", center=false, className=""}:Props) => {
  return (
    <div className={`${center ?"flex-center" :"flex" } ${className}`}>
        <div className={`spinner-custom ${size} ${color}`}></div>
    </div>
  )
}
