
interface Props {
    children: React.ReactNode
    closeModal: () => void
    action?: () => void
    buttonName?: string
    disabled?: boolean
    isLoading?: boolean
    noOverflow?: boolean
}

export const Modal = ({ children, closeModal, action, buttonName = "Add", noOverflow=false, disabled = false, isLoading = false }: Props) => {

    return (
        <div className="modal">

            <div className={`modal__card ${noOverflow ?"no-overflow":""}`}>
                <div className="modal__content">

                    {children}

                    {
                        !isLoading &&
                        <div className="modal__botones mt-2">
                            <button
                                className="modal-btn btn-close mr-05"
                                onClick={() => closeModal()}
                            > Cancel
                            </button>
                            {
                                action &&
                                <button
                                    disabled={disabled}
                                    className={`modal-btn ml-05 ${disabled ? 'btn-disabled' : 'btn-add'}`}
                                    onClick={() => action()}
                                > {buttonName}
                                </button>
                            }
                        </div>
                    }

                </div>
            </div>



        </div>
    )
}
