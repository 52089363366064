import { useCallback, useState } from "react";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { DocuPdf } from "../components/pdf/DocuPdf";

export const useDownloadPdf = () => {

    const [isGenerating, setIsGenerating] = useState(false)
    const [isFinish, setIsFinish] = useState(false)

    const generatePDF = useCallback(
        async (allData, fileName) => {
            setIsGenerating(true)
            const blob = await pdf((
                <DocuPdf allData={allData} />
            )).toBlob();
            saveAs(blob, fileName);
            setIsGenerating(false)
            setIsFinish(true)
        },
      [],
    )
    ;

    return {
        isGenerating,
        isFinish,
        generatePDF
    }
}
