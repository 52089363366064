import { useState } from 'react'
import { ModalBtn } from './ModalBtn'
import { useCreateSupplier } from '../../api/useSupplier'
import { Supplier, SupplierList } from '../../interfaces/interfaces.auth'
import { useDispatch } from 'react-redux'
import { startChecking } from '../../store/asyncMethods/authMethods'
import { hasLength } from '../../helpers/validators'
import toast from 'react-hot-toast'

interface Props {
    target: SupplierList
    closeModal: () => void
}

export const ModalAddSupplier = ({ closeModal, target }: Props) => {

    const dispatch = useDispatch()

    const [ref, setRef] = useState("")
    const [name, setName] = useState("")

    const { mutate, isLoading } = useCreateSupplier()

    const addSupplier = () => {

        const cleanRef = ref.trim()
        const cleanName = name.trim()

        if(target.suppliers.find(sup => sup.ref.trim() === cleanRef || sup.name.trim() === cleanName)) return toast.error("Supplier already exists")

        if(!hasLength(ref) || !hasLength(name)) return toast.error("Please fill in all fields")
        if(name.length < 3) return toast.error("Name should be at least 3 characters")
        if( ref.length < 2 || ref.length > 6) return toast.error("Ref should be between 2 and 5 characters")
        
        mutate({
            id: target.id,
            model: target.model,
            ref: cleanRef,
            name: cleanName
        }, {
            onSuccess: () => {
                dispatch(startChecking())
                closeModal()
            },
            onError: (error) => console.log(error)
        })
    };

    return (

        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">

                    <p className='font-normal mb-02 ml-02'>Ref</p>
                    <input
                        type="text"
                        value={ref}
                        maxLength={6}
                        onChange={(e) => setRef(e.target.value.toUpperCase())}
                    />

                    <>
                        <p className='mt-2 font-normal mb-02 ml-02'>Name</p>
                        <input
                            className="mb-1"
                            type="text"
                            value={name}
                            maxLength={40}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </>

                    <ModalBtn
                        action={addSupplier}
                        closeModal={closeModal}
                        actionTitle='Add Supplier'
                        isLoading={isLoading}
                    />

                </div>
            </div>
        </div>

    )
}
