import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useTempRepack } from '../../api/useRepackTemp';
import { Charging } from '../ui/Charging';
import { RepackContext } from '../../context/RepackContext';
import { AccordionCard } from '../cards/AccordionCard';
import { BatchInfo } from '../ui/BatchInfo';
import { MainData } from './Inputs/MainData';
import { RepackPrePost } from './Repack/RepackPrePost';
import { RepackPrePostEdit } from './Repack/RepackPrePostEdit';

export const CreateRepack = () => {

    // const navigate = useNavigate()
    // const { path } = useParams<string>();
    // const { create, id } = queryString.parse(path!) as unknown as QueryParams;

    // useEffect(() => {
    //     if (!id || !["repack", "report"].includes(create)) {
    //         navigate("/")
    //         return
    //     }
    // }, [create, id])

    const navigate = useNavigate()
    const { id } = useParams<string>();


    const { data, isLoading, isError } = useTempRepack(id!)
    const { mainData, prePostRepack, handleMain, getInitialData } = useContext(RepackContext)

    useEffect(() => {
        if (data) { getInitialData(data) }
        if (data && !data.unfinished) navigate("/")
    }, [data])

    if (isError || !data) {
        navigate("/")
        return
    }

    return (
        <div className="content intake">
            <main className="container">
                {
                    isLoading
                        ? <Charging />
                        :
                        <>
                            <h2 className="main-title mb-2">Repack</h2>

                            <AccordionCard
                                afterTitle={
                                    <div className="flex ml-1 split-badge" >
                                        <p className="font-small">{mainData?.pallet_ref || "--"}</p>
                                    </div>
                                }
                                title='Batch Info' className='mb-2' defaultOpen={false}
                                additionalBtn={
                                    <BatchInfo mainData={data.mainData} reportId={id!} editBtn handleMainData={handleMain} model="repack" />
                                }
                            >
                                <MainData
                                    mainData={mainData}
                                    isList
                                />
                            </AccordionCard>

                            {
                                prePostRepack &&
                                <RepackPrePostEdit
                                prePostRepack={prePostRepack}
                                />
                            }



                            {/* {
                                mainData &&
                                <CreateReportSteps
                                    mainData={mainData}
                                    fruit={fruit}
                                    reportId={id!}
                                    pallets={pallets}
                                    setPallets={setPallets}
                                />
                            } */}
                        </>
                }
            </main>

        </div>
    )
}
