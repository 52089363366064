import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';

import { useParams } from 'react-router-dom'
import { dateFormat } from '../../helpers/dateFormat'
import { AccordeonLife } from '../ui/AccordeonLife'
import { ModalDay } from '../ui/ModalDay'
import { ModalConfirmation } from '../ui/ModalConfirmation';
import toast from 'react-hot-toast';
import { useDeleteLastDay, useLifeTest } from '../../api/useLifeTest';
import { useQueryClient } from '@tanstack/react-query';
import { useRemoveLifeTest } from '../../api/useLifeTests';
import { Status } from '../ui/Status';
import { useNavigate } from 'react-router-dom';
import { ScoreBtn } from '../scores/ScoreBtn';

export const SingleLifeTest = () => {

    const { id } = useParams()
    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const { data, isLoading, isError } = useLifeTest(id)
    const { mutate: mutateRemove, isLoading: isRemoving } = useRemoveLifeTest()
    const { mutate: mutateDeleteDay, isLoading: isDeletingDay } = useDeleteLastDay()

    /* Modal */
    const [openModalDay, setOpenModalDay] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [openDayConfirmation, setOpenDayConfirmation] = useState(false)


    const deleteLastDay = async () => {
        mutateDeleteDay(id, {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['lifetest', id])
                await queryClient.invalidateQueries(['lifeTests', 1, 0])
                setOpenDayConfirmation(false)
            }
        })
    };

    const removeLife = async () => {
        mutateRemove(id, {
            onSuccess: async () => {
                navigate('/life');
                await queryClient.invalidateQueries(['lifeTests', 1, 0])
            },
            onError: () => toast.error('Something went wrong')
        })
    };

    if (isError) {
        toast.error('Something went wrong')
        navigate('/life')
    }

    if (isLoading || isRemoving) {
        return <div className="loading">Loading&#8230;</div>
    }

    return (
        <div className="content content-flex">

            <main className="container lifetest">

                {
                    openModalDay &&
                    <ModalDay
                        closeModal={() => setOpenModalDay(false)}
                        lifeTestId={id}
                        dayNum={data.test.length + 1 || 0}
                    />
                }

                {
                    openConfirmation &&
                    <ModalConfirmation
                        msg="Are you sure you want to remove this Shel Life Test?"
                        closeConfirmation={setOpenConfirmation}
                        action={(removeLife)}
                    />
                }

                {
                    openDayConfirmation &&
                    <ModalConfirmation
                        msg={`Are you sure you want to remove Day ${data.test.length}?`}
                        closeConfirmation={setOpenDayConfirmation}
                        action={(deleteLastDay)}
                    />
                }

                {
                    data &&
                    <>
                        <div className='flex-space-between mb-2'>
                            <h2 className="main-title">Shel Life Test</h2>
                            <Status
                                id={id}
                                status={data.status}
                                test={data.test}
                            />
                        </div>

                        {
                            data.reportId?.mainData &&
                            <div className='card-life mb-2'>
                                <div className='flex-space-between mb-05'>
                                    <h2 className="main-title">{data.reportId?.mainData.pallet_ref || '--'}</h2>

                                    <div>
                                        <ScoreBtn date={data.date} score={data.reportId?.score} />
                                    </div>

                                </div>
                                <div className='main-info__item'>
                                    <p className='bold'>Grower</p>
                                    <p>{data.grower || data.reportId?.mainData.grower || '--'}</p>
                                </div>
                                <div className='main-info__item'>
                                    <p className='bold'>GGN</p>
                                    <p>{data.reportId?.mainData.gln_ggn || '--'}</p>
                                </div>
                                <div className='main-info__item'>
                                    <p className='bold'>Product</p>
                                    <p>{data.reportId?.mainData.product || '--'}</p>
                                </div>
                                <div className='main-info__item'>
                                    <p className='bold'>Date</p>
                                    <p>{dateFormat(data.date) || '--'}</p>
                                </div>
                                <div className='main-info__item'>
                                    <p className='bold'>Variety</p>
                                    <p>{data.reportId?.mainData.variety || '--'}</p>
                                </div>
                            </div>
                        }
                        <div className="pallets-container life-columns">
                            {
                                data.test?.length > 0 &&
                                data.test.map((test, index) => (
                                    <AccordeonLife
                                        key={uuidv4()}
                                        lifeId={id}
                                        index={index}
                                        {...test}
                                    />
                                ))
                            }
                        </div>

                        <div className="life-btns mt-3 mb-5">
                            {
                                data.test.length > 0 &&

                                <button
                                    disabled={isDeletingDay}
                                    className="btn-add-life life-red"
                                    onClick={() => setOpenDayConfirmation(true)}
                                >
                                    <img src="/assets/img/edit-remove.svg" alt="remove-day" />
                                    <p>Delete Day {data.test.length}</p>
                                </button>

                            }

                            {
                                data.test.length < 7 &&
                                <button
                                    className="btn-add-life life-blue"
                                    onClick={() => setOpenModalDay(true)}
                                >
                                    <img src="/assets/img/new-day.svg" alt="data" />
                                    <p>Add Day {data.test.length + 1}</p>
                                </button>
                            }
                        </div>

                        <button
                            disabled={isRemoving}
                            onClick={() => setOpenConfirmation(true)}
                            className='remove-life'>Remove Life Test</button>
                    </>

                }


            </main>
        </div>
    )
}
