
import { DetailObject, InitialPallets } from '../../interfaces/intakes.reports'
import { DetailName } from '../../interfaces/interfaces'
import { ModalBtn } from '../modals/ModalBtn'
import { IoAddCircleSharp, IoRemoveCircle } from 'react-icons/io5';

interface Props {
    palletId: string
    detailName: DetailName
    closeModal: () => void
    item: DetailObject
    handleChangeArray: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void
    setPallets: React.Dispatch<React.SetStateAction<InitialPallets[]>>

}

export const ModalPressure = ({ palletId, detailName, closeModal, item, handleChangeArray, setPallets }: Props) => {

    const handleAddRest = (action: "rest" | "add") => {
        setPallets(pallets => pallets.map(pallet => {
            if (pallet.pid === palletId) {
                return {
                    ...pallet,
                    [detailName]: {
                        ...pallet[detailName],
                        data: pallet[detailName]?.data.map(inp => {
                            if (inp.name === item.name) {
                                return {
                                    ...inp,
                                    value: 
                                        action === "add"
                                        ? [...inp.value as string[], ""]
                                        : (inp.value as string[])?.length === 1 ? inp.value : (inp.value as string[])?.slice(0, -1) 
                                }
                            } return inp
                        })
                    }
                }
            }
            return pallet
        }))
    };

    return (
        <div className="modal">

            <div className="modal__card">

                {
                    <div className="modal__content">

                        <label className="modal-label mb-1">{item.label}</label>

                        <div>
                            {
                                (item.value as string[]).map((oneVal, index) => (
                                    <div key={index} className="flex flex-gap mb-05">
                                        <div className="flex">
                                            <input
                                                className='input-outline w-100'
                                                type="number"
                                                min="0"
                                                value={oneVal}
                                                onChange={(e) => handleChangeArray(e, index)}
                                                maxLength={20}
                                            />
                                            <p className='bold font-small ml-02'>kg.</p>
                                        </div>
                                        <div className='flex'>
                                            <input
                                                className='w-100'
                                                disabled
                                                type="number"
                                                min="0"
                                                value={oneVal.length === 0 ? "" : (Number(oneVal) * 2.205).toFixed(2)}
                                            />
                                            <p className='bold font-small ml-02'>lb.</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div className='flex-end mt-1'>
                            <button onClick={() => handleAddRest('rest')} ><IoRemoveCircle size={26} /></button>
                            <button onClick={() => handleAddRest('add')} className='ml-05'><IoAddCircleSharp size={26} /></button>
                        </div>


                        <ModalBtn
                            closeModal={() => closeModal()}
                        />

                    </div>
                }


            </div>



        </div>
    )
}