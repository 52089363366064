import { Text, View, Image } from "@react-pdf/renderer";
import { v4 as uuidv4 } from 'uuid';
import { PDFList } from "./PDFList";
import { ImageType, InitialPallets, Report } from "../../interfaces/intakes.reports"
import { PDFListDefects } from "./PDFListDefects";
import { PDFPage } from "./PDFPage";
import { photosToShow } from "../../helpers/photosToShow";
import { PDFPalletData } from "./PDFPalletData";
import { globalStyles } from "./DocuPdf";
import { useIsNewReport } from "../../hooks/useIsNewReport";
import { PDFListDefectsNew } from "./PDFListDefectsNew";

interface Props {
    pallet: InitialPallets
    index: number
    data: Report
}

interface ImageProps {
    image: ImageType
}

export const PDFPallets = ({ pallet, index, data }: Props) => {

    const isNewReport = useIsNewReport(data?.date)

    const ImagesBox = ({ image }: ImageProps) => (
        <View style={{ width: "33%", maxHeight: "225px", marginBottom: 4 }}>
            <Image
                src={image.imgURL || image.imgURL_low}
                style={{ width: "100%", objectFit: 'contain' }}
            />
        </View>
    )

    return (
        <>
            <PDFPage data={data} wrap={false}>
                {
                    index === 0 &&
                    <View style={[globalStyles.comments, { marginBottom: 10 }]}>
                        <Text style={[globalStyles.h2]}>General Comments</Text>
                        <Text style={{ color: '#878787', fontSize: '8.5px', lineHeight: '1.4px' }}>{data.comments}</Text>
                    </View>
                }

                <PDFPalletData index={index} pallet={pallet} date={data?.date || new Date()} isNewReport={isNewReport}/>

                <View style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <View style={{ width: '46%' }}>
                        <PDFList pallet={pallet} detailName="labels" isNewReport={isNewReport}/>
                        <View style={{ marginBottom: 15 }} />
                        <PDFList pallet={pallet} detailName={isNewReport ? "appearance" :"appareance"} isNewReport={isNewReport}/>
                    </View>
                    <View style={{ width: '46%' }}>
                        {
                            (pallet.comments && pallet.comments.length > 0) &&
                            <View style={[globalStyles.comments, { marginBottom: 15 }]}>
                                <Text style={[globalStyles.h2]}>Pallet comments</Text>
                                <Text style={{ color: '#878787', fontSize: '8.5px', lineHeight: '1.4px' }}>{pallet.comments}</Text>
                            </View>
                        }
                        
                        {
                            isNewReport
                            ? <PDFListDefectsNew pallet={pallet} data={data} />
                            : <PDFListDefects pallet={pallet} data={data} />
                        }
                        
                    </View>
                </View>
            </PDFPage>

            {
                isNewReport
                ? pallet.images.length > 0 &&
                    <PDFPage data={data}>
                        <View style={{
                            flex: 1,
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            alignItems: 'center',
                        }}>
                            {pallet.images.map(image => (<ImagesBox key={uuidv4()} image={image} />))}
                        </View>
                    </PDFPage>
                    :
                    (pallet.images.length + photosToShow(pallet).length) > 0 &&
                    <PDFPage data={data}>
                        <View style={{
                            flex: 1,
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            alignItems: 'center',
                        }}>
                            {photosToShow(pallet).map(image => (<ImagesBox key={uuidv4()} image={image.photo} />))}
                            {pallet.images.map(image => (<ImagesBox key={uuidv4()} image={image} />))}
                            {(((pallet.images.length + photosToShow(pallet).length) % 3) !== 0) && <View style={{ width: "33%" }} />}

                        </View>
                    </PDFPage>
            }
        </>
    )
}
