import { ChangeEvent, Dispatch, SetStateAction, useContext } from "react";
import MainInput from "../Inputs/MainInput";
import { jsonKeyToString } from "../../../helpers/jsonToString";
import { PrePostRepack } from "../../../interfaces/intakes.repack";
import { RepackContext } from "../../../context/RepackContext";
import { MainData } from "../Inputs/MainData";

interface Props {
    prePostRepack: [PrePostRepack, PrePostRepack]
    className?: string
}

export const RepackPrePostEdit = ({ prePostRepack, className }: Props) => {

    const { } = useContext(RepackContext)
    const [pre, post] = prePostRepack

    return (

        <div className={`grid-query ${className} grid-gap-2`}>

            <div className={`card-accordeon p-1`}>
                <h3 className="mb-1">Pre Repack</h3>
                <MainData
                    mainData={pre}
                    isList
                />
            </div>
            <div className={`card-accordeon p-1`}>
                <h3 className="mb-1">Post Repack</h3>
                <MainData
                    mainData={post}
                    isList
                />
            </div>
        </div>
    )
}
