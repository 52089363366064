import { useState } from "react"
import { ModalEditMainData } from "./ModalEditMainData"
import { MainData } from '../../interfaces/intakes.reports';
import { IoCreateOutline } from "react-icons/io5";
import { Create } from "../../interfaces/interfaces";

interface Props {
    reportId: string
    mainData: MainData
    model?: Create
    editBtn?: boolean
    handleMainData?: (mainData: MainData) => void
}

export const BatchInfo = ({ reportId, mainData, model = "report", editBtn, handleMainData }: Props) => {

    const [openMainData, setOpenMainData] = useState(false)

    return (
        <>

            {
                openMainData &&
                <ModalEditMainData
                    closeModal={() => setOpenMainData(false)}
                    reportId={reportId}
                    mainData={mainData}
                    model={model}
                    handleMainData={handleMainData}
                />
            }

            {
                editBtn
                    ?
                    <button
                        className='accordion-open-btn ml-05'
                        onClick={() => setOpenMainData(true)}
                        >
                        <IoCreateOutline size={20}/>
                    </button>
                    :
                    <button
                        className='btn-copy flex mb-1'
                        onClick={() => setOpenMainData(true)}
                    >
                        <img src="/assets/img/edit-blue.svg" alt="pdf-data" width={14} className="mr-02" />
                        <p>Edit Batch Info</p>
                    </button>
            }

        </>
    )
}