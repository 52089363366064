import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";

const getSingleLifeTest = async (id) => {
    const { data } = await qcareApi.get(`/life-test/${id}`)
    return data
};


const updateStatus = async (lifeId) => {
    const { data } = await qcareApi.put(`/life-test/status/${lifeId}`)
    return { data, id: lifeId }
}


//ADD DAY
const addDay = async ({ images, conditions, lifeTestId, temperature, date, dayNum }) => {

    const formData = new FormData();

    for (const img of images) {
        formData.append('multerLife', img)
    }

    for (const condition of conditions) {
        formData.append('conditions', condition)
    }

    formData.append('lifeTestId', lifeTestId)
    formData.append('temperature', temperature)
    formData.append('day_date', date.toString())
    formData.append('day', dayNum.toString())

    const { data } = await qcareApi.put('/life-test/add-day', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })

    return data
}

//ADD ADITIONAL IMAGE
const addLifeImage = async ({ lifeId, dayId, images }) => {

    const formData = new FormData();

    for (const img of images) {
        formData.append('multerLife', img)
    }

    formData.append('lifeId', lifeId)
    formData.append('dayId', dayId)

    const { data } = await qcareApi.put('/life-test/add-life-images', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    })
    return {data, lifeId}
}

//EDIT DAY
const editDay = async ({ lifeId, dayId, conditions, temperature }) => {

    const { data } = await qcareApi.put(`/life-test/edit-day`, {
        lifeId,
        dayId,
        conditions,
        temperature
    })
    return data
}

//DELETE IMAGE
const deleteLifeImage = async ({ lifeId, testId, key, key_low }) => {

    const { data } = await qcareApi.post(`/life-test/delete-image`, {
        lifetestId: lifeId,
        testId,
        keyName: key,
        keyLow: key_low
    })
    return {data, lifeId}

}

//DELETE LAST DAY
const deleteLastDay = async (id) => {
    const { data } = await qcareApi.put(`/life-test/delete-day/${id}`)
    return data
}



// ------------------------- HOOKS -------------------------

//OK
export const useLifeTest = (id) => {
    return useQuery(
        ['lifetest', id],
        () => getSingleLifeTest(id),
        { select: (data) => { return data?.singleLife } }
    );
}

//OK
export const useAddDay = () => {
    return useMutation(addDay)
}

//OK
export const useAddLifeImage = () => {
    const queryClient = useQueryClient()
    return useMutation(addLifeImage,{
        onSuccess: async (res) => await queryClient.invalidateQueries(['lifetest', res.lifeId])
    })
}

//OK
export const useEditDay = () => {
    return useMutation(editDay)
}

//OK
export const useUpdateStatus = () => {
    return useMutation(updateStatus)
}

//OK
export const useDeleteLifeImage = () => {
    const queryClient = useQueryClient()

    return useMutation(deleteLifeImage,{
        onSuccess: async (res) => await queryClient.invalidateQueries(['lifetest', res.lifeId])
    })
}

//OK
export const useDeleteLastDay = () => {
    return useMutation(deleteLastDay)
}
