import { mainDataValue } from '../../helpers/mainDataConvert'
import { jsonKeyToString } from '../../helpers/jsonToString'
import EmailFormBtn from './EmailFormBtn'
import { PDFMainData } from './ModalSendPDF'
import { ChangeEvent } from 'react'

interface Props{
    mainData: PDFMainData[]
    setMainData: React.Dispatch<React.SetStateAction<PDFMainData[]>>
    closeModal: () => void
    setStep: React.Dispatch<React.SetStateAction<number>>
}

export const StepTwo = ({ mainData, setMainData, setStep, closeModal }:Props) => {

    const handleCheck = (e:ChangeEvent<HTMLInputElement>, item: PDFMainData) => {
        const newMainData = mainData.map(info => {
            if (info.key === item.key) {
                return {
                    ...info,
                    check: e.target.checked
                }
            } else {
                return info
            }
        })
        setMainData(newMainData)
    }

    return (
        <>
            <h4 className='mb-2'>Select the data to display</h4>
            <div className='mb-3'>
                {
                    mainData && mainData.length > 0 &&
                    mainData.map(item => (
                        <div key={item.key} className='main-info__item mb-05'>
                            <div className='flex'>
                                <input
                                    className='check-on-off'
                                    type="checkbox"
                                    checked={item.check}
                                    onChange={(e) => handleCheck(e, item)}
                                />
                                <p className='font-normal'>{jsonKeyToString(item.key)}</p>
                            </div>

                            <p className='bold font-normal'>{mainDataValue(item.key, item.value)}</p>
                        </div>
                    ))
                }
            </div>

            <EmailFormBtn
                actionNext={() => setStep(step => step + 1)}
                actionPrevious={() => setStep(step => step - 1)}
                closeModal={closeModal}
            />
        </>
    )
}
