import {  Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import { jsonKeyToString } from "../../helpers/jsonToString";
import { mainDataValue } from "../../helpers/mainDataConvert";
import { objectToArray } from "../../helpers/objToArray";
import { Report } from "../../interfaces/intakes.reports";
import { globalStyles } from "./DocuPdf";

interface Props {
    data: Report
  }

export const PDFHeader = ({data}:Props) => {

    const { total_pallets, samples, format_gr,...rest } = data.mainData
    const mainData = [{ key: 'Date of inspection', value: new Date(data?.date || new Date()).toLocaleDateString() }, ...objectToArray(rest)]

    const isMuch = mainData.length > 20
    const chunkSize = Math.ceil(mainData.length / (isMuch ? 5 : 4))

    const result = [];
    for (let i = 0; i < mainData.length; i += chunkSize) {
        const chunk = mainData.slice(i, i + chunkSize);
        result.push(chunk);
    }

    return (
        <View fixed style={{ flexDirection: 'row', paddingBottom: 5, justifyContent: 'space-between', alignItems: 'center', borderBottomColor: 'gray', borderBottomWidth: .5 }}>
            <View style={{ flex: 1 }}>

                <Text style={globalStyles.h2}>Summary</Text>

                <View style={{ flexDirection: 'row' }}>
                    {
                        result.length > 0 &&
                        result.map((dataMain, index) => (

                            <View key={index} style={ { paddingRight: 25 }}>
                                {
                                    dataMain.map(item => (
                                        <Text key={item.key}>
                                            <Text style={[styles.key, { fontSize: isMuch ? '8px' :'9px' }]}>{jsonKeyToString(item.key) || "--"}: </Text>
                                            <Text style={[styles.textValue, { fontSize: isMuch ? '8px' :'9px' }]}>{mainDataValue(item.key, item.value) || "--"}</Text>
                                        </Text>
                                    ))
                                }
                            </View>

                        ))
                    }
                </View>
            </View>

            <Image
                src="../../assets/img/qc-logo-color.png"
                style={{ width: "60px", height: "auto" }}
            />
        </View>
    )
}

const styles = StyleSheet.create({

    key: {
        color: "gray",
    },
    textValue: {
        fontStyle: 'bold',
        color: "#353644",
    },

});