import { useState } from 'react'

import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserPic } from '../ui/UserPic'
import { ModalContact } from '../ui/ModalContact';
import { ModalEditContact } from '../ui/ModalEditContact';
import { ModalConfirmation } from '../ui/ModalConfirmation';
import { startChecking } from '../../store/asyncMethods/authMethods';
import qcareApi from '../../api/qcareApi';
import toast from 'react-hot-toast';
import { TeamCard } from './TeamCard';
import { SupplierList } from '../../interfaces/interfaces.auth';
import { useTypedSelector } from '../../hooks/useTypedSelector';
// import { Contacts } from './Contacts';

//const baseURL = process.env.REACT_APP_API_URL


export const User = () => {

    const [openUserModal, setOpenUserModal] = useState(false)
    const [openEditContact, setOpenEditContact] = useState(false)
    const [contact, setContact] = useState(undefined)

    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [currentId, setCurrentId] = useState(undefined)
    const [currentEmail, setCurrentEmail] = useState(undefined)



    const { teamsOwner, teamsUser, name, email, lastname, profile, company } = useTypedSelector(state => state.auth);



    // Remove contact ------------------------------------------------

    const dispatch = useDispatch()

    const update = () => dispatch(startChecking())

    const removeContact = async () => {

        try {
            await qcareApi.post('/auth/remove-contact', { contactId: currentId })
            toast.success("Contact removed")

        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")

        } finally {
            setOpenEditContact(false)
            update()
        }
    };

    // -----------------------------------------------------------------

    return (
        <div className="content edit">

            {
                openUserModal &&
                <ModalContact
                    setOpenUserModal={setOpenUserModal}
                    update={update}
                />
            }

            {
                openEditContact &&
                <ModalEditContact
                    theContact={contact}
                    setOpenEditContact={setOpenEditContact}
                    update={update}
                />
            }

            {/* {
                openConfirmation &&
                <ModalConfirmation
                    msg={`Are you sure you want to remove ${currentEmail}?`}
                    closeConfirmation={setOpenConfirmation}
                    action={async () => {
                        await removeContact()
                        setOpenConfirmation(false)
                    }}
                />
            } */}

            <main className="container">
                <UserPic profile={profile || "../assets/img/no-user.jpg"} editable={false} />

                <Link to="/" className="return">
                    <svg version="1.1" viewBox="0 0 200 200" >
                        <style type="text/css">
                        </style>
                        <polygon points="140.8,27.1 129.7,16 45.7,100 45.7,100 45.7,100 129.7,184 140.8,172.9 67.9,100 " />
                    </svg>
                </Link>

                <div className="mb-1 text-center">
                    <h2 className="main-title text-center pt-2">{name} {lastname.charAt(0) + "."}</h2>
                    {company && <p>{company}</p>}
                    <p className="font-normal text-mute">{email}</p>
                    <Link to="/edit-user" className="edit__profile">edit profile</Link>
                </div>

                {
                    [...teamsOwner, ...teamsUser].length > 0 &&

                    <div className='card-edit p-1 mb-2'>
                        <h4 className="mb-1 text-center">Teams</h4>
                        {
                            teamsOwner.length > 0 &&
                            teamsOwner.map(team => (
                                <TeamCard
                                    key={team._id}
                                    team={team}
                                    isAdmin={true}
                                />
                            ))
                        }
                        {
                            teamsUser.length > 0 &&
                            teamsUser.map(team => (
                                <TeamCard
                                    key={team._id}
                                    team={team}
                                    isAdmin={false}
                                />
                            ))
                        }
                    </div>
                }


                {/* {
                    <div className='card-edit p-1'>
                        <div>
                            <h4 className="text-center mb-1">Contacts</h4>
                            <div className='contacts mb-2'>

                                {
                                    contacts.length !== 0 &&
                                    <div key={uuidv4()} className="list py-02">
                                        <p className='bold'>Ref</p>
                                        <p className='bold'>Supplier</p>
                                        <p className='bold'>Contact</p>
                                        <p className='bold'>Email</p>
                                    </div>
                                }

                                {
                                    contacts.length !== 0 &&

                                    <ContactsList
                                        contacts={sortContact(contacts)}
                                        setCurrentId={setCurrentId}
                                        setCurrentEmail={setCurrentEmail}
                                        setOpenEditContact={setOpenEditContact}
                                        setContact={setContact}
                                        setOpenConfirmation={setOpenConfirmation}
                                    />
                                }

                            </div>
                            <button
                                className='edit__addcontact mt-1'
                                onClick={() => setOpenUserModal(true)}
                            >
                                <img src='./assets/img/mas-icon.svg' alt='plus-icon' />
                            </button>
                        </div>
                    </div>
                } */}


            </main>

        </div>
    );
}
