import { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { fruitList, scoreList } from '../../data/search'
import { CardReport } from '../CardReport';
import { Charging } from '../ui/Charging'
import { ModalConfirmation } from '../ui/ModalConfirmation';
import { ModalSendPDF } from '../ui/ModalSendPDF';
import { Pagination } from '../ui/Pagination';
import { SearchList } from '../ui/SearchList'
import { useRemoveReport, useReports } from '../../api/useReports';
import { SelectTeam } from '../ui/SelectTeam';
import { selectValue } from '../../helpers/eliminarEs';
import { useNavigate } from 'react-router-dom';

export const Reports = () => {

    const navigate = useNavigate();

    const [filterFruit, setFilterFruit] = useState([]);
    const [filterScore, setFilterScore] = useState([]);
    const [filterSupplier, setFilterSupplier] = useState("");
    const [filterPalletRef, setFilterPalletRef] = useState("");
    const [filterDeliveryNote, setFilterDeliveryNote] = useState("");

    const [currentId, setCurrentId] = useState(null)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [openSendPDF, setOpenSendPDF] = useState(false)

    const [page, setPage] = useState(1)
    const [toggle, setToggle] = useState(0)

    const { data, isLoading } = useReports(page, toggle)
    const { mutateAsync, isLoading: isDeleting } = useRemoveReport()

    
    useEffect(() => {
        const filterOn = localStorage.getItem('filter')
        if (filterOn) {
            navigate(filterOn)
        }
    }, [navigate])

    const handleRemove = async () => {
        await mutateAsync(currentId, {
            onError: () => {
                toast.error(("Something went wrong"))
            },
        })
        setOpenConfirmation(false)
    };
    
    const handleToggle = (e) => {
        setToggle(selectValue(e.target.value))
        setPage(1)
    };

    /* ------------------------- FILTER ------------------------- */

    const refresh = (item) => {

        switch (item) {
            case "supplier":
                setFilterSupplier("")
                break;
            case "palletRef":
                setFilterPalletRef("")
                break;
            case "deliveryNote":
                setFilterDeliveryNote("")
                break;
            default:
                break;
        }
    };

    const selectFruit = (fruit) => {
        if (filterFruit.includes(fruit)) {
            setFilterFruit(f => filterFruit.filter(fru => fru !== fruit))
        } else {
            setFilterFruit(f => [...f, fruit])
        }
    };
    const selectScore = (score) => {
        if (filterScore.includes(score)) {
            setFilterScore(f => filterScore.filter(sco => sco !== score))
        } else {
            setFilterScore(f => [...f, score])
        }
    };

    const handleFilter = async () => {

        let newQuery = ""

        if (filterPalletRef.trim().length > 0) {
            const fullWord = filterPalletRef.trim().split(" ").join("+").toLowerCase()
            newQuery += `&palletRef=${fullWord}`
        }

        if (filterDeliveryNote.trim().length > 0) {
            const fullWord = filterDeliveryNote.trim().split(" ").join("+").toLowerCase()
            newQuery += `&deliveryNote=${fullWord}`
        }

        if (filterFruit.length > 0) {
            for (const fru of filterFruit) {
                newQuery += `&fruit=${fru}`
            }
        }

        if (filterScore.length > 0) {
            for (const sco of filterScore) {
                newQuery += `&score=${sco}`
            }
        }

        if (filterSupplier.trim().length > 0) {
            const fullWord = filterSupplier.trim().split(" ").join("+").toLowerCase()
            newQuery += `&supplier=${fullWord}`
        }

        if (newQuery.trim().length === 0) {
            return toast.error("No filter selected")
        }

        localStorage.setItem('filter', `/search/page=1${newQuery}`)
        navigate(`/search/page=1${newQuery}`)
    };

    /* ------------------------- FILTER ------------------------- */

    return (
        <div className="reports content">

            {
                isLoading
                    ? <Charging />
                    :
                    <main className="container">

                        {
                            openConfirmation &&
                            <ModalConfirmation
                                msg="Are you sure you want to remove this Report?"
                                closeConfirmation={setOpenConfirmation}
                                action={() => handleRemove(currentId)}
                                isLoading={isDeleting}
                            />
                        }

                        {
                            (openSendPDF) &&
                            <ModalSendPDF
                                currentId={currentId}
                                setOpenSendPDF={setOpenSendPDF}
                            />
                        }

                        <div className="reports__title mb-2">
                            <h2 className="main-title">All Reports</h2>
                            <div className="flex">
                                <p className="font-small mr-05">Filter</p>

                                <nav className="searching">
                                    <div className="searching__container">
                                        <div className="searching__menu">

                                            <label htmlFor="search" className="filter">
                                                <img src='./assets/img/filter-icon.svg' alt='filter-icon' />
                                            </label>

                                            <input type="checkbox" name="search" id="search" />
                                            <div className="searching__list px-3" name="search">
                                                <label htmlFor="search" className="close-icon">
                                                    <img src="/assets/img/close-icon-black.svg" alt="close-icon" />
                                                    <input type="checkbox" name="search" id="search" />
                                                </label>
                                                <h2 className="mb-2 ml-05">Filter by</h2>

                                                {/* Filter by Pallet Ref */}
                                                <p className="mb-05 font-small bold ml-05">Pallet Ref.:</p>
                                                <div className="filter-input mb-2">
                                                    <input
                                                        type='text'
                                                        name='pallet-ref'
                                                        value={filterPalletRef}
                                                        onChange={(e) => setFilterPalletRef(e.target.value)}
                                                    />
                                                    <button className="ml-05"
                                                        onClick={() => refresh('palletRef')}
                                                    >
                                                        <img src='assets/img/close-icon.svg' alt="close-supplier" />
                                                    </button>
                                                </div>


                                                {/* Filter by Delivery Note */}
                                                <p className="mb-05 font-small bold ml-05">Delivery Note:</p>
                                                <div className="filter-input mb-2">
                                                    <input
                                                        type='text'
                                                        name='delivery-note'
                                                        value={filterDeliveryNote}
                                                        onChange={(e) => setFilterDeliveryNote(e.target.value)}
                                                    />
                                                    <button className="ml-05"
                                                        onClick={() => refresh('deliveryNote')}
                                                    >
                                                        <img src='assets/img/close-icon.svg' alt="close-supplier" />
                                                    </button>
                                                </div>


                                                {/* Filter by Product */}
                                                <p className="mb-05 font-small bold ml-05">Product:</p>
                                                <div className="filter-grid mb-2">
                                                    {
                                                        fruitList.map((fru, index) => (
                                                            <SearchList
                                                                key={index}
                                                                itemName={fru}
                                                                selectFruit={selectFruit}
                                                            // filterActive={filterActive}
                                                            />
                                                        ))
                                                    }
                                                </div>

                                                <p className="mb-05 font-small bold ml-05">Score:</p>
                                                <div className="filter-grid mb-2">
                                                    {
                                                        scoreList.map((score, index) => (
                                                            <SearchList
                                                                key={index}
                                                                itemName={score}
                                                                selectFruit={selectScore}
                                                            />
                                                        ))
                                                    }
                                                </div>

                                                {/* Filter by Supplier */}
                                                <p className="mb-05 font-small bold ml-05">Supplier:</p>
                                                <div className="filter-input mb-2">
                                                    <input
                                                        type='text'
                                                        name='supplier'
                                                        value={filterSupplier}
                                                        onChange={(e) => setFilterSupplier(e.target.value)}
                                                    />
                                                    <button className="ml-05"
                                                        onClick={() => refresh('supplier')}
                                                    >
                                                        <img src='assets/img/close-icon.svg' alt="close-supplier" />
                                                    </button>
                                                </div>


                                                {/* Search Button */}
                                                <button
                                                    className="mt-2 btn-primary outline shadow"
                                                    onClick={handleFilter}
                                                >Search
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </nav>

                            </div>
                        </div>

                        <SelectTeam
                            toggle={toggle}
                            handleToggle={handleToggle}
                        />

                        {
                            data &&
                                data?.reports?.length === 0
                                ? <p className="text-center">No reports</p>
                                :
                                <div>
                                    <div className="reports__list">
                                        {
                                            data?.reports?.map(report =>
                                            (
                                                <CardReport
                                                    key={report._id}
                                                    setOpenConfirmation={setOpenConfirmation}
                                                    setCurrentId={setCurrentId}
                                                    setOpenSendPDF={setOpenSendPDF}
                                                    {...report}
                                                />
                                            )
                                            )
                                        }
                                    </div>

                                    {
                                        data.totalPages && data.totalPages > 1 &&
                                        <Pagination
                                            totalPages={data.totalPages}
                                            page={page}
                                            setPage={setPage}
                                        />
                                    }
                                </div>
                        }
                    </main>
            }


        </div>
    );
}
