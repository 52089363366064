import { useState } from 'react'
import toast from 'react-hot-toast';

import { useEditStatus } from '../../api/useEditScore';
import { Charging } from './Charging';
import { InitialPallets } from '../../interfaces/intakes.reports';
import { ActionNum, GradeNum, ScoreNum, Status } from '../../interfaces/interfaces';
import { ModalBtn } from '../modals/ModalBtn';

interface Props {
    closeModal: () => void
    reportId: string
    pallet: InitialPallets
    status: Status
}

export const ModalEditStatus = ({ closeModal, reportId, pallet, status = "score" }: Props) => {

    const [newValue, setNewValue] = useState<ScoreNum | GradeNum | ActionNum>(pallet[status])

    const { mutate, isLoading } = useEditStatus()

    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNewValue(e.target.value as ScoreNum | GradeNum | ActionNum)
    }

    const sendEditItem = async () => {

        if (pallet[status] === newValue) return closeModal()

        mutate({ reportId, palletId: pallet.pid, status, value: newValue }, {
            onSuccess: async () => {
                toast.success('Edited successfully')
                closeModal()
            },
            onError: () => toast.error('Something went wrong'),
        })
    };

    return (
        <div className="modal">

            <div className="modal__card">
                {
                    isLoading
                        ? <Charging />
                        :
                        <div className="modal__content pallet-comments">

                            {
                                status === 'score' &&
                                <>
                                    <label className="modal-label mb-2">Score</label>
                                    <select onChange={(e) => handleSelect(e)} value={newValue}>
                                        <option value="0">Select a Score</option>
                                        <option value="1">1 - Bad</option>
                                        <option value="2">2 - Insufficient</option>
                                        <option value="3">3 - Fair Quality</option>
                                        <option value="4">4 - Acceptable Quality</option>
                                        <option value="5">5 - Good Quality</option>
                                    </select>

                                </>
                            }
                            {
                                status === 'grade' &&
                                <>
                                    <label className="modal-label mb-2">QC Appreciation</label>

                                    <select onChange={(e) => handleSelect(e)} value={newValue}>
                                        <option value="0">Select a Grade</option>
                                        <option value="1">Industry</option>
                                        <option value="2">Borderline CAT 2</option>
                                        <option value="3">CAT 2</option>
                                        <option value="4">Borderline CAT 1</option>
                                        <option value="5">CAT 1</option>
                                        <option value="6">Extra</option>
                                    </select>
                                </>
                            }

                            {
                                status === 'action' &&
                                <>
                                    <label className="modal-label mb-2">Action</label>

                                    <select onChange={(e) => handleSelect(e)} value={newValue}>
                                        <option value="0">Select an Action</option>
                                        <option value="1">Dump</option>
                                        <option value="2">Rejected</option>
                                        <option value="3">Hold</option>
                                        <option value="4">Priority for Departure</option>
                                        <option value="5">Suitable for QC check/REPACK</option>
                                        <option value="6">Suitable for departure</option>
                                        <option value="7">Suitable for Storage</option>
                                    </select>
                                </>

                            }

                            <ModalBtn
                                disabled={pallet[status] === newValue}
                                closeModal={closeModal}
                                isLoading={isLoading}
                                action={sendEditItem}
                                actionTitle='Edit'
                            />

                        </div>
                }
            </div>



        </div>
    )
}
