import qcareApi from "./qcareApi";
import { ImageTemp } from "../interfaces/intakes.reports"
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface Base {
    reportId: string
    palletId: string
}

export interface PalletImages extends Base {
    images: ImageTemp[]
}

const uploadPalletImages = async (uploadData: PalletImages) => {

    if(uploadData.images.length === 0) return { reportId: uploadData.reportId}
    const formData = new FormData();

    for (const img of uploadData.images) {
        formData.append('uploady', img.file)
    }

    formData.append('reportId', uploadData.reportId)
    formData.append('palletId', uploadData.palletId)

    const { data } = await qcareApi.post('/report/add-files', formData)
    return data
};


//--------------- HOOKS ------------------//


export const useImageToPallet = () => {
    const queryClient = useQueryClient()
    return useMutation(uploadPalletImages,
        { onSuccess: async (data) => {
            await queryClient.invalidateQueries(['report', data.reportId])
        } }
    )
}