import { useState, useEffect } from 'react'

import { useSelector } from 'react-redux';
import { Spinner } from './Spinner';
import { ModalBtn } from '../modals/ModalBtn';

export const ModalTeam = ({ closeModal, assignToAll }) => {

    const { teams } = useSelector(state => state.auth);
    const [selectedTeam, setSelectedTeam] = useState(null)

    useEffect(() => {
        if (teams.length > 0) {
            setSelectedTeam(teams[0])
        }
    }, [teams])


    const handleTeam = (e) => {
        const choosenTeam = teams.find(team => team._id === e.target.value)
        setSelectedTeam(choosenTeam)
    };

    if (!teams || !selectedTeam) return <Spinner />

    return (
        <div className="modal">

            <div className="modal__card">
                <div className="modal__content">

                    <div className='mb-1'>
                        <p className='text-left mb-02 bold'>Team</p>
                        <div className='select-input'>
                            <select onChange={handleTeam}>
                                {
                                    teams.map(team => (
                                        <option key={team._id} value={team._id}>{team.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <p className='text-mute font-small'>*This action will be applied to all intakes</p>

                    <ModalBtn
                        action={() => assignToAll( selectedTeam._id )}
                        closeModal={() => closeModal()}
                        actionTitle='Assign'
                    />
                </div>
            </div>



        </div>
    )
}