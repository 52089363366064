// import { useEffect, useState } from "react"
import { tempFileLS } from "../helpers/photoToUpload";
import { ImageTemp } from "../interfaces/intakes.reports"
import { DetailName } from "../interfaces/interfaces"

interface LocalStorageProps {
    detailName: DetailName
    images: any[]
}

const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

const base64ToFile = (base64: any, fileName: string): File => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
};

export const useLocalStorageImages = () => {

    const getLSImages = async(pid: string) => {
        const LSObject = localStorage.getItem(pid);
        if (LSObject) {
            const { detailName, images } = JSON.parse(LSObject) as LocalStorageProps;
            const baseToFile = images.map( image => base64ToFile(image, 'temp.jpg'));
            const recoveredImages = baseToFile.map( file => tempFileLS((file)));

            const imagesTemp = await Promise.all(recoveredImages);

            return {
                detailName,
                images: imagesTemp
            };
        }
        return undefined;
    };

    const setLSImages = async (pid: string, detailName: string, images: ImageTemp[]) => {
        const promiseFiles = images.map(async image => await fileToBase64(image.file));

        const sanitizedImages = await Promise.all(promiseFiles);

        const LSObject = { detailName, images: sanitizedImages };
        localStorage.setItem(pid, JSON.stringify(LSObject));
    };

    const cleanLocalStorage = (pid: string, detailName: string) => {

        const LSObject = localStorage.getItem(pid);
        if (LSObject) {
            const { detailName:detailNameLS } = JSON.parse(LSObject) as LocalStorageProps;
            detailNameLS === detailName && localStorage.removeItem(pid);
        }
    };

    return {
        getLSImages,
        setLSImages,
        cleanLocalStorage
    };
}
