import React, { useState } from 'react'
import { ModalTeamMember } from '../ui/ModalTeamMember'

export const TeamCard = ({ team, isAdmin }) => {

    const [modalTeam, setModalTeam] = useState(false)
    const [member, setMember] = useState(null)

    return (
        <div>
            {
                modalTeam &&
                <ModalTeamMember
                setCloseModal={setModalTeam}
                member={member}
                team={team}
                />
            }
            <div className='team-header mb-02'>
                <p className='bold'>{team.name}</p>
            </div>

            <div className='mb-1'>
                {
                    team.members.map(member => (
                        <button
                        disabled={!isAdmin}
                        key={member.uid}
                        className='team-list'
                        onClick={ () => {
                            setMember(member)
                            setModalTeam(true)
                        }}
                        >
                            <div className={`team-list__rol ${member.rol === "ADMIN" ? 'team-list__admin' : 'team-list__user'}` }>{ member.rol }</div>
                            <div className='team-list__text'>
                                <p className='font-small bold'>{ member.name } { member.lastname }</p>
                                <p className='font-small text-mute'>{ member.email }</p>
                            </div>

                            <div className={`team-list__active ${member.active  ? 'team-list__active-on' : 'team-list__active-off'}` }>{ member.active ? "ACTIVE" : "INACTIVE"}</div>
                        </button>
                    ))
                }
            </div>

            {/* <button
            className='edit__addcontact mt-1'
            onClick={() => setOpenUserModal(true)}
        >
            <img src='./assets/img/mas-icon.svg' alt='plus-icon' />
        </button> */}
        </div>
    )
}
