import { ScoreNum } from "../interfaces/interfaces";

export const scoreComments = ( score:ScoreNum ):string => {
    if(score === "1") return "Bad Quality. Not suitable for supermarket. Rejection recommended: This term indicates that the evaluated fruit does not meet the minimum quality standards and is not suitable for sale in supermarkets or other distribution channels. Rejecting this fruit and removing it from the marketing process is recommended. It's essential to thoroughly document the defects and reasons behind this evaluation, as well as to identify possible underlying causes to avoid the repetition of similar issues in the future."

    if(score === "2") return "Insufficient. Sorting required. Predicted claim: This description indicates that the quality of the evaluated fruit does not meet the required minimum standards, and therefore, additional selection is needed to eliminate significant defects. It's important to anticipate the possibility of filing claims due to the deficiencies observed in the quality of the fruit. Thoroughly documenting the reasons supporting this evaluation and taking corrective measures to improve production and handling processes in the future is recommended."

    if(score === "3") return "Fair Quality. Claim prediction, possible sorting: This term indicates that the evaluated fruit has a quality considered fair. The possibility of claims is predicted, and additional sorting may be required. It is essential to anticipate these circumstances and properly document the processes to avoid later issues."
    if(score === "4") return "Acceptable Quality. Reselection. Possible claim: This term indicates that the evaluated fruit meets the minimum established quality criteria but may require additional selection to eliminate minor defects or imperfections. Reselection is suggested to ensure that only the highest quality fruit is sent to the market. Additionally, it's important to note that there is a possibility of filing claims if discrepancies with respect to the agreed-upon standards are identified."

    if(score === "5") return "Good Quality: This term indicates that the evaluated fruit has reached an exceptional standard of quality. It's important to highlight that the fruit in this category meets or exceeds the established quality criteria, making it highly desirable for consumption or sale in the market."

    return ""
}