import { useEffect, useState } from "react";

export const useGP = (email="") => {

    const [isGP, setIsGP] = useState(false);

    useEffect(() => {
      setIsGP( email.includes("@growerspackers.com") )
    }, [email])
    
  return { isGP };
}
