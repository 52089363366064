import { ChangeEvent, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';

import { CardLifeTest } from '../CardLifeTest'
import { Charging } from '../ui/Charging'
import { Pagination } from '../ui/Pagination'
import { SelectTeam } from '../ui/SelectTeam';
import { optionValue } from '../../helpers/eliminarEs';
import { useFilterLife } from '../../api/useFilterLifeTest';
import { useLocation } from 'react-router-dom';
import { FilterWindow } from '../ui/FilterWindow';
import queryString from 'query-string';

export const LifeTestSearch = () => {

  const {search} = useLocation()
  const query = queryString.parse(search)

  const [page, setPage] = useState(1)
  const [toggle, setToggle] = useState(0)

  const { data, isLoading, isFetching } = useFilterLife(page, query)

  // -------------------------------- Filter --------------------------------

  const handleToggle = (e:ChangeEvent<HTMLSelectElement>) => {
    setToggle(optionValue(e.target.value))
    setPage(1)
  };

  if (isLoading || isFetching) return <Charging />

  return (
    <div className="content">

      <main className="container lifetest">
        <div className='mb-2 flex-space-between'>
          <h2 className="main-title">Shelf Life Test</h2>

          <FilterWindow isSearchPage/>
        </div>

        <SelectTeam
          toggle={toggle}
          handleToggle={handleToggle}
          title='All Results'
        />

        {
          data && data.allLifeTest.length === 0
            ? <p className="text-center">No Life Tests</p>
            :
            <div>
              <div className="lifetest__list">
                <div className="life-item py-02 mb-05 bg-grey border-round">
                  <p className='life-status font-small bold pl-05'>Status</p>
                  <p className='life-title ml-1 font-small bold'>Report Info</p>
                  <p className='life-days font-small bold'>Days</p>
                </div>
                {
                  data?.allLifeTest.map( life => (
                    <CardLifeTest
                      key={uuidv4()}
                      life={life}
                    />
                  ))
                }
              </div>

              {
                data?.totalPages && data?.totalPages > 1 &&
                <Pagination
                  totalPages={data.totalPages}
                  page={page}
                  setPage={setPage}
                />
              }
            </div>

        }
      </main>
    </div>

  )
}
