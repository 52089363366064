import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react"
import { useTypedSelector } from "../../../hooks/useTypedSelector"
import { MainData } from "../../../interfaces/intakes.reports"
import { cleanString } from "../../../helpers/stringHelpers"
import { IoCheckmarkCircle, IoInformationCircle, IoPersonAddOutline } from "react-icons/io5"
import { SupplierList } from "../../../interfaces/interfaces.auth"
import { ModalSelectSupplier } from "../../modals/ModalSelectSupplier"
import { InputCustom } from "./InputCustom"

interface Props {
    mainData: MainData
    setMainData: Dispatch<SetStateAction<MainData>>
    team: string | null
    supplierId: string | null
    setSupplier: Dispatch<SetStateAction<string | null>>
}

export const SupplierSelector = ({ mainData, setMainData, supplierId, setSupplier, team }: Props) => {

    const { suppliers, uid } = useTypedSelector(state => state.auth)

    const [supplierList, setSupplierList] = useState<SupplierList | undefined>(undefined)
    const [modalSelectSupplier, setModalSelectSupplier] = useState<boolean>(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMainData(mainData => ({ ...mainData, [e.target.name]: e.target.value }))
    }

    useEffect(() => {
        if (team) setSupplierList(suppliers.find(supplier => supplier.id === team))
        else setSupplierList(suppliers.find(supplier => supplier.id === uid))

    }, [team, suppliers, uid])

    useEffect(() => {

        const matchSupplier = supplierList?.suppliers.find(supplier => cleanString(supplier.name) === cleanString(mainData.supplier || ""))

        if (matchSupplier) setSupplier(matchSupplier._id)
        else setSupplier(null)

    }, [supplierList, mainData.supplier, setSupplier])

    return (
        <>

            {
                (modalSelectSupplier && supplierList) &&
                <ModalSelectSupplier
                    closeModal={() => setModalSelectSupplier(false)}
                    supplierList={supplierList}

                    setMainData={setMainData}
                    supplierId={supplierId}
                    setSupplier={setSupplier}
                />

            }
            {
                suppliers.length > 0 &&
                <div className="grid mb-05">
                    <label className="span-6 input-label">Supplier</label>

                    <div className="span-6 select-input flex">
                        <InputCustom
                            value={mainData?.supplier || ""}
                            onChange={(e) => handleChange(e)}
                            iconAfter={
                                supplierId
                                ? <IoCheckmarkCircle size={20} color="#1a9141"/>
                                : <IoInformationCircle size={20} color="#e6a23c"/>
                            }
                        />
                        {supplierList &&
                            <button
                                onClick={() => setModalSelectSupplier(true)}
                                className="d-input-btn ml-02"
                            >
                                <IoPersonAddOutline />
                            </button>
                        }
                    </div>
                </div>
            }
        </>


    )
}