import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import useMediaQuery from '../../hooks/useMediaQuery';
import { PallGrowDefects } from '../../interfaces/intakes.reports';
import { GradeNum, GradeResult } from '../../interfaces/interfaces';

ChartJS.register(ArcElement, Tooltip, Legend);

interface PallgrowShort{
    totalRest: number
    totalMajor: number
    totalMinor: number
}

interface Props {
    pallgrow: PallGrowDefects | PallgrowShort
    nameAppreciation?: GradeNum
}

export const ChartPieDefects = ({ pallgrow, nameAppreciation = "0" }: Props) => {

    const matches = useMediaQuery("(min-width: 768px)");
    const nameRest = GradeResult[`grade${nameAppreciation}`]
    const chartdata = {
        labels: [nameRest, "Major defects", "Minor defects"] || "No Data",
        datasets: [
            {
                label: "Markets Monitored",
                data: [pallgrow.totalRest, pallgrow.totalMajor, pallgrow.totalMinor] || 0,
                backgroundColor: ["#39b54a", "#FF4D4D", "#f9b233"],
                borderColor: ["#39b54a", "#FF4D4D", "#f9b233"],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div>
            <Doughnut
                options={{
                    plugins: {
                        legend: { position: matches ? 'left' : 'top' },
                    },

                }}
                data={chartdata}
            />
        </div>
    )
}
