import { ComboboxOption } from "../interfaces/interfaces";
import { Contact, Supplier } from "../interfaces/interfaces.auth";


export const sortContact = (contacts:Contact[]) => {

    function compare(a:any, b:any) {
        if (a.ref < b.ref) {
            return -1;
        }
        if (a.ref > b.ref) {
            return 1;
        }
        return 0;
    }

    return contacts.sort(compare);
};



export const sortSuppliersByName = (suppliers: Supplier[]): Supplier[] => {
    return suppliers.sort((a, b) => (a.name.trim().toLocaleLowerCase()).localeCompare(b.name.trim().toLocaleLowerCase()));
  };

  export const sortOptionsByLabel = (options: ComboboxOption[]): ComboboxOption[] => {
    return options.sort((a, b) => (a.label.trim().toLocaleLowerCase()).localeCompare(b.label.trim().toLocaleLowerCase()));    
  };

// ------------------------------- Delete contact -------------------------------

export const combinedContacts = (contacts:any, teamsContact:any) => {
    const contactEmails = contacts.map((contact:any) => contact.email);
    const filteredTeamsContact = teamsContact.filter(
        (teamContact:any) => !contactEmails.includes(teamContact.email)
    );
    return contacts.concat(filteredTeamsContact);
};


export const filterContactsByEmail = (contacts:any, teamsContact:any) => {
    const contactEmails = contacts.map((contact:any) => contact.email);
    return teamsContact.filter((teamContact:any) => !contactEmails.includes(teamContact.email));
  };

