import { Accordeon } from '../../ui/Accordeon'

export const ReportPallet = ({ date, pallets, format, reportId, fruit, samples }) => {

    return (
        <div className="pallets-container">
            {
                pallets.map((pallet, i) => {

                    const labels = pallet.details.labels || []
                    const appareance = pallet.details.appareance || []
                    const pallgrow = pallet.details?.pallgrow || []
                    const prereport = pallet.prereport || null

                    const weight_format = Number((pallet.details?.pallgrow?.find(app => app.name === "weight_10"))?.valor) || 0

                    return (

                        <Accordeon
                            key={pallet.pid}
                            date={date}
                            pid={pallet.pid}
                            format={format}
                            labels={labels}
                            appareance={appareance}
                            pallgrow={pallgrow}
                            weight_format={weight_format}
                            i={i}
                            pallet={pallet}
                            reportId={reportId}
                            score={pallet.score}
                            fruit={fruit}
                            addGrower={pallet.addGrower}
                            prereport={prereport}
                            samples={samples}
                        />

                    )
                })
            }
        </div>
    )
}
