import { useMutation, useQuery } from "@tanstack/react-query";
import qcareApi from "./qcareApi";
import { AllLifeTestResponse } from "../interfaces/interface.lifeTest";

interface QueryParams{
    page: number;
    team?: string;
}

const getLifeTests = async ( page:number, team: string | 0 ) => {

    const params:QueryParams = { page }
    if (team) { params.team = team }

    const { data } = await qcareApi.get<AllLifeTestResponse>(`/life-test`, { params });
    return data
};

export const removeLifeTest = async ( id:string ) => {
    const { data } = await qcareApi.get(`/life-test/remove-life/${id}`);
    return data
}


// ------------------------- HOOKS -------------------------

export const useLifeTests = ( page = 1, team:string | 0 ) => {

    const data = useQuery(
        ['lifeTests', page, team],
        () => getLifeTests(page, team),
        { 
            keepPreviousData: true,
            staleTime: 1000 * 60 * 10
        }
    );

    return data
}


export const useRemoveLifeTest = () => {
    return useMutation( removeLifeTest )
}