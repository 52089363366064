import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import qcareApi from "./qcareApi";


const getAllPrereports = async (page = 1, team = 0) => {
    const params = { page }
    if (team) { params.team = team }

    const { data } = await qcareApi.get('/prereport', { params });
    return data
};


const removePrereport = async (id) => {
    const { data } = await qcareApi.get(`/prereport/delete/${id}`);
    return data
}


// ------------------------- HOOKS -------------------------


export const usePrereports = (page, team) => {
    const data = useQuery(
        ['prereports', page, team],
        () => getAllPrereports(page, team),
        { keepPreviousData: true }
    );

    return data
}


export const useRemovePrereport = () => {
    const queryClient = useQueryClient()
    return useMutation(removePrereport, {
        onSuccess: async () => { await queryClient.invalidateQueries(['prereports']) }
    })
}