import { InitialRepackPallets } from "../../interfaces/intakes.repack"
import { ImageType, InitialPallets } from "../../interfaces/intakes.reports"

interface Props {
    pallets: InitialPallets[] | InitialRepackPallets[]
}

export const CoverImage = ({ pallets }: Props) => {

    let coverImage: ImageType | undefined = pallets?.find(cover => cover.images.length > 0)?.images[0]

    if (!coverImage) {

        const palletsImages = pallets.map(pallet => {
            let images: ImageType[] = [...pallet.labels?.images || [], ...pallet.appearance?.images || []]
            if ('pallgrow' in pallet) images.push(...pallet.pallgrow?.images || [])

            return images
        }).flat()

        coverImage = palletsImages.find(cover => cover.imgURL_low || cover.imgURL)

    }

    return (
        <img src={coverImage ? coverImage.imgURL_low : "/assets/img/no-image.jpg"
        } alt="img-pallet" loading='lazy' style={{ width: "100%", height: "100%", objectFit: "cover" }} />
    )
}

