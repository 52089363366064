import { FormEvent } from 'react'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast';

import useForm from '../../hooks/useForm'
import { startLogin } from '../../store/asyncMethods/authMethods';

export const Login = () => {

    const dispatch = useDispatch();

    const { values, handleInputChange } = useForm({
        email: "",
        password: ""
    })

    const { email, password } = values

    const userLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        dispatch(startLogin(email, password, toast))
        // reset()
    }


    return (
        <div className="login-bg">
            <img src="../assets/img/qc-logo.png" className="logo" alt="logo-qc" />

            <div className="l-form">

                <form onSubmit={userLogin} className="form">
                    <h1 className="mb-2 text-center text-white"> Login </h1>
                    <div className="form__div mb-2">
                        <input
                            type="email"
                            name="email"
                            className="form__input"
                            placeholder="email"
                            value={email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form__div mb-2">
                        <input
                            type="password"
                            name="password"
                            className="form__input"
                            placeholder="password"
                            value={password}
                            onChange={handleInputChange}
                        />
                    </div>

                    <button
                        type="submit"
                        className="btn-primary outline-2"
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    )
}
