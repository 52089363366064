import { Navigate } from "react-router-dom";

interface Props{
    children: React.ReactNode 
    isAuthenticated: boolean
}

export const PublicRoutes = ({ children, isAuthenticated }:Props) => {

    return (isAuthenticated)
        ? <Navigate to='/' />
        : <>{children}</>

}