import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Navigate,
    Route,
    Routes,
} from "react-router-dom";
import { Login } from '../components/auth/Login';
import { PublicRoutes } from './PublicRoute';
import { PrivateRoutes } from './PrivateRoute';
import { startChecking } from '../store/asyncMethods/authMethods';
import { Reports } from '../components/pages/Reports';
import { User } from '../components/profile/User';
import { NewIntake } from '../components/pages/NewIntake';
import { Intakes } from '../components/pages/Intakes';
import { UpdateUser } from '../components/profile/UpdateUser';
import { History } from '../components/pages/History';
import { ViewPdf } from '../components/pages/ViewPdf';
import { SharePdf } from '../components/pages/SharePdf';
import { LifeTestPage } from '../components/pages/LifeTestPage';
import { SingleLifeTest } from '../components/pages/SingleLifeTest';
import { PreReports } from '../components/pages/PreReports';
import { SinglePreReport } from '../components/pages/SinglePreReport';
import { SearchPage } from '../components/pages/SearchPage';
import { DownloadPdf } from '../components/pages/DownloadPdf';
import { DownloadZip } from '../components/pages/DownloadZip';
import { ErrorFound } from '../components/ui/ErrorFound';
import { LifeTestSearch } from '../components/pages/LifeTestSearch';
import { New } from '../components/pages/New';
import { NewPage } from '../components/pages/NewPage';
import { CreateRepack } from '../components/pages/CreateRepack';
import { Spinner } from '../components/ui/Spinner';
import { Report } from '../components/pages/Report';
import { CreatePageSteps } from '../components/pages/CreatePageSteps';
import { Intake } from '../components/pages/Intake';
import { Repacks } from '../components/pages/Repacks';
import { SuppliersPage } from '../components/pages/SuppliersPage';
// import { SharePdfImages } from '../components/pages/SharePdfImages';

export const AppRoutes = () => {

    const dispatch = useDispatch();
    const { uid, checking } = useSelector((state:any) => state.auth);

    useEffect(() => {
        dispatch(startChecking())
    }, [dispatch])

    if (checking) {
        return <Spinner />
    }

    return (

       <>
            <Routes>
                <Route path="/login" element={
                    <PublicRoutes isAuthenticated={!!uid}>
                        <Login />
                    </PublicRoutes>
                } />

                <Route path="/" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <Reports />
                    </PrivateRoutes>
                } />

                <Route path="/report/:id" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <Report />
                    </PrivateRoutes>
                } />

                <Route path="/search/:id" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <SearchPage />
                    </PrivateRoutes>
                } />


                <Route path="/new" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <New />
                    </PrivateRoutes>
                } />

                <Route path="/new/:id" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <NewPage />
                    </PrivateRoutes>
                } />

                {/* <Route path="/create/:path" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <CreateRepack />
                    </PrivateRoutes>
                } /> */}

                <Route path="/create-report/:id" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <CreatePageSteps />
                    </PrivateRoutes>
                } />

                <Route path="/create-repack/:id" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <CreateRepack />
                    </PrivateRoutes>
                } />

                <Route path="/intake/:id" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <Intake />
                    </PrivateRoutes>
                } />

                <Route path="/prereports" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <PreReports />
                    </PrivateRoutes>
                } />

                <Route path="/repacks" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <Repacks />
                    </PrivateRoutes>
                } />

                <Route path="/prereport/:id" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <SinglePreReport />
                    </PrivateRoutes>
                } />

                <Route path="/newintake" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <NewIntake />
                    </PrivateRoutes>
                } />

                <Route path="/intakes" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <Intakes />
                    </PrivateRoutes>
                } />

                <Route path="/user" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <User />
                    </PrivateRoutes>
                } />

                <Route path="/suppliers" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <SuppliersPage />
                    </PrivateRoutes>
                } />

                <Route path="/life" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <LifeTestPage />
                    </PrivateRoutes>
                } />

                <Route path="/life-search" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <LifeTestSearch />
                    </PrivateRoutes>
                } />

                <Route path="/life-test/:id" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <SingleLifeTest />
                    </PrivateRoutes>
                } />

                <Route path="/edit-user" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <UpdateUser />
                    </PrivateRoutes>
                } />

                <Route path="/history" element={
                    <PrivateRoutes isAuthenticated={!!uid}>
                        <History />
                    </PrivateRoutes>
                } />

                {/* ---------------- */}

                <Route path="/404" element={<ErrorFound />} />
                <Route path="/view-pdf/:id" element={<ViewPdf />} />
                <Route path="/share-report/:id" element={<SharePdf />} />
                <Route path="/share-report-qc/:id"
                    // element={<SharePdfImages />}
                    element={<DownloadPdf />}
                />
                <Route path="/zipped-images/:id" element={<DownloadZip />} />
                <Route
                    path="*"
                    element={<Navigate to="/404" replace />}
                />
            </Routes>
        </>


    )
}
