import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import qcareApi from '../../api/qcareApi'
import { sleep } from '../../helpers/sleep'
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";

export const DownloadZip = () => {


    const { id } = useParams()

    const [isZipped, setIsZipped] = useState(false)

    useEffect(() => {

        async function fetchData() {

            await qcareApi.get(`/pdf/get-pdf/${id}`)

                .then(async (res) => {

                    const id = res.data.pdfData.reportId

                    const { data } = await qcareApi.get(`/report/share-report/${id}`)

                    const finalData = data.singleReport
                    const pdf = res.data.pdfData.pdfImages
                    const palletes = data.singleReport.pallets

                    // ----------------- MIX ----------------- //

                    for (const palo of palletes) {

                        const pido = palo.pid
                        const pdff = pdf.find(g => g.pid === pido)

                        palo.images = pdff.images
                    }

                    finalData.pallets = palletes

                    // ----------------- MIX ----------------- //

                    let zip = new JSZip();
                    let count = 0;
                    let zipFilename = `${finalData.mainData.pallet_ref}.zip`;
                    let urls = [];

                    for (const pallet of finalData.pallets) {
                        for (const image of pallet.images) {
                            urls.push(image.imgURL)
                        }
                    }

                    urls.forEach(function (url) {
                        let name = url.split("/")
                        let filename = name[name.length - 1];

                        JSZipUtils.getBinaryContent(url, function (err, data) {
                            if (err) {
                                alert("Something went wrong")
                                throw err; // or handle the error
                            }
                            zip.file(filename, data, { binary: true });
                            count++;
                            if (count === urls.length) {
                                zip.generateAsync({ type: "blob" }).then(function (content) {
                                    saveAs(content, zipFilename);
                                }).then(() => setIsZipped(true));
                            }
                        });
                    });




                })
        }
        fetchData()

    }, [id])

    useEffect(() => {

        const close = async () => {
            if (isZipped) {
                await sleep(1)
                window.close();
            }
        }
        close()

    }, [isZipped])


    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh" }}>
            {
                !isZipped
                    ?
                    <p>Creating Zip...</p>
                    :
                    <p>Done</p>
            }
        </div>
    )
}
