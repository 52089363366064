import { MainData } from "../interfaces/intakes.reports";


export const subjectString = (mainData: MainData, date: Date) => {
    let newSubject = "Quality report"

    if (mainData.pallet_ref && mainData.pallet_ref.trim().length>0) {
        newSubject += ` ${mainData.pallet_ref.trim()}`
    }
    if (mainData.format_gr && mainData.format_gr.trim().length>0) {
        // newSubject += ` ${data.mainData.format.split("*")[1] || ""}`
        newSubject += ` ${mainData.format_gr.trim() || ""}g`
    }
    if (date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        // newSubject += ` ${[day, month, year].join('-')}`
        newSubject += ` ${day}${month}${year}`
    }

    return newSubject
};