import { Create, Fruit } from "../interfaces/interfaces"

export const createData:Create[] = [ "intake", "repack", "report" ]
export const createSteps:Create[] = [ "intake", "repack", "report"]
export const fruitsData:Fruit[] = [
    "blueberries",
    "raspberries",
    "blackberries",
    "strawberries",
    "pears",
    "figs",
    "kiwiberries",
    "apples",
    "cherries",
    "red currants",
    "other"
]