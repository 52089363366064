import ButtonSubmit from '../ui/ButtonSubmit'
import { useNavigate } from 'react-router-dom';
import { MainData } from '../../interfaces/intakes.reports';
import toast from 'react-hot-toast';
import { Fruit } from '../../interfaces/interfaces';
import { useFetchInitialRepack } from '../../api/useInitialReport';
import { palletRepack } from '../../data/pallet-repack';
import { InitialRepack, InitialRepackPallets } from '../../interfaces/intakes.repack';
import { useState } from 'react';

interface Props {
    mainData: MainData
    team: string | null
    fruit: Fruit | null
    preRepack: MainData
    postRepack: MainData
}

const CreateButtonRepack = ({ mainData, team, fruit, preRepack, postRepack }: Props) => {

    const navigate = useNavigate();
    const { mutate, isLoading } = useFetchInitialRepack()

    const [supplier, setSupplier] = useState<string | null>(null)


    const saveRepack = () => {

        if (!mainData?.pallet_ref) return toast.error("Please add a pallet reference")

        let fetchPallets: InitialRepackPallets[] = []

        if (+mainData?.total_pallets >= 1 && +mainData?.total_pallets <= 10) {
            for (let index = 0; index < Number(mainData?.total_pallets); index++) {
                fetchPallets.push(palletRepack(
                    fruit as Fruit,
                    index + 1
                ))
            }
        } else {
            fetchPallets.push(palletRepack(
                fruit as Fruit,
                1
            ))
        }

        const fetchData: InitialRepack = {
            mainData,
            fruit: fruit as Fruit,
            team,
            supplier,
            pallets: fetchPallets,
            preRepack,
            postRepack
        }

        mutate(fetchData, {
            onSuccess: async (data) => {
                data.repackId
                    ? navigate(`/create/create=repack&id=${data.repackId}`)
                    : navigate('/')
            },
            onError: () => toast.error("Something went wrong, please try again later")
        })
    };

    return (
        <>
            <ButtonSubmit
                center
                title='Start Repack'
                action={saveRepack}
                color="green"
                isLoading={isLoading}
            />
        </>
    )
}

export default CreateButtonRepack