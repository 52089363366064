import { useState } from 'react'
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Charging } from '../ui/Charging'
import { ReportPallet } from './ReportData/ReportPallet'
import { ModalSendPDF } from '../ui/ModalSendPDF';
import { isMobile } from 'react-device-detect';
import { BatchInfo } from '../ui/BatchInfo';
import { teamInCharge, teamName } from '../../helpers/teamFilter';
import { useSelector } from 'react-redux';
import { useReport } from '../../api/useReport';
import Badge from '../ui/Badge'
import { MainData } from './Inputs/MainData'
import { LifeButton } from '../ui/LifeButton';
import { Comments } from '../ui/Comments';
import { ReportPalletNew } from './ReportData/ReportPalletNew';
import { useIsNewReport } from '../../hooks/useIsNewReport';
import { IoMailOutline } from 'react-icons/io5';

export const Report = () => {

    const navigate = useNavigate()

    const { id } = useParams<string>()
    const { data, isLoading, isError } = useReport(id!)

    const { teamsOwner, teamsUser } = useSelector((state: any) => state.auth)
    const [openPDFModal, setOpenPDFModal] = useState(false)

    const isNewReport = useIsNewReport(data?.date)

    if (isError) navigate('/')

    return (
        <div className="content">
            {
                isLoading || !data
                    ? <Charging />
                    :
                    <main className="container">

                        {/* {
                            openPDFModal &&
                            <ModalSendPDF
                                setOpenSendPDF={setOpenPDFModal}
                                currentId={id}
                                supplier={data?.mainData?.supplier || ""}
                                team={data.team || null}
                            />
                        } */}
                        {
                            openPDFModal &&
                            <ModalSendPDF
                                mainData={data.mainData}
                                pallets={data.pallets}
                                closeModal={() => setOpenPDFModal(false)}
                                team={data.team || null}
                                supplier={data.supplier || null}
                                date={data.date}
                                reportId={id!}
                            />
                        }

                        {
                            data &&
                            <>
                                <div>

                                    <div className="report">

                                        <div className="mb-2">
                                            <div className='flex-space-between'>
                                                <h2 className='mb-1 font-large flex-1'>{data.mainData.pallet_ref}</h2>
                                                <LifeButton data={data} />
                                            </div>
                                            {
                                                data?.team &&
                                                <div className='flex mb-1'>
                                                    <Badge icon="team-done" mr>
                                                        {teamName([...teamsOwner, ...teamsUser], data.team)}
                                                    </Badge>

                                                    {
                                                        teamInCharge([...teamsOwner, ...teamsUser], data.team, data.user) &&
                                                        <Badge color='grey'>
                                                            {teamInCharge([...teamsOwner, ...teamsUser], data.team, data.user) || "--"}
                                                        </Badge>
                                                    }
                                                </div>
                                            }
                                            {(data.date) &&
                                                <small className="text-muted">{dayjs(data.date).format('DD/MM/YYYY')} | {dayjs(data.date).format('HH:mm')}</small>
                                            }
                                        </div>
                                    </div>

                                    <BatchInfo reportId={id!} mainData={data?.mainData} />

                                    <div className='mb-2'>
                                        <MainData mainData={data?.mainData} isList={true} />
                                    </div>

                                    {
                                        data.pallets.length > 0

                                            ?
                                            isNewReport
                                                //new Date(data.date).getTime() > new Date(process.env.REACT_APP_NEW_REPORT as string).getTime()
                                                ?
                                                <ReportPalletNew
                                                    pallets={data.pallets}
                                                    reportId={id!}
                                                    fruit={data.fruit}
                                                    samples={data.mainData.samples}
                                                />
                                                : <ReportPallet
                                                    pallets={data.pallets}
                                                    format={Number(data.mainData.format_gr || data.formatGr || 0)}
                                                    reportId={id}
                                                    fruit={data.fruit}
                                                    samples={data.mainData.samples}
                                                    date={data.date || new Date()}
                                                />


                                            : <p>No Pallets</p>
                                    }

                                    {
                                        (data) &&
                                        <Comments comments={data.comments} reportId={data._id} />
                                    }
                                </div>

                                <div className={isMobile ? "flex-space-between" : "flex"}>

                                    <Link to={`/view-pdf/${id}`} target="_blank" className="btn-exports red-pdf mr-05">
                                        <div className="flex" >
                                            <img src="/assets/img/pdf-icon.svg" alt="pdf-data" style={{ height: "18px"}}/>
                                            <p>View PDF</p>
                                        </div>
                                    </Link>


                                    <button
                                        className="btn-exports share-blue ml-05"
                                        onClick={() => setOpenPDFModal(true)}
                                    >
                                        <div className="flex flex-gap" >
                                            <IoMailOutline color='white' size={21}/>
                                            <p>Share by Mail</p>
                                        </div>
                                    </button>

                                </div>
                            </>
                        }
                    </main>
            }
        </div>
    );
}
