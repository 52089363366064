import { useEffect } from 'react'
import { InitialPallets } from '../../interfaces/intakes.reports'
import EmailFormBtn from './EmailFormBtn'
import { ImagesPDF } from './ImagesPDF'
import { PDFImages } from './ModalSendPDF'
import { Charging } from './Charging'

interface Props {
    pallets: InitialPallets[]
    images: PDFImages[]
    setImages: React.Dispatch<React.SetStateAction<PDFImages[]>>
    createLink: () => void
    closeModal: () => void
    setStep: React.Dispatch<React.SetStateAction<number>>
    isLoading: boolean
}

export const StepThree = ({ pallets, images, setImages, createLink, setStep, closeModal, isLoading }: Props) => {

    return (
        <>
            <h4 className='mb-2'>Select the images</h4>

            {
                pallets.map((pallet, i) => (
                    <div key={pallet.pid}>
                        <p className="mb-02 font-normal color-primary bold">Pallet {i + 1}</p>
                        {
                            pallet.images.length > 0
                                ?
                                <div className="modal-images mb-2 mt-05">
                                    {
                                        pallet.images.map(img => (
                                            <ImagesPDF
                                                key={img.key}
                                                pid={pallet.pid}
                                                images={images}
                                                setImages={setImages}
                                                img={img}
                                            />
                                        ))
                                    }
                                </div>
                                : <p className='font-small mb-2'>No Images</p>
                        }
                    </div>
                ))
            }

            <EmailFormBtn
                isLoading={isLoading}
                actionPrevious={() => setStep(step => step - 1)}
                actionNext={createLink}
                closeModal={closeModal}
            />
        </>
    )
}
