import { useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Suppliers } from '../profile/Suppliers'

export const SuppliersPage = () => {

    const { suppliers } = useTypedSelector(state => state.auth);

    const [active, setActive] = useState<number>(0)

    return (
        <div className="content">
            <main className="container">
                <h2 className="main-title mb-2">Suppliers</h2>

                <div className="flex flex-gap border-b pb-1 mb-1 suppliers-btns">
                    {
                        suppliers.length > 0 &&
                        suppliers.map((supplier, index) => (
                            <button
                                key={supplier.id}
                                onClick={() => setActive(index)}
                                className={`supplier-btn ${active === index ? "active" : ""} flex flex-gap`}>
                                {supplier.name}
                                <div className={`supplier-count font-xsmall ${active === index && "active"}`}>{supplier.suppliers.length}</div>
                            </button>
                        ))
                    }
                </div>

                {
                    suppliers[active] && 
                    <Suppliers suppliers={suppliers[active]} />
                }
            </main>
        </div>
    )
}
