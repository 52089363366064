import { DetailObject, PallGrowDefects } from "../../interfaces/intakes.reports"

interface Props {
    pallgrow: PallGrowDefects
    activeModal: (tipe: string, name: string, detailName: string, label: string, valor: string | string[]) => void
}

interface DefectProps {
    defect: DetailObject
    isMajor: boolean
}

export const PallgrowPallet = ({ pallgrow, activeModal }: Props) => {

    const Defects = ({ defect, isMajor }: DefectProps) => {
        return (
            <div className="table accordeon-table">
                <p>{defect.label}</p>
                <div className="grid">
                    {/* <strong className="span-3">{defect.average}</strong> */}
                    {!isMajor && <div className="span-6"/>}
                    <p className={`span-6 defect ${isMajor ? "major" : "minor"}`}>{defect.percentage}%</p>
                </div>
                <button
                    className="accordeon-edit"
                    onClick={() => activeModal((defect?.tipe || defect?.type || "text"), defect.name, 'pallgrow', defect.label, defect.valor as string[])}
                >
                    <img src="/assets/img/edit-grey.svg" alt="edit-item" />
                </button>
            </div>
        )
    };

    return (
        <div className="grid-table mb-1">

            <div className="table accordeon-table">
                <p>Weight 10 Fruits</p>
                <div className="grid">
                    <strong className="span-6">{pallgrow.weight10 ? pallgrow.weight10 + 'g' : "--"}</strong>
                </div>
                <button
                    className="accordeon-edit"
                    onClick={() => activeModal("text", "weight_10", 'pallgrow', "Weight 10 Fruits", pallgrow.weight10 as string)}
                >
                    <img src="/assets/img/edit-grey.svg" alt="edit-item" />
                </button>
            </div>

            {
                [...pallgrow.minor, ...pallgrow.major].length > 0 &&
                <>
                    <div className="table accordeon-table mt-2">
                        <strong>Defects</strong>
                        <div className="grid">
                            <p className="span-6 defect major mt-05 font-small">Major</p>
                            <p className="span-6 defect minor mt-05 font-small">Minor</p>
                        </div>
                    </div>

                    {pallgrow.minor.map(defect => (<Defects key={defect.name} defect={defect} isMajor={false} />))}
                    {pallgrow.major.map(defect => (<Defects key={defect.name} defect={defect} isMajor />))}

                    <div className="table accordeon-table">
                        <strong>Total</strong>
                        <div className="grid">
                            <p className="span-6 defect major">{pallgrow.totalMajor}%</p>
                            <p className="span-6 defect minor">{pallgrow.totalMinor}%</p>
                        </div>
                    </div>
                    <div className="table accordeon-table">
                        <strong>Total Defects</strong>
                        <div className="grid">
                            <p className="span-8 bold flex-center">{pallgrow.totalDefects}%</p>
                        </div>
                    </div>
                </>
            }

        </div>
    )
}
