import { useEffect, useState } from "react"
import { ModalBtn } from "./ModalBtn"
import Combobox from "../pages/Inputs/Combobox"
import { SupplierList } from "../../interfaces/interfaces.auth"
import { ComboboxOption } from "../../interfaces/interfaces"
import { sortOptionsByLabel } from "../../helpers/sortContacts"
import { TempContact } from "../ui/ModalSendPDF"

interface Props {
    closeModal: () => void
    supplierList: SupplierList
    setMailTo: React.Dispatch<React.SetStateAction<TempContact[]>>
}

export const ModalEmailContacts = ({ closeModal, supplierList, setMailTo}: Props) => {

    const [selected, setSelected] = useState<ComboboxOption | null>(null)
    const [contacts, setContacts] = useState<TempContact[]>([])

    const handleCombobox = () => {
        setMailTo( mails => {
            const newMails:TempContact[] = contacts.filter( contact => !mails.map( mail => mail.id ).includes(contact.id)  ).filter( contact => contact.check )
            return [...mails, ...newMails]
        } )
        closeModal()
    };

    useEffect(() => {
        const contacts = supplierList.suppliers.find(supp => supp._id === selected?.value)?.contacts || []
        setContacts(contacts.map( contact => ({
            id: contact._id,
            check: true,
            name: contact.name,
            email: contact.email
        }) ) )
    }, [supplierList, selected])

    return (
        <div className="modal">

            <div className="modal__card no-overflow">

                <div>

                    <Combobox
                        options={sortOptionsByLabel(supplierList.suppliers.map(supp => ({ value: supp._id, label: supp.name })) || [])}
                        value={selected}
                        onChange={(option: ComboboxOption) => setSelected(option)}
                        defaultValue="Select a Supplier"
                    />

                    {
                        contacts.length > 0
                        ?
                        contacts.map(contact => (

                            <div key={contact.id} className="supplier-contact">
                                <input
                                    type="checkbox"
                                    checked={contact.check}
                                    onChange={() => setContacts(contacts.map(item => {
                                        if (item.id === contact.id) {
                                            return {
                                                ...item,
                                                check: !item.check
                                            }
                                        } else {
                                            return item
                                        }
                                    }))}
                                />

                                <div className="ml-1">
                                    <p className='font-small bold'>{contact.name}</p>
                                    <p className='font-small text-mute'>{contact.email}</p>
                                </div>
                            </div>


                        ))
                        : selected && <p className="font-small flex-center text-mute">This supplier has no contacts</p>
                    }

                    <ModalBtn
                        closeModal={closeModal}
                        action={handleCombobox}
                        actionTitle="Add Contacts"
                    />
                </div>
            </div>
        </div>
    )
}
