
const typeText = [
    "l_code",
    "t_code",
    "ean_code",
    "variety",

    "weight_10",
    "format_gr",
    "samples",
]

const typeNumber = [
    "temperature_c",
    "undersize",
]

const typeCheckbox = [
    "temperature_c",
    "box_label",
    "punnet_label",
    "name_weight",
    "insect",
    "pollution",
    "humidity",
    "juicing",
    "wet_punnets",
]

const typeSelect = [
    "pallet_type",
]

const typeArray = [
    "brix",
    "weight_check",
]

const typeRange = [
    "pallet_integrity",
    "fresh",
    "size",
    "coloration",
    "taste",
    "internal_coloration"
]

const typeUndefined = [
    "fruit_pressure",
]

export const typeData = (name: string) => {
    if (typeText.includes(name)) return "text"
    if (typeNumber.includes(name)) return "number"
    if (typeCheckbox.includes(name)) return "checkbox"
    if (typeSelect.includes(name)) return "select"
    if (typeArray.includes(name)) return "arrays"
    if (typeRange.includes(name)) return "range"
    if (typeUndefined.includes(name)) return undefined
    return "text"
}