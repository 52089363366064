import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import toast from 'react-hot-toast';

export const ZipDownload = ({ refName = "Report-images", pallets = [] }) => {

    const downloadZip = async () => {
        let zip = new JSZip();
        let count = 0;
        let zipFilename = `${refName}.zip`;
        let urls = [];

        for (const pallet of pallets) {
            for (const image of pallet.images) {
                urls.push(image.imgURL)
            }
        }

        urls.forEach(function (url) {
            let name = url.split("/")
            let filename = name[name.length - 1];

            JSZipUtils.getBinaryContent(url, function (err, data) {
                if (err) {
                    toast.error("Something went wrong")
                    throw err; // or handle the error
                }
                zip.file(filename, data, { binary: true });
                count++;
                if (count === urls.length) {
                    zip.generateAsync({ type: "blob" }).then(function (content) {
                        saveAs(content, zipFilename);
                    });
                }
            });
        });
    }


    return (
        <button
            className="share-card mb-1"
            onClick={downloadZip}
        >
            <img src='../assets/img/zip-icon.png' alt='pdf-icon' width={40} />
            <p>Zipped images</p>

            <div className="spinner-container mr-1">
                <img src='../assets/img/download-icon.png' alt='d-icon' width={30} style={{ opacity: .2 }} />
            </div>
        </button>
    )
}
